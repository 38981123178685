import * as Types from '../StaticTypes'

const initialState = {
  userToken: undefined,
  isAuthenticated: false,
  clearAutomaticRefreshRef: {
    current: null
  },
  accessToken: null,
  cognito: null,
  tokenExpiration: undefined,
  userName: ''
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case Types.SET_ACCESS_TOKEN:
      const { token } = action.payload
      if (!token) {
        return {
          ...state,
          userToken: token,
          isAuthenticated: false
        }
      }
      return {
        ...state,
        userToken: token,
        isAuthenticated: true
      }
    case Types.SET_REFRESH_TOKEN:
      const { refreshToken } = action.payload;
      return {
        ...state,
        refreshToken
      }
    case Types.SET_TOKEN_EXPIRATION:
      const { exp } = action.payload;
      return {
        ...state,
        tokenExpiration: exp
      }
    case Types.COGNITO_TOKEN:
      const { data } = action.payload
      return {
        ...state,
        cognito: data
      }
    case Types.SET_USER_NAME_TOKEN:
      const { userName } = action.payload
      return {
        ...state,
        userName
      }
    default:
      return state
  }
}