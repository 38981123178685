import React, { useEffect } from "react";
import { connect } from "react-redux";
import styles from "../../../Stylesheets/Modules/Admin/Students/help.module.scss";
import helpBanner from "../../../Assets/Images/help-banner.png";
import { useNavigate, useLocation } from "react-router-dom";
import HubspotForm from "react-hubspot-form";
import { logPageView } from "../../../Utils/analytics";

export const Help = (props) => {
  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] Help Page");
  }, [location]);

  return (
    <div className={styles["main-div"]}>
      <div className={styles["menu-display"]}>
        <div className={styles["help-form-wrapper"]}>
          <div className={styles["menu-header"]}>Need Help?</div>
          <div className={styles["menu-sub-header"]}>
            We will get back to you within 24 hours
          </div>
          <div className={styles["form-wrapper"]}>
            <div className={styles["help-banner"]}>
              <img src={helpBanner} alt="banner" className={styles["banner"]} />
            </div>
            <div className={styles["hubspot-form"]}>
              <HubspotForm
                portalId="20124119"
                formId="0ac9e534-437a-4858-adb5-5e3db9ff29bb"
                onSubmit={() => console.log("Submit!")}
                onReady={(form) => console.log("Form ready!")}
                loading={<div>Loading...</div>}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    themeInfo: user.themeInfo,
  };
};

export default connect(mapStateToProps, null)(Help);
