import React, { useEffect } from "react";
import styles from "../Stylesheets/Modules/notFound.module.scss";
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { logPageView } from "../Utils/analytics";

const NotFound = () => {
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    logPageView("Page Not Found");
  }, [location]);

  return (
    <center className={styles["main"]}>
      <h2>Oops! Page not found</h2>
      <Button
        data-event-name="Back to Home From Page Not Found"
        classes={{
          root: styles["button-root"],
        }}
        variant="contained"
        onClick={() => {
          navigate(`/`);
        }}
      >
        Back to Home
      </Button>
    </center>
  );
};

export default NotFound;
