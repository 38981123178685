import React from "react";
import { toast, Toaster, ToastBar } from "react-hot-toast";

export default function Toast() {
  return (
    <Toaster position="top-right">
      {(t) => (
        <ToastBar
          toast={t}
          style={{
            borderRadius: "10px",
            background: "#3B4046",
            color: "#fff",
          }}
        >
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== "loading" && (
                <span
                  onClick={() => toast.dismiss(t.id)}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fas fa-times" style={{ fontSize: 12 }}></i>
                </span>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}
