import React from 'react'
import styles from "../../../../Stylesheets/Modules/Admin/Students/studentProfile/studentProfile.module.scss"
import { Box } from "@mui/material";
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';

function StudentBadges(props) {
    const { themeInfo, badges } = props;
    const history = useNavigate();
    return (
        <Box className={styles["personal-details-box"]}>
            <Box
                className={`${styles["profile-header"]}`}
                style={{
                    background:
                        themeInfo[0]?.primarycolor !== "#0e1555"
                            ? themeInfo[0]?.primarycolor
                            : "",
                    fontFamily: themeInfo[0]?.fontfamily,
                }}
            >
                <Box className={styles['profile-header-name']}>Badges</Box>
            </Box>
            <div className={styles['certificate-wrapper']}>
                {badges?.length === 0 ? <div className={styles["no-skills-found"]}>
                    Click <div className={styles["redirection"]} onClick={() => history("/cohorts")}> here </div> to start earning !
                </div> : badges?.map((item, idx) => {
                    return (
                        <div key={idx} className={styles['badge-canvas']} >
                            <img src={item.path} alt="badge" className={styles['badge-image']} key={idx} />
                        </div>
                    )
                })}

            </div>
        </Box>
    )
}
const mapStateToProps = ({ user }) => ({
    themeInfo: user.themeInfo,
    badges: user.learnerProfileDetails.badges,
    studentId: user.userDetails.id
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentBadges)