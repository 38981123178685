import React, { useEffect, useState } from 'react'
import styles from "../../../../Stylesheets/Modules/Admin/Students/studentProfile/studentProfile.module.scss"
import { connect } from 'react-redux'
import { Box, Button } from "@mui/material";
import ProfileModal from './modals/ProfileModal';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { deletePrompt } from '../../../../Store/Actions/actions.user';
import Dialog from '@mui/material/Dialog';
function StudentAtWorkPrompts(props) {
    const { themeInfo, preview } = props;
    const [open, setOpen] = useState(false);
    const [prompts, setPrompts] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedPrompt, setSelectedPromp] = useState();
    const [moreItem, setMoreItem] = useState();
    const [editMode, setEditMode] = useState(false);
    const toggleShowMore = () => {
        setShowMore(!showMore);
    };
    useEffect(() => {
        if (props.allPrompts)
            setPrompts(props.allPrompts)
    }, [props.allPrompts])

    const handleDelete = (item) => {
        props.deletePrompt(item._id, item.prompt, item.promptType, item.promptReply, false, props.userUID, props.userId)
    }
    const onClose = () => {
        setDialogOpen(false);
    }
    const handleEdit = (item) => {
        setEditMode(true);
        setOpen(true)
        setSelectedPromp(item);
    }
    return (
        <Box className={styles["personal-details-box"]}>
            <ProfileModal
                modalName="promptModal"
                open={open}
                setOpen={setOpen}
                handleClose={() => setOpen(false)}
                promptFor="HOW_TO_WORK_WITH_ME"
                promptToBeEdited={selectedPrompt}
                editMode={editMode}
            />
            <Box
                className={`${styles["profile-header"]} ${styles["profile-header-first"]}`}
                style={{
                    background:
                        themeInfo[0]?.primarycolor !== "#0e1555"
                            ? themeInfo[0]?.primarycolor
                            : "",
                    fontFamily: themeInfo[0]?.fontfamily,
                }}
            >
                <Box className={styles['profile-header-name']}></Box>Me and Work</Box>
            {!preview && <Box className={styles['add-button-section']}>
                <div className={styles['count-indicator']}>{prompts?.filter(item => item.promptType === "HOW_TO_WORK_WITH_ME" && item.isActive).length + " / 4"}</div>
                {prompts?.filter(item => item.promptType === "HOW_TO_WORK_WITH_ME" && item.isActive).length < 4 && <Button
                    variant="outlined"
                    className={styles["personal-add-button"]}
                    onClick={() => {
                        setOpen(true)
                        setEditMode(false);
                    }}
                >Add Prompt</Button>}
            </Box>}
            <Box className={styles['prompts-section']}>
                {prompts?.filter(item => item.promptType === "HOW_TO_WORK_WITH_ME" && item.isActive)?.map((item, key) => {
                    return (
                        <Box key={key} className={styles['prompt']}>
                            <div className={styles['options-section']}>
                                <div className={styles['edit-section']}><EditIcon sx={{ cursor: "pointer", marginRight: "10px" }} onClick={() => handleEdit(item)} /></div>
                                <div className={styles['delete-section']}><CloseIcon sx={{ cursor: "pointer" }} onClick={() => handleDelete(item)} /></div>
                            </div>
                            <div className={styles['question']}>{item.prompt}</div>
                            <div className={`${!preview ? styles['answer'] : styles['answer-long']}`}>{item.promptReply}</div>
                            {item.promptReply.length > 120 && !preview && (
                                <div className={styles['view-more']} onClick={() => {
                                    toggleShowMore();
                                    setMoreItem(item);
                                    setDialogOpen(true);
                                }}>
                                    {!showMore && 'View More'}
                                </div>
                            )}
                            {item.skills && <div className={styles['skills-section']}>
                                <div className={styles['label']}>Skills:</div>
                                <div className={styles['skill-display-wrapper']}>
                                    {item.skills.map((skill, key) => {
                                        return (
                                            <Box className={styles['skill']} key={key}>{skill}</Box>
                                        )
                                    })}
                                </div>
                            </div>}
                        </Box>
                    )
                })}
            </Box>
            <Dialog open={dialogOpen} onClose={() => {
                onClose()
                toggleShowMore()
            }}
                PaperProps={{ style: { boxShadow: 'none', backgroundColor: 'transparent' } }}
            >
                <Box className={styles['prompt']}>
                    <div className={styles['question']}>{moreItem?.prompt}</div>
                    <div className={styles['answer-long']}>{moreItem?.promptReply}</div>
                    {moreItem?.skills && <div className={styles['skills-section']}>
                        <div className={styles['label']}>Skills:</div>
                        <div className={styles['skill-display-wrapper']}>
                            {moreItem?.skills.map((skill, key) => {
                                return (
                                    <Box className={styles['skill']} key={key}>{skill}</Box>
                                )
                            })}
                        </div>
                    </div>}
                </Box>
            </Dialog>
        </Box>
    )
}
const mapStateToProps = ({ user }) => ({
    userId: user.userDetails.id,
    userUID: user.userUid,
    themeInfo: user.themeInfo,
    allPrompts: user.learnerProfileDetails.prompts
});

const mapDispatchToProps = (dispatch) => ({
    deletePrompt: (id, prompt, promptType, promptReply, isActive, userUID, userId) =>
        dispatch(deletePrompt(id, prompt, promptType, promptReply, isActive, userUID, userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentAtWorkPrompts)