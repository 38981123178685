import React from "react";

import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";

import "../../Stylesheets/Components/GenericModal.scss";
import { StyledCloseIcon } from "../../Components/StyledComponents/Icons.style";

export default function GenericModal(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    open,
    togglePopUp,
    children,
    show,
    maxWidth,
    setUpdateMode,
    page,
    setSelectedSkills,
    bgClass,
  } = props;
  const handleClose = () => {
    togglePopUp(false);
    page && page("page1");
    setUpdateMode && setUpdateMode(false);
    setSelectedSkills && setSelectedSkills([]);
  };

  return (
    <Dialog
      classes={{
        root: "generic-modal-root",
        container: "generic-modal-container",
        paper: `generic-modal-paper ${bgClass}`,
      }}
      maxWidth={maxWidth ? maxWidth : "sm"}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      disableEnforceFocus
    >
      {show == "false" ? null : (
        <div className={"close-container"} onClick={handleClose}>
          <StyledCloseIcon />
        </div>
      )}
      {children}
    </Dialog>
  );
}
