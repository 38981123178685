import React, { useState, useEffect } from "react";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { connect } from "react-redux";
import Container from "@mui/material/Container";

import { Button, Divider } from "@mui/material";
import axios from "axios";
import fileDownload from "js-file-download";
import styles from "../../../Stylesheets/Modules/Admin/Students/questionnaireFeedBack.module.scss";
import { StyledArrowBackIcon } from "../../../Components/StyledComponents/Icons.style";
import {
  getViewOnboardingSubmissions,
  getCohortAdditionalDetails,
  getdtaDocumentLink,
} from "../../../Store/Actions/actions.cohort";
import { logPageView } from "../../../Utils/analytics";

export const ViewSubmissions = (props) => {
  const { cohortId, studentId, questionnaireId } = useParams();
  const history = useNavigate();
  const [questionsContent, setQuestionsContent] = useState([]);
  const [searchParams] = useSearchParams();
  const { getViewOnboardingSubmissions, onBoardingSubmissions } = props;
  const additionalDetails = props.additionalDetails;
  const dtaDocumentLink = props.onBoardingData.dtaDocumentLink;
  useEffect(() => {
    if (onBoardingSubmissions.length > 0) {
      setQuestionsContent(onBoardingSubmissions);
    }
  }, [onBoardingSubmissions]);

  useEffect(() => {
    props.getCohortAdditionalDetails(cohortId);
  }, []);

  useEffect(() => {
    props.getdtaDocumentLink(cohortId, studentId);
  }, []);
  useEffect(() => {
    getViewOnboardingSubmissions(studentId, cohortId, questionnaireId);
  }, []);
  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] View Submissions Page");
  }, [location]);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }

  return (
    <>
      <Container
        maxWidth="xl"
        classes={{
          root: styles["container-root"],
        }}
      >
        <div className={styles["student-questionnaire-feedback-menu-header"]}>
          <StyledArrowBackIcon
            sx={{ cursor: "pointer", marginRight: "0.625rem" }}
            onClick={() => {
              history(
                `/admin/cohort/${cohortId}/applications?cohort=${searchParams.get(
                  "cohort"
                )}`
              );
            }}
          />{" "}
          {questionsContent[0]?.title}
        </div>

        <div className={styles["questions-container"]}>
          {questionsContent[0]?.questions?.map((item, idx) => {
            return (
              <div key={idx}>
                <div className={styles["question"]}>
                  <div className={styles["span-question"]}>
                    <span className={styles["title-wrapper"]}>
                      {`Q${idx + 1}.`}{" "}
                    </span>{" "}
                    <div className={styles["question-title"]}>
                      {" "}
                      {`${item.question}`}{" "}
                    </div>
                  </div>
                  {item.answer ? (
                    <>
                      <div className={styles["answers"]}>
                        {item.answer instanceof Array
                          ? item.answer.join(", ")
                          : item.answer}
                      </div>
                    </>
                  ) : (
                    <div className={styles["no-submission"]}>
                      {"No submissions yet"}{" "}
                    </div>
                  )}
                </div>
                {questionsContent[0].questions.length !== idx + 1 && (
                  <Divider />
                )}
              </div>
            );
          })}
        </div>
        <div className={styles["additional-details"]}>
          {dtaDocumentLink !== "" && (
            <div className={styles["heading"]}>ADDITIONAL DETAILS</div>
          )}

          {dtaDocumentLink !== "" && dtaDocumentLink !== undefined && (
            <Button
              onClick={() => {
                handleDownload(dtaDocumentLink, "test-download.pdf");
              }}
              className={styles["download-button"]}
            >
              Download Document
            </Button>
          )}
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = ({ app, cohort }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    onBoardingSubmissions: cohort.onBoardingSubmissions,
    onBoardingData: cohort.onBoardingData,
    additionalDetails: cohort.additionalDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getViewOnboardingSubmissions: (studentId, cohortId, questionnaireId) =>
      dispatch(
        getViewOnboardingSubmissions(studentId, cohortId, questionnaireId)
      ),
    getCohortAdditionalDetails: (cohortId) =>
      dispatch(getCohortAdditionalDetails(cohortId)),

    getdtaDocumentLink: (cohortId, studentId) =>
      dispatch(getdtaDocumentLink(cohortId, studentId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSubmissions);
