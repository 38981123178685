import React, { useCallback, useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import styles from "../../../Stylesheets/Modules/Admin/Cohorts/cohorts.module.scss";
import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  FormHelperText,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import GenericModal from "../../../Reusable/Modals/GenericModal";
import {
  StyledClearIcon,
  StyledFilterAltIcon,
} from "../../../Components/StyledComponents/Icons.style";
import { isValidDate } from "../../../Helpers/utils";

const FilterOption = ({
  item,
  handlers,
  programOptions,
  order,
  startDate,
  endDate,
  published,
  programTitle,
}) => {
  switch (item?.key) {
    case "order":
      return (
        <div className={styles["sort-by-wrapper"]}>
          <div className={styles["form-label"]}>{item.label}</div>
          <FormControl
            sx={{ m: 1 }}
            classes={{ root: styles["form-control-root"] }}
          >
            <Select value={order} onChange={handlers.handleOrderByChange}>
              <MenuItem value={"asc"}>Asc</MenuItem>
              <MenuItem value={"desc"}>Desc</MenuItem>
              <MenuItem value={"both"}>None</MenuItem>
            </Select>
          </FormControl>
        </div>
      );
    case "programTitle":
      return (
        <div className={styles["program-sort-wrapper"]}>
          <div className={styles["form-label"]}>{item.label}</div>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={programOptions}
            classes={{ root: styles["form-control-root"] }}
            sx={{ marginLeft: "0.5rem" }}
            value={programTitle}
            renderInput={(params) => <TextField {...params} label="Programs" />}
            onChange={(event, newValue) =>
              handlers.onProgramTitleChange(newValue)
            }
          />
        </div>
      );
    // case "startDate":
    //   return (
    //     <div className={styles["date-wrapper"]}>
    //       <div className={styles["form-label"]}>{item.label}</div>
    //       <FormControl
    //         sx={{ m: 1 }}
    //         size="small"
    //         classes={{ root: styles["form-control-root"] }}
    //       >
    //         <LocalizationProvider dateAdapter={AdapterDateFns}>
    //           <DatePicker
    //             value={startDate}
    //             onChange={handlers.onStartDateChanged}
    //             renderInput={(params) => (
    //               <>
    //                 <TextField {...params} />
    //                 <FormHelperText style={{ color: "red", marginTop: "0px" }}>
    //                   {startDate && !isValidDate(startDate) && "Invalid date"}
    //                 </FormHelperText>
    //               </>
    //             )}
    //           />
    //         </LocalizationProvider>
    //       </FormControl>
    //     </div>
    //   );
    // case "endDate":
    // return (
    //   <div className={styles["date-wrapper"]}>
    //     <div className={styles["form-label"]}>{item.label}</div>
    //     <FormControl
    //       sx={{ m: 1 }}
    //       size="small"
    //       classes={{ root: styles["form-control-root"] }}
    //     >
    //       <LocalizationProvider dateAdapter={AdapterDateFns}>
    //         <DatePicker
    //           value={endDate}
    //           minDate={startDate}
    //           onChange={handlers.onEndDateChanged}
    //           renderInput={(params) => (
    //             <>
    //               <TextField {...params} />
    //               <FormHelperText style={{ color: "red", marginTop: "0px" }}>
    //                 {endDate && !isValidDate(endDate) && "Invalid date"}
    //               </FormHelperText>
    //             </>
    //           )}
    //         />
    //       </LocalizationProvider>
    //     </FormControl>
    //   </div>
    // );
    case "published":
      return (
        <div className={styles["published-wrapper"]}>
          <div className={styles["form-label"]}>{item.label}</div>
          <FormControl
            sx={{ m: 1 }}
            classes={{ root: styles["form-control-root"] }}
          >
            <Select value={published} onChange={handlers.handlePublishedChange}>
              <MenuItem value={"true"}>Yes</MenuItem>
              <MenuItem value={"false"}>No</MenuItem>
              <MenuItem value={"both"}>All</MenuItem>
            </Select>
          </FormControl>
        </div>
      );
    default:
      return null;
  }
};

export const CohortFilterOptions = ({
  onFilterChange,
  allPrograms,
  customization,
  themeInfo,
  search,
  setSearch,
  filters,
}) => {
  const [toggle, setToggle] = useState(false);
  const [order, setOrder] = useState("both");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [published, setPublished] = useState("both");
  const [programTitle, setProgramTitle] = useState("");
  const [programOptions, setProgramOptions] = useState([]);

  useEffect(() => {
    if (allPrograms) {
      const newProgramOptions = allPrograms.map(
        (program) => program.programName
      );
      setProgramOptions(newProgramOptions);
    }
  }, [allPrograms]);

  useEffect(() => {
    if (filters) {
      setOrder(filters.order === "" ? "both" : filters.order);
      setStartDate(filters.startDate);
      setEndDate(filters.endDate);
      setPublished(filters.published === "" ? "both" : filters.published);
      setProgramTitle(filters.programTitle);
    }
  }, []);

  const applyFilters = () => {
    const filterData = {
      order: order === "both" ? "" : order,
      startDate: startDate ? new Date(startDate).toISOString() : "",
      endDate: endDate ? new Date(endDate).toISOString() : "",
      published: published === "both" ? "" : published,
      programTitle,
    };
    onFilterChange(filterData);
    setToggle(false);
  };

  // Handlers
  const handleOrderByChange = useCallback((event) => {
    setOrder(event.target.value);
  }, []);

  const handlePublishedChange = useCallback((event) => {
    setPublished(event.target.value);
  }, []);

  const onStartDateChanged = useCallback((date) => {
    setStartDate(date);
  }, []);

  const onEndDateChanged = useCallback((date) => {
    setEndDate(date);
  }, []);

  const onProgramTitleChange = useCallback((program) => {
    setProgramTitle(program);
  }, []);

  // Reset filters
  const resetFilters = useCallback(() => {
    setOrder("both");
    setStartDate(null);
    setEndDate(null);
    setPublished("both");
    setProgramTitle("");
  }, []);

  const filterOptions = useMemo(
    () => [
      { key: "order", label: "Sort By" },
      { key: "programTitle", label: "Select Program" },
      { key: "startDate", label: "Start Date" },
      { key: "endDate", label: "End Date" },
      { key: "published", label: "Published" },
    ],
    []
  );

  const renderFilterModal = () => (
    <div className={styles["filter-model-main"]}>
      {filterOptions.map((item, key) => (
        <FilterOption
          key={key}
          item={item}
          handlers={{
            handleOrderByChange,
            handlePublishedChange,
            onStartDateChanged,
            onEndDateChanged,
            onProgramTitleChange,
          }}
          programOptions={programOptions}
          order={order}
          startDate={startDate}
          endDate={endDate}
          published={published}
          programTitle={programTitle}
        />
      ))}
      <div className={styles["button-wrapper"]}>
        <Button
          className={`${styles["btn"]} ${styles["cancel-btn"]}`}
          onClick={resetFilters}
        >
          Reset
        </Button>
        <Button
          className={`${styles["btn"]} ${styles["save-btn"]}`}
          onClick={applyFilters}
          disabled={
            (startDate && !isValidDate(startDate)) ||
            (endDate && !isValidDate(endDate))
          }
        >
          Apply
        </Button>
      </div>
    </div>
  );

  return (
    <div className={styles["filter-main-div"]}>
      <FormControl
        sx={{ m: 1 }}
        size="small"
        classes={{ root: styles["form-control-root"] }}
      >
        <TextField
          label={`Search ${customization.cohort_label || "Cohorts"}`}
          variant="outlined"
          style={{
            color: themeInfo[0]?.primarycolor,
            fontFamily: themeInfo[0]?.fontfamily,
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: search && (
              <StyledClearIcon
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setSearch("");
                }}
              />
            ),
          }}
        />
      </FormControl>

      <StyledFilterAltIcon
        onClick={() => setToggle(!toggle)}
        sx={{
          color: themeInfo[0]?.primarycolor,
          fontFamily: themeInfo[0]?.fontfamily,
          cursor: "pointer",
        }}
      />
      <GenericModal open={toggle} togglePopUp={setToggle}>
        {renderFilterModal()}
      </GenericModal>
    </div>
  );
};

const mapStateToProps = ({ program, cohort, app, user, config }) => ({
  cohorts: cohort.cohorts,
  appLoadingStatus: app.appLoadingStatus,
  themeInfo: user.themeInfo,
  customization: user.customization,
  config: config.configData,
  allPrograms: program.allPrograms,
});

const mapDispatchToProps = (dispatch) => ({
  getAllPrograms: (orgId) => dispatch(getAllPrograms(orgId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CohortFilterOptions);
