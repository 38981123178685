import React, { useState, useEffect } from "react";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import styles from "../../../Stylesheets/Modules/Admin/Modules/adminModuleOverview.module.scss";

import { connect } from "react-redux";
import { Container } from "@mui/material";
import { StyledArrowBackIcon } from "../../../Components/StyledComponents/Icons.style";
import {
  createNewModule,
  getAllModules,
  updataModuleStatus,
  updateModuleDetails,
  deleteModule,
  updateModules,
  getModuleOptions,
} from "../../../Store/Actions/actions.modules";
import { logPageView } from "../../../Utils/analytics";

const AdminModuleOverview = (props) => {
  const history = useNavigate();
  const { cohortId, moduleId } = useParams();
  const [searchParams] = useSearchParams();
  const [options, setModuleOptions] = useState([]);
  const { moduleOptions, customization } = props;
  const sectionCards = [
    {
      title: "Content",
      key: "content",
      desc: "Course content",
      image: require("../../../Assets/Images/content-bg.png"),
      active: true,
      routeName: `/admin/cohort/${cohortId}/module/${moduleId}/content?cohort=${searchParams.get(
        "cohort"
      )}&module=${searchParams.get("module")}`,
    },
    {
      title: "Questions",
      key: "questions",
      desc: `${customization.module_label || "Module"} questions`,
      image: require("../../../Assets/Images/questions-bg.png"),
      active: true,
      routeName: `/admin/cohort/${cohortId}/module/${moduleId}/questions?cohort=${searchParams.get(
        "cohort"
      )}&module=${searchParams.get("module")}`,
    },
    {
      title: "Sessions",
      key: "sessions",
      desc: "Live Session",
      image: require("../../../Assets/Images/sessions-bg.png"),
      active: true,
      routeName: `/admin/cohort/${cohortId}/module/${moduleId}/liveSessions?cohort=${searchParams.get(
        "cohort"
      )}&module=${searchParams.get("module")}`,
    },
  ];

  const shortSections = [
    {
      title: "Discuss with Peers",
      key: "discuss_with_peers",
      class: "discuss-with-peers",
      size: "small",
    },
    {
      title: "Additional Resources",
      key: "additional_resources",
      class: "additional-resources",
      size: "small",
    },
  ];
  const getAllModuleOptions = async (moduleId) => {
    await getModuleOptions(moduleId);
  };
  getAllModuleOptions(moduleId);
  useEffect(() => {
    setModuleOptions(moduleOptions);
  }, [moduleOptions]);

  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] Module Overview Page");
  }, [location]);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }

  return (
    <>
      <Container
        maxWidth="xl"
        classes={{
          root: styles["container-root"],
        }}
      >
        <div className={styles["create-module-header"]}>
          <StyledArrowBackIcon
            sx={{ cursor: "pointer", marginRight: "0.625rem" }}
            onClick={() =>
              history(
                `/admin/createModule/${cohortId}?cohort=${searchParams.get(
                  "cohort"
                )}`
              )
            }
            style={{ color: props.themeInfo[0]?.secondarycolor }}
          />
          <span style={{ color: props.themeInfo[0]?.secondarycolor }}>
            {`${searchParams.get("cohort") || "Cohort"} ${
              customization.module_label || "Module"
            } Overview`}
          </span>
        </div>
        <div className={styles["module-overview-container"]}>
          {sectionCards.map((section) => {
            return (
              <div
                key={section.key}
                className={`
                    ${styles["overview-section"]} 
                    ${section?.size ? styles[section?.size] : ""}
                    ${section?.active ? styles["active"] : ""}
                  `}
                onClick={() => section?.active && history(section.routeName)}
              >
                <div
                  className={styles["title"]}
                  style={{ color: props.themeInfo[0]?.secondarycolor }}
                >
                  {section.title}
                </div>
                <div className={styles["desc"]}>{section.desc}</div>
                <img
                  className={styles["bg-image"]}
                  src={section.image}
                  alt={section.title}
                />
              </div>
            );
          })}
          <div className={styles["overview-section-small"]}>
            {shortSections.map((section) => {
              return (
                <div
                  key={section.key}
                  className={`${styles["overview-section"]} ${
                    styles[section.class]
                  }`}
                >
                  <div className={styles["title"]}>{section.title}</div>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </>
  );
};
const mapStateToProps = ({ app, modules, user }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    allModules: modules.modules,
    themeInfo: user.themeInfo,
    moduleOptions: modules.moduleOptions,
    customization: user.customization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createNewModule: (modules) => dispatch(createNewModule(modules)),
    updateModuleDetails: (moduleId, moduleData) =>
      dispatch(updateModuleDetails(moduleId, moduleData)),
    getAllModules: (cohortId) => dispatch(getAllModules(cohortId)),
    updataModuleStatus: (moduleId, status) =>
      dispatch(updataModuleStatus(moduleId, status)),
    deleteModule: (moduleId, cohortId) =>
      dispatch(deleteModule(moduleId, cohortId)),
    updateModules: (moduleData, moduleId, cohortId) =>
      dispatch(updateModules(moduleData, moduleId, cohortId)),
    getModuleOptions: (moduleId) => dispatch(getModuleOptions(moduleId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminModuleOverview);
