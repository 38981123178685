import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logPageView } from "../Utils/analytics";

const SocialAuthCallback = () => {
  useEffect(() => {
    setTimeout(() => {
      window.opener.authenticateCallback();
      window.close();
    }, 1000);
  });

  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return (
    <div className="social-auth-text">
      Authenticated successfully! you are being redirected...
    </div>
  );
};

export default SocialAuthCallback;
