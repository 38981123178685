import React, { useState } from "react";
import styles from "../../../../Stylesheets/Modules/Admin/Students/studentProfile/studentProfile.module.scss";
import { Box, Button, Modal } from "@mui/material";
import { connect } from "react-redux";
import { Document, Page, pdfjs } from "react-pdf";
import { useNavigate } from "react-router-dom";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

// Configure pdfjs to use the worker from CDN
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function StudentCertificates(props) {
  const { themeInfo, certificates, studentId } = props;
  const history = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);

  const handleOpenModal = (pdfUrl, event) => {
    event.preventDefault();
    setSelectedPdf(pdfUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);

  return (
    <Box className={styles["personal-details-box"]}>
      <Box
        className={`${styles["profile-header"]}`}
        style={{
          background:
            themeInfo[0]?.primarycolor !== "#0e1555"
              ? themeInfo[0]?.primarycolor
              : "",
          fontFamily: themeInfo[0]?.fontfamily,
        }}
      >
        <Box className={styles["profile-header-name"]}>Certificates</Box>
      </Box>
      <div className={styles["certificate-wrapper"]}>
        {certificates?.length === 0 ? (
          <div className={styles["no-skills-found"]}>
            Click{" "}
            <div
              className={styles["redirection"]}
              onClick={() => history("/cohorts")}
            >
              {" "}
              here{" "}
            </div>{" "}
            to start earning !
          </div>
        ) : (
          certificates?.map((item, idx) => {
            const thumbnailUrl = `${process.env.REACT_APP_API_ENDPOINT}/public/download/certificate/${item.path}?studentId=${studentId}&thumbnail=true`;
            const pdfUrl = `${process.env.REACT_APP_API_ENDPOINT}/public/download/certificate/${item.path}?studentId=${studentId}`;
            return (
              <div
                key={idx}
                className={styles["certificate"]}
                onClick={(event) => handleOpenModal(pdfUrl, event)}
              >
                <img
                  src={thumbnailUrl}
                  alt="pdf thumbnail"
                  className={styles["pdf-image"]}
                />
                {item.name}
              </div>
            );
          })
        )}
      </div>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "60%", sm: "60%", md: "60%" },
            maxHeight: "80vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "9px",
            p: 4,
          }}
        >
          {selectedPdf && (
            <Document file={selectedPdf}>
              <Page
                pageNumber={1}
                width={window.innerWidth * 0.6}
                scale={0.8}
              />
            </Document>
          )}
          <Button href={selectedPdf} download>
            Download PDF
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}
const mapStateToProps = ({ user }) => ({
  themeInfo: user.themeInfo,
  certificates: user.learnerProfileDetails.certificates,
  studentId: user.userDetails.id,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentCertificates);
