import React, { useState, useEffect } from "react";
import { useParams, useSearchParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import CohortBackNav from "../Components/CohortBackNav";
import EditorWrapper from "../Components/EditorWrapper";
import { format } from "date-fns";
import { getSingleLiveSession } from "../Store/Actions/actions.cohort";

import styles from "../Stylesheets/Modules/liveSession.module.scss";
import { logPageView } from "../Utils/analytics";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
export const LiveSessionStudentView = (props) => {
  const { appLoadingStatus, singleLiveSessionDetail, getSingleLiveSession } =
    props;
  const [searchParams] = useSearchParams();
  const { cohortId, moduleId, livessionId } = useParams();
  const [liveSessionDetail, setLiveSessionDetail] = useState([]);

  useEffect(() => {
    fetchLiveSessionData(cohortId, livessionId);
  }, []);

  const fetchLiveSessionData = async (cohortId, livessionId) => {
    await getSingleLiveSession(cohortId, livessionId);
  };

  useEffect(() => {
    if (singleLiveSessionDetail) {
      const filtered =
        singleLiveSessionDetail &&
        singleLiveSessionDetail.dateSlot &&
        singleLiveSessionDetail.dateSlot.filter((item) => {
          return item.moduleId === moduleId;
        });
      singleLiveSessionDetail.dateSlot = filtered;
      setLiveSessionDetail(singleLiveSessionDetail);
    }
  }, [singleLiveSessionDetail]);

  const dateSlotFilter = (dateSlot) => {
    return dateSlot?.filter((item) => {
      return item.moduleId === moduleId;
    });
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("[Student] Live Sessions Details Page");
  }, [location]);

  if (appLoadingStatus) {
    return <div className="gif-loader" />;
  }
  const breadCrumps = (customization) => {
    return (
      <Breadcrumbs
        aria-label="breadcrumb"
        style={{
          color: "white",
        }}
      >
        <Link underline="hover" color="inherit" href="/cohorts">
          {customization.cohort_label || "Cohorts"}
        </Link>
        <Link underline="hover" color="inherit" href={`/cohort/${cohortId}`}>
          {searchParams.get("cohort")}
        </Link>
        <Link
          href={`/cohort/${cohortId}/curriculum?cohort=${searchParams.get(
            "cohort"
          )}`}
          underline="hover"
          color="inherit"
        >
          {customization.module_label}
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href={`/cohort/${cohortId}/curriculum/${moduleId}?cohort=${searchParams.get(
            "cohort"
          )}&module=${searchParams.get("module")}`}
        >
          {searchParams.get("module")}
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href={`/cohort/${cohortId}/curriculum/${moduleId}/liveSessions/?cohort=${searchParams.get(
            "cohort"
          )}&module=${searchParams.get("module")}`}
        >
          Live Session
        </Link>
        <Typography>
          <b>{liveSessionDetail.topic}</b>
        </Typography>
      </Breadcrumbs>
    );
  };
  return (
    <div className={styles["livesession-content-main"]}>
      <CohortBackNav breadCrumps={breadCrumps(props.customization)} />
      <div className={styles["information-wrapper"]}>
        <div className={styles["information-box"]}>
          <div className={styles["left-side-wrapper"]}>
            <div className={styles["speaker-wrapper"]}>
              <img src={liveSessionDetail.photoUrl} alt="usericon" />
              <div className={styles["speaker-name"]}>
                {liveSessionDetail.speakerName}
              </div>
            </div>
            <div className={styles["event-detail-wrapper"]}>
              <div className={styles["row-wrapper"]}>
                <div className={styles["left-label"]}>Start Date</div>
                <div className={styles["right-value"]}>
                  {dateSlotFilter(liveSessionDetail.dateSlot)?.[0]?.start &&
                    format(
                      new Date(
                        dateSlotFilter(liveSessionDetail.dateSlot)[0].start
                      ),
                      "MMM do, yyyy"
                    )}
                </div>
              </div>
              <div className={styles["row-wrapper"]}>
                <div className={styles["left-label"]}>End Date</div>
                <div className={styles["right-value"]}>
                  {dateSlotFilter(liveSessionDetail.dateSlot)?.[0]?.end &&
                    format(
                      new Date(
                        dateSlotFilter(liveSessionDetail.dateSlot)[0].end
                      ),
                      "MMM do, yyyy"
                    )}
                </div>
              </div>
              <div className={styles["row-wrapper"]}>
                <div className={styles["left-label"]}>Time Slot</div>
                <div className={styles["right-value"]}>
                  {liveSessionDetail.dateSlot &&
                  dateSlotFilter(liveSessionDetail.dateSlot)?.[0]?.start
                    ? format(
                        new Date(
                          dateSlotFilter(liveSessionDetail.dateSlot)[0].start
                        ),
                        "h:mm aa"
                      )
                    : ""}{" "}
                  {"-"}{" "}
                  {liveSessionDetail.dateSlot &&
                  dateSlotFilter(liveSessionDetail.dateSlot)?.[0]?.end
                    ? format(
                        new Date(
                          dateSlotFilter(liveSessionDetail.dateSlot)[0].end
                        ),
                        "h:mm aa"
                      )
                    : ""}
                </div>
              </div>
              <div className={styles["row-wrapper"]}>
                <div className={styles["left-label"]}>Created By</div>
                <div className={styles["right-value"]}>
                  {liveSessionDetail.createdBy || liveSessionDetail.updatedBy}
                </div>
              </div>
              <div className={styles["row-wrapper"]}>
                <div className={styles["left-label"]}>Event Link</div>
                <div
                  className={styles["link-value"]}
                  onClick={() =>
                    window.open(liveSessionDetail.googleMeetLink, "_blank")
                  }
                >
                  {liveSessionDetail.googleMeetLink}
                </div>
              </div>
            </div>
          </div>
          <div className={styles["divider"]} />
          <div className={styles["right-side-info"]}>
            <div className={styles["speaker-wrapper"]}>
              <div className={styles["heading"]}>About Speaker</div>
              <div className={styles["content"]}>
                {liveSessionDetail.speaker && (
                  <EditorWrapper
                    childRoot="display-event-details-content-wrapper"
                    readOnly={true}
                    content={liveSessionDetail.speaker}
                  />
                )}
              </div>
            </div>
            <div className={styles["event-wrapper"]}>
              <div className={styles["heading"]}>Event Overview</div>
              <div className={styles["content"]}>
                {liveSessionDetail.eventOverview && (
                  <EditorWrapper
                    childRoot="display-event-details-content-wrapper"
                    readOnly={true}
                    content={liveSessionDetail.eventOverview}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ app, user, cohort }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    userInfo: user.userInfo,
    singleLiveSessionDetail: cohort.singleLiveSessionDetail,
    customization: user.customization,
    themeInfo: user.themeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSingleLiveSession: (cohortId, livessionId) =>
      dispatch(getSingleLiveSession(cohortId, livessionId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveSessionStudentView);
