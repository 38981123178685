import React from "react";
import { connect } from "react-redux";
import {
  getAllCohorts,
  setAllCohorts,
} from "../../Store/Actions/actions.cohort";
import { getTheme } from "../../Store/Actions/actions.user";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  getAllModules,
  getModuleContent,
  getModuleQuestionContent,
} from "../../Store/Actions/actions.modules";
import { useParams } from "react-router-dom";

const ConfirmDialog = (props) => {
  const { confirmDialog, setConfirmDialog, confirmFor } = props;
  const { cohortId, moduleId } = useParams();

  // Access the Material-UI theme
  const theme = useTheme();

  // Create media queries for different screen sizes
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isXl = useMediaQuery(theme.breakpoints.only("xl"));

  // Determine font size based on screen size
  const getFontSize = () => {
    if (isXl) return "h6";
    if (isLg) return "h6";
    if (isMd) return "h6";
    if (isSm) return "subtitle1";
    if (isXs) return "body1";
  };

  // Apply responsive styles to DialogActions
  const dialogActionsStyle = {
    justifyContent: isXs ? "center" : "space-between",
    padding: theme.spacing(isXs ? 1 : 2),
  };

  // Apply responsive styles to Buttons inside DialogActions
  const buttonStyle = {
    margin: theme.spacing(1),
    padding: theme.spacing(isXs ? 0.5 : 1),
    fontSize: isXs ? "small" : "medium",
  };

  return (
    <Dialog open={confirmDialog.isOpen}>
      <DialogTitle></DialogTitle>

      <DialogContent>
        <Typography
          variant={getFontSize()}
          style={{ color: props.themeInfo[0]?.primarycolor }}
        >
          {confirmDialog.title}
        </Typography>
      </DialogContent>
      <DialogActions style={dialogActionsStyle}>
        <Button
          onClick={confirmDialog.onConfirm}
          style={{ ...buttonStyle, color: props.themeInfo[0]?.primarycolor }}
        >
          Yes
        </Button>
        <Button
          onClick={() => {
            setConfirmDialog({ ...confirmDialog, isOpen: false });
            if (confirmFor === "module") props.getAllModules(cohortId);
            else if (confirmFor === "content") {
              props.getModuleContent(moduleId);
              props.setDraggedSections(false);
            } else if (confirmFor === "questions") {
              props.getModuleQuestionContent(moduleId);
              props.setDraggedQuestionaireSections(false);
            }
          }}
          style={{ ...buttonStyle, color: props.themeInfo[0]?.primarycolor }}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const mapStateToProps = ({ auth, user, app, cohort }) => {
  return {
    userToken: auth.userToken,
    isAuthenticated: auth.isAuthenticated,
    themeInfo: user.themeInfo,
    appLoadingStatus: app.appLoadingStatus,
    cohorts: cohort.cohorts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAllCohorts: (cohort) => dispatch(setAllCohorts(cohort)),
    getAllCohorts: () => dispatch(getAllCohorts()),
    getTheme: (organisationId) => dispatch(getTheme(organisationId)),
    getAllModules: (cohortId) => dispatch(getAllModules(cohortId)),
    getModuleContent: (moduleId) => dispatch(getModuleContent(moduleId)),
    getModuleQuestionContent: (moduleId) =>
      dispatch(getModuleQuestionContent(moduleId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDialog);
