import React, { useState, useEffect } from "react";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { connect } from "react-redux";
import Container from "@mui/material/Container";

import { Divider } from "@mui/material";
import EditorWrapper from "../../../Components/EditorWrapper";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  getStudentModuleQuestionContent,
  createFeedback,
  requestResubmission,
  getAllModules,
} from "../../../Store/Actions/actions.modules";
import { getStudentCohortModuleProgress } from "../../../Store/Actions/actions.cohort";

import styles from "../../../Stylesheets/Modules/Admin/Students/questionnaireFeedBack.module.scss";
import {
  StyledArrowBackIcon,
  StyledChevronLeftIcon,
  StyledChevronRightIcon,
  StyledKeyboardArrowDownIcon,
  StyledKeyboardArrowUpIcon,
} from "../../../Components/StyledComponents/Icons.style";
import { logPageView } from "../../../Utils/analytics";
import GenericModal from "../../../Reusable/Modals/GenericModal";
import {
  FilledButton,
  OutlinedButton,
} from "../../../Components/StyledComponents/Button.style";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import StatusIcon from "../../../Reusable/StatusIcon";

const previewOptions = [
  {
    key: "createdDate",
    label: "Date",
    active: true,
  },
  {
    key: "submitDate",
    label: "Submit By",
    active: false,
  },
  {
    key: "totalQuestions",
    label: "Total Questions",
    active: true,
  },
  {
    key: "status",
    label: "Status",
    active: false,
  },
];
export const QuestionnaireFeedBack = (props) => {
  const { cohortId, studentId, moduleId, questionnaireId } = useParams();
  const history = useNavigate();
  const [questionsContent, setQuestionsContent] = useState([]);
  const [content, setContent] = useState("");
  const [searchParams] = useSearchParams();
  const [selectedID, setSelectedID] = useState(null);
  const [toggleEditor, setToggleEditor] = useState({});
  const [isEditorLoading, setEditorLoading] = useState(true);
  const [grade, setGrade] = useState({});
  const [toggleEdit, updateToggleEdit] = useState(false);
  const [reviewType, setReviewType] = useState("grade");
  const [selectedQuestion, setSelectedQuestion] = useState([]);
  const [modalContent, setModalContent] = useState("gradeOrResubmitForm");
  const [passOrFail, setPassOrFail] = useState("pass");
  const [currentQuestionnaireIndex, setCurrentQuestionnaireIndex] =
    useState(-1);
  const [currentModuleIndex, setCurrentModuleIndex] = useState(-1);
  const [hasNextQuestionnaire, setHasNextQuestionnaire] = useState(false);
  const [hasPreviousQuestionnaire, setHasPreviousQuestionnaire] =
    useState(false);
  const [hasNextModule, setHasNextModule] = useState(false);

  const {
    getStudentCohortModuleProgress,
    getStudentModuleQuestionContent,
    createFeedback,
    requestResubmission,
    allModules,
    allQuestionnaires,
  } = props;

  useEffect(() => {
    // this is to re-render and get ckeditor as it is not rendering in initial load
    setEditorLoading(false);
  }, []);

  useEffect(() => {
    getModuleQuestionsData(moduleId);
  }, []);

  useEffect(() => {
    props.getAllModules(cohortId);
  }, []);

  useEffect(() => {
    setModalContent("gradeOrResubmitForm");
  }, [toggleEdit]);

  const getModuleQuestionsData = async (moduleId) => {
    await getStudentCohortModuleProgress(cohortId, studentId);
    const questionsData = await getStudentModuleQuestionContent(
      studentId,
      cohortId,
      moduleId
    );
    setQuestionsContent([]);
    if (questionsData) {
      const filteredData = questionsData.filter(
        (e) => e._id === questionnaireId
      );
      setQuestionsContent(filteredData);
    }
  };

  const handleChange = (questionId) => {
    setToggleEditor({
      ...toggleEditor,
      [questionId]: !toggleEditor[questionId],
    });
  };

  const onClickPostFeedback = async (questionId) => {
    const payload = {
      questionId: questionId,
      msg: content,
    };
    await createFeedback(
      studentId,
      cohortId,
      moduleId,
      questionnaireId,
      payload
    );
    getModuleQuestionsData(moduleId);
  };
  const updateStatus = async (value, questionId) => {
    const payload = [
      {
        status: value,
        questionId: questionId,
        pointsGained:
          selectedQuestion.typeOfQuestion === "PASS_FAIL"
            ? passOrFail === "pass"
              ? parseInt(selectedQuestion.weightage)
              : 0
            : parseInt(grade[selectedID]),
      },
    ];
    await requestResubmission(
      studentId,
      cohortId,
      moduleId,
      questionnaireId,
      payload
    );
    await getModuleQuestionsData(moduleId);
  };

  useEffect(() => {
    if (
      allModules &&
      allQuestionnaires &&
      allModules.length &&
      allQuestionnaires.length
    ) {
      setCurrentQuestionnaireIndex(
        allQuestionnaires.findIndex((q) => q._id === questionnaireId)
      );
      setCurrentModuleIndex(
        allModules.findIndex((m) => m.moduleId === moduleId)
      );
    }
  }, [allQuestionnaires, allModules]);

  useEffect(() => {
    setHasNextQuestionnaire(
      currentQuestionnaireIndex < allQuestionnaires.length - 1
    );
    setHasPreviousQuestionnaire(currentQuestionnaireIndex > 0);
    setHasNextModule(currentModuleIndex < allModules.length - 1);
  }, [currentQuestionnaireIndex, currentModuleIndex]);

  const goToNextQuestionnaire = () => {
    if (hasNextQuestionnaire) {
      const nextQuestionnaire =
        allQuestionnaires[currentQuestionnaireIndex + 1];
      // Create the URL for navigating to the next questionnaire
      const url = `/admin/cohort/students/${cohortId}/${moduleId}/progress/${studentId}/questionnaires/${
        nextQuestionnaire._id
      }?cohort=${searchParams.get("cohort")}&moduleId=${searchParams.get(
        "moduleId"
      )}&student=${searchParams.get("student")}`;
      // Navigate to the next questionnaire
      history(url);
    }
  };

  const goToPreviousQuestionnaire = () => {
    if (hasPreviousQuestionnaire) {
      const previousQuestionnaire =
        allQuestionnaires[currentQuestionnaireIndex - 1];
      // Create the URL for navigating to the previous questionnaire
      const url = `/admin/cohort/students/${cohortId}/${moduleId}/progress/${studentId}/questionnaires/${
        previousQuestionnaire._id
      }?cohort=${searchParams.get("cohort")}&moduleId=${searchParams.get(
        "moduleId"
      )}&student=${searchParams.get("student")}`;
      // Navigate to the previous questionnaire
      history(url);
    }
  };

  const getModuleQuestionnaire = (moduleId) => {
    const module = props.modulesList?.find((item) => item?._id === moduleId);
    return module?.questionnaires[0]?._id;
  };

  const isNextModuleQuestionnaireAvailable = () => {
    const nextModule = allModules[currentModuleIndex + 1];
    const module = props.modulesList?.find(
      (item) => item?._id === nextModule.moduleId
    );
    return module?.questionnaires?.length > 0;
  };

  const goToNextModule = () => {
    if (hasNextModule) {
      const nextModule = allModules[currentModuleIndex + 1];
      const url = `/admin/cohort/students/${cohortId}/${
        nextModule.moduleId
      }/progress/${studentId}/questionnaires/${getModuleQuestionnaire(
        nextModule.moduleId
      )}?cohort=${searchParams.get("cohort")}&moduleId=${
        nextModule.moduleId
      }&student=${searchParams.get("student")}`;
      history(url);
    }
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] Questionnaire Feedback Page");
    getModuleQuestionsData(moduleId);
  }, [location]);

  const getCreditLeft = () => {
    const item = questionsContent[0]?.questions.filter(
      (e) => e._id === selectedID
    )[0];
    const calulatedValue = item?.weightage - (grade[item?._id] || 0);
    return calulatedValue;
  };

  const isSaveButtonDisabled = () => {
    return getCreditLeft() < 0;
  };

  const onSave = () => {
    updateStatus("Completed", selectedID);
    updateToggleEdit(false);
  };
  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }
  const gradingFormComponent = () => {
    return (
      <div className={styles["form-wrapper"]}>
        <div className={styles["modal-header"]}>Review Question</div>
        <div className={styles["content-body"]}>
          <br />
          <div className={styles["input-wrapper"]}>
            <div className={styles["modal-grade"]}>Enter Grade</div>
            <FormControl variant="outlined" className={styles["form-control"]}>
              <TextField
                type={"Number"}
                className={styles["text-field"]}
                variant="outlined"
                inputProps={{
                  min: 0,
                  style: { textAlign: "center" },
                }}
                value={grade[selectedID] ? grade[selectedID] : ""}
                onChange={(e) => {
                  setGrade({ [selectedID]: e.target.value });
                }}
              />
            </FormControl>
            <div className={styles["credits-left"]}>
              Credits Left:{getCreditLeft()}
            </div>
          </div>

          <br />
          <div className={styles["button-section"]}>
            <OutlinedButton
              variant="contained"
              className={styles["cancel-btn"]}
              bgcolor={
                props.themeInfo[0]?.primarycolor
                  ? props.themeInfo[0]?.primarycolor
                  : "#0e1555"
              }
              fontFamily={
                props.themeInfo[0]?.fontfamily
                  ? props.themeInfo[0]?.fontfamily
                  : "Nunito"
              }
              onClick={() => {
                setModalContent("gradeOrResubmitForm");
              }}
            >
              Back
            </OutlinedButton>
            <FilledButton
              variant="contained"
              bgcolor={
                props.themeInfo[0]?.primarycolor
                  ? props.themeInfo[0]?.primarycolor
                  : "#0e1555"
              }
              fontFamily={
                props.themeInfo[0]?.fontfamily
                  ? props.themeInfo[0]?.fontfamily
                  : "Nunito"
              }
              className={styles["save-btn"]}
              onClick={() => onSave()}
              disabled={isSaveButtonDisabled()}
            >
              Save
            </FilledButton>
          </div>
          <br />
        </div>
      </div>
    );
  };
  const gradeOrResubmitForm = () => {
    // Find the current question using selectedID
    const currentQuestion = questionsContent[0]?.questions.find(
      (q) => q._id === selectedID
    );

    // Determine if the Resubmit option should be shown
    const showResubmitOption =
      currentQuestion &&
      parseInt(currentQuestion.numberOfTimesResubmitted) <
        parseInt(currentQuestion.numberOfResubmissionAllowed);

    return (
      <div className={styles["grade-or-resubmit-form-div"]}>
        <div className={styles["heading"]}>Select review type</div>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="grade"
            name="radio-buttons-group"
            className={styles["radio-group-wrapper"]}
            onChange={(e) => setReviewType(e.target.value)}
          >
            <FormControlLabel
              value="grade"
              control={
                <Radio
                  sx={{
                    color: "#0e1555",
                    "&.Mui-checked": {
                      color: "#0e1555",
                    },
                  }}
                />
              }
              label="Grade"
            />
            {showResubmitOption && (
              <FormControlLabel
                value="resubmit"
                control={
                  <Radio
                    sx={{
                      color: "#0e1555",
                      "&.Mui-checked": {
                        color: "#0e1555",
                      },
                    }}
                  />
                }
                label="Resubmit"
              />
            )}
          </RadioGroup>
        </FormControl>
        <div className={styles["button-section"]}>
          <OutlinedButton
            className={styles["cancel-btn"]}
            bgcolor={
              props.themeInfo[0]?.primarycolor
                ? props.themeInfo[0]?.primarycolor
                : "#0e1555"
            }
            fontFamily={
              props.themeInfo[0]?.fontfamily
                ? props.themeInfo[0]?.fontfamily
                : "Nunito"
            }
            onClick={() => {
              setSelectedID(null);
              updateToggleEdit(false);
            }}
          >
            Cancel
          </OutlinedButton>
          <FilledButton
            variant="contained"
            bgcolor={
              props.themeInfo[0]?.primarycolor
                ? props.themeInfo[0]?.primarycolor
                : "#0e1555"
            }
            fontFamily={
              props.themeInfo[0]?.fontfamily
                ? props.themeInfo[0]?.fontfamily
                : "Nunito"
            }
            className={styles["save-btn"]}
            onClick={() => {
              if (reviewType === "grade")
                setModalContent("gradingFormComponent");
              else {
                updateStatus("ReSubmit", selectedID);
                setReviewType("grade");
                updateToggleEdit(false);
              }
            }}
          >
            Save
          </FilledButton>
        </div>
      </div>
    );
  };
  const passFailFormComponent = () => {
    return (
      <div className={styles["pass-fail-form-component"]}>
        <div className={styles["heading"]}>Review Question</div>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="pass"
            name="radio-buttons-group"
            className={styles["radio-group-wrapper"]}
            onChange={(e) => setPassOrFail(e.target.value)}
          >
            <div className={styles["pass-div"]}>
              <FormControlLabel
                value="pass"
                control={
                  <Radio
                    sx={{
                      color: "#0e1555",
                      "&.Mui-checked": {
                        color: "#0e1555",
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body1"
                    style={{
                      fontFamily: "lato",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Pass
                  </Typography>
                }
              />
            </div>
            <div className={styles["fail-div"]}>
              <FormControlLabel
                value="fail"
                control={
                  <Radio
                    sx={{
                      color: "#0e1555",
                      "&.Mui-checked": {
                        color: "#0e1555",
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body1"
                    style={{
                      fontFamily: "lato",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Fail
                  </Typography>
                }
              />
            </div>
          </RadioGroup>
        </FormControl>
        <div className={styles["button-section"]}>
          <OutlinedButton
            className={styles["cancel-btn"]}
            bgcolor={
              props.themeInfo[0]?.primarycolor
                ? props.themeInfo[0]?.primarycolor
                : "#0e1555"
            }
            fontFamily={
              props.themeInfo[0]?.fontfamily
                ? props.themeInfo[0]?.fontfamily
                : "Nunito"
            }
            onClick={() => {
              setModalContent("gradeOrResubmitForm");
            }}
          >
            Back
          </OutlinedButton>
          <FilledButton
            variant="contained"
            bgcolor={
              props.themeInfo[0]?.primarycolor
                ? props.themeInfo[0]?.primarycolor
                : "#0e1555"
            }
            fontFamily={
              props.themeInfo[0]?.fontfamily
                ? props.themeInfo[0]?.fontfamily
                : "Nunito"
            }
            className={styles["save-btn"]}
            onClick={onSave}
          >
            Save
          </FilledButton>
        </div>
      </div>
    );
  };
  return (
    <>
      <Container
        maxWidth="xl"
        classes={{
          root: styles["container-root"],
        }}
      >
        <div className={styles["student-questionnaire-feedback-menu-header"]}>
          <StyledArrowBackIcon
            sx={{ cursor: "pointer", marginRight: "0.625rem" }}
            onClick={() => {
              history(
                `/admin/cohort/students/${cohortId}/${searchParams.get(
                  "moduleId"
                )}/progress/${studentId}/questionnaires?cohort=${searchParams.get(
                  "cohort"
                )}&moduleId=${searchParams.get(
                  "moduleId"
                )}&student=${searchParams.get("student")}`
              );
            }}
          />{" "}
          {questionsContent[0]?.title}
        </div>
        <div className={styles["preview-section"]}>
          {previewOptions.map((item, key) => {
            return (
              <>
                {item.active && (
                  <div className={styles["option"]} key={key}>
                    <div className={styles["heading"]}>{item.label}</div>
                    <div className={styles["value"]}>
                      {item.key === "createdDate"
                        ? questionsContent[0]?.createdAt.substring(0, 10)
                        : item.key === "totalQuestions"
                        ? questionsContent[0]?.questions.length
                        : "-"}
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </div>
        <div className={styles["questions-container"]}>
          {questionsContent[0] && Object.keys(questionsContent[0]).length ? (
            questionsContent[0]?.questions?.map((item, idx) => {
              return (
                <div key={idx}>
                  <div className={styles["review-wrapper"]}>
                    <div
                      onClick={() => {
                        setSelectedID(item._id);
                        updateToggleEdit(true);
                        setSelectedQuestion(item);
                      }}
                      className={`${
                        item.status !== "Submitted"
                          ? styles["invisible"]
                          : styles["review-button"]
                      }`}
                    >
                      Review
                    </div>
                    {item.status && <StatusIcon status={item.status} />}
                  </div>
                  <div className={styles["question"]}>
                    <div className={styles["span-question"]}>
                      <div className={styles["title"]}>
                        {idx + 1}
                        {")"}&nbsp;
                        <EditorWrapper
                          className={styles["question-title"]}
                          root="question-editor-wrapper"
                          childRoot="question-wrapper"
                          readOnly={true}
                          content={item.question}
                        />
                      </div>
                      {item.creditsEnabled && (
                        <div className={styles["grade-display"]}>
                          {item.pointsGained}
                        </div>
                      )}
                    </div>

                    {item?.answer?.length > 0 && item?.answer ? (
                      <>
                        <div className={styles["answers"]}>
                          {item.answer instanceof Array
                            ? item.answer.join(", ")
                            : item.answer}
                        </div>
                        <div className={styles["feedback-wrapper"]}>
                          <div
                            className={styles["give-view-feedback-accordion"]}
                          >
                            <div
                              className={styles["give-view-feedback-label"]}
                              onClick={() => {
                                handleChange(item?._id);
                              }}
                            >
                              <span>
                                {!item.feedback
                                  ? "Give Feedback"
                                  : "View Feedback"}
                              </span>
                              {toggleEditor[item._id] ? (
                                <StyledKeyboardArrowUpIcon
                                  classes={{ root: styles["icon-class-up"] }}
                                />
                              ) : (
                                <StyledKeyboardArrowDownIcon
                                  classes={{ root: styles["icon-class-down"] }}
                                />
                              )}
                            </div>
                            {toggleEditor[item._id] && (
                              <>
                                <EditorWrapper
                                  readOnly={item.feedback}
                                  content={item.feedback}
                                  setContent={setContent}
                                  isEditorLoading={isEditorLoading}
                                  setEditorLoading={setEditorLoading}
                                />
                                {!item.feedback && (
                                  <Button
                                    classes={{ root: styles["button-root"] }}
                                    onClick={() =>
                                      onClickPostFeedback(item._id)
                                    }
                                  >
                                    {"Post"}
                                  </Button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className={styles["no-submission"]}>
                        {"No submission yet"}{" "}
                      </div>
                    )}
                  </div>
                  {questionsContent[0].questions.length !== idx + 1 && (
                    <Divider />
                  )}
                </div>
              );
            })
          ) : (
            <div className={styles["no-data-found"]}>
              {"No Questions Found"}
            </div>
          )}
        </div>
        {/* Nav Wrapper ----- Start */}
        <div className={styles["module-section-nav-wrapper"]}>
          <div className={styles["nav-prev"]}>
            {hasPreviousQuestionnaire && (
              <div
                className={styles["nav-prev"]}
                onClick={goToPreviousQuestionnaire}
              >
                <StyledChevronLeftIcon /> Prev
              </div>
            )}
          </div>

          <div className={styles["nav-next"]}>
            {hasNextQuestionnaire ? (
              <div
                className={styles["nav-next"]}
                onClick={goToNextQuestionnaire}
              >
                Next <StyledChevronRightIcon />
              </div>
            ) : (
              <>
                {hasNextModule && isNextModuleQuestionnaireAvailable() && (
                  <div className={styles["nav-next"]} onClick={goToNextModule}>
                    Next{" "}
                    {props.customization.module_label === ""
                      ? "Module"
                      : props.customization.module_label}
                    <StyledChevronRightIcon />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {/* Nav Wrapper ----- End */}
        <GenericModal open={toggleEdit} togglePopUp={updateToggleEdit}>
          {modalContent === "gradeOrResubmitForm" && gradeOrResubmitForm()}
          {modalContent === "gradingFormComponent" &&
            selectedQuestion.typeOfQuestion === "FIXED_GRADING" &&
            gradingFormComponent()}
          {modalContent === "gradingFormComponent" &&
            selectedQuestion.typeOfQuestion === "PASS_FAIL" &&
            passFailFormComponent()}
        </GenericModal>
      </Container>
    </>
  );
};

const mapStateToProps = ({ app, user, cohort, modules }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    themeInfo: user.themeInfo,
    customization: user.customization,
    allQuestionnaires: modules.questionnaires,
    allModules: cohort.studentCohortModuleProgress,
    modulesList: modules.modules,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStudentModuleQuestionContent: (studentId, cohortId, moduleId) =>
      dispatch(getStudentModuleQuestionContent(studentId, cohortId, moduleId)),
    getStudentCohortModuleProgress: (cohortId, studentId) =>
      dispatch(getStudentCohortModuleProgress(cohortId, studentId)),
    createFeedback: (studentId, cohortId, moduleId, questionnaireId, payload) =>
      dispatch(
        createFeedback(studentId, cohortId, moduleId, questionnaireId, payload)
      ),
    getAllModules: (cohortId) => dispatch(getAllModules(cohortId)),
    requestResubmission: (
      studentId,
      cohortId,
      moduleId,
      questionnaireId,
      payload
    ) =>
      dispatch(
        requestResubmission(
          studentId,
          cohortId,
          moduleId,
          questionnaireId,
          payload
        )
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireFeedBack);
