import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { setAuthModal, setForgotPassModal } from "../Store/Actions/actions.app";
import { logout, setUserToken } from "../Store/Actions/actions.auth";
import styles from "../Stylesheets/Modules/navbar.module.scss";
import Logo from "../Assets/Images/Gritly-logo.svg";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NotificationSettings from "../Pages/NotificationSettings";
import { Box, Button, Modal } from "@mui/material";
import { useConfirmDialog } from "../Context/ConfirmDialogContext";

const Navbar = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [showNotification, setNotificationModal] = useState(false);
  const [imageUrl, setImgURL] = useState("");
  const navigate = useNavigate();
  const { checkConditionAndShowDialog } = useConfirmDialog();
  const location = useLocation();

  const logoutHandler = (e) => {
    e.preventDefault();
    props.logout(props.userInfo.id);
  };

  const handleClick = (e) => {
    e.preventDefault();
    checkConditionAndShowDialog(() => {
      setAnchorEl(e.currentTarget);
    });
  };

  const openAuthModal = (e) => {
    e.preventDefault();
    props.setAuthModal(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const conditionalNavigation = (e, path) => {
    e.preventDefault(); // Prevent default link behavior
    checkConditionAndShowDialog(() => navigate(path));
  };

  useEffect(() => {
    if (props.themeInfo[0]?.logourl) {
      setImgURL(props.themeInfo[0]?.logourl);
    } else {
      setImgURL(Logo);
      // window.getElementById("logoPreview").setAttribute("src", Logo);
    }
  }, [props.themeInfo, props.appLoadingStatus]);

  return (
    <div className={styles["navbar-container"]}>
      <div className={styles["nav-left"]}>
        <div className={styles["logo"]}>
          <Link
            data-event-name="NavBar - Logo Go to Home"
            className={styles["nav-link"]}
            to="/"
            onClick={(e) => conditionalNavigation(e, "/")}
          >
            <img
              src={imageUrl}
              alt="Gritly"
              id="logoPreview"
              className={styles["logo-container"]}
            />
          </Link>
        </div>
      </div>
      {props.hasRight && (
        <div className={styles["nav-right"]}>
          {props.userToken ? (
            <div className={styles["nav-right-private"]}>
              {props.userInfo?.role !== "ORG_ADMIN" && (
                <div className={`${styles["list-item"]} ${location.pathname === "/cohorts" && styles["first-child-style"]}`}>
                  <Link
                    data-event-name="[Student] NavBar - Go to Home"
                    className={styles["nav-link"]}
                    to={"/cohorts"}
                    onClick={(e) => conditionalNavigation(e, "/cohorts")}
                  >
                    <span className={styles["nav-list-icon"]}>
                      <i
                        className={"fas fa-graduation-cap"}
                        style={{ color: props.themeInfo[0]?.primarycolor }}
                      />
                    </span>
                    <span
                      style={{
                        fontFamily: props.themeInfo[0]?.fontfamily,
                        color: props.themeInfo[0]?.primarycolor,
                      }}
                      className={styles["nav-item-name"]}
                    >
                      {props.customization?.cohort_label || "Cohorts"}
                    </span>
                  </Link>
                </div>
              )}
              <div
                className={`${styles["list-item"]} ${styles["profile"]}`}
                style={{ color: props.themeInfo[0]?.primarycolor }}
              >
                {props.userInfo?.profileImgUrl ? (
                  <div
                    data-event-name="[Student] NavBar - Clicked on Profile"
                    className={styles["profile-container"]}
                    onClick={handleClick}
                  >
                    <img
                      src={props.userInfo?.profileImgUrl}
                      alt=""
                      className={styles["avatar"]}
                      referrerPolicy="no-referrer"
                    />
                  </div>
                ) : (
                  <div
                    data-event-name="[Student] NavBar - Clicked on Profile"
                    className={styles["profile-container-no-img"]}
                    style={{
                      backgroundColor: props.themeInfo[0]?.primarycolor,
                    }}
                    onClick={handleClick}
                  >
                    <span
                      style={{
                        fontFamily: props.themeInfo[0]?.fontfamily,
                        color: "white",
                      }}
                    >
                      {props.userInfo.firstName?.[0]}
                    </span>
                  </div>
                )}
              </div>
              <div
                data-event-name="[Student] NavBar - Logout"
                onClick={logoutHandler}
                className={styles["list-item"]}
                style={{ color: props.themeInfo[0]?.primarycolor }}
              >
                <span className={styles["nav-list-icon"]}>
                  <i className={"fas fa-arrow-right-from-bracket"} />
                </span>
                <span
                  className={styles["nav-item-name"]}
                  style={{ fontFamily: props.themeInfo[0]?.fontfamily }}
                >
                  Logout
                </span>
              </div>
            </div>
          ) : (
            <div className={styles["nav-right-public"]}>
              <div className={`${styles["list-item"]} ${location.pathname === "/cohorts" && styles["first-child-style"]}`}>
                <Link
                  data-event-name="NavBar - Go to Home"
                  className={styles["nav-link"]}
                  to={"/cohorts"}
                >
                  <span className={styles["nav-list-icon"]}>
                    <i
                      className={"fas fa-graduation-cap"}
                      style={{
                        color: props.themeInfo[0]?.primarycolor,
                      }}
                    />
                  </span>
                  <span
                    className={styles["nav-item-name"]}
                    style={{
                      fontFamily: props.themeInfo[0]?.fontfamily,
                      color: props.themeInfo[0]?.primarycolor,
                    }}
                  >
                    {props.customization?.cohort_label || "Cohorts"}
                  </span>
                </Link>
              </div>
              <div
                data-event-name="NavBar - Login"
                className={styles["list-item"]}
                onClick={openAuthModal}
              >
                <span className={styles["nav-list-icon"]}>
                  <i
                    className={"fas fa-user"}
                    style={{ color: props.themeInfo[0]?.primarycolor }}
                  />
                </span>
                <span
                  className={styles["nav-item-name"]}
                  style={{
                    fontFamily: props.themeInfo[0]?.fontfamily,
                    color: props.themeInfo[0]?.primarycolor,
                  }}
                >
                  Login
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          data-event-name="NavBar Profile - Clicked on Profile"
          onClick={() => {
            if (props.userInfo.role === "ORG_ADMIN") navigate("/adminProfile");
            else navigate(`/profile`);
            setAnchorEl(null);
          }}
        >
          Profile
        </MenuItem>
        <MenuItem
          data-event-name="NavBar Profile - Clicked on Notifications"
          onClick={() => {
            setNotificationModal(true);
            setAnchorEl(null);
          }}
        >
          Notifications
        </MenuItem>
        <MenuItem
          data-event-name="NavBar Profile - Clicked on Reset Password"
          onClick={() => {
            props.setForgotPassModal(true);
            setAnchorEl(null);
          }}
        >
          Reset Password
        </MenuItem>
        <MenuItem
          data-event-name="NavBar Profile - Clicked on Need help?"
          onClick={() => {
            navigate(`/help`);
            setAnchorEl(null);
          }}
        >
          Need help?
        </MenuItem>
      </Menu>
      <Modal
        open={showNotification}
        sx={{ color: "white" }}
        onClose={() => {
          showNotification && setNotificationModal(false);
        }}
      >
        <Box
          classes={{
            root: styles["notification-box-root"],
            paper: styles["notification-box-paper"],
            container: styles["notification-box-container"],
          }}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", sm: "60%", md: 400 },
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "9px",
            p: 4,
          }}
        >
          <NotificationSettings student={true} />
        </Box>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ app, auth, user }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    userToken: auth.userToken,
    userInfo: user.userDetails,
    customization: user.customization,
    themeInfo: user.themeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAuthModal: (status) => dispatch(setAuthModal(status)),
    setUserToken: (token) => dispatch(setUserToken(token)),
    setForgotPassModal: (status) => dispatch(setForgotPassModal(status)),
    logout: (userId) => dispatch(logout(userId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
