import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logPageView } from "../../../Utils/analytics";
import Box from "@mui/material/Box";
import ProfileBanner from "../Students/StudentProfile/ProfileBanner";

import styles from "../../../Stylesheets/Modules/Admin/Profile/adminProfile.module.scss";
import AdminBootcampDetails from "./AdminBootcampDetails";
import AdminUserDetails from "./AdminUserDetails";

function AdminProfile() {
  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] Live Session Wrapper Page");
  }, [location]);
  return (
    <Box className={styles["profile-page"]}>
      <ProfileBanner />
      <Box className={styles["student-profile-body"]}>
        <AdminBootcampDetails />
        <AdminUserDetails />
      </Box>
    </Box>
  );
}

export default AdminProfile;
