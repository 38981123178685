import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import styles from "../Stylesheets/Modules/home.module.scss";
import Banner from "../Assets/Images/cohort-home-banner.png";
import RIBanner from "../Assets/Images/recruring-innovation-bg.jpeg";
import ASA from "../Assets/Images/ASA_BG_high_res.png";
import { getAllCohorts, setAllCohorts } from "../Store/Actions/actions.cohort";
import { setAuthModal } from "../Store/Actions/actions.app";

import { getTheme } from "../Store/Actions/actions.user";
import { FilledButton } from "../Components/StyledComponents/Button.style";
import {
  StyledArrowForwardIcon,
  StyledCheckCircleIcon,
  StyledCloseIcon,
  StyledRemoveRedEyeIcon,
} from "../Components/StyledComponents/Icons.style";
import { logPageView } from "../Utils/analytics";

const Home = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [onGoingCohorts, setOngoingCohorts] = useState([]);
  const [customBanner, setCustomBanner] = useState(window.location.hostname);
  const { themeInfo } = props;
  const fromWebsite = searchParams.get("fromWebsite");
  if (fromWebsite) {
    props.setAuthModal(true);
  }

  useEffect(() => {
    getAllCohortsData();
  }, []);

  const getAllCohortsData = async () => {
    await props.getAllCohorts();
  };

  useEffect(() => {
    setOngoingCohorts(computeData());
    if (props.cohortInfo) props.getTheme(props.cohortInfo.organization);
  }, [props.cohorts]);

  const computeData = () => {
    const programs = props?.cohorts?.reduce((r, c) => {
      if (!r[c?.program?._id]) {
        r[c?.program?._id] = {
          ...c?.program,
          cohorts: [],
        };
      }
      r[c?.program?._id].cohorts.push(c);
      return r;
    }, {});

    return Object.values(programs);
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("Landing Page");
  }, [location]);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }

  const getDuration = (duration) => {
    let { period, type } = duration;
    return `${period} ${type}s`;
  };

  const onJoinClick = (cohort) => {
    navigate(`/cohort/${cohort._id}`);
  };
  return (
    <div className={styles["home-container"]}>
      <div className={styles["header"]}>
        <div
          className={`${
            customBanner.includes("recruitinginnovation")
              ? styles["ri-styles-left"]
              : customBanner.includes("victorylap")
              ? styles["plain-bg"]
              : customBanner.includes("asa")
              ? styles["asa-bg"]
              : styles["left"]
          }`}
        >
          <div
            className={`${
              customBanner.includes("recruitinginnovation")
                ? styles["ri-styles-text-container"]
                : customBanner.includes("victorylap")
                ? styles["vl-styles-text-container"]
                : styles["text-container"]
            }`}
          >
            {customBanner.includes("recruitinginnovation") ? (
              <>
                <span className={styles["title"]}>
                  CERTIFIED TECH RECRUITING
                </span>
                <span className={styles["sub-title"]}>
                  Fill Tech Roles 20% Faster
                </span>
                <span className={styles["sub-title"]}>
                  Learn how to source tech roles with our self-paced, online
                  training. Designed by tech recruiters, taught by tech experts.
                </span>
              </>
            ) : (
              <>
                <span className={styles["title"]}>
                  {customBanner.includes("victorylap")
                    ? "Your #1 Path Into Tech Sales"
                    : customBanner.includes("asa")
                    ? "Welcome to The Skills Quest"
                    : themeInfo[0]?.landingPageHeader}
                </span>
                <span className={styles["sub-title"]}>
                  {customBanner.includes("victorylap")
                    ? "No Upfront Cost. Unlimited Income Potential. Lifelong Community "
                    : customBanner.includes("asa")
                    ? "Powered by Gritly"
                    : themeInfo[0]?.landingPageSubHeader}
                </span>
              </>
            )}
          </div>
        </div>
        <div
          className={`${
            customBanner.includes("recruitinginnovation")
              ? styles["ri-styles-right"]
              : customBanner.includes("asa")
              ? styles["asa-right"]
              : styles["right"]
          }`}
        >
          <img
            className={styles["banner"]}
            src={
              customBanner.includes("recruitinginnovation")
                ? RIBanner
                : customBanner.includes("asa")
                ? ASA
                : Banner
            }
            alt="Banner"
          />
        </div>
      </div>
      <div
        className={styles["cohorts-container"]}
        style={{ color: props.themeInfo[0]?.primarycolor }}
      >
        {onGoingCohorts.length > 0 &&
          onGoingCohorts.map((program, idx) => {
            return (
              <div className={styles["category-container"]} key={idx}>
                <div className={styles["category-header"]}>
                  <span
                    className={styles["header"]}
                    style={{ color: props.themeInfo[0]?.secondarycolor }}
                  >
                    {program.programName}
                  </span>
                  <span className={styles["desc"]}>
                    {program.programDescription}
                  </span>
                </div>
                <div className={styles["cohorts-main-container"]}>
                  {program.cohorts.map((cohort, index) => {
                    let status;
                    if (cohort.access) {
                      const { applicationStatus } = cohort.access;
                      status = applicationStatus;
                    }
                    return (
                      <div
                        className={styles["cohort-cards-container"]}
                        key={index}
                      >
                        <div
                          data-event-name="Clicked On Cohort Card"
                          className={styles["cohort-card"]}
                          onClick={() => onJoinClick(cohort)}
                        >
                          <div className={styles["content"]}>
                            {/* <span className={styles["creator"]}>
                              {`${cohort.organization.name}'s`}
                            </span> */}
                            <div
                              className={styles["title"]}
                              title={cohort.title}
                              style={{
                                color: props.themeInfo[0]?.secondarycolor,
                              }}
                            >
                              {cohort.title}
                            </div>
                            <span className={styles["sub"]}>
                              {cohort.overview}
                            </span>
                          </div>
                          {cohort.startDate && (
                            <div className={styles["date-container"]}>
                              <span>
                                {new Date(cohort.startDate).getDate()}
                              </span>
                              <span>
                                {new Date(cohort.startDate).toLocaleString(
                                  "default",
                                  { month: "short" }
                                )}
                              </span>
                            </div>
                          )}
                          <div className={styles["action-container"]}>
                            {status === "Applied" && (
                              <span
                                className={`${styles["status-container"]} ${styles["applied"]}`}
                              >
                                <StyledCheckCircleIcon />
                                <span className={styles["text"]}>Applied</span>
                              </span>
                            )}
                            {status === "InReview" && (
                              <span
                                className={`${styles["status-container"]} ${styles["in-review"]}`}
                              >
                                <StyledRemoveRedEyeIcon />
                                <span className={styles["text"]}>
                                  In Review
                                </span>
                              </span>
                            )}
                            {status === "Rejected" && (
                              <span
                                className={`${styles["status-container"]} ${styles["rejected"]}`}
                              >
                                <StyledCloseIcon />
                                <span className={styles["text"]}>Rejected</span>
                              </span>
                            )}
                            {status === "Accepted" && <></>}
                            {!status && (
                              <FilledButton
                                data-event-name="Clicked On Apply Now"
                                primary="true"
                                bgcolor={
                                  props.themeInfo[0]?.primarycolor
                                    ? props.themeInfo[0]?.primarycolor
                                    : "#0e1555"
                                }
                                fontFamily={
                                  props.themeInfo[0]?.fontfamily
                                    ? props.themeInfo[0]?.fontfamily
                                    : "Nunito"
                                }
                                variant="contained"
                                onClick={() => onJoinClick(cohort)}
                                endIcon={
                                  <StyledArrowForwardIcon
                                    classes={{
                                      root: styles["arrow-icon"],
                                    }}
                                  />
                                }
                              >
                                Apply Now
                              </FilledButton>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth, user, app, cohort }) => {
  return {
    userToken: auth.userToken,
    isAuthenticated: auth.isAuthenticated,
    themeInfo: user.themeInfo,
    appLoadingStatus: app.appLoadingStatus,
    cohorts: cohort.cohorts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAllCohorts: (cohort) => dispatch(setAllCohorts(cohort)),
    getAllCohorts: () => dispatch(getAllCohorts()),
    setAuthModal: (status) => dispatch(setAuthModal(status)),
    getTheme: (organisationId) => dispatch(getTheme(organisationId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
