import * as Types from "../StaticTypes";

const initialState = {
  userUid: '',
  orgList: {},
  userInfo: {
    role: "PUBLIC",
  },
  userDetails: {
    role: "PUBLIC",
  },
  userProfile: {
    profileImgUrl: "",
    bio: "",
    country: "",
    city: "",
    links: [""],
    openForWork: false,
    professionalDetails: {
      position: "",
      bio: "",
      startDate: "",
      endDate: "",
      currentProfession: "",
    },
    educationDetails: {
      school: "",
      degree: "",
      eduStartDate: "",
      eduEndDate: "",
      achievements: "",
      currentInstitution: "",
    },
    skills: [],
  },
  bootcampDetails: {
    bootcampName: "",
    bootcampDescription: "",
    trainingIndustry: "",
    trainingRole: "",
    salaryPreProgram: "",
    salaryPostProgram: "",
  },
  adminUserDetails: {
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    linkedin: "",
    bio: "",
  },
  themeInfo: [],
  customization: {},
  learnerProfileDetails: {},
  learnerProfileData: {},
  allPrompts: {}
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case Types.SET_USER_INFO:
      const { userInfo } = action.payload;
      return {
        ...state,
        userInfo: userInfo,
      };
    case Types.SET_ORG_LIST_DETAILS:
      const { orgData } = action.payload;
      return {
        ...state,
        orgList: orgData,
      };
    case Types.SET_USER_UID:
      const { userUid } = action.payload;
      return {
        ...state,
        userUid: userUid,
      };
    case Types.SET_USER_DETAILS:
      const { userDetails } = action.payload;
      return {
        ...state,
        userDetails: userDetails,
      };
    case Types.SET_PROFILE_DATA:
      const { userProfile } = action.payload;
      return {
        ...state,
        userProfile: userProfile,
      };
    case Types.SET_USER_NAME_MOBILE:
      const { firstName, lastName, mobileNo } = action.payload;
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          firstName,
          lastName,
          mobileNo,
        },
      };
    case Types.SET_CUSTOMIZATION_INFO:
      const { customization } = action.payload;
      return {
        ...state,
        customization: customization,
      };
    case Types.SET_IMAGE_URL:
      const { imageUrl } = action.payload;
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          profileImgUrl: imageUrl,
        },
      };
    case Types.SET_USER_PERSONAL_DATA:
      const { bio, city, country, links, openForWork } = action.payload;
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          bio,
          city,
          country,
          links,
          openForWork,
        },
      };
    case Types.SET_USER_EDUCATION_DATA:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          educationDetails: action.payload,
        },
      };
    case Types.SET_USER_PROFESSIONAL_DATA:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          professionalDetails: action.payload,
        },
      };
    case Types.SET_SKILLS_PROFILE_DATA:
      return {
        ...state,
        userProfile: action.payload,

      };
    case Types.SET_ADMIN_BOOTCAMP_DATA:
      const bootcampDetails = action.payload;
      return {
        ...state,
        bootcampDetails,
      };
    case Types.SET_ADMIN_USER_DATA:
      const adminUserDetails = action.payload;
      return {
        ...state,
        adminUserDetails,
      };
    case Types.SET_LEARNER_PROFILE_DATA:
      const { profileUser } = action.payload;
      return {
        ...state,
        learnerProfileDetails: profileUser
      };
    case Types.SET_ALL_PROMPTS:
      const { prompts } = action.payload;
      return {
        ...state,
        allPrompts: prompts
      }
    case Types.SET_THEME_INFO:
      const { themeInfo } = action.payload;
      if (themeInfo.length === 0) {
        return {
          ...state,
          themeInfo: [
            {
              primarycolor: "#0e1555",
              secondarycolor: "#142297",
              fontfamily: "Nunito",
              logourl: "",
            },
          ],
        };
      } else {
        return {
          ...state,
          themeInfo: themeInfo,
        };
      }
    default:
      return state;
  }
}
