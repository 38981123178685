import { Button } from "@mui/material";
import React from "react";
import styles from "../Stylesheets/Modules/underDevelopment.module.scss";
import { useNavigate } from "react-router-dom";
import PaletteIcon from "@mui/icons-material/Palette";
import { OutlinedButton } from "../Components/StyledComponents/Button.style";
import { StyledPaletteIcon } from "../Components/StyledComponents/Icons.style";

export default function UnderDevelopment() {
  return (
    <div className={styles["under-dev-container"]}>
      <img
        className={styles["page-build-img"]}
        src={require("../Assets/Images/inprogress.png")}
        alt={"Under development"}
      />
    </div>
  );
}
