function validateEmail(email) {
  const emailRegX =
    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegX.test(email);
}

function validatePassword(password) {
  const passRegX = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/
  return passRegX.test(password)
}

function validatePhone(phone) {
  const regex = /^\+\d{1,4}[0-9]{7,15}$/;
  return regex.test(phone);
}

export { validateEmail, validatePassword, validatePhone };
