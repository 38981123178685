import React from "react";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import styles from "../../../Stylesheets/Modules/Admin/Profile/adminBootcampEditModal.module.scss";

const SalaryField = ({ label, value, onChange }) => {
  return (
    <div className={styles["input-wrapper"]}>
      <div className={styles["label"]}>{label}</div>
      <FormControl fullWidth sx={{ marginTop: "10px" }}>
        <OutlinedInput
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          type="number"
          className={styles["input-field"]}
          value={value}
          onChange={onChange}
        />
      </FormControl>
    </div>
  );
};

export default SalaryField;
