import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import SettingsIcon from "@mui/icons-material/Settings";

import { useNavigate, useLocation, matchRoutes } from "react-router-dom";
import Footer from "./Footer";
import { connect } from "react-redux";
import { setCurrentPage } from "../Store/Actions/actions.app";
import styles from "../Stylesheets/Modules/sideMenuBar.module.scss";

const drawerWidth = 240;

const SideMenuBar = (props) => {
  const history = useNavigate();

  const menuItems = [
    // {
    //   key: "dashboard",
    //   label: "Dashboard",
    //   icon: <GridViewIcon />,
    //   path: "/admin/dashboard",
    //   onClick: () => history("/admin/dashboard"),
    // },
    // {
    //   key: "onboarding",
    //   label: "Onboarding",
    //   icon: <SchoolIcon />,
    //   path: "/admin/onboarding",
    //   onClick: () => history("/admin/onboarding"),
    // },
    // {
    //   key: "candidates",
    //   label: "All Candidates",
    //   icon: <PeopleIcon />,
    //   path: "/admin/candidates",
    //   onClick: () => history("/admin/candidates"),
    // },
    {
      key: "cohorts",
      label: `${props.customization.cohort_label}` || 'Cohorts',
      icon: <LeaderboardIcon />,
      path: "/admin/cohorts",
      onClick: () => history("/admin/cohorts"),
    },
    {
      key: "settings",
      label: "Settings",
      icon: <SettingsIcon />,
      path: "/admin/settings",
      onClick: () => history("/admin/settings"),
    },
  ];

  const useCurrentPath = () => {
    const location = useLocation();
    const routeObj = matchRoutes(menuItems, location);
    const route = routeObj?.[0]?.route;

    if (!route) {
      return;
    }

    return { name: route.key, path: route.path };
  };

  const [page, setPage] = useState(useCurrentPath());

  const updateCurrentPath = (route) => {
    setPage({
      name: route.key,
      path: route.path,
    });
  };

  useEffect(() => {
    page && props.setCurrentPage(page);
  }, [props, page]);

  const drawer = (
    <div>
      <List>
        {menuItems.map((route) => {
          const { label, icon, onClick } = route;
          return (
            <ListItem
              key={label}
              className={
                props.currentPage?.name === route.key ? styles["selected"] : ""
              }
              classes={{
                root: styles["list-item-root"],
              }}
              disablePadding
              onClick={() => {
                updateCurrentPath(route);
                onClick();
              }}
            >
              <ListItemButton>
                <ListItemIcon sx={{ color: "#FFFFFF" }}>{icon}</ListItemIcon>

                <ListItemText>
                  <span style={{ fontFamily: props.themeInfo[0]?.fontfamily }}>
                    {label}
                  </span>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
        "@media(max-width:767px)": {
          width: 60,
          flexShrink: 0,
        },
      }}
    >
      <Drawer
        className={styles["drawer-styles"]}
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            marginTop: "4.4rem",
            height: "calc(100% - 4.375rem)",

            background:
              props.themeInfo[0]?.primarycolor === ""
                ? "#0e1555"
                : props.themeInfo[0]?.primarycolor,
            color: "#FFFFFF",
          },
          "@media(max-width:767px)": {
            display: { xs: "block", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: 60,
              marginTop: "4.4rem",
              height: "calc(100% - 4.375rem)",
              overflow: "hidden",
              background:
                props.themeInfo[0]?.primarycolor === ""
                  ? "#0e1555"
                  : props.themeInfo[0]?.primarycolor,
              color: "#FFFFFF",
            },
          },
        }}
        open
      >
        {drawer}
        <Footer />
      </Drawer>
    </Box>
  );
};

const mapStateToProps = ({ app, user }) => {
  return {
    currentPage: app.currentPage,
    customization: user.customization,
    themeInfo: user.themeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPage: (value) => dispatch(setCurrentPage(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenuBar);
