import { connect } from "react-redux";
import { Box } from "@mui/system";
import styles from "../../../../Stylesheets/Modules/Admin/Students/studentProfile/studentProfile.module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
function StudentSkills({ skills, themeInfo }) {
  const [studentSkills, setStudentSkills] = useState([])
  const history = useNavigate();
  useEffect(() => {
    if (skills)
      setStudentSkills(skills)
  }, [skills])
  return (
    <Box className={styles["personal-details-box"]}>
      <Box className={styles["personal-details-header"]}>
        <Box
          className={styles["profile-header"]}
          style={{
            background:
              themeInfo[0]?.primarycolor !== "#0e1555"
                ? themeInfo[0]?.primarycolor
                : "",
            fontFamily: themeInfo[0]?.fontfamily,
          }}
        >
          <Box className={styles["profile-header-name"]}>Skills</Box>
        </Box>
      </Box>

      {studentSkills.length != 0 ? <Box className={styles["skills-content-box"]}>
        {studentSkills?.map((skill, index) => (
          <p className={styles["skills-content"]} key={index}>
            {skill?.skill}
          </p>
        ))}
      </Box> :
        <div className={styles["no-skills-found"]}>
          Click <div className={styles["redirection"]} onClick={() => history("/cohorts")}> here </div> to start earning !
        </div>
      }
    </Box>
  );
}

const mapStateToProps = ({ user }) => ({
  skills: user?.learnerProfileDetails?.profileDetails?.skills,
  themeInfo: user.themeInfo,
});


export default connect(mapStateToProps)(StudentSkills);
