import API from "../../Helpers/axiosInit";
import { setAppLoading } from "./actions.app";

export const setAllPrograms = (allPrograms) => {
  return {
    type: "SET_ALL_PROGRAMS",
    payload: {
      allPrograms,
    },
  };
};

export const getAllPrograms = (orgId) => async (dispatch) => {
  dispatch(setAppLoading(true, "getAllPrograms - true"));
  try {
    const res = await API.get(`/programs/${orgId}`);
    const { data: resData } = res;
    dispatch(setAllPrograms(resData));
  } catch (err) {
  } finally {
    dispatch(setAppLoading(false, "getAllPrograms - false"));
  }
};

export const createNewProgram = (programData) => async (dispatch) => {
  dispatch(setAppLoading(true, "createProgram - true"));
  try {
    await API.post(`/programs`, {
      ...programData,
    });
    dispatch(getAllPrograms(programData.organizationId));
  } catch (err) {
  } finally {
    dispatch(setAppLoading(false, "createProgram - false"));
  }
};
export const updateProgram = (programId, programData) => async (dispatch) => {
  dispatch(setAppLoading(true, "updateProgram - true"));
  try {
    await API.put(`/programs/${programId}`, {
      ...programData,
    });
  } catch (err) {
  } finally {
    dispatch(setAppLoading(false, "updateProgram - true"));
  }
};
