import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Card, Container } from "@mui/material";
import styles from "../Stylesheets/Modules/themeSetting.module.scss";

import { toast } from "react-hot-toast";
import ConfirmDialog from "../Reusable/Modals/ConfirmDialog";
import { FilledButton } from "../Components/StyledComponents/Button.style";
import {
  StyledArrowBackIcon,
  StyledRotateLeftIcon,
} from "../Components/StyledComponents/Icons.style";
import { getTheme, setTheme, updateTheme } from "../Store/Actions/actions.user";
import { connect } from "react-redux";
import { logPageView } from "../Utils/analytics";
import CropImageModal from "../Reusable/Modals/CropImageModal";
import ImageUploader from "../Reusable/ImageUploader";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
export const ThemeSetting = (props) => {
  const [file, setFile] = useState();
  const [themeData, setThemeData] = useState([]);
  const [logoUrl, setLogoUrl] = useState("");
  const [originalFileName, setOriginalFileName] = useState("");
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
  });
  const [cropDialogOpen, setCropDialogOpen] = useState(false);
  const { themeInfo } = props;

  useEffect(() => {
    setThemeData(themeInfo);
  }, [themeInfo]);
  function handleChange(event) {
    setFile(event[0]);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setLogoUrl(reader.result);
    });
    reader.readAsDataURL(event[0]);
    setOriginalFileName(event[0].name);
  }
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const [primarycolor, setPcolor] = useState();
  const [secondarycolor, setScolor] = useState();
  const [fonttype, setfonttype] = useState();

  const setPrimaryColor = (e) => {
    setPcolor(e.target.value);
  };
  const setSecondaryColor = (e) => {
    setScolor(e.target.value);
  };

  useEffect(() => {
    setPcolor(themeInfo[0]?.primarycolor);
    setScolor(themeInfo[0]?.secondarycolor);
    setfonttype(themeInfo[0]?.fontfamily);
  }, [props]);

  const handleSave = () => {
    if (themeInfo?.[0]?._id) {
      props.updateTheme(props.userInfo.orgId, themeInfo[0]._id, {
        primarycolor: primarycolor,
        secondarycolor: secondarycolor,
        fontfamily: fonttype,
        logourl: logoUrl,
      });
      toast.success("Theme Updated Successfully");
    } else {
      props.setTheme(props.userInfo.orgId, {
        primarycolor: primarycolor,
        secondarycolor: secondarycolor,
        fontfamily: fonttype,
        logourl: logoUrl,
      });
      toast.success("Theme Added Successfully");
    }
    const host =
      window.location.hostname === "localhost"
        ? "dev"
        : window.location.hostname.split(".")[0];
    props.getTheme(host);
    history("/admin/settings/themesettings");
  };

  useEffect(() => {
    setCropDialogOpen(true);
  }, [file]);

  const history = useNavigate();
  const fonts = [
    "Helvetica",
    "Arial",
    "Arial Black",
    "Verdana",
    "Tahoma",
    "Trebuchet MS",
    "Impact",
    "Gill Sans",
    "Times New Roman",
    "Georgia",
    "Palatino",
    "Baskerville",
    "Andalé Mono",
    "Courier",
    "Lucida",
    "Monaco",
    "Bradley Hand",
    "Brush Script MT",
    "Luminari",
    "Comic Sans MS",
  ];

  const handlefontchange = (e) => {
    setfonttype(e.target.value);
  };

  const deleteHandler = async () => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });

    await props.updateTheme(props.userInfo.orgId, themeInfo[0]._id, {
      primarycolor: "#0e1555",
      secondarycolor: " #142297",
      fontfamily: "Nunito",
      logourl: "",
    });
    window.location.reload();
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] Theme Settings Page");
  }, [location]);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }

  return (
    <div className={styles["main-div"]}>
      <Container
        maxWidth="xl"
        classes={{
          root: styles["main-container"],
        }}
      >
        <div
          className={styles["theme-container-header"]}
          style={{
            color: themeInfo[0]?.primarycolor,
            fontFamily: themeInfo[0]?.fontfamily,
          }}
        >
          <Breadcrumbs
            aria-label="breadcrumb"
            style={{
              color: props.themeInfo[0]?.secondarycolor,
            }}
          >
            <Link underline="hover" color="inherit" href="/admin/settings">
              Settings
            </Link>

            <Typography>
              <b>Theme Settings</b>
            </Typography>
          </Breadcrumbs>
        </div>
        <Card className={styles["modules-tiles-wrapper"]}>
          <span className={styles["heading-for-tiles"]}>
            Choose a theme color to personalise your dashboard:
          </span>
          <div className={styles["preview-section"]}>
            <div className={styles["left-side-preview"]}>
              <div className={styles["primary-color-section"]}>
                <div className={styles["label-section"]}>
                  <li>
                    {" "}
                    <label
                      htmlFor="primary_color"
                      className={styles["label-primary"]}
                    >
                      Set Primary Color
                    </label>
                  </li>
                </div>
                <div className={styles["input-color-section"]}>
                  <input
                    type="color"
                    value={primarycolor ? primarycolor : ""}
                    id="primary_color"
                    onChange={setPrimaryColor}
                    className={styles["input-primary-color"]}
                  />
                  {primarycolor}
                </div>
              </div>
              <div className={styles["secondary-color-section"]}>
                <div className={styles["label-section"]}>
                  <li>
                    {" "}
                    <label
                      htmlFor="secondary_color"
                      className={styles["label-secondary"]}
                    >
                      Set Secondry Color
                    </label>
                  </li>
                </div>
                <div className={styles["input-color-section"]}>
                  <input
                    type="color"
                    id="secondary_color"
                    value={secondarycolor ? secondarycolor : ""}
                    onChange={setSecondaryColor}
                    className={styles["input-secondary-color"]}
                  />
                  {secondarycolor}
                </div>
              </div>
              <div className={styles["font-type-section"]}>
                <div className={styles["label-section"]}>
                  <li>
                    {" "}
                    <label htmlFor="font-select">Choose font</label>
                  </li>
                </div>
                <div className={styles["input-section"]}>
                  <select
                    id="font-select"
                    name="font"
                    value={fonttype ? fonttype : ""}
                    onChange={handlefontchange}
                  >
                    {fonts.map((font, index) => {
                      return (
                        <option key={index} value={font}>
                          {font}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className={styles["upload-logo-section"]}>
                <div className={styles["label-section"]}>
                  <li>
                    {" "}
                    <label>Upload Logo</label>
                  </li>
                </div>
                <div className={styles["input-logo-section"]}>
                  <form onSubmit={handleSubmit}>
                    <ImageUploader
                      acceptedFormats={[
                        "image/svg",
                        "image/svg+xml",
                        "image/png",
                      ]}
                      supportedExtensions={[".svg", ".png"]}
                      customButton={
                        <label>
                          {!file
                            ? `Choose logo to upload (PNG, SVG)`
                            : file.name}
                        </label>
                      }
                      onDrop={handleChange}
                    />
                  </form>
                </div>
                <div className={styles["logo-display-section"]}>{/*  */}</div>
              </div>
            </div>
            <div className={styles["right-side-preview"]}>
              <div className={styles["preview-header"]}>Preview</div>
              <div className={styles["preview-window"]}>
                <div className={styles["preview-mini-window"]}>
                  <span
                    style={{
                      textAlign: "center",
                      color: secondarycolor,
                      fontFamily: fonttype,
                    }}
                  >
                    Lorem Ipsum
                  </span>
                  <span
                    style={{
                      fontFamily: fonttype,
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </span>
                  <FilledButton
                    style={{
                      backgroundColor: primarycolor,
                      color: "white",
                    }}
                    className={styles["preview-button"]}
                  >
                    Button
                  </FilledButton>
                </div>
              </div>
              {themeInfo[0]?.primarycolor !== "#0e1555" &&
                themeInfo[0]?.secondarycolor !== "#142297" &&
                themeInfo[0]?.fontfamily !== "Nunito" && (
                  <div className={styles["present-theme-preview"]}>
                    <div className={styles["present-header"]}>
                      Present Theme
                    </div>
                    <div className={styles["present-window"]}>
                      <div className={styles["present-mini-window"]}>
                        <span
                          style={{
                            fontFamily: themeInfo[0]?.fontfamily,
                            textAlign: "center",
                            fontSize: "14px",
                          }}
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor
                        </span>
                        <FilledButton
                          style={{
                            backgroundColor: themeInfo[0]?.primarycolor,
                            color: "white",
                          }}
                          className={styles["preview-button"]}
                        >
                          Button
                        </FilledButton>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>

          <div className={styles["save-section"]}>
            <Button
              className={styles["cancel-button"]}
              onClick={() => {
                history("/admin/settings/");
              }}
            >
              Cancel
            </Button>

            <FilledButton
              className={styles["save-button"]}
              onClick={handleSave}
              bgcolor={
                themeInfo[0]?.primarycolor
                  ? themeInfo[0]?.primarycolor
                  : "#0e1555"
              }
            >
              Save
            </FilledButton>

            <Button
              className={styles["reset-button"]}
              onClick={(e) => {
                e.stopPropagation();
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure you want to reset",
                  onConfirm: () => {
                    deleteHandler();
                  },
                });
              }}
            >
              <StyledRotateLeftIcon />
              Reset
            </Button>
            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
          </div>
          {logoUrl !== "" && (
            <CropImageModal
              imageToBeCropped={logoUrl}
              cropDialogOpen={cropDialogOpen}
              originalFileName={originalFileName}
              maxWidth={250}
              maxHeight={150}
              setCropDialogOpen={setCropDialogOpen}
              setFileURL={setLogoUrl}
              aspect={250 / 150}
            />
          )}
        </Card>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ app, user }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    themeInfo: user.themeInfo,
    userInfo: user.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTheme: (organisationId) => dispatch(getTheme(organisationId)),
    setTheme: (orgId, themeData) => dispatch(setTheme(orgId, themeData)),
    updateTheme: (organisationId, themeId, themeInfo) =>
      dispatch(updateTheme(organisationId, themeId, themeInfo)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ThemeSetting);
