import { styled } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LanguageIcon from "@mui/icons-material/Language";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArticleIcon from "@mui/icons-material/Article";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowForward from "@mui/icons-material/ArrowForward";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import PaletteIcon from "@mui/icons-material/Palette";
import AddIcon from "@mui/icons-material/Add";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import ClearIcon from "@mui/icons-material/Clear";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";

import PasswordIcon from "@mui/icons-material/Password";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
export const StyledArrowBackIcon = styled(ArrowBackIcon)(
  ({ primarycolor }) => ({
    color: primarycolor,
  })
);

export const StyledLanguageIcon = styled(LanguageIcon)(({ primarycolor }) => ({
  color: primarycolor,
}));
export const StyledCheckCircleIcon = styled(CheckCircleIcon)(
  ({ primarycolor }) => ({
    color: primarycolor,
  })
);

export const StyledRemoveRedEyeIcon = styled(RemoveRedEyeIcon)(
  ({ primarycolor }) => ({
    color: primarycolor,
  })
);

export const StyledArrowForwardIcon = styled(ArrowForwardIcon)(
  ({ primarycolor }) => ({
    color: primarycolor,
    backgroundColor: " rgba(255, 255, 255, 0.31)",
    borderRadius: " 3.125rem",
    padding: "0.3125rem",
    fontSize: "1.125rem",
    right: "1.25rem",
    top: "0.625rem",
  })
);

export const StyledMenuIcon = styled(MenuIcon)(({ primarycolor }) => ({
  color: primarycolor,
}));

export const StyledCloseIcon = styled(CloseIcon)(({ primarycolor }) => ({
  color: primarycolor,
}));

export const StyledChevronLeftIcon = styled(ChevronLeftIcon)(
  ({ primarycolor }) => ({
    color: primarycolor,
  })
);

export const StyledChevronRightIcon = styled(ChevronRightIcon)(
  ({ primarycolor }) => ({
    color: primarycolor,
  })
);

export const StyledPendingActionsIcon = styled(PendingActionsIcon)(
  ({ primarycolor }) => ({
    color: primarycolor,
  })
);

export const StyledKeyboardArrowUpIcon = styled(KeyboardArrowUpIcon)(
  ({ primarycolor }) => ({
    color: primarycolor,

    width: "1.5rem",
    height: "1rem",
  })
);

export const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)(
  ({ primarycolor }) => ({
    color: primarycolor,

    width: "1.5rem",
    height: "1rem",
  })
);

export const StyledArticleIcon = styled(ArticleIcon)(({ primarycolor }) => ({
  color: primarycolor,

  fontSize: "1.25rem",
}));

export const StyledVisibility = styled(Visibility)(({ primarycolor }) => ({
  color: primarycolor,
}));

export const StyledVisibilityOff = styled(VisibilityOff)(
  ({ primarycolor }) => ({
    color: primarycolor,
  })
);

export const StyledArrowForward = styled(ArrowForward)(({ primarycolor }) => ({
  color: primarycolor,
}));

export const StyledRotateLeftIcon = styled(RotateLeftIcon)(
  ({ primarycolor }) => ({
    color: primarycolor,
  })
);

export const StyledPaletteIcon = styled(PaletteIcon)(({ primarycolor }) => ({
  color: primarycolor,
}));

export const StyledAddIcon = styled(AddIcon)(({ primarycolor }) => ({
  color: primarycolor,
  backgroundColor: " rgba(255, 255, 255, 0.31)",
  borderRadius: "3.125rem",
  padding: "0.3125rem",
  fontSize: " 1.125rem",
  right: "1.25rem",
  top: "0.625rem",
}));

export const StyledAccessTimeIcon = styled(AccessTimeIcon)(
  ({ primarycolor }) => ({
    color: "#788188",
    width: "1.25rem",
    height: "1.25rem",
  })
);

export const StyledContentCopyIcon = styled(ContentCopyIcon)(
  ({ primarycolor }) => ({
    color: "#262626",
    width: "1.25rem",
    height: "1.25rem",
    marginRight: "0.625rem",
  })
);

export const StyledEditIcon = styled(EditIcon)(({ primarycolor }) => ({
  color: primarycolor,

  width: "1.25rem",
  height: "1.25rem",
  cursor: "pointer",
}));

export const StyledDeleteIcon = styled(DeleteIcon)(
  ({ primarycolor, width, height }) => ({
    color: primarycolor,
    width: width ? width : "1.25rem",
    height: height ? height : "1.25rem",
    cursor: "pointer",
  })
);

export const StyledDragHandleIcon = styled(DragHandleIcon)(
  ({ primarycolor }) => ({
    color: primarycolor,

    width: "1.25rem",
    height: "1.25rem",
    cursor: "pointer",
  })
);

export const StyledClearIcon = styled(ClearIcon)(({ primarycolor }) => ({
  color: primarycolor,
}));

export const StyledBorderColorOutlinedIcon = styled(BorderColorOutlinedIcon)(
  ({ primarycolor }) => ({
    color: primarycolor,
  })
);

export const StyledVisibilityOutlinedIcon = styled(VisibilityOutlinedIcon)(
  ({ primarycolor }) => ({
    color: primarycolor,
  })
);

export const StyledQuestionModeEditIcon = styled(ModeEditIcon)(
  ({ primarycolor, width, height }) => ({
    color: primarycolor,
    width: width ? width : "1.25rem",
    height: height ? height : "1.25rem",
    cursor: "pointer",
  })
);

export const StyledQuestionFolderCopyIcon = styled(FolderCopyIcon)(
  ({ primarycolor, width, height }) => ({
    color: primarycolor,
    width: width ? width : "1.25rem",
    height: height ? height : "1.25rem",
    cursor: "pointer",
  })
);
export const StyledPasswordIcon = styled(PasswordIcon)(({ primarycolor }) => ({
  color: primarycolor,
}));
export const StyledLogoutIcon = styled(LogoutIcon)(({ primarycolor }) => ({
  color: primarycolor,
}));

export const StyledNotificationIcon = styled(NotificationsIcon)(
  ({ primarycolor }) => ({
    color: primarycolor,
  })
);
export const StyledFilterAltIcon = styled(FilterAltIcon)(
  ({ primarycolor }) => ({
    color: primarycolor,
  })
);
