import * as Types from "../StaticTypes";

const initialState = {
  cohortInfo: {},
  cohorts: [],
  totalPages: 0,
  page: 0,
  total: 0,
  cohortData: [],
  applicationInfo: {},
  cohortApplicants: [],
  applicantsMetadata: {},
  cohortStudents: [],
  totalStudents: 0,
  videoMeta: {
    title: "",
    url: "",
  },
  progress: 0,
  onBoardingData: {},
  onBoardingSubmissions: [],
  additionalDetails: {
    dtaMandatory: false,
    videoInterviewMandatory: false,
    videoInterviewLink: "",
    dtaDocumentLink: "",
    autoEnroll: false,
  },
  singleLiveSessionDetail: {},
  studentCohortModuleProgress: {},
  cohortStats: {},
  certificatesandBadgesList: [],
  uploadProgress: {}
};

export default function cohort(state = initialState, action) {
  switch (action.type) {
    case Types.SET_COHORT_INFO:
      const { cohortInfo } = action.payload;
      return {
        ...state,
        cohortInfo: cohortInfo,
      };
    case Types.SET_ALL_COHORTS:
      const { cohorts } = action.payload;
      return {
        ...state,
        cohorts: cohorts,
      };
    case Types.SET_ADMIN_ALL_COHORTS:
      const { adminCohorts, totalPages, total, page } = action.payload;
      return {
        ...state,
        cohorts: adminCohorts,
        totalPages: totalPages,
        total: total,
        page: page,
      };
    case Types.SET_NEW_COHORT:
      const { cohortData } = action.payload;
      return {
        ...state,
        cohortData: cohortData,
      };
    case Types.SET_APPLICATION_INFO:
      const { applicationInfo } = action.payload;
      return {
        ...state,
        applicationInfo,
      };
    case Types.SET_COHORT_APPLICATIONS:
      const { applications, metadata } = action.payload;
      return {
        ...state,
        cohortApplicants: applications,
        applicantsMetadata: metadata,
      };
    case Types.SET_COHORT_STUDENTS:
      const { students } = action.payload;
      const totalCount = action.payload.totalStudents;
      return {
        ...state,
        cohortStudents: students,
        totalStudents: totalCount,
      };
    case Types.SET_VIDEO_METADATA:
      const { metaData } = action.payload;
      return {
        ...state,
        videoMeta: metaData,
      };
    case Types.SET_UPLOAD_PROGRESS:
      const { progress } = action.payload;
      return {
        ...state,
        progress: progress,
      };
    case Types.SET_CONTENT_VIDEO_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: action.payload,
      };
    case Types.SET_ONBOARDING_APPLICATION_DATA:
      const { data } = action.payload;
      return {
        ...state,
        onBoardingData: data,
      };
    case Types.SET_ONBOARDING_SUBMISSIONS:
      const { onBoardingSubmissions } = action.payload;
      return {
        ...state,
        onBoardingSubmissions: onBoardingSubmissions,
      };
    case Types.SET_COHORT_LIVE_SESSIONS:
      const { liveSessionDetails } = action.payload.liveSessionDetails;
      return {
        ...state,
        liveSessionDetails,
      };
    case Types.SET_COHORT_ADDITIONAL_DETAILS:
      const additionalDetails = action.payload.additionalDetails;
      const autoEnroll = action.payload.autoEnroll;
      additionalDetails["autoEnroll"] = autoEnroll;
      return {
        ...state,
        additionalDetails,
      };
    case Types.SET_SINGLE_LIVE_SESSION:
      const { singleLiveSessionDetail } = action.payload;
      return {
        ...state,
        singleLiveSessionDetail,
      };
    case Types.SET_STUDENT_COHORT_MODULE_PROGRESS:
      const { studentCohortModuleProgress } = action.payload;
      return {
        ...state,
        studentCohortModuleProgress,
      };
    case Types.SET_COHORT_STATS:
      const { cohortStats } = action.payload;
      return {
        ...state,
        cohortStats,
      };
    case Types.SET_COHORT_CERTIFICATES_AND_BADGES:
      const { certificatesandBadgesList } = action.payload;
      return {
        ...state,
        certificatesandBadgesList: certificatesandBadgesList
      }
    default:
      return state;
  }
}
