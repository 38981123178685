import { useState } from "react";
import { connect } from "react-redux";
import { Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import StudentFormHeader from "./StudentFormHeader";
import ProfileModal from "./modals/ProfileModal";

import { delUserProfileData } from "../../../../Store/Actions/actions.user";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "../../../../Stylesheets/Modules/Admin/Students/studentProfile/studentProfile.module.scss";
import RemoveIcon from "@mui/icons-material/Remove";
function StudentEducationDetails({
  educationDetails,
  delUserProfile,
  userId,
  themeInfo,
  preview,
  userUid,
}) {
  const [open, setOpen] = useState(false);
  const [eleindex, setEleIndex] = useState(0);
  const [mode, setMode] = useState("Add");

  const handleAddOnClick = (mode) => {
    setOpen(true);
    setMode(mode);
  };

  const handleEditOnClick = (index, mode) => {
    setEleIndex(index);
    setMode(mode);
    setOpen(true);
  };
  const handleDelete = (index) => {
    delUserProfile(index, userId, "education", userUid);
  };
  return (
    <Box className={styles["personal-details-box"]}>
      <ProfileModal
        eleindex={eleindex}
        mode={mode}
        modalName="EditEducationDetails"
        open={open}
        handleClose={() => setOpen(false)}
      />

      <StudentFormHeader
        headerName="Education"
        buttonName="Add"
        handleOnClick={() => handleAddOnClick("Add")}
        themeInfo={themeInfo}
        preview={preview}
      />
      {educationDetails?.length > 0 &&
        educationDetails.map((ele, index) => (
          <Box className={styles["profession-box"]} key={index}>
            <Box className={styles["profession-position"]}>
              <Box> {ele.school} </Box>
              {!preview && (
                <Box className={styles["profession-position-icons"]}>
                  <Box
                    className={styles["position-icon"]}
                    onClick={() => handleEditOnClick(index, "Edit")}
                  >
                    <EditIcon style={{ cursor: "pointer" }} />
                  </Box>
                  <Box
                    className={styles["position-icon"]}
                    onClick={() => handleDelete(ele._id)}
                  >
                    <DeleteIcon style={{ cursor: "pointer" }} />
                  </Box>
                </Box>
              )}
            </Box>
            <Box className={styles["profession-date"]}>
              {" "}
              {`${ele.eduStartDate}`}
              <RemoveIcon />
              {!ele.currentInstitution ? `${ele.eduEndDate}` : "Present"}
            </Box>
            <Box className={styles["profession-bio"]}> {ele.degree} </Box>
            {ele.currentInstitution && (
              <Box className={styles["profession-current"]}>
                <Checkbox
                  className={styles["profile-checkbox"]}
                  checked={ele.currentInstitution}
                />
                Currently At this Institution
              </Box>
            )}
            {ele?.achievement?.length > 0 && (
              <Box className={styles["achievement-section"]}>
                Achievements :
                {ele.achievement.map((item, idx) => {
                  return item !== "" ? (
                    <div className={styles["achievement"]} key={idx}>
                      {item}
                    </div>
                  ) : (
                    <></>
                  );
                })}
              </Box>
            )}
          </Box>
        ))}
    </Box>
  );
}

const mapStateToProps = ({ user }) => ({
  userId: user.userDetails.id,
  educationDetails:
    user?.learnerProfileDetails?.profileDetails?.educationDetails,
  themeInfo: user.themeInfo,
  userUid: user.userUid,
});

const mapDispatchToProps = (dispatch) => ({
  delUserProfile: (index, userId, sectionName, userUid) =>
    dispatch(delUserProfileData(index, userId, sectionName, userUid)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentEducationDetails);
