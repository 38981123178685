import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import styles from "../Stylesheets/Modules/moduleOverview.module.scss";
import CohortBackNav from "../Components/CohortBackNav";
import { setModuleContent } from "../Store/Actions/actions.modules";
import { getCohortInfo } from "../Store/Actions/actions.cohort";
import { logPageView } from "../Utils/analytics";
import {
  getModuleCourseContent,
  getStudentModuleQuestionContent,
} from "../Store/Actions/actions.modules";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const ModuleOverview = (props) => {
  const navigate = useNavigate();
  const { cohortId, moduleId } = useParams();
  const studentId = props.userInfo.id;
  const [navigationSection, setNavigationSection] = useState("");
  const [moduleQuestionNavigationSection, setModuleQuestionNavigationSection] =
    useState("");
  const [searchParams] = useSearchParams();
  const { getCohortsDetails, cohortInfo } = props;
  const info = cohortInfo?.modules?.filter(
    (ele) => ele?.module === moduleId
  )[0];
  useEffect(() => {
    props.getModuleCourseContent(cohortId, studentId, moduleId);
    props.getStudentModuleQuestionContent(studentId, cohortId, moduleId);
  }, []);

  useEffect(() => {
    if (props.sections) {
      setNavigationSection(props.sections[0]?._id);
    }
  }, [props.sections]);

  useEffect(() => {
    if (props.moduleQuestions) {
      setModuleQuestionNavigationSection(props.moduleQuestions[0]?._id);
    }
  }, [props.moduleQuestions]);

  const sectionCards = [
    {
      title: "Content",
      key: "content",
      desc: "View course content",
      image: require("../Assets/Images/content-bg.png"),
      active:
        info?.moduleOptions?.filter((e) => e.key === "courseContent")[0]
          ?.active || false,
      routeName: "content",
    },
    {
      title: "Questions",
      key: "questions",
      desc: `${props.customization.module_label || "Module"} questions`,
      image: require("../Assets/Images/questions-bg.png"),
      active:
        info?.moduleOptions?.filter((e) => e.key === "moduleQuestions")[0]
          ?.active || false,
      routeName: "questions",
    },
    {
      title: "Sessions",
      key: "sessions",
      desc: "Live Session",
      image: require("../Assets/Images/sessions-bg.png"),
      active:
        info?.moduleOptions?.filter((e) => e.key === "liveSessions")[0]
          ?.active || false,
      routeName: "liveSessions",
    },
  ];

  // const shortSections = [
  //   {
  //     title: "Discuss with Peers",
  //     key: "discuss_with_peers",
  //     class: "discuss-with-peers",
  //     size: "small",
  //   },
  //   {
  //     title: "Additional Resources",
  //     key: "additional_resources",
  //     class: "additional-resources",
  //     size: "small",
  //   },
  // ];

  useEffect(() => {
    getCohortsDetails(cohortId);
  }, []);

  const location = useLocation();

  useEffect(() => {
    logPageView("[Student] Module Overview Page");
  }, [location]);
  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }
  const breadCrumps = (customization) => {
    return (
      <Breadcrumbs
        aria-label="breadcrumb"
        style={{
          color: "white",
        }}
      >
        <Link underline="hover" color="inherit" href="/cohorts">
          {customization.cohort_label || "Cohorts"}
        </Link>
        <Link underline="hover" color="inherit" href={`/cohort/${cohortId}`}>
          {searchParams.get("cohort")}
        </Link>
        <Link
          href={`/cohort/${cohortId}/curriculum?cohort=${searchParams.get(
            "cohort"
          )}`}
          underline="hover"
          color="inherit"
        >
          {customization.module_label}
        </Link>
        <Typography>
          <b>{searchParams.get("module")}</b>
        </Typography>
      </Breadcrumbs>
    );
  };
  return (
    <div>
      <CohortBackNav breadCrumps={breadCrumps(props.customization)} />

      <div className={styles["module-overview-container"]}>
        {navigationSection !== "" &&
          sectionCards.map((section) => {
            return (
              section?.active && (
                <div
                  data-event-name={`[Student] Clicked On ${section.title}`}
                  key={section.key}
                  className={`
                    ${styles["overview-section"]} 
                    ${section?.size ? styles[section?.size] : ""}
                    ${section?.active ? styles["active"] : ""}
                  `}
                  onClick={() => {
                    if (section.key === "courseContent")
                      props.setModuleContent([]);
                    section?.active &&
                      navigate(
                        `/cohort/${cohortId}/curriculum/${moduleId}/${
                          section.routeName
                        }?cohort=${searchParams.get(
                          "cohort"
                        )}&module=${searchParams.get("module")}&sectionId=${
                          section.key === "content"
                            ? navigationSection
                            : moduleQuestionNavigationSection
                        }`
                      );
                  }}
                >
                  <div className={styles["title"]}>{section.title}</div>
                  <div className={styles["desc"]}>{section.desc}</div>
                  <img
                    className={styles["bg-image"]}
                    src={section.image}
                    alt={section.title}
                  />
                </div>
              )
            );
          })}
        {/* <div className={styles["overview-section-small"]}>
          {shortSections.map((section) => {
            return (
              <div
                key={section.key}
                className={`${styles["overview-section"]} ${
                  styles[section.class]
                }`}
              >
                <div className={styles["title"]}>{section.title}</div>
              </div>
            );
          })}
        </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, cohort, modules, app }) => {
  return {
    customization: user.customization,
    cohortInfo: cohort.cohortInfo,
    userInfo: user.userDetails,
    sections: modules.sections.sections,
    moduleQuestions: modules.questionnaires,
    appLoadingStatus: app.appLoadingStatus,
    themeInfo: user.themeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getModuleCourseContent: (cohortId, studentId, moduleId) =>
      dispatch(getModuleCourseContent(cohortId, studentId, moduleId)),
    setModuleContent: (content) => dispatch(setModuleContent(content)),
    getCohortsDetails: (cohortId) => dispatch(getCohortInfo(cohortId)),
    getStudentModuleQuestionContent: (studentId, cohortId, moduleId) =>
      dispatch(getStudentModuleQuestionContent(studentId, cohortId, moduleId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleOverview);
