import React from "react";
import { connect } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const RequireAuth = (props) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  // Additional checks for the 'USER' role based on the conditions
  if (props?.role === "ORG_USER") {
    const userId = urlParams.get("user_id");
    const uniqueTaskKey = urlParams.get("unique_task_key");
    const stepNumber = urlParams.get("step_number");
    localStorage.setItem(
      "referrerObj",
      JSON.stringify({
        referrer: document.referrer.includes("evolveme") ? document.referrer : "",
        userId: userId,
        uniqueTaskKey: uniqueTaskKey,
        stepNumber: stepNumber,
      })
    );
  }

  return props?.role && props.allowedRoles.includes(props.role) ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

const mapStateToProps = ({ user }) => {
  return {
    role: user.userDetails?.role,
  };
};

export default connect(mapStateToProps, {})(RequireAuth);
