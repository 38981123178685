const environment = {
  stageVariable: process.env.NODE_ENV ? process.env.NODE_ENV : "development",
  baseUrl: process.env.REACT_APP_API_ENDPOINT_SQL
    ? process.env.REACT_APP_API_ENDPOINT_SQL
    : "https://dev-api.gritly.us",
  aws_project_region: process.env.REACT_APP_AWS_REGION
    ? process.env.REACT_APP_AWS_REGION
    : "us-west-1",
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
      ? process.env.REACT_APP_IDENTITY_POOL_ID
      : "us-west-1:28cd24be-1cc4-497f-a3e8-fa9d1879431f", // (required) - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_AWS_REGION
      ? process.env.REACT_APP_AWS_REGION
      : "us-west-1", // (required) - Amazon Cognito Region
    userPoolId: process.env.REACT_APP_USER_POOL_ID
      ? process.env.REACT_APP_USER_POOL_ID
      : "us-west-1_ElsWLMg8R", // (optional) - Amazon Cognito User Pool ID
    userPoolWebClientId_Student: process.env.REACT_APP_CLIENT_ID_STUDENT
      ? process.env.REACT_APP_CLIENT_ID_STUDENT
      : "3o4vtbt757bd2qfftoi7o5r8nq", // (optional) - Amazon Cognito Web Client ID (App client secret needs to be disabled)
    userPoolWebClientId_Admin: process.env.REACT_APP_CLIENT_ID
      ? process.env.REACT_APP_CLIENT_ID
      : "3o4vtbt757bd2qfftoi7o5r8nq", //change it once ******
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN
        ? process.env.REACT_APP_OAUTH_DOMAIN
        : "identity.gritly.us",
      scope: process.env.REACT_APP_SCOPE
        ? process.env.REACT_APP_SCOPE?.split(",")
        : ["phone", "email", "openid"],
      redirectSignIn: window.location.origin + "/cohorts",
      redirectSignOut: window.location.origin + "/cohorts",
      responseType: process.env.REACT_APP_RESPONSE_TYPE
        ? process.env.REACT_APP_RESPONSE_TYPE
        : "code",
    },
  },
};
export default environment;
