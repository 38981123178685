import { useState } from "react";
import { connect } from "react-redux";
import { Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import {
  setUserEducationData,
  editUserProfileData,
} from "../../../../../Store/Actions/actions.user";

import styles from "../../../../../Stylesheets/Modules/Admin/Students/studentProfile/studentProfile.module.scss";
import { DeleteOutlineTwoTone } from "@mui/icons-material";
import toast from "react-hot-toast";

function ModalEducationDetails(props) {
  const {
    eleindex,
    mode,
    userId,
    educationDetails,
    setEducationData,
    handleClose,
    editProfileData,
    userUid,
  } = props;
  const [school, setSchool] = useState(
    (mode === "Edit" && educationDetails[eleindex]?.school) || ""
  );
  const [degree, setDegree] = useState(
    (mode === "Edit" && educationDetails[eleindex]?.degree) || ""
  );
  const [eduStartDate, setStartDate] = useState(
    (mode === "Edit" && educationDetails[eleindex]?.eduStartDate) || ""
  );
  const [eduEndDate, setEndDate] = useState(
    (mode === "Edit" && educationDetails[eleindex]?.eduEndDate) || ""
  );
  const [achievement, setAchievement] = useState(
    (mode === "Edit" && educationDetails[eleindex]?.achievement) || []
  );
  const [currentInstitution, setCurrentInstitution] = useState(
    (mode === "Edit" && educationDetails[eleindex]?.currentInstitution) || false
  );

  const handleSubmit = () => {
    const data = {
      school,
      degree,
      eduStartDate,
      eduEndDate,
      achievements: achievement,
      currentInstitution,
    };
    mode === "Add"
      ? setEducationData(data, userId, userUid)
      : editProfileData(data, userId, "education", eleindex, userUid);
    handleClose();
  };

  const addachievements = () => {
    setAchievement([...achievement, ""]);
  };

  const handleDeleteAchievement = (index) => {
    const achievementArray = [...achievement];
    achievementArray.splice(index, 1);
    setAchievement([...achievementArray]);
  };

  const handleLinkChange = (index, e) => {
    let achievementArray = [...achievement];
    achievementArray[index] = e.target.value;
    setAchievement(achievementArray);
  };
  function isValidDate(dateString) {
    const date = new Date(dateString);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return !isNaN(date.getTime()) && date <= today;
  }
  function isStartDateMoreThanEndDate(startDate, endDate) {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    return date1 > date2;
  }

  const isSubmitButtonDisabled = () => {
    if (currentInstitution) {
      return school === "" || !isValidDate(eduStartDate);
    } else {
      return (
        school === "" ||
        (eduEndDate && !isValidDate(eduEndDate)) ||
        isStartDateMoreThanEndDate(eduStartDate, eduEndDate) ||
        (eduStartDate && !isValidDate(eduStartDate))
      );
    }
  };

  return (
    <Box className={styles["modal-Edu-box"]}>
      <Box className={styles["modal-Edu-heading"]}>
        <Box>{mode} Education</Box>
        <div className={styles["modal-heading-close"]} onClick={handleClose}>
          <CloseIcon />
        </div>
      </Box>

      <Box>
        <TextField
          label="School"
          placeholder="School"
          value={school}
          onChange={(e) => setSchool(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          className={styles["modal-Edu-textbox"]}
        />
      </Box>

      <Box>
        <TextField
          label="Degree"
          placeholder="Degree"
          value={degree}
          onChange={(e) => setDegree(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          className={`${styles["modal-Edu-textbox"]}`}
        />
      </Box>

      <Box className={styles["modal-Edu-fistLine"]}>
        <TextField
          label="Start Date"
          placeholder="DD/MM/YY"
          value={eduStartDate}
          onChange={(e) => setStartDate(e.target.value)}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          error={eduStartDate && !isValidDate(eduStartDate)}
          className={styles["modal-Edu-content"]}
        />
        {!currentInstitution && (
          <TextField
            label="End Date"
            placeholder="DD/MM/YY"
            value={eduEndDate}
            onChange={(e) => setEndDate(e.target.value)}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: new Date(Date.now() - 86400000).toISOString().split("T")[0],
            }}
            error={
              (eduEndDate && !isValidDate(eduEndDate)) ||
              isStartDateMoreThanEndDate(eduStartDate, eduEndDate)
            }
            className={styles["modal-Edu-content"]}
          />
        )}

        <Box className={styles["modal-Edu-text"]}>
          <Checkbox
            className={styles["modal-Edu-checkbox"]}
            checked={currentInstitution}
            value={currentInstitution}
            onChange={() => {
              if (
                !currentInstitution &&
                educationDetails.some(
                  (detail, index) =>
                    detail.currentInstitution &&
                    (mode === "Add" || index !== eleindex)
                )
              ) {
                toast.error("You can't have more than one current institution");
              } else {
                setCurrentInstitution(!currentInstitution);
              }
            }}
          />{" "}
          Currently At this Instutuion
        </Box>
      </Box>

      <Box className={styles["achivements-parent-wrapper"]}>
        <div className={styles["achivements-parent"]}>
          <div
            style={{
              fontFamily: props.themeInfo[0]?.fontfamily,
              color: props.themeInfo[0]?.primarycolor,
            }}
            className={styles["add-links"]}
            onClick={() => addachievements()}
          >
            <AddCircleIcon className={styles["modal-links-add"]} />
            Add Achievements
          </div>
        </div>
        <div className={styles["achievements-delete-wrapper"]}>
          {achievement.map((ele, index) => (
            <div className={styles["achievements-wrapper"]} key={index}>
              <TextField
                label="Acheivements"
                placeholder="Ex. Sales Intern"
                value={ele}
                onChange={(e) => handleLinkChange(index, e)}
                InputLabelProps={{
                  shrink: true,
                }}
                className={`${styles["modal-Edu-content"]} ${styles["achievements"]}`}
              />
              <DeleteOutlineTwoTone
                className={styles["delete-container"]}
                onClick={() => handleDeleteAchievement(index)}
              />
            </div>
          ))}
        </div>
      </Box>

      <Box className={styles["modal-Edu-add-box"]}>
        <Button
          className={styles["modal-Edu-add"]}
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitButtonDisabled()}
        >
          Add
        </Button>
      </Box>
    </Box>
  );
}

const mapStateToProps = ({ user }) => ({
  userId: user.userDetails.id,
  educationDetails:
    user.learnerProfileDetails?.profileDetails?.educationDetails,
  userUid: user.userUid,
  themeInfo: user.themeInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setEducationData: (data, userId, userUid) =>
    dispatch(setUserEducationData(data, userId, userUid)),
  editProfileData: (data, userId, mode, eleindex, userUid) =>
    dispatch(editUserProfileData(data, userId, mode, eleindex, userUid)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalEducationDetails);
