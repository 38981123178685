import React, { useState } from "react";
import {
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  FilledButton,
  OutlinedButton,
} from "../../../Components/StyledComponents/Button.style";
import { connect } from "react-redux";
import styles from "../../../Stylesheets/Modules/Admin/LiveSession/SetTime.module.scss";
import { useEffect } from "react";
import { toast } from "react-hot-toast";

const SetTime = (props) => {
  const [startDateAndTime, setStartDateAndTime] = useState(new Date());
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const hourDuration = new Array(25).fill(0).map((_, idx) => ({
    key: `${idx}`,
    value: `${idx}`,
  }));
  const minDuration = [
    {
      key: "1",
      value: "0",
    },
    {
      key: "2",
      value: "15",
    },
    {
      key: "3",
      value: "30",
    },
    {
      key: "4",
      value: "45",
    },
  ];

  const handleHour = (event) => {
    setHours(event.target.value);
  };

  const handleMinute = (event) => {
    setMinutes(event.target.value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };

  useEffect(() => {
    if (props.dialougeStatus && props.editMode && props.timeSlotModule) {
      const timeSlotToEdit = props.timeSlots.find(
        (slot) => slot.moduleId === props.timeSlotModule
      );

      if (timeSlotToEdit) {
        setStartDateAndTime(new Date(timeSlotToEdit.start));
        const startTime = new Date(timeSlotToEdit.start);
        const endTime = new Date(timeSlotToEdit.end);
        const timeDifference = endTime - startTime;

        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );

        setHours(hours.toString());
        setMinutes(minutes.toString());
      }
    }
  }, [
    props.dialougeStatus,
    props.editMode,
    props.timeSlotModule,
    props.timeSlots,
  ]);

  const validateDuration = () => {
    if (
      (hours === "" && minutes === "") ||
      (hours === "0" && minutes === "0")
    ) {
      toast.error("Duration cannot be zero");
      return false;
    }
    if (hours === "" || minutes === "") {
      toast.error("Please select both hours and minutes");
      return false;
    }
    return true;
  };

  const getStartDateandEndDate = () => {
    const startDate = new Date(startDateAndTime);
    const addedTime =
      startDate.getTime() +
      Number(hours) * 60 * 60 * 1000 +
      Number(minutes) * 60 * 1000;

    const endDate = new Date(addedTime);

    return {
      start: startDate.toISOString(),
      end: endDate.toISOString(),
    };
  };

  const updateData = () => {
    if (validateDuration()) {
      const updatedSelectedDatesAndIds = [...props.timeSlots];
      const existingSlotIndex = updatedSelectedDatesAndIds.findIndex(
        (slot) => slot.moduleId === props.timeSlotModule
      );

      if (existingSlotIndex !== -1) {
        updatedSelectedDatesAndIds[existingSlotIndex] = {
          moduleId: props.timeSlotModule,
          start: getStartDateandEndDate().start,
          end: getStartDateandEndDate().end,
        };
      } else {
        updatedSelectedDatesAndIds.push({
          moduleId: props.timeSlotModule,
          start: getStartDateandEndDate().start,
          end: getStartDateandEndDate().end,
        });
      }

      props.setTimeSlots(updatedSelectedDatesAndIds);
      props.setDialougeStatus(false);
    }
  };

  return (
    <>
      <Dialog
        open={props.dialougeStatus}
        className={styles["set-time-wrapper"]}
      >
        <div className={styles["start-end-date-wrapper"]}>
          <div className={styles["input-fields-labels"]}>
            <span>
              Event Start Date And Time
              <span className={styles["mandatory"]}>*</span>
            </span>
            <FormControl
              sx={{ m: 1 }}
              size="small"
              classes={{
                root: styles["form-control-root"],
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  value={startDateAndTime}
                  onChange={(newValue) => setStartDateAndTime(newValue)}
                  minDate={new Date()}
                  renderInput={(params) => <TextField {...params} />}
                  minTime={
                    new Date(startDateAndTime).getDate() ===
                      new Date().getDate()
                      ? new Date()
                      : ""
                  }
                />
              </LocalizationProvider>
            </FormControl>
          </div>
        </div>
        <div className={styles["duration-wrapper"]}>
          <div className={styles["input-fields-labels"]}>
            <span>
              Duration <span className={styles["mandatory"]}>*</span>
            </span>
          </div>
          <div className={styles["form-wrapper"]}>
            <FormControl
              sx={{ m: 1, minWidth: 120 }}
              size="small"
              classes={{
                root: styles["form-control-root"],
              }}
            >
              <InputLabel>Hours</InputLabel>
              <Select
                value={hours}
                label="Hours"
                onChange={handleHour}
                MenuProps={MenuProps}
                className={styles["select-tag-root"]}
              >
                {hourDuration.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.value}>
                      {item.value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl
              sx={{ m: 1, minWidth: 120 }}
              size="small"
              classes={{
                root: styles["form-control-root"],
              }}
            >
              <InputLabel>Minutes</InputLabel>
              <Select
                value={minutes}
                label="Minutes"
                onChange={handleMinute}
                MenuProps={MenuProps}
                className={styles["select-tag-root"]}
              >
                {minDuration.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.value}>
                      {item.value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={styles["button-section"]}>
          <OutlinedButton
            variant="contained"
            className={styles["cancel-btn"]}
            bgcolor={
              props.themeInfo[0]?.primarycolor
                ? props.themeInfo[0]?.primarycolor
                : "#0e1555"
            }
            fontFamily={
              props.themeInfo[0]?.fontfamily
                ? props.themeInfo[0]?.fontfamily
                : "Nunito"
            }
            onClick={() => {
              // clearPopUpData();
              props.setDialougeStatus(false);
            }}
          >
            Cancel
          </OutlinedButton>
          <FilledButton
            variant="contained"
            bgcolor={
              props.themeInfo[0]?.primarycolor
                ? props.themeInfo[0]?.primarycolor
                : "#0e1555"
            }
            fontFamily={
              props.themeInfo[0]?.fontfamily
                ? props.themeInfo[0]?.fontfamily
                : "Nunito"
            }
            className={styles["create-btn"]}
            onClick={() => {
              updateData();
              // props.setDialougeStatus(false)
            }}
          >
            Update
          </FilledButton>
        </div>
      </Dialog>
    </>
  );
};
const mapStateToProps = ({ user }) => {
  return {
    themeInfo: user.themeInfo,
  };
};
export default connect(mapStateToProps, null)(SetTime);
