import React, { useEffect, useState } from "react";
import TextEditor from "../Components/TextEditor";
import styles from "../Stylesheets/Modules/TitleDescription.module.scss";

export default function EditorWrapper(props) {
  const {
    childRoot,
    content,
    setContent,
    readOnly,
    showPreview,
    root,
    childNode,
  } = props;
  // const [content, setContent] = useState("");
  const [isEditorLoading, setEditorLoading] = useState(true);
  useEffect(() => {
    // this is to re-render and get ckeditor as it is not rendering in initial load
    setEditorLoading(false);
  }, []);

  return (
    <div className={`${styles["content-wrapper"]} ${styles[`${root}`]}`}>
      <div className={styles["title-desc-wrapper"]}>
        <TextEditor
          root={childRoot}
          child={childNode}
          className
          showPreview={showPreview}
          readOnly={readOnly}
          content={content}
          setContent={setContent}
          isEditorLoading={isEditorLoading}
          setEditorLoading={setEditorLoading}
        />
      </div>
    </div>
  );
}
