import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { getStudentModuleQuestionContent } from "../../../Store/Actions/actions.modules";
import { StyledArrowBackIcon } from "../../../Components/StyledComponents/Icons.style";
import styles from "../../../Stylesheets/Modules/Admin/Students/viewStudentProgress.module.scss";
import { logPageView } from "../../../Utils/analytics";
import { Badge } from "@mui/material";

export const QuestionsProgress = (props) => {
  const history = useNavigate();
  const { cohortId, studentId, moduleId } = useParams();
  const [searchParams] = useSearchParams();
  const [questions, setQuestions] = useState([]);
  const { getStudentModuleQuestionContent } = props;

  useEffect(() => {
    getModuleQuestions(studentId, cohortId, moduleId);
  }, []);

  const getModuleQuestions = async (studentId, cohortId, moduleId) => {
    const questions = await getStudentModuleQuestionContent(
      studentId,
      cohortId,
      moduleId
    );
    if (questions) {
      setQuestions(questions);
    }
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] Questionnaire Progress Page");
  }, [location]);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }

  return (
    <>
      <div className={styles["student-progress-menu-header"]}>
        <StyledArrowBackIcon
          sx={{ cursor: "pointer", marginRight: "0.625rem" }}
          onClick={() => {
            history(
              `/admin/cohort/students/${cohortId}/progress/${studentId}?cohort=${searchParams.get(
                "cohort"
              )}&moduleId=${searchParams.get(
                "moduleId"
              )}&student=${searchParams.get("student")}`
            );
          }}
        />{" "}
        {searchParams.get("student")}
      </div>
      <div className={styles["list-wrapper"]}>
        {questions?.map((section, key) => {
          return (
            <div
              className={styles["parent-wrap"]}
              key={key}
              onClick={() =>
                history(
                  `/admin/cohort/students/${cohortId}/${moduleId}/progress/${studentId}/questionnaires/${
                    section._id
                  }?cohort=${searchParams.get(
                    "cohort"
                  )}&moduleId=${searchParams.get(
                    "moduleId"
                  )}&student=${searchParams.get("student")}`
                )
              }
            >
              <div className={styles["title"]}>
                {section.title}
                {section.showBadge && (
                  <Badge
                    className={styles["section-badge"]}
                    color="error"
                    badgeContent={"New"}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
      {!questions.length && (
        <div className={styles["no-data-found"]}>No Sections Found</div>
      )}
    </>
  );
};

const mapStateToProps = ({ app }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStudentModuleQuestionContent: (studentId, cohortId, moduleId) =>
      dispatch(getStudentModuleQuestionContent(studentId, cohortId, moduleId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsProgress);
