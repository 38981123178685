import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { ButtonBase } from "@mui/material";

import styles from "../../../Stylesheets/Modules/Admin/Cohorts/cohorts.module.scss";

import ConfirmDialog from "../../../Reusable/Modals/ConfirmDialog";
import CohortFilterOptions from "./CohortFilterOptions";
import {
  getAllCohorts,
  setAllCohorts,
  updateCohortStatus,
  setCohortInfo,
  setVideoData,
  cloneCohort,
  deleteCohort,
} from "../../../Store/Actions/actions.cohort";
import { getAllPrograms } from "../../../Store/Actions/actions.program";
import { Switch } from "@mui/material";
import TopicIcon from "@mui/icons-material/Topic";
import {
  StyledAccessTimeIcon,
  StyledAddIcon,
  StyledContentCopyIcon,
  StyledEditIcon,
  StyledDeleteIcon,
} from "../../../Components/StyledComponents/Icons.style";
import { logPageView } from "../../../Utils/analytics";
import Pagination from "@mui/material/Pagination";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
const CreateNewCohort = (props) => {
  const history = useNavigate();
  const {
    appLoadingStatus,
    getAllCohorts,
    cohorts,
    updateCohortStatus,
    setVideoData,
    setCohortInfo,
    customization,
    cloneCohort,
    deleteCohort,
    userInfo,
    totalPages,
  } = props;

  const isCohortCreationEnabled = props.config.cohortCreation;

  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    order: "",
    programTitle: "",
    startDate: "",
    endDate: "",
    published: "",
  });

  useEffect(() => {
    getAllCohortsData();
  }, [page, search, filters]);

  const getAllCohortsData = async () => {
    // Combine filters and pagination into one object
    const queryParams = {
      search,
      page,
      limit: perPage,
      ...filters,
    };

    await getAllCohorts(queryParams, !Boolean(search));
  };

  const handleSearchChange = (newSearch) => {
    setSearch(newSearch);
    setPage(1); // Reset to first page on new search
  };

  const handleFilterChange = (filterData) => {
    console.log("filterData", filterData);
    setFilters({ ...filters, ...filterData });
    setPage(1); // Reset to first page on new filters
  };

  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
  });

  useEffect(() => {
    props.getAllPrograms(userInfo.orgId);
  }, []);

  const cloneHandler = (cohortId) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    cloneCohort(cohortId);
  };

  const deleteHandler = (cohortId) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    deleteCohort(cohortId);
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] Cohorts Page");
  }, [location]);

  if (appLoadingStatus) {
    return <div className="gif-loader" />;
  }
  return (
    <Container maxWidth="xl" classes={{ root: styles["main-container"] }}>
      <div
        className={styles["cohort-header"]}
        style={{
          color: props.themeInfo[0]?.secondarycolor,
          fontFamily: props.themeInfo[0]?.fontfamily,
        }}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{
            color: props.themeInfo[0]?.secondarycolor,
          }}
        >
          <Link underline="hover" color="inherit" href="/">
            {customization.cohort_label || "Cohorts"}
          </Link>
        </Breadcrumbs>

        <div className={styles["filter-options"]}>
          <CohortFilterOptions
            onFilterChange={handleFilterChange}
            setSearch={handleSearchChange}
            search={search}
            filters={filters}
          />
        </div>
      </div>
      {isCohortCreationEnabled === false && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="info">
            Adding Cohort is a premium feature, contact support@Gritly.us to
            Enable!
          </Alert>
        </Stack>
      )}
      <Container classes={{ root: styles["cohort-tiles-main-container"] }}>
        <Card classes={{ root: styles["create-cohort-tiles-wrapper"] }}>
          <ButtonBase disabled={!isCohortCreationEnabled}>
            <CardContent
              classes={{ root: styles["create-cohort-tiles-content"] }}
              onClick={() => {
                setCohortInfo({});
                setVideoData({
                  url: "",
                  title: "",
                });
                history("/admin/createNewCohort");
              }}
            >
              <StyledAddIcon />
              Create {customization.cohort_label || "Cohorts"}
            </CardContent>
          </ButtonBase>
        </Card>
        {cohorts.length > 0 &&
          cohorts.map((cohort, idx) => {
            return (
              <Card
                classes={{ root: styles["cohort-tiles-wrapper"] }}
                key={idx}
                onClick={() =>
                  history(
                    `/admin/cohortMenu/${cohort._id}?cohort=${cohort.title}`
                  )
                }
              >
                <CardContent classes={{ root: styles["cohort-tiles-content"] }}>
                  <div className={styles["cohort-tile-title"]}>
                    <span
                      className={styles["wrap-text"]}
                      ref={(node) => {
                        // Get the DOM node of the span
                        if (node) {
                          // Check if the text is truncated
                          const isTruncated =
                            node.offsetWidth < node.scrollWidth;
                          if (isTruncated) {
                            // Add the title attribute if the text is truncated
                            node.setAttribute("data-tooltip", cohort.title);
                          }
                        }
                      }}
                    >
                      {cohort.title}
                    </span>
                    <div className={styles["cohort-tile-options"]}>
                      {
                        /**
                         * commenting for the time being till it is implemented
                         */
                        false && <StyledContentCopyIcon />
                      }
                      <StyledEditIcon
                        onClick={(event) => {
                          event.stopPropagation();
                          history(`/admin/createNewCohort/${cohort._id}`, {
                            state: { ...cohort, editMode: true },
                          });
                        }}
                      />

                      <Switch
                        classes={{
                          checked: styles["pubnub-switch-root"],
                        }}
                        checked={cohort.published || false}
                        onChange={(e) => {
                          updateCohortStatus(e.target.checked, cohort._id);
                        }}
                        onClick={(e) => e.stopPropagation()}
                      />
                      <StyledContentCopyIcon
                        onClick={(e) => {
                          setConfirmDialog({
                            isOpen: true,
                            title: "Are you sure you want to clone ?",
                            onConfirm: () => {
                              cloneHandler(cohort._id);
                            },
                          });
                          e.stopPropagation();
                        }}
                      />
                      <StyledDeleteIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          setConfirmDialog({
                            isOpen: true,
                            title: "Are you sure you want to delete ?",
                            onConfirm: () => {
                              deleteHandler(cohort._id);
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  {cohort.startDate && cohort.endDate && (
                    <div className={styles["cohort-tile-date-wrapper"]}>
                      <div className={styles["cohort-tile-date-start"]}>
                        <StyledAccessTimeIcon />
                        <span className={styles["label"]}>
                          {"Starts :"} &nbsp;
                        </span>
                        <span className={styles["value"]}>
                          {new Date(cohort.startDate).toLocaleDateString(
                            "en-us",
                            { year: "numeric", month: "short", day: "numeric" }
                          )}
                        </span>
                      </div>
                      <div className={styles["cohort-tile-date-end"]}>
                        <span className={styles["label"]}>
                          {"Ends :"}&nbsp;
                        </span>
                        <span className={styles["value"]}>
                          {new Date(cohort.endDate).toLocaleDateString(
                            "en-us",
                            {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            }
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className={styles["cohort-tile-program-wrapper"]}>
                    <TopicIcon />
                    <div className={styles["label"]}>
                      {customization.program_label || "Program"}:
                    </div>
                    <div className={styles["value"]}>
                      {cohort?.program?.programName}
                    </div>
                  </div>
                </CardContent>
              </Card>
            );
          })}
      </Container>
      <Pagination
        className={styles["pagination"]}
        count={totalPages}
        page={page}
        onChange={handlePageChange}
      />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Container>
  );
};

const mapStateToProps = ({ cohort, app, user, config }) => {
  return {
    cohorts: cohort.cohorts,
    totalPages: cohort.totalPages,
    totalCohorts: cohort.total,
    currentPage: cohort.page,
    appLoadingStatus: app.appLoadingStatus,
    themeInfo: user.themeInfo,
    customization: user.customization,
    config: config.configData,
    userInfo: user.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAllCohorts: (cohort) => dispatch(setAllCohorts(cohort)),
    getAllCohorts: (queryParams, isSearch) =>
      dispatch(getAllCohorts(queryParams, isSearch)),
    updateCohortStatus: (publish, cohortId) =>
      dispatch(updateCohortStatus(publish, cohortId)),
    setCohortInfo: (cohort) => dispatch(setCohortInfo(cohort)),
    setVideoData: (video) => dispatch(setVideoData(video)),
    cloneCohort: (cohortId) => dispatch(cloneCohort(cohortId)),
    deleteCohort: (cohortId) => dispatch(deleteCohort(cohortId)),
    getAllPrograms: (orgId) => dispatch(getAllPrograms(orgId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewCohort);
