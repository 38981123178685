import * as Types from "../StaticTypes";

const initialState = {
  modules: [],
  creditsEnabled: false,
  moduleInfo: {},
  sections: [],
  questionnaires: [],
  moduleOptions: [
    {
      id: "1",
      key: "courseContent",
      label: "Course Content",
      active: true,
      weightage: 0,
    },
    {
      id: "2",
      key: "moduleQuestions",
      label: "Module Questions",
      active: true,
      weightage: 0,
    },
    {
      id: "3",
      key: "liveSessions",
      label: "Live Sessions",
      active: false,
      weightage: 0,
    },
  ],
  TimeTracking: [],
  moduleSkillsList: []
};

export default function module(state = initialState, action) {
  switch (action.type) {
    case Types.SET_ALL_MODULES:
      const { allModules, creditsEnabled } = action.payload;
      return {
        ...state,
        creditsEnabled,
        modules: allModules,
      };
    case Types.SET_MODULES_INFO:
      const { moduleInfo } = action.payload;
      return {
        ...state,
        moduleInfo: moduleInfo,
      };
    case Types.SET_MODULE_CONTENT:
      const { sections } = action.payload;
      return {
        ...state,
        sections: sections,
      };
    case Types.ADD_MODULE_CONTENT_SECTION:
      const { section } = action.payload;
      return {
        ...state,
        sections: [...state.sections, section],
      };
    case Types.SET_MODULE_QUESTION_CONTENT:
      const { questionnaires } = action.payload;
      return {
        ...state,
        questionnaires: questionnaires,
      };
    case Types.SET_MODULE_OPTIONS:
      const { moduleOptions } = action.payload;
      return {
        ...state,
        moduleOptions: moduleOptions,
      };
    case Types.SET_TIMESPENT_ON_CONTENT:
      const { idx, time } = action.payload;
      return {
        ...state,
        TimeTracking: {
          ...state.TimeTracking,
          [idx]: time
        }
      };
    case Types.SET_MODULE_LIVE_SESSIONS:
      const { liveSessionDetails } = action.payload.liveSessionDetails;
      return {
        ...state,
        liveSessionDetails,
      };
    case Types.SET_MODULE_SKILLS_LIST:
      const { skills } = action.payload;
      return {
        ...state,
        moduleSkillsList: skills
      }
    default:
      return state;
  }
}
