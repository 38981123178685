import API from "../../Helpers/axiosInit";
import { setAppLoading } from "./actions.app";
import Logo from "../../Assets/Images/Gritly-logo.svg";
import toast from "react-hot-toast";

export const setUserInfo = (userInfo) => {
  return {
    type: "SET_USER_INFO",
    payload: {
      userInfo,
    },
  };
};

export const setUserUid = (userUid) => {
  return {
    type: "SET_USER_UID",
    payload: {
      userUid,
    },
  };
};

export const setOrglistData = (orgData) => {
  return {
    type: "SET_ORG_LIST_DETAILS",
    payload: {
      orgData,
    },
  };
};

export const setUserDetails = (userDetails) => {
  return {
    type: "SET_USER_DETAILS",
    payload: {
      userDetails,
    },
  };
};

export const setThemeInfo = (themeInfo) => {
  return {
    type: "SET_THEME_INFO",
    payload: {
      themeInfo,
    },
  };
};

export const setCustomizationInfo = (customization) => {
  return {
    type: "SET_CUSTOMIZATION_INFO",
    payload: {
      customization,
    },
  };
};

export const setImageURL = (url) => {
  return {
    type: "SET_IMAGE_URL",
    payload: {
      imageUrl: url,
    },
  };
};

export const setUserNameMobileAction = (firstName, lastName, mobileNo) => {
  return {
    type: "SET_USER_NAME_MOBILE",
    payload: {
      firstName,
      lastName,
      mobileNo,
    },
  };
};

export const setUserProfileDataAction = (data) => {
  return {
    type: "SET_PROFILE_DATA",
    payload: data,
  };
};

export const setUserPersonalDataAction = (data) => {
  return {
    type: "SET_USER_PERSONAL_DATA",
    payload: data,
  };
};

export const setUserEducationDataAction = (data) => {
  return {
    type: "SET_USER_EDUCATION_DATA",
    payload: data,
  };
};

export const setAdminBootcampDetails = (data) => {
  return {
    type: "SET_ADMIN_BOOTCAMP_DATA",
    payload: data,
  };
};

export const setAdminUserDetails = (data) => {
  return {
    type: "SET_ADMIN_USER_DATA",
    payload: data,
  };
};

export const setUserProfessionalDataAction = (data) => {
  return {
    type: "SET_USER_PROFESSIONAL_DATA",
    payload: data,
  };
};

export const setSkillsUserDataAction = (data) => {
  return {
    type: "SET_SKILLS_PROFILE_DATA",
    payload: data,
  };
};

export const setLearnerProfileData = (data) => {
  return {
    type: "SET_LEARNER_PROFILE_DATA",
    payload: data
  }
}

export const setAllPrompts = (data) => {
  return {
    type: "SET_ALL_PROMPTS",
    payload: data
  }
}

export const getTheme = (hostname) => async (dispatch) => {
  dispatch(setAppLoading(true, "getTheme-true"));
  try {
    const res = await API.get(`/theme/${hostname}`);
    const { data: resData } = res;
    dispatch(setThemeInfo(resData));
  } catch (err) {
    dispatch(
      setThemeInfo([
        {
          primarycolor: "#0e1555",
          secondarycolor: "#142297",
          fontfamily: "Nunito",
          logourl: Logo,
        },
      ])
    );
  } finally {
    dispatch(setAppLoading(false, "getTheme-false"));
  }
};

export const setTheme = (orgId, themeData) => async (dispatch) => {
  dispatch(setAppLoading(true, "setTheme-true"));
  const themeInfo = { orgId, ...themeData };
  try {
    await API.post(`/theme`, {
      ...themeInfo,
    });
  } catch (err) {
  } finally {
    dispatch(setAppLoading(false, "setTheme-false"));
  }
};

export const updateTheme =
  (organisationId, themeId, themeInfo) => async (dispatch) => {
    dispatch(setAppLoading(true, "updateTheme-true"));
    try {
      await API.put(`/theme/${themeId}`, {
        ...themeInfo,
      });
      const host = window.location.hostname === 'localhost' ? "dev" : window.location.hostname.split(".")[0];
      getTheme(host);
    } catch (err) {
    } finally {
      dispatch(setAppLoading(false, "updateTheme-false"));
    }
  };

export const updateLabels = (labelInfo) => async (dispatch) => {
  dispatch(setAppLoading(true, "updateLabels - true"));
  try {
    await API.put(`/organizations`, {
      ...labelInfo,
    });
    toast.success("Updated Labels Succesfully");
    window.location.reload();
  } catch (err) {
  } finally {
    dispatch(setAppLoading(false, "updateLabels - false"));
  }
};

export const setProfileImageUrl = (url, userId) => async (dispatch) => {
  try {
    await API.put(`/user/updateProfileImage`, {
      url: url,
      userId: userId,
    }).then((res) => {
      toast.success("Updated profile photo");
      dispatch(setImageURL(res.data.url));
      window.location.reload();
    });
  } catch {
    console.log("image upload to db failed");
  }
};

export const setUserPersonalData = (data, userId, userUid) => async (dispatch) => {
  try {
    await API.put("/user/setUserPersonalData", { data, userId }).then((res) => {
      toast.success("added personal data successfully");
      dispatch(setUserPersonalDataAction(res.data.profileData));
      dispatch(
        setUserNameMobileAction(
          res.data.firstName,
          res.data.lastName,
          res.data.mobileNo
        )
      );
    });
    dispatch(getLearnerProfileDetails(userUid))
  } catch (err) {
    console.log("error while uploading user personal data", err);
  }
};

export const setUserEducationData = (data, userId, userUid) => async (dispatch) => {
  try {
    await API.put("/user/setUserEducationData", { data, userId }).then(
      (res) => {
        toast.success("added Education data successfully");
        dispatch(getLearnerProfileDetails(userUid))
      }
    );
  } catch (err) {
    console.log("error while uploading user educational data", err);
  }
};

export const setUserProfessionalData = (data, userId, userUid) => async (dispatch) => {
  try {
    await API.put("/user/setUserProfessionalData", { data, userId }).then(
      (res) => {
        toast.success("added professional data");
        dispatch(setUserProfessionalDataAction(res.data));
      }
    );
    dispatch(getLearnerProfileDetails(userUid))
  } catch (err) {
    console.log("error while uploading user professional data", err);
  }
};

export const setSkillsUserData = (data, userId) => async (dispatch) => {
  try {
    await API.put("/user/setSkillsData", { data, userId }).then((res) => {
      toast.success("added skills successfully");
      dispatch(setSkillsUserDataAction(res.data));
    });
  } catch (err) {
    console.log("errro while updating skills data", err);
  }
};

export const editUserProfileData =
  (data, userId, sectionName, index, userUid) => async (dispatch) => {
    try {
      await API.put("/user/editUserProfessionalData", {
        data,
        userId,
        sectionName,
        index,
      }).then((res) => {
        toast.success("Edited profile successfully");
        dispatch(getLearnerProfileDetails(userUid))
      });
    } catch (err) {
      console.log("error while editing user profile data");
    }
  };

export const delUserProfileData =
  (index, userId, sectionName, userUid) => async (dispatch) => {
    try {
      await API.put("/user/delUserProfileData", {
        index,
        userId,
        sectionName,
      }).then((res) => {
        toast.error("Deleted profile successfully");
        dispatch(getLearnerProfileDetails(userUid));
      });
    } catch (err) {
      console.log("error while deleting user profile data");
    }
  };

export const getUserProfileDataAndSet =
  (userId, sectionName) => async (dispatch) => {
    try {
      await API.put("/user/getProfileDetails", { userId, sectionName }).then(
        (res) => {
          if (sectionName === "professional")
            dispatch(setUserProfessionalDataAction(res.data || {}));
          else if (sectionName === "education")
            dispatch(setUserEducationDataAction(res.data || {}));
        }
      );
    } catch (err) {
      console.log(err, "error in getting and setting the updated profile data");
    }
  };

export const getAdminBootcampDetails = (userId) => async (dispatch) => {
  dispatch(setAppLoading(true, "getAdminBootcampDetails-true"));
  try {
    const res = await API.get(`/user/bootcampDetails/${userId}`);
    const { data: resData } = res;
    dispatch(setAdminBootcampDetails(resData));
  } catch (err) {
  } finally {
    dispatch(setAppLoading(false, "getAdminBootcampDetails-false"));
  }
};

export const getAdminUserDetails = (userId) => async (dispatch) => {
  dispatch(setAppLoading(true, "getAdminUserDetails-true"));
  try {
    const res = await API.get(`/user/adminUserDetails/${userId}`);
    const { data: resData } = res;
    dispatch(setAdminUserDetails(resData));
  } catch (err) {
  } finally {
    dispatch(setAppLoading(false, "getAdminUserDetails-false"));
  }
};

export const updateBootcampDetails =
  (userId, bootcampDetails) => async (dispatch) => {
    dispatch(setAppLoading(true, "updateBootcampDetails-true"));
    try {
      const res = await API.put(
        `/user/updateBootcampDetails/${userId}`,
        bootcampDetails
      ).then(() => {
        toast.success("Updated successfully");
      });
      return res;
    } catch (err) {
      toast.error("Something went wrong");
    } finally {
      dispatch(setAppLoading(false, "updateBootcampDetails-false"));
    }
  };
export const updateAdminUserDetails =
  (userId, userDetails) => async (dispatch) => {
    dispatch(setAppLoading(true, "updateAdminUserDetails-true"));
    try {
      const res = await API.put(
        `/user/updateUserDetails/${userId}`,
        userDetails
      );
      if (res.status === 200) {
        dispatch(getAdminUserDetails(userId));
        toast.success("Updated successfully");
      }
    } catch (err) {
      toast.error("Email is used by another user");
    } finally {
      dispatch(setAppLoading(false, "updateAdminUserDetails-false"));
    }
  };


export const getLearnerProfileDetails = (userUId) => async (dispatch) => {
  dispatch(setAppLoading(true, "getLearnerProfileDetails-true"));
  try {
    const res = await API.get(`/public/profile/${userUId}`);
    const { data: resData } = res;
    dispatch(setLearnerProfileData(resData));
  } catch (err) {
  } finally {
    dispatch(setAppLoading(false, "getLearnerProfileDetails-false"))
  }
}

export const addPrompts = (prompt, promptType, promptReply, isActive, userUID, userId) => async (dispatch) => {
  dispatch(setAppLoading(true, "addprompt-true"));
  const prompts = [
    {
      prompt: prompt,
      promptType: promptType,
      promptReply: promptReply,
      isActive: isActive
    }
  ]
  try {
    const res = await API.put(`/user/updateUserPrompt/${userId}`,
      { prompts: prompts }
    ).then((res) => {
      toast.success("Added prompt successfully");
    });
    dispatch(dispatch(getLearnerProfileDetails(userUID)));
  } catch (err) {
  } finally {
    dispatch(setAppLoading(false, "addprompt-false"))
  }
}

export const updatePrompt = (promptId, prompt, promptType, promptReply, isActive, userUID, userId) => async (dispatch) => {
  dispatch(setAppLoading(true, "updatePrompt-true"));
  const prompts = [
    {
      _id: promptId,
      prompt: prompt,
      promptType: promptType,
      promptReply: promptReply,
      isActive: isActive
    }
  ]
  try {
    const res = await API.put(`/user/updateUserPrompt/${userId}`,
      { prompts: prompts }
    ).then((res) => {
      toast.success("Updated prompt successfully");
    });
    dispatch(dispatch(getLearnerProfileDetails(userUID)));
  } catch (err) {
  } finally {
    dispatch(setAppLoading(false, "updatePrompt-false"))
  }
}
export const deletePrompt = (id, prompt, promptType, promptReply, isActive, userUID, userId) => async (dispatch) => {
  dispatch(setAppLoading(true, "deletePrompt-true"));
  const prompts = [
    {
      _id: id,
      prompt: prompt,
      promptType: promptType,
      promptReply: promptReply,
      isActive: isActive
    }
  ]
  try {
    const res = await API.put(`/user/updateUserPrompt/${userId}`,
      { prompts: prompts }
    )
    if (res) {
      toast.success("Deleted prompt successfully");
    }
    dispatch(dispatch(getLearnerProfileDetails(userUID)));
  } catch (err) {
  } finally {
    dispatch(setAppLoading(false, "deletePrompt-false"))
  }
}
export const getAllPrompts = () => async (dispatch) => {
  dispatch(setAppLoading(true, "getallprompts-true"));
  try {
    const res = await API.get('/prompts');
    const { data: resData } = res;
    dispatch(setAllPrompts(resData));
  } catch (err) { }
  finally {
    dispatch(setAppLoading(false, "getallprompts-false"))
  }
}
