import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import MuiPhoneNumber from "material-ui-phone-number";
import { OutlinedInput } from "@mui/material";
import { updateAdminUserDetails } from "../../../Store/Actions/actions.user";
import styles from "../../../Stylesheets/Modules/Admin/Profile/adminUserDetailsEditModal.module.scss";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "9px",
};

const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
const isValidURL = (url) => /^(ftp|http|https):\/\/[^ "]+$|^$/.test(url);

const UserEditModal = ({
  open,
  handleClose,
  adminUserDetails,
  updateAdminUserDetails,
}) => {
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    linkedin: "",
    bio: "",
  });

  useEffect(() => {
    if (adminUserDetails) {
      setUnSetData();
    }
  }, [adminUserDetails]);

  const setUnSetData = () => {
    setUserDetails({
      firstName: adminUserDetails.firstName || "",
      lastName: adminUserDetails.lastName || "",
      email: adminUserDetails.email || "",
      mobileNo: adminUserDetails.mobileNo || "",
      linkedin: adminUserDetails.linkedin || "",
      bio: adminUserDetails.bio || "",
    });
  };

  const handleInputChange = useCallback((field, value) => {
    setUserDetails((prevDetails) => ({ ...prevDetails, [field]: value }));
  }, []);

  const updateUserDetails = () => {
    updateAdminUserDetails(adminUserDetails._id, userDetails);
    closeModal();
  };

  const closeModal = () => {
    setUnSetData();
    handleClose();
  };

  const isUpdateDisabled =
    !isValidEmail(userDetails.email) || !isValidURL(userDetails.linkedin);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles["modal-heading"]}>
          <div className={styles["heading"]}>Edit User Details</div>
          <div className={styles["close-icon"]}>
            <CloseIcon onClick={closeModal} />
          </div>
        </div>
        <div className={styles["form-wrapper"]}>
          <TextField
            label="First Name"
            variant="outlined"
            fullWidth
            value={userDetails.firstName}
            onChange={(e) => handleInputChange("firstName", e.target.value)}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            fullWidth
            value={userDetails.lastName}
            onChange={(e) => handleInputChange("lastName", e.target.value)}
          />
          <TextField
            label="Email"
            error={!isValidEmail(userDetails.email)}
            variant="outlined"
            fullWidth
            value={userDetails.email}
            onChange={(e) => handleInputChange("email", e.target.value)}
          />
          <MuiPhoneNumber
            label="Mobile"
            defaultCountry={"us"}
            variant={"outlined"}
            value={userDetails.mobileNo}
            fullWidth
            onChange={(val) => handleInputChange("mobileNo", val)}
          />
          <TextField
            label="Linkedin"
            error={!isValidURL(userDetails.linkedin)}
            variant="outlined"
            fullWidth
            value={userDetails.linkedin}
            onChange={(e) => handleInputChange("linkedin", e.target.value)}
          />
          <OutlinedInput
            placeholder="Bio"
            fullWidth
            multiline
            value={userDetails.bio}
            onChange={(e) => handleInputChange("bio", e.target.value)}
          />
        </div>
        <div className={styles["button-wrapper"]}>
          <Button className={styles["cancel-button"]} onClick={closeModal}>
            Cancel
          </Button>
          <Button
            className={`${styles["update-button"]} ${
              isUpdateDisabled ? styles["disabled"] : ""
            }`}
            onClick={updateUserDetails}
            disabled={isUpdateDisabled}
          >
            Update
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

const mapStateToProps = ({ user }) => ({
  adminUserDetails: user.adminUserDetails,
});

const mapDispatchToProps = {
  updateAdminUserDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEditModal);
