import { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MuiPhoneNumber from "material-ui-phone-number";
import { setUserPersonalData } from "../../../../../Store/Actions/actions.user";
import InputAdornment from "@mui/material/InputAdornment";
import styles from "../../../../../Stylesheets/Modules/Admin/Students/studentProfile/studentProfile.module.scss";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import SelfLinkModal from "./SelfLinkModal";
import { useLoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import { DeleteOutlineTwoTone } from "@mui/icons-material";

const libraries = ["places"];
const LocationField = ({ label, placeholder, value, onChange, onError }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_PLACES_API_KEY,
    libraries,
  });

  const searchBoxRef = useRef();
  const [inputValue, setInputValue] = useState(value);
  const [isError, setIsError] = useState(false);

  const onPlacesChanged = useCallback(() => {
    const places = searchBoxRef.current.getPlaces();
    if (places && places.length > 0) {
      onChange(places[0].formatted_address, isError);
      setInputValue(places[0].formatted_address);
      setIsError(false);
      onError(false);
    }
  }, [onChange, onError]);

  return (
    <>
      {isLoaded && (
        <div className={styles["modal-personal-location-selector"]}>
          <StandaloneSearchBox
            onLoad={(ref) => (searchBoxRef.current = ref)}
            onPlacesChanged={onPlacesChanged}
          >
            <TextField
              label={label}
              placeholder={placeholder}
              InputLabelProps={{
                shrink: true,
              }}
              className={styles["modal-personal-location-content"]}
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
                setIsError(true);
                onError(true);
              }}
              error={isError}
              helperText={
                isError ? "Please select a value from the dropdown." : ""
              }
            />
          </StandaloneSearchBox>
        </div>
      )}
    </>
  );
};

function ModalPersonalDetails(props) {
  const { userInfo, setPersonalDetails, handleClose, profileDetails } = props;
  const fullName = userInfo.firstName.concat(" ", userInfo.lastName);

  const [name, setName] = useState(fullName || "");
  const [mobile, setMobile] = useState(userInfo.mobileNo);
  const [country, setCountry] = useState(profileDetails?.country || "");
  const [city, setCity] = useState(profileDetails?.city || "");
  const [bio, setBio] = useState(profileDetails?.bio || "");
  const [selfLinks, setSelfLinks] = useState(profileDetails?.socialLinks || []);
  const [checked, setChecked] = useState(profileDetails?.openForWork || false);
  const [selfLinkModal, setSelfLinkModal] = useState(false);
  const [typeOfLink, setTypeOfLink] = useState("");
  const [url, setUrl] = useState("");
  const [locationError, setLocationError] = useState(false);

  const handleSubmit = () => {
    const data = {
      name,
      mobileNo: mobile,
      country,
      city,
      bio,
      openForWork: checked,
      socialLinks: [...selfLinks],
    };
    setPersonalDetails(data, userInfo.id, props.userUid);
    handleClose();
  };
  useEffect(() => {
    if (selfLinks?.length === 0)
      setSelfLinks([
        {
          typeOfLink: "facebook",
          url: "",
        },
        {
          typeOfLink: "linkedin",
          url: "",
        },
        {
          typeOfLink: "twitter",
          url: "",
        },
        {
          typeOfLink: "github",
          url: "",
        },
      ]);
  }, []);
  const handleLinkChange = () => {
    let singleLink = {
      typeOfLink: typeOfLink,
      url: url,
    };
    setSelfLinks([...selfLinks, singleLink]);
    setSelfLinkModal(false);
  };
  const handleChange = (index, newValue) => {
    const updatedSelfLinks = [...selfLinks];
    updatedSelfLinks[index].url = newValue;
    setSelfLinks(updatedSelfLinks);
  };

  const handleDeleteSelfLinks = (index) => {
    const updatedSelfLinks = [...selfLinks];
    updatedSelfLinks.splice(index, 1);
    setSelfLinks(updatedSelfLinks);
  };

  return (
    <Box className={styles["modal-personal-box"]}>
      <Box className={styles["modal-personal-heading"]}>
        <Box>Edit Profile</Box>
        <div className={styles["modal-heading-close"]} onClick={handleClose}>
          <CloseIcon />
        </div>
      </Box>

      <Box className={styles["modal-personal-fistLine"]}>
        <TextField
          label="Name"
          placeholder="Name"
          InputLabelProps={{
            shrink: true,
          }}
          className={styles["modal-personal-content"]}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <MuiPhoneNumber
          label="Mobile No"
          data-cy="user-phone"
          className={styles["modal-personal-content"]}
          defaultCountry={"us"}
          variant={"outlined"}
          value={mobile}
          fullWidth
          onChange={(val) => setMobile(val)}
        />
        <LocationField
          className={styles["modal-personal-content-location"]}
          label="Location"
          placeholder="Location"
          value={city}
          onChange={setCity}
          onError={(val) => setLocationError(val)}
        />
      </Box>
      <Box className={styles["modal-personal-secondLine"]}>
        {/* <LocationField
          className={styles["modal-personal-content-location"]}
          label="Country"
          placeholder="Country"
          value={country}
          onChange={setCountry}
        /> */}
      </Box>
      <Box>
        <TextField
          label="Bio"
          placeholder="Bio"
          InputLabelProps={{
            shrink: true,
          }}
          className={styles["modal-personal-bio"]}
          value={bio}
          onChange={(e) => setBio(e.target.value)}
        />
      </Box>

      <Box className={styles["links-wrapper"]}>
        <div className={styles["self-link-parent"]}>
          <div
            style={{
              fontFamily: props.themeInfo[0]?.fontfamily,
              color: props.themeInfo[0]?.primarycolor,
            }}
            className={styles["add-links"]}
            onClick={() => setSelfLinkModal(true)}
          >
            <AddCircleIcon className={styles["modal-links-add"]} />
            Add Self Link
          </div>
        </div>
        <div className={styles["self-link-delete-wrapper"]}>
          {selfLinks?.map((link, index) => (
            <div className={styles["self-links-wrapper"]} key={index}>
              <TextField
                label={link?.typeOfLink}
                placeholder={link?.typeOfLink}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {link?.typeOfLink === "github" ? (
                        <GitHubIcon />
                      ) : link?.typeOfLink === "twitter" ? (
                        <TwitterIcon />
                      ) : link?.typeOfLink === "facebook" ? (
                        <FacebookIcon />
                      ) : link?.typeOfLink === "linkedin" ? (
                        <LinkedInIcon />
                      ) : (
                        ""
                      )}
                    </InputAdornment>
                  ),
                }}
                className={`${styles["modal-personal-content"]} ${styles["self-links-content"]}`}
                value={link?.url}
                onChange={(e) => handleChange(index, e.target.value)}
              />
              <DeleteOutlineTwoTone
                className={styles["delete-container"]}
                onClick={() => handleDeleteSelfLinks(index)}
              />
            </div>
          ))}
        </div>
      </Box>

      <Box className={styles["modal-personal-text"]}>
        Are you open for work?
      </Box>
      <Box className={styles["modal-personal-checkbox-container"]}>
        <div>
          <Checkbox
            className={styles["modal-personal-checkbox"]}
            value={checked}
            checked={checked}
            onChange={() => setChecked(!checked)}
          />{" "}
        </div>
        <div>
          <span>Yes I’m currently seeking employment</span>
        </div>
      </Box>

      <Box className={styles["modal-personal-save-box"]}>
        <Button
          className={styles["modal-personal-save"]}
          variant="contained"
          onClick={handleSubmit}
          disabled={locationError}
        >
          Save
        </Button>
      </Box>
      <SelfLinkModal
        open={selfLinkModal}
        setOpen={setSelfLinkModal}
        setTypeOfLink={setTypeOfLink}
        setUrl={setUrl}
        handleLinkChange={handleLinkChange}
      />
    </Box>
  );
}

const mapStateToProps = ({ user }) => ({
  userInfo: user.userDetails,
  profileDetails: user.learnerProfileDetails.profileDetails,
  userUid: user.userUid,
  themeInfo: user.themeInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setPersonalDetails: (data, userId, userUid) =>
      dispatch(setUserPersonalData(data, userId, userUid)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalPersonalDetails);
