import React, { useEffect, useState } from "react";
import styles from "../../../../Stylesheets/Modules/Admin/Students/studentProfile/studentProfile.module.scss";
import Box from "@mui/material/Box";
import { getLearnerProfileDetails } from "../../../../Store/Actions/actions.user";
import { connect } from "react-redux";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import RemoveIcon from "@mui/icons-material/Remove";
import { getUrlType } from "../../../../Helpers/utils";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { Modal } from "@mui/material";

// Configure pdfjs to use the worker from CDN
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function ShareableProfile(props) {
  const { userId } = useParams();
  const { learnerProfileDetails, themeInfo } = props;
  const [prompts, setPrompts] = useState();
  const [showMore, setShowMore] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [moreItem, setMoreItem] = useState();
  const [studentSkills, setStudentSkills] = useState([]);
  const [studentId, setStudentId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);

  const handleOpenModal = (pdfUrl, event) => {
    event.preventDefault();
    setSelectedPdf(pdfUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);
  const headerMapping = {
    City: "Location",
  };

  useEffect(() => {
    props.getLearnerProfileDetails(userId);
  }, []);

  useEffect(() => {
    setPrompts(learnerProfileDetails.prompts);
    setStudentSkills(learnerProfileDetails?.profileDetails?.skills);
    setStudentId(learnerProfileDetails?._id);
  }, [learnerProfileDetails]);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  const onClose = () => {
    setDialogOpen(false);
  };
  const dataObj = {
    City: learnerProfileDetails?.profileDetails?.city,
    Bio: learnerProfileDetails?.profileDetails?.bio,
  };

  const handleClick = (item) => {
    const urlType = getUrlType(item?.url);
    if (urlType === "email") {
      // Open the email client for email links
      window.location.href = "mailto:" + item?.url;
    } else if (urlType === "linkedin") {
      // Redirect to LinkedIn for LinkedIn profiles
      window.open(item?.url, "_blank");
    } else if (urlType === "facebook") {
      // Redirect to Facebook for Facebook profiles
      window.open(item?.url, "_blank");
    } else if (urlType === "twitter") {
      // Redirect to Twitter for Twitter profiles
      window.open(item?.url, "_blank");
    } else if (urlType === "github") {
      // Redirect to GitHub for GitHub profiles
      window.open(item?.url, "_blank");
    } else {
      // Open external links in a new tab
      window.open(item?.url, "_blank", "noopener noreferrer");
    }
  };

  return (
    <Box className={styles["profile-page"]}>
      <div
        className={styles["student-profile-banner"]}
        style={{
          background:
            themeInfo[0]?.primarycolor !== "#0e1555"
              ? themeInfo[0]?.primarycolor
              : "",
        }}
      >
        <Box className={styles["profile-banner-back"]}></Box>
        <Box className={styles["profile-banner-content"]}>
          <Badge
            overlap="circular"
            classes={{ badge: styles["badge-background"] }}
            className={styles["profile-camera-badge"]}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Avatar
              alt="Profile Photo"
              className={styles["profile-banner-photo"]}
              src={learnerProfileDetails.profileImgUrl}
              style={{
                backgroundColor: themeInfo[0]?.primarycolor,
              }}
            >
              {learnerProfileDetails.firstName?.[0]}
            </Avatar>
          </Badge>
          <Box className={styles["profile-banner-info"]}>
            <Box className={styles["profile-banner-info-name"]}>
              {learnerProfileDetails.firstName} {learnerProfileDetails.lastName}{" "}
            </Box>
          </Box>
        </Box>
      </div>
      <Box className={styles["student-profile-body"]}>
        <Box className={styles["personal-details-box"]}>
          <Box
            className={`${styles["profile-header"]}`}
            style={{
              background:
                themeInfo[0]?.primarycolor !== "#0e1555"
                  ? themeInfo[0]?.primarycolor
                  : "",
              fontFamily: themeInfo[0]?.fontfamily,
            }}
          >
            <Box className={styles["profile-header-name"]}></Box>Things I care
            about
          </Box>

          <Box className={styles["prompts-section"]}>
            {prompts
              ?.filter(
                (item) =>
                  item.promptType === "THINGS_I_CARE_ABOUT" && item.isActive
              )
              ?.map((item, key) => {
                return (
                  <Box key={key} className={styles["prompt"]}>
                    <div className={styles["question"]}>{item.prompt}</div>
                    <div className={styles["answer"]}>{item.promptReply}</div>
                    {item.promptReply.length > 120 && (
                      <div
                        className={styles["view-more"]}
                        onClick={() => {
                          toggleShowMore();
                          setMoreItem(item);
                          setDialogOpen(true);
                        }}
                      >
                        {!showMore && "View More"}
                      </div>
                    )}
                    {item.skills && (
                      <div className={styles["skills-section"]}>
                        <div className={styles["label"]}>Skills:</div>
                        <div className={styles["skill-display-wrapper"]}>
                          {item.skills.map((skill, key) => {
                            return (
                              <Box className={styles["skill"]} key={key}>
                                {skill}
                              </Box>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </Box>
                );
              })}
          </Box>
          <Dialog
            open={dialogOpen}
            onClose={() => {
              onClose();
              toggleShowMore();
            }}
            PaperProps={{
              style: { boxShadow: "none", backgroundColor: "transparent" },
            }}
          >
            <Box className={styles["prompt"]}>
              <div className={styles["question"]}>{moreItem?.prompt}</div>
              <div className={styles["answer-long"]}>
                {moreItem?.promptReply}
              </div>
              {moreItem?.skills && (
                <div className={styles["skills-section"]}>
                  <div className={styles["label"]}>Skills:</div>
                  <div className={styles["skill-display-wrapper"]}>
                    {moreItem?.skills.map((skill, key) => {
                      return (
                        <Box className={styles["skill"]} key={key}>
                          {skill}
                        </Box>
                      );
                    })}
                  </div>
                </div>
              )}
            </Box>
          </Dialog>
        </Box>
        <Box className={styles["personal-details-box"]}>
          <Box
            className={`${styles["profile-header"]}`}
            style={{
              background:
                themeInfo[0]?.primarycolor !== "#0e1555"
                  ? themeInfo[0]?.primarycolor
                  : "",
              fontFamily: themeInfo[0]?.fontfamily,
            }}
          >
            <Box className={styles["profile-header-name"]}></Box>Me and Work
          </Box>

          <Box className={styles["prompts-section"]}>
            {prompts
              ?.filter(
                (item) =>
                  item.promptType === "HOW_TO_WORK_WITH_ME" && item.isActive
              )
              ?.map((item, key) => {
                return (
                  <Box key={key} className={styles["prompt"]}>
                    <div className={styles["question"]}>{item.prompt}</div>
                    <div className={styles["answer"]}>{item.promptReply}</div>
                    {item.promptReply.length > 120 && (
                      <div
                        className={styles["view-more"]}
                        onClick={() => {
                          toggleShowMore();
                          setMoreItem(item);
                          setDialogOpen(true);
                        }}
                      >
                        {!showMore && "View More"}
                      </div>
                    )}
                    {item.skills && (
                      <div className={styles["skills-section"]}>
                        <div className={styles["label"]}>Skills:</div>
                        <div className={styles["skill-display-wrapper"]}>
                          {item.skills.map((skill, key) => {
                            return (
                              <Box className={styles["skill"]} key={key}>
                                {skill}
                              </Box>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </Box>
                );
              })}
          </Box>
          <Dialog
            open={dialogOpen}
            onClose={() => {
              onClose();
              toggleShowMore();
            }}
            PaperProps={{
              style: { boxShadow: "none", backgroundColor: "transparent" },
            }}
          >
            <Box className={styles["prompt"]}>
              <div className={styles["question"]}>{moreItem?.prompt}</div>
              <div className={styles["answer-long"]}>
                {moreItem?.promptReply}
              </div>
              {moreItem?.skills && (
                <div className={styles["skills-section"]}>
                  <div className={styles["label"]}>Skills:</div>
                  <div className={styles["skill-display-wrapper"]}>
                    {moreItem?.skills.map((skill, key) => {
                      return (
                        <Box className={styles["skill"]} key={key}>
                          {skill}
                        </Box>
                      );
                    })}
                  </div>
                </div>
              )}
            </Box>
          </Dialog>
        </Box>
        <Box className={styles["personal-details-box"]}>
          <Box className={styles["personal-details-header"]}>
            <Box
              className={`${styles["profile-header"]} ${styles["profile-header-first"]}`}
              style={{
                background:
                  themeInfo[0]?.primarycolor !== "#0e1555"
                    ? themeInfo[0]?.primarycolor
                    : "",
                fontFamily: themeInfo[0]?.fontfamily,
              }}
            >
              <Box className={styles["profile-header-name"]}>
                Personal Details
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={styles["details-box-wrapper"]}>
          <Box className={styles["details-box-name"]}>
            <Box className={styles["content-heading-name"]}>Name</Box>
            <Box className={styles["content-data-name"]}>
              {" "}
              {learnerProfileDetails.firstName}{" "}
            </Box>
          </Box>
          <Box className={styles["details-box-name"]}>
            <Box className={styles["content-heading-name"]}>Mobile No</Box>
            <Box className={styles["content-data-name"]}>
              {" "}
              {learnerProfileDetails.mobileNo}{" "}
            </Box>
          </Box>
          {/* <Box className={styles["details-box-name"]}>
            <Box className={styles["content-heading-name"]}>Country</Box>
            <Box className={styles["content-data-name"]}>
              {learnerProfileDetails.profileDetails?.country}
            </Box>
          </Box> */}
        </Box>
        {Object.keys(dataObj).map((key, index) => (
          <Box
            className={`${styles["details-box"]} ${styles[key]}`}
            key={index}
          >
            <Box className={`${styles["content-heading"]} ${styles[key]}`}>
              {headerMapping[key] ? headerMapping[key] : key}
            </Box>

            <Box className={`${styles["content-data"]} ${styles[key]}`}>
              {dataObj[key]}
            </Box>
          </Box>
        ))}

        <Box className={styles["details-box"]}>
          <Box className={styles["content-heading"]}>
            Are you open for work?{" "}
          </Box>
          <Box className={styles["content-data"]}>
            <Checkbox
              className={styles["profile-checkbox"]}
              checked={
                learnerProfileDetails?.profileDetails?.openForWork || false
              }
            />
            Yes I’m currently seeking employment
          </Box>
        </Box>
        {learnerProfileDetails?.profileDetails?.socialLinks?.map(
          (item, key) => {
            return (
              item?.url !== "" && (
                <Box className={styles["details-box"]} key={key}>
                  <Box className={styles["content-heading"]}>
                    {item?.typeOfLink}{" "}
                  </Box>
                  <Box className={styles["content-data"]}>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleClick(item);
                      }}
                    >
                      {item?.url}
                    </a>
                  </Box>
                </Box>
              )
            );
          }
        )}
        <Box className={styles["personal-details-box"]}>
          <Box className={styles["personal-details-header"]}>
            <Box
              className={styles["profile-header"]}
              style={{
                background:
                  themeInfo[0]?.primarycolor !== "#0e1555"
                    ? themeInfo[0]?.primarycolor
                    : "",
                fontFamily: themeInfo[0]?.fontfamily,
              }}
            >
              <Box className={styles["profile-header-name"]}>Skills</Box>
            </Box>
          </Box>

          <Box className={styles["skills-content-box"]}>
            {studentSkills?.map((skill, index) => (
              <p className={styles["skills-content"]} key={index}>
                {skill?.skill}
              </p>
            ))}
          </Box>
        </Box>
        <Box className={styles["personal-details-box"]}>
          <Box
            className={styles["profile-header"]}
            style={{
              background:
                themeInfo[0]?.primarycolor !== "#0e1555"
                  ? themeInfo[0]?.primarycolor
                  : "",
              fontFamily: themeInfo[0]?.fontfamily,
            }}
          >
            <Box className={styles["profile-header-name"]}>Certificates</Box>
          </Box>
          <div className={styles["certificate-wrapper"]}>
            {learnerProfileDetails?.certificates?.map((item, idx) => {
              const thumbnailUrl = `${process.env.REACT_APP_API_ENDPOINT}/public/download/certificate/${item.path}?studentId=${studentId}&thumbnail=true`;
              const pdfUrl = `${process.env.REACT_APP_API_ENDPOINT}/public/download/certificate/${item.path}?studentId=${studentId}`;
              return (
                <div
                  key={idx}
                  className={styles["certificate"]}
                  onClick={(event) => handleOpenModal(pdfUrl, event)}
                >
                  <img
                    src={thumbnailUrl}
                    alt="pdf thumbnail"
                    className={styles["pdf-image"]}
                  />
                  {item.name}
                </div>
              );
            })}
          </div>
        </Box>
        <Box className={styles["personal-details-box"]}>
          <Box
            className={styles["profile-header"]}
            style={{
              background:
                themeInfo[0]?.primarycolor !== "#0e1555"
                  ? themeInfo[0]?.primarycolor
                  : "",
              fontFamily: themeInfo[0]?.fontfamily,
            }}
          >
            <Box className={styles["profile-header-name"]}>Badges</Box>
          </Box>
          <div className={styles["certificate-wrapper"]}>
            {learnerProfileDetails?.badges?.map((item, idx) => {
              return (
                <div key={idx} className={styles["badge-canvas"]}>
                  <img
                    src={item.path}
                    alt="badge"
                    className={styles["badge-image"]}
                    key={idx}
                  />
                </div>
              );
            })}
          </div>
        </Box>
        <Box className={styles["personal-details-box"]}>
          <Box className={styles["personal-details-header"]}>
            <Box
              className={styles["profile-header"]}
              style={{
                background:
                  themeInfo[0]?.primarycolor !== "#0e1555"
                    ? themeInfo[0]?.primarycolor
                    : "",
                fontFamily: themeInfo[0]?.fontfamily,
              }}
            >
              <Box className={styles["profile-header-name"]}>
                Professional Details
              </Box>
            </Box>
          </Box>
          {learnerProfileDetails?.profileDetails?.professionalDetails?.length >
            0 &&
            learnerProfileDetails?.profileDetails?.professionalDetails.map(
              (ele, index) => (
                <Box className={styles["profession-box"]} key={index}>
                  <Box className={styles["profession-position"]}>
                    <Box>{ele.position}</Box>
                    <Box className={styles["profession-position-icons"]}></Box>
                  </Box>
                  <Box className={styles["profession-date"]}>
                    {`${ele.startDate}`}
                    <RemoveIcon />
                    {!ele.currentProfession ? `${ele.endDate}` : "Present"}
                  </Box>
                  <Box className={styles["profession-bio"]}> {ele.bio} </Box>
                  {ele.currentProfession && (
                    <Box className={styles["profession-current"]}>
                      <Checkbox
                        className={styles["profile-checkbox"]}
                        checked={ele.currentProfession}
                      />
                      Currently At this Possition
                    </Box>
                  )}
                </Box>
              )
            )}
        </Box>
        <Box className={styles["personal-details-box"]}>
          <Box className={styles["personal-details-header"]}>
            <Box
              className={styles["profile-header"]}
              style={{
                background:
                  themeInfo[0]?.primarycolor !== "#0e1555"
                    ? themeInfo[0]?.primarycolor
                    : "",
                fontFamily: themeInfo[0]?.fontfamily,
              }}
            >
              <Box className={styles["profile-header-name"]}>
                Educational Details
              </Box>
            </Box>
          </Box>

          {learnerProfileDetails?.profileDetails?.educationDetails?.length >
            0 &&
            learnerProfileDetails?.profileDetails?.educationDetails.map(
              (ele, index) => (
                <Box className={styles["profession-box"]} key={index}>
                  <Box className={styles["profession-position"]}>
                    <Box> {ele.school} </Box>
                  </Box>
                  <Box className={styles["profession-date"]}>
                    {" "}
                    {`${ele.eduStartDate} `}
                    <RemoveIcon />
                    {!ele.currentInstitution ? `${ele.eduEndDate}` : "Present"}
                  </Box>
                  <Box className={styles["profession-bio"]}> {ele.degree} </Box>
                  {ele.currentInstitution && (
                    <Box className={styles["profession-current"]}>
                      <Checkbox
                        className={styles["profile-checkbox"]}
                        checked={ele.currentInstitution}
                      />
                      Currently At this Institution
                    </Box>
                  )}
                </Box>
              )
            )}
        </Box>
      </Box>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "60%", sm: "60%", md: "60%" },
            maxHeight: "80vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "9px",
            p: 4,
          }}
        >
          {selectedPdf && (
            <Document file={selectedPdf}>
              <Page
                pageNumber={1}
                width={window.innerWidth * 0.6}
                scale={0.8}
              />
            </Document>
          )}
        </Box>
      </Modal>
    </Box>
  );
}

const mapStateToProps = ({ user }) => ({
  learnerProfileDetails: user.learnerProfileDetails,
  themeInfo: user.themeInfo,
  studentId: user.learnerId,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getLearnerProfileDetails: (userUId) =>
      dispatch(getLearnerProfileDetails(userUId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareableProfile);
