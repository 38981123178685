import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import styles from "../../../../../Stylesheets/Modules/Admin/Students/studentProfile/studentProfile.module.scss";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};

export default function SelfLinkModal(props) {
    const { open, setOpen, setTypeOfLink, setUrl, handleLinkChange } = props;
    const handleClose = () => setOpen(false);
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className={styles['input-section']}>
                        <div className={styles['label']}>
                            Link Name
                        </div>

                        <TextField id="outlined-basic" variant="outlined" onChange={(e) => setTypeOfLink(e.target.value)} />
                    </div>
                    <div className={styles['input-section']}>
                        <div className={styles['label']}>
                            Link Address
                        </div>
                        <TextField id="outlined-basic" variant="outlined" onChange={(e) => setUrl(e.target.value)} />
                    </div>
                    <div className={styles['button-section']}>
                        <Button className={styles['cancel-button']} onClick={handleClose}>Cancel</Button>
                        <Button className={styles['submit-button']} onClick={handleLinkChange}>Save</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}