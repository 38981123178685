import { combineReducers } from "redux";
import app from "./Reducers/app";
import auth from "./Reducers/auth";
import user from "./Reducers/user";
import cohort from "./Reducers/cohort";
import program from "./Reducers/program";
import modules from "./Reducers/modules";
import config from "./Reducers/config";

export default combineReducers({
  app,
  auth,
  user,
  cohort,
  program,
  modules,
  config,
});
