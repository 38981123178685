import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  Alert,
  Container,
  FormControl,
  Input,
  MenuItem,
  Select,
} from "@mui/material";
import NotesIcon from "@mui/icons-material/Notes";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Add from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DoneIcon from "@mui/icons-material/Done";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { OutlinedButtonSmall } from "../../../Components/StyledComponents/Button.style";
import { StyledArrowBackIcon } from "../../../Components/StyledComponents/Icons.style";
import styles from "../../../Stylesheets/Modules/Admin/Applications/onBoardingApplications.module.scss";
import {
  cohortApplicationProcess,
  getOnboardingApplication,
  updateApplicationProcessData,
  updateCohortAdditionalDetails,
  getCohortAdditionalDetails,
} from "../../../Store/Actions/actions.cohort";
import toast from "react-hot-toast";
import { logPageView } from "../../../Utils/analytics";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
const additionalOptions = [
  {
    key: "dta",
    question: "Upload document ?",
    options: ["Mandatory", "Not Required"],
  },
  {
    key: "videoInterview",
    question: "Video Interview ?",
    options: ["Mandatory", "Not Required"],
  },
  {
    key: "autoEnroll",
    question: "Enable Auto Enroll ?",
    options: ["Yes", "No"],
  },
];
export const OnboardingApplication = (props) => {
  const history = useNavigate();
  const { cohortId } = useParams();
  const [searchParams] = useSearchParams();
  const [qAA, setQAA] = useState([]);
  const cohortAddtionalDetails = props.additionalDetails;
  const [currQues, setCurrQues] = useState({});
  const [isUpdated, setIsUpdated] = useState(false);
  const [additionalDetails, setAdditionalDetails] = useState(true);
  const [dtamandatory, setDtaMandatory] = useState(false);
  const [videoMandatory, setVideoMandatory] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [autoEnroll, setAutoEnroll] = useState(false);
  const types = [
    {
      key: "TEXT",
      value: "TEXT",
      icon: NotesIcon,
    },
    {
      key: "RADIO",
      value: "RADIO",
      icon: RadioButtonCheckedIcon,
    },
    {
      key: "CHECKBOX",
      value: "CHECKBOX",
      icon: CheckBoxIcon,
    },
  ];
  const {
    themeInfo,
    cohortApplicationProcess,
    getOnboardingApplication,
    onBoardingQuestions,
    updateApplicationProcessData,
    getCohortAdditionalDetails,
  } = props;

  useEffect(() => {
    getApplicationData(cohortId);
  }, [cohortId]);

  useEffect(() => {
    getAdditionalDetails(cohortId);
  }, [cohortId]);

  useEffect(() => {
    if (cohortAddtionalDetails) {
      setDtaMandatory(cohortAddtionalDetails?.dtaMandatory);
      setVideoMandatory(cohortAddtionalDetails?.videoInterviewMandatory);
      setVideoLink(cohortAddtionalDetails?.videoInterviewLink);
      setAutoEnroll(cohortAddtionalDetails?.autoEnroll);
    }
  }, [cohortAddtionalDetails]);

  const getApplicationData = async (cohortId) => {
    await getOnboardingApplication(cohortId);
  };
  const getAdditionalDetails = async (cohortId) => {
    await getCohortAdditionalDetails(cohortId);
  };
  useEffect(() => {
    if (onBoardingQuestions?.questions?.length > 0) {
      setQAA(onBoardingQuestions?.questions);
      let ques = onBoardingQuestions?.questions;
      ques = ques?.reduce((res, curr) => {
        res[curr._id] = curr;
        return res;
      }, {});
      setCurrQues(ques);
      setIsUpdated(true);
    } else {
      setCurrQues({});
    }
  }, [onBoardingQuestions, onBoardingQuestions?.questions]);

  const updateQues = (id, key, val) => {
    const newQues = { ...currQues };
    newQues[id] = {
      ...newQues[id],
      [key]: val,
    };
    setCurrQues(newQues);
  };
  const cohortApplicationSubmit = () => {
    const payload = Object.values(currQues);
    let error = false;
    payload.forEach((item) => {
      delete item._id;
      item.answer = "";
      if (item.question === "") {
        error = true;
      }
    });
    if (error) {
      toast.error("Questions cannot be empty");
      getApplicationData(cohortId);
    } else {
      const questionsPayload = {
        cohortId: cohortId,
        questions: payload,
      };
      const objectsEqual = (o1, o2) =>
        Object.keys(o1).length === Object.keys(o2).length &&
        Object.keys(o1).every((p) => o1[p] === o2[p]);

      if (Object.keys(onBoardingQuestions).length === 0) {
        cohortApplicationProcess(questionsPayload, autoEnroll);
      } else if (
        onBoardingQuestions?.questions?.length === 0 &&
        questionsPayload?.questions?.length !== 0
      ) {
        cohortApplicationProcess(questionsPayload, autoEnroll);
      } else if (
        !objectsEqual(
          onBoardingQuestions?.questions,
          questionsPayload?.questions
        )
      ) {
        updateApplicationProcessData(
          cohortId,
          onBoardingQuestions._id,
          payload,
          autoEnroll
        );
      } else {
        // if (
        //   onBoardingQuestions.questions.length === 0 &&
        //   questionsPayload.questions.length === 0
        // ) {
        //   toast.error("Questions Cannot be empty");
        // }
        updateApplicationProcessData(
          cohortId,
          onBoardingQuestions._id,
          payload,
          autoEnroll
        );
        if (additionalDetails) {
          if (videoLink === "" && videoMandatory) {
            toast.error("Link is empty");
          } else {
            props.updateCohortAdditionalDetails(cohortId, {
              dtaMandatory: dtamandatory,
              videoInterviewMandatory: videoMandatory,
              videoInterviewLink: videoLink,
            });
          }
        }
      }
    }
  };

  const handleChecked = (key) => {
    if (key === "dta") {
      setDtaMandatory(!dtamandatory);
    } else if (key === "videoInterview") {
      setVideoMandatory(!videoMandatory);
    } else {
      setAutoEnroll(!autoEnroll);
    }
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] Cohort Onboarding Application Page");
  }, [location]);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }

  return (
    <>
      <Container
        maxWidth="xl"
        classes={{
          root: styles["container-root"],
        }}
      >
        <div
          className={styles["create-onboarding-header"]}
          style={{
            color: localStorage.getItem("primarycolor"),
            fontFamily: localStorage.getItem("fontfamily"),
          }}
        >
          <div className={styles["create-onboarding-header-left"]}>
            <Breadcrumbs
              aria-label="breadcrumb"
              style={{
                color: props.themeInfo[0]?.secondarycolor,
              }}
            >
              <Link underline="hover" color="inherit" href="/admin/cohorts">
                {props.customization.cohort_label || "Cohorts"}
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href={`/admin/cohortMenu/${cohortId}?cohort=${searchParams.get(
                  "cohort"
                )}`}
              >
                {searchParams.get("cohort") + " Overview"}
              </Link>
              <Typography>
                <b>{"Onboarding"}</b>
              </Typography>
            </Breadcrumbs>
          </div>
          <div className={styles["create-onboarding-header-right"]}>
            <OutlinedButtonSmall
              startIcon={
                <Add
                  classes={{
                    root: styles["arrow-icon"],
                  }}
                />
              }
              onClick={() => {
                const newQues = { ...currQues };
                const id = Date.now();
                newQues[id] = {
                  questionType: "TEXT",
                  question: "",
                  _id: id,
                };
                setCurrQues(newQues);
              }}
              bgcolor={
                themeInfo[0]?.primarycolor
                  ? themeInfo[0]?.primarycolor
                  : "#0e1555"
              }
              fontFamily={
                themeInfo[0]?.fontfamily ? themeInfo[0]?.fontfamily : "Nunito"
              }
            >
              Add Question
            </OutlinedButtonSmall>

            <OutlinedButtonSmall
              startIcon={
                <DoneIcon
                  classes={{
                    root: styles["arrow-icon"],
                  }}
                />
              }
              onClick={() => {
                const tqAA = qAA?.reduce((res, curr) => {
                  let tc = curr;
                  if (tc._id === cohortId._id) {
                    tc = {
                      ...curr,
                      questions: [...Object.values(currQues)],
                    };
                  }
                  res.push(tc);
                  return res;
                }, []);
                setQAA(tqAA);

                cohortApplicationSubmit();
              }}
              bgcolor={
                themeInfo[0]?.primarycolor
                  ? themeInfo[0]?.primarycolor
                  : "#0e1555"
              }
              fontFamily={
                themeInfo[0]?.fontfamily ? themeInfo[0]?.fontfamily : "Nunito"
              }
              style={{ marginLeft: "20px" }}
            >
              Save
            </OutlinedButtonSmall>
          </div>
        </div>

        <div
          className={`${styles["questions-body"]} ${styles["disable-scroll"]}`}
        >
          <>
            <div className={styles["main-body-container"]}>
              <>
                <div
                  className={`${styles["questions-container"]} ${styles["creation-mode"]}`}
                >
                  <Alert severity="info">
                    To enable student enrollment, either Add Question using{" "}
                    <b>Add Question</b> option or choose YES in{" "}
                    <b>Auto Enroll</b>.
                  </Alert>
                  {Object.values(currQues)?.map((question, qIdx) => {
                    return (
                      <div
                        className={styles["question"]}
                        key={`question-${qIdx}`}
                      >
                        <div className={styles["header-container"]}>
                          <div className={styles["question-header"]}>
                            <div className={styles["question-title"]}>
                              <span className={styles["title-prefix"]}>{`Q${
                                qIdx + 1
                              })`}</span>
                              <Input
                                value={question.question}
                                classes={{
                                  root: styles["input-root"],
                                }}
                                onChange={(e) => {
                                  updateQues(
                                    question._id,
                                    "question",
                                    e.target.value
                                  );
                                }}
                              />
                              {/* </span> */}
                            </div>
                            <div className={styles["question-type"]}>
                              <FormControl
                                classes={{
                                  root: styles["form-root"],
                                }}
                              >
                                <Select
                                  classes={{
                                    select: styles["menu-select"],
                                  }}
                                  value={question.questionType}
                                  onChange={(e) => {
                                    updateQues(
                                      question._id,
                                      "questionType",
                                      e.target.value
                                    );
                                  }}
                                >
                                  {types?.map((type) => {
                                    return (
                                      <MenuItem
                                        key={type.key}
                                        value={type.key}
                                        classes={{
                                          root: styles["menu-item-root"],
                                          selected: styles["selected-item"],
                                        }}
                                      >
                                        <type.icon />
                                        <span
                                          className={styles["menu-item-text"]}
                                        >
                                          {type.value}
                                        </span>
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                          <div
                            className={styles["delete-container"]}
                            onClick={() => {
                              const newQues = { ...currQues };
                              delete newQues[question._id];
                              setCurrQues(newQues);
                            }}
                          >
                            <DeleteOutlineIcon />
                          </div>
                        </div>
                        {(question.questionType === "RADIO" ||
                          question.questionType === "CHECKBOX") && (
                          <div className={styles["options-container"]}>
                            <OutlinedInput
                              type="text"
                              value={(() => {
                                if (question?.values instanceof Array) {
                                  return question?.values?.join(", ") || "";
                                }
                                return question?.values;
                              })()}
                              id="outlined-adornment-email"
                              placeholder={`Please enter comma ',' separated text for multiple options`}
                              classes={{
                                root: styles["outline-root"],
                                notchedOutline: styles["notched-outline-root"],
                                input: styles["input-root"],
                                focused: styles["input-focused"],
                              }}
                              onChange={(e) => {
                                const optionsVal = e.target.value
                                  .split(",")
                                  .map((t) => t.replace(/^\s+/, ""));
                                updateQues(question._id, "values", optionsVal);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {additionalDetails && (
                    <div className={styles["additional-details-section"]}>
                      <div className={styles["heading"]}>
                        ADDITIONAL DETAILS
                      </div>
                      {additionalOptions.map((item, key) => {
                        return (
                          <div className={styles["dta-section"]} key={key}>
                            <div className={styles["question"]}>
                              {item.question}
                              {item.key === "autoEnroll" && (
                                <span className={styles["mandatory"]}>*</span>
                              )}
                            </div>
                            <div className={styles["checkbox"]}>
                              <div className={styles["wrapper"]}>
                                <div className={styles["mandatory"]}>
                                  <Checkbox
                                    classes={{
                                      root: styles["checkbox-root"],
                                    }}
                                    checked={(() => {
                                      if (item.key === "dta")
                                        return dtamandatory;
                                      else if (item.key === "videoInterview")
                                        return videoMandatory;
                                      else return autoEnroll;
                                    })()}
                                    onChange={() => handleChecked(item.key)}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                  <div className={styles["label"]}>
                                    {item.options[0]}
                                  </div>
                                </div>
                                <div className={styles["optional"]}>
                                  <Checkbox
                                    classes={{
                                      root: styles["checkbox-root"],
                                    }}
                                    checked={(() => {
                                      if (item.key === "dta")
                                        return !dtamandatory;
                                      else if (item.key === "videoInterview")
                                        return !videoMandatory;
                                      else return !autoEnroll;
                                    })()}
                                    onChange={(e) => handleChecked(item.key)}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                  <div className={styles["label"]}>
                                    {item.options[1]}
                                  </div>
                                </div>
                              </div>
                              {item.key === "videoInterview" &&
                                videoMandatory && (
                                  <div className={styles["video-link"]}>
                                    <TextField
                                      id="interview-link"
                                      label="Interview Link"
                                      variant="outlined"
                                      value={videoLink}
                                      onChange={(e) => {
                                        setVideoLink(e.target.value);
                                      }}
                                    />
                                  </div>
                                )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </>
            </div>
          </>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = ({ app, user, cohort }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    themeInfo: user.themeInfo,
    onBoardingQuestions: cohort.onBoardingData,
    additionalDetails: cohort.additionalDetails,
    customization: user.customization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cohortApplicationProcess: (questionsPayload, autoEnroll) => {
      dispatch(cohortApplicationProcess(questionsPayload, autoEnroll));
    },
    getOnboardingApplication: (cohortId) => {
      dispatch(getOnboardingApplication(cohortId));
    },
    updateApplicationProcessData: (
      cohortId,
      questionId,
      questions,
      autoEnroll
    ) => {
      dispatch(
        updateApplicationProcessData(
          cohortId,
          questionId,
          questions,
          autoEnroll
        )
      );
    },
    updateCohortAdditionalDetails: (cohortId, payload) => {
      dispatch(updateCohortAdditionalDetails(cohortId, payload));
    },
    getCohortAdditionalDetails: (cohortId) => {
      dispatch(getCohortAdditionalDetails(cohortId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingApplication);
