import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import styles from "../Stylesheets/Modules/cohortCurriculum.module.scss";
import CohortBackNav from "../Components/CohortBackNav";
import { connect } from "react-redux";
import { getCohortInfo } from "../Store/Actions/actions.cohort";
import Lock from "../Assets/Images/lock.svg";
import { getStudentCohortModuleProgress } from "../Store/Actions/actions.cohort";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { logPageView } from "../Utils/analytics";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
const CohortCurriculum = (props) => {
  const navigate = useNavigate();
  const { cohortId } = useParams();
  const [cohort, setCohort] = useState();
  const {
    getCohortsDetails,
    customization,
    studentCohortModuleProgress,
    user,
  } = props;

  useEffect(() => {
    // TODO: need to integrate with API
    if (props.cohortInfo) {
      setCohort(props.cohortInfo);
    }
  }, [props.cohortInfo]);

  useEffect(() => {
    getCohortsDetails(cohortId);
  }, []);

  const getStudentProgress = async (cohortId, studentId) => {
    await props.getStudentCohortModuleProgress(cohortId, studentId);
  };
  useEffect(() => {
    getStudentProgress(cohortId, user.id);
  }, []);
  const getModuleProgress = (moduleId) => {
    if (studentCohortModuleProgress.length !== undefined) {
      let obj = studentCohortModuleProgress?.find(
        (item) => item.moduleId === moduleId
      );
      var numerator = 0;
      var denominator = 0;
      obj?.moduleSubSections?.forEach((item) => {
        if (item.key !== "liveSessions") {
          numerator += item.subSectionCompleted;
          denominator += item.subSectionTotal;
        }
      });
      return { numerator, denominator };
    }
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("[Student] Module Overview Page");
  }, [location]);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }

  const breadCrumps = (customization) => {
    return (
      <Breadcrumbs
        aria-label="breadcrumb"
        style={{
          color: "white",
        }}
      >
        <Link underline="hover" color="inherit" href="/cohorts">
          {customization.cohort_label || "Cohorts"}
        </Link>
        <Link underline="hover" color="inherit" href={`/cohort/${cohortId}`}>
          {cohort?.title}
        </Link>
        <Typography>
          <b>{customization.module_label}</b>
        </Typography>
      </Breadcrumbs>
    );
  };
  return (
    <div>
      <CohortBackNav breadCrumps={breadCrumps(props.customization)} />
      <div className={styles["container"]}>
        {cohort?.phases ? (
          cohort?.phaseMapping?.map((phase) => {
            return (
              <div className={styles["phase"]} key={phase.title}>
                <div className={styles["phase-header"]}>
                  <span className={styles["phase-header-title"]}>
                    {phase.title}: {phase.duration}
                  </span>
                </div>
                <div className={styles["modules-container"]}>
                  {phase.moduleIds.map((id, idx) => {
                    const module = cohort.modules.find((m) => m._id === id);
                    return (
                      <div
                        data-event-name="[Student] Clicked On Module"
                        className={styles["module"]}
                        key={module._id}
                        onClick={() =>
                          navigate(
                            `/cohort/${cohortId}/curriculum/${module.module._id}?cohort=${cohort?.title}&module=${module?.name}`
                          )
                        }
                      >
                        <div className={styles["module-header"]}>
                          {`${customization.module_label || "Module"} ${
                            idx + 1
                          }:`}
                        </div>
                        {!module.published && (
                          <div className={styles["module-lock"]}>
                            <img src={Lock} alt={"Module locked"} />
                          </div>
                        )}
                        <div className={styles["module-title"]}>
                          {module.name}
                        </div>
                        <div className={styles["module-desc"]}>
                          {module.description}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
        ) : (
          <div className={styles["modules-container"]}>
            {cohort?.modules?.map((module, idx) => {
              return (
                <div
                  data-event-name="[Student] Clicked On Module"
                  className={`${styles["module"]} ${
                    !module.published ? styles["locked"] : ""
                  }`}
                  key={module.module}
                  onClick={() =>
                    module.published &&
                    navigate(
                      `/cohort/${cohortId}/curriculum/${module.module}?cohort=${cohort?.title}&module=${module?.name}`
                    )
                  }
                >
                  <div className={styles["module-header"]}>
                    {`${customization.module_label || "Cohorts"} ${idx + 1}:`}
                  </div>
                  {!module.published ? (
                    <div className={styles["module-lock"]}>
                      <img src={Lock} alt={"Module locked"} />
                    </div>
                  ) : (
                    <div className={styles["module-progress"]}>
                      <Box
                        sx={{
                          position: "relative",
                          display: "flex",
                          right: "2.25rem",
                          top: "3.5rem",
                        }}
                      >
                        {(studentCohortModuleProgress !== {} &&
                          getModuleProgress(module.module)?.numerator === 0) ||
                        getModuleProgress(module.module)?.denominator == 0 ? (
                          <>
                            <CircularProgress
                              classes={{
                                root: styles["progress-root"],
                                svg: styles["svg-styles"],
                                circle: styles["svg-path"],
                              }}
                              variant="determinate"
                              value={0}
                            />
                          </>
                        ) : (
                          <>
                            <CircularProgress
                              classes={{
                                root: styles["progress-root"],
                                svg: styles["svg-styles"],
                                circle: styles["svg-path"],
                              }}
                              variant="determinate"
                              value={
                                getModuleProgress(module.module)
                                  ?.denominator !== 0
                                  ? (getModuleProgress(module.module)
                                      ?.numerator /
                                      getModuleProgress(module.module)
                                        ?.denominator) *
                                    100
                                  : 0
                              }
                            />
                          </>
                        )}
                        <Box
                          sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                          >
                            {`${Math.round(
                              getModuleProgress(module.module)?.denominator !==
                                0
                                ? (getModuleProgress(module.module)?.numerator /
                                    getModuleProgress(module.module)
                                      ?.denominator) *
                                    100
                                : 0
                            )}%`}
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                  )}
                  <div className={styles["module-title"]}>{module.name}</div>
                  <div className={styles["module-desc"]}>
                    {module.description}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ app, user, cohort }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    cohortInfo: cohort.cohortInfo,
    customization: user.customization,
    user: user.userDetails,
    studentCohortModuleProgress: cohort.studentCohortModuleProgress,
    themeInfo: user.themeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCohortsDetails: (cohortId) => dispatch(getCohortInfo(cohortId)),
    getStudentCohortModuleProgress: (cohortId, studentId) =>
      dispatch(getStudentCohortModuleProgress(cohortId, studentId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CohortCurriculum);
