import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import GenericModal from "../../../Reusable/Modals/GenericModal";
import TitleDescriptionComp from "../../../Reusable/TitleDescriptionComp";

import ClearIcon from "@mui/icons-material/Clear";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import styles from "../../../Stylesheets/Modules/Admin/Cohorts/createCohort.module.scss";
import "../../../Stylesheets/Modules/general.scss";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import {
  setNewCohort,
  setCohortCreated,
  getCohortInfo,
  updateCohort,
  fetchVideoURL,
  getCohortCertifcatesAndBadges,
  deleteVideo,
  setUploadProgress,
} from "../../../Store/Actions/actions.cohort";

import {
  getAllPrograms,
  createNewProgram,
  updateProgram,
} from "../../../Store/Actions/actions.program";
import {
  FilledButton,
  FilledButtonSmall,
  OutlinedButton,
} from "../../../Components/StyledComponents/Button.style";
import {
  StyledArrowBackIcon,
  StyledEditIcon,
} from "../../../Components/StyledComponents/Icons.style";
import { logPageView } from "../../../Utils/analytics";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import toast from "react-hot-toast";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import { Switch } from "@mui/material";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const filter = createFilterOptions();
const CreateCohorts = (props) => {
  const history = useNavigate();
  const { state } = useLocation();
  const {
    setNewCohort,
    userInfo,
    getAllPrograms,
    createNewProgram,
    updateProgram,
    allPrograms,
    cohortInfo,
    getCohortInfo,
    updateCohort,
    fetchVideoURL,
    videoMeta,
    progress,
    customization,
    certificatesandBadgesList,
    deleteVideo,
    setUploadProgress,
  } = props;
  const [title, setTitle] = React.useState("");
  const [overView, setOverview] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [programs, setProgram] = React.useState([]);
  const [selectedProgram, setSelectedProgram] = React.useState("");
  const [instructor, setInstructor] = React.useState("");
  const [lang, setLang] = React.useState("");
  const [url, setURL] = React.useState("");
  const [content, setContent] = React.useState("");
  const [videoName, setVideoName] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [custom, setCustom] = React.useState(false);
  const [toggle, updateToggle] = React.useState(custom);
  const [toggleEdit, updateToggleEdit] = React.useState(custom);
  const [page, setPage] = React.useState("page1");
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [level, setLevel] = React.useState("");
  const [editProgram, setEditProgram] = React.useState(false);
  const [programName, setProgramName] = React.useState("");
  const [courseTimeline, setCourseTimeline] = React.useState("anytime");
  const [certifcationBoolean, setCertificationBoolean] = React.useState(false);
  const [badgesBoolean, setBadgesBoolean] = React.useState(false);
  const [selectedCertificates, setSelectedCertificates] = React.useState([]);
  const [selectedBadges, setSelectedBadges] = React.useState([]);
  const [creditsEnabled, setCreditsEnabled] = React.useState(false);
  const isVideoUploadingEnabled = props.config.videoUploading;
  const videoJsPlayerRef = useRef(null);

  const courseTimeLineOptions = [
    {
      key: "anytime",
      value: "Student can learn anytime",
    },
    {
      key: "timePeriod",
      value: "There's a time period",
    },
  ];
  React.useEffect(() => {
    getProgramDetails(userInfo.orgId);
  }, []);

  React.useEffect(() => {
    props.getCohortCertifcatesAndBadges();
  }, []);
  const getProgramDetails = async (orgId) => {
    await getAllPrograms(orgId);
  };

  React.useEffect(() => {
    state?._id && getCohortsDetails(state._id);
  }, [state]);

  const getCohortsDetails = async (cohortId) => {
    await getCohortInfo(cohortId);
  };

  React.useEffect(() => {
    setProgram(allPrograms);
  }, [allPrograms]);

  React.useEffect(() => {
    if (cohortInfo && cohortInfo._id) {
      setTitle(cohortInfo.title);
      setOverview(cohortInfo.overview);
      setSelectedProgram(cohortInfo.program);
      setStartDate(cohortInfo.startDate);
      setEndDate(cohortInfo.endDate);
      setInstructor(cohortInfo.instructorName);
      setLevel(cohortInfo.level);
      setLang(cohortInfo.language);
      setURL(cohortInfo.cohortVideoUrl);
      setVideoName(cohortInfo.cohortVideoName);
      setContent(cohortInfo.courseDetails.courseContent);
      setCreditsEnabled(cohortInfo.creditsEnabled);
      setCourseTimeline(cohortInfo.courseTimeline || "anytime");
      cohortInfo?.certificates?.length > 0
        ? setCertificationBoolean(true)
        : setCertificationBoolean(false);
      const previouslySelectedCertificates = cohortInfo?.certificates
        ? certificatesandBadgesList.filter((certificateInfo) =>
            cohortInfo.certificates.includes(certificateInfo._id)
          )
        : [];
      setSelectedCertificates(previouslySelectedCertificates);
      cohortInfo?.badges?.length > 0
        ? setBadgesBoolean(true)
        : setBadgesBoolean(false);
      const previouslySelectedBadges = cohortInfo?.badges
        ? certificatesandBadgesList.filter((badgesInfo) =>
            cohortInfo.badges.includes(badgesInfo._id)
          )
        : [];

      setSelectedBadges(previouslySelectedBadges);
    }
  }, [cohortInfo]);
  const captureTitle = (val) => {
    setTitle(val);
  };
  const captureOverviewContent = (val) => {
    setOverview(val);
  };
  const onStartDateChanged = (date) => {
    setStartDate(date);
    if (endDate && date > endDate) {
      setEndDate(date);
    }
  };

  const onEndDateChanged = (date) => {
    setEndDate(date);
  };

  const handleInstructorNameChange = (event) => {
    setInstructor(event.target.value);
  };
  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  };
  const handleLangChange = (event) => {
    setLang(event.target.value);
  };

  const handleURLChange = async (e) => {
    const file = e.target.files[0];
    if (file.type !== "video/mp4") {
      toast.error("Please upload a valid mp4 video file.");
      return;
    }
    await fetchVideoURL(file);
  };

  React.useEffect(() => {
    setURL(videoMeta.url);
    setVideoName(videoMeta.title);
    setUploadProgress(0);
  }, [videoMeta.url, videoMeta.title]);

  React.useEffect(() => {
    setOptions(
      programs.map((item) => {
        return item.programName;
      })
    );
  });
  useEffect(() => {
    let player;

    // Function to initialize Video.js
    const initializePlayer = () => {
      if (videoJsPlayerRef.current) {
        player = videojs(videoJsPlayerRef.current, {
          controlBar: {
            playToggle: true, // Play/Pause button
            currentTimeDisplay: true,
            timeDivider: true,
            durationDisplay: true,
            progressControl: true, // Seek bar
            remainingTimeDisplay: true,
            volumePanel: true, // Volume control
            fullscreenToggle: false, // Fullscreen button
          },
        });

        // Disable right-click on the video player
        const videoElement = player.el();
        videoElement.addEventListener("contextmenu", (event) => {
          event.preventDefault();
        });

        return player;
      }
    };

    // Initialize player if URL is set and is not a Vimeo URL
    if (url && !url.includes("vimeo")) {
      player = initializePlayer();
    }

    // Clean up
    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, [url]);

  const onClickSave = async () => {
    if (!custom) {
      const certificateList = selectedCertificates.map((item) => item._id);
      const badgeList = selectedBadges.map((item) => item._id);
      const payload = {
        program: selectedProgram,
        organization: userInfo.orgId,
        title: title,
        overview: overView,
        language: lang,
        creditsEnabled: creditsEnabled,
        cohortVideoUrl: url,
        cohortVideoName: videoName,
        startDate: courseTimeline === "timePeriod" ? startDate : null,
        endDate: courseTimeline === "timePeriod" ? endDate : null,
        instructorName: instructor,
        level: level,
        courseTimeline: courseTimeline,
        certificates: certifcationBoolean ? certificateList : [],
        badges: badgesBoolean ? badgeList : [],
        courseDetails: {
          courseContent: content,
        },
        edit: state && state.editMode,
      };
      if (state && state.editMode) {
        await updateCohort(payload, state._id);
      } else {
        await setNewCohort(payload);
      }
      history("/admin/cohorts");
    }
  };
  const handleProgramCreation = () => {
    const payload = {
      organizationId: userInfo.orgId,
      programName: name,
      programDescription: description,
    };
    createNewProgram(payload);

    updateToggle(false);
  };

  const handleProgramEdition = () => {
    updateProgram(selectedProgram, {
      programName: name,
      programDescription: description,
    });
    getAllPrograms(userInfo.orgId);

    updateToggleEdit(false);
  };
  const onBackNavigation = () => {
    history("/admin/cohorts");
  };
  const isSubmitButtonDisabled = () => {
    return (
      title === "" ||
      overView === "" ||
      selectedProgram === "" ||
      (courseTimeline === "timePeriod" &&
        (startDate === null || !isValidDate(startDate))) ||
      startDate > endDate ||
      (courseTimeline === "timePeriod" &&
        (endDate === null || !isValidDate(endDate))) ||
      startDate > endDate ||
      instructor === "" ||
      level === "" ||
      content === "" ||
      isStartDateMoreThanEndDate(startDate, endDate)
    );
  };
  React.useEffect(() => {
    if (programs && selectedProgram) {
      programs.forEach((item) => {
        item._id === selectedProgram && setProgramName(item.programName);
      });
    }
  }, [selectedProgram]);

  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] Create Cohort Page");
  }, [location]);

  function isValidDate(dateString) {
    const date = new Date(dateString);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return !isNaN(date.getTime()) && date >= today;
  }
  function isStartDateMoreThanEndDate(startDate, endDate) {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    return date1 > date2;
  }
  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }
  return (
    <div className={styles["main-container-wrapper"]}>
      <Container
        maxWidth="xl"
        classes={{
          root: styles["main-container"],
        }}
      >
        <div
          className={styles["create-cohort-header"]}
          style={{
            color: props.themeInfo[0]?.primarycolor,
            fontFamily: props.themeInfo[0]?.fontfamily,
          }}
        >
          <Breadcrumbs
            aria-label="breadcrumb"
            style={{
              color: props.themeInfo[0]?.secondarycolor,
            }}
          >
            <Link underline="hover" color="inherit" href="/admin/cohorts">
              {customization.cohort_label || "Cohorts"}
            </Link>
            <Typography>
              <b> Create {customization.cohort_label || "Cohorts"}</b>
            </Typography>
          </Breadcrumbs>
        </div>
        <div className={styles["cohort-input-fields"]}>
          <div className={styles["cohort-title-overview-wrapper"]}>
            <span
              className={styles["title-header"]}
              style={{
                fontFamily: props.themeInfo[0]?.fontfamily
                  ? props.themeInfo[0]?.fontfamily
                  : "Nunito",
              }}
            >
              Title
              <span className={styles["mandatory"]}>*</span>
            </span>
            <OutlinedInput
              type="text"
              id="outlined-adornment-title"
              classes={{
                root: `${styles["outline-root"]} ${styles["title"]}`,
                notchedOutline: styles["notched-outline-root"],
                input: styles["input-root"],
                focused: styles["input-focused"],
              }}
              value={title}
              onChange={(e) => captureTitle(e.target.value)}
            />
            <span className={styles["overview-header"]}>
              Overview
              <span className={styles["mandatory"]}>*</span>
            </span>
            <OutlinedInput
              type="text"
              id="outlined-adornment-overview"
              multiline
              maxRows={4}
              inputProps={{
                maxLength: 255,
              }}
              classes={{
                root: styles["outline-root"],
                notchedOutline: styles["notched-outline-root"],
                input: styles["input-root"],
                focused: styles["input-focused"],
              }}
              value={overView}
              endAdornment={
                <InputAdornment
                  position="end"
                  className={styles["char-length"]}
                >
                  <Typography variant="caption">
                    {overView.length}/{255}
                  </Typography>
                </InputAdornment>
              }
              onChange={(e) => captureOverviewContent(e.target.value)}
            />
            <div className={styles["cohort-input-fields-labels"]}>
              <div className={styles["credits-section"]}>
                <div className={styles["overview-header"]}>
                  <div className={styles["label"]}> CreditsEnabled </div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          focusVisibleClassName=".Mui-focusVisible"
                          disableRipple
                          checked={creditsEnabled}
                          onChange={() => {
                            setCreditsEnabled(!creditsEnabled);
                          }}
                        />
                      }
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className={styles["cohort-input-fields-labels"]}>
              <div className={styles["certification-section"]}>
                <div className={styles["overview-header"]}>
                  <div className={styles["label"]}>Enable Certifications ?</div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={certifcationBoolean}
                          onChange={() =>
                            setCertificationBoolean(!certifcationBoolean)
                          }
                        />
                      }
                    />
                  </FormGroup>
                </div>
                {certifcationBoolean && (
                  <div className={styles["selection-section"]}>
                    <Autocomplete
                      multiple
                      id="skills-selection"
                      options={certificatesandBadgesList.filter(
                        (item) => !item.badge
                      )}
                      classes={{
                        root: styles["select-root"],
                      }}
                      getOptionLabel={(option) =>
                        option.name.replace(/_/g, " ")
                      }
                      value={selectedCertificates}
                      onChange={(event, newValue) => {
                        setSelectedCertificates(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={styles["skills-auto-box"]}
                          placeholder="Select certificates"
                        />
                      )}
                    />
                  </div>
                )}
              </div>
              <div className={styles["certification-section"]}>
                <div className={styles["overview-header"]}>
                  <div className={styles["label"]}>Enable Badges ?</div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={badgesBoolean}
                          onChange={() => setBadgesBoolean(!badgesBoolean)}
                        />
                      }
                    />
                  </FormGroup>
                </div>
                {badgesBoolean && (
                  <div className={styles["selection-section"]}>
                    <Autocomplete
                      multiple
                      id="skills-selection"
                      options={certificatesandBadgesList.filter(
                        (item) => item.badge
                      )}
                      classes={{
                        root: styles["select-root"],
                      }}
                      getOptionLabel={(option) =>
                        option.name.replace(/_/g, " ")
                      }
                      value={selectedBadges}
                      onChange={(event, newValue) => {
                        setSelectedBadges(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={styles["skills-auto-box"]}
                          placeholder="Select badges"
                        />
                      )}
                    />
                  </div>
                )}
              </div>
              <div className={styles["course-timeline-wrapper"]}>
                <span className={styles["overview-header"]}>
                  Course Timeline
                  <span className={styles["mandatory"]}>*</span>
                </span>
                <div className={styles["options"]}>
                  <RadioGroup
                    value={courseTimeline}
                    name="radio-buttons-group"
                    className={styles["radio-group-wrapper"]}
                    onChange={(e) => setCourseTimeline(e.target.value)}
                  >
                    {courseTimeLineOptions.map((item) => {
                      return (
                        <FormControlLabel
                          key={item.key}
                          control={<Radio />}
                          label={item.value}
                          value={item.key}
                        />
                      );
                    })}
                  </RadioGroup>
                </div>
              </div>
            </div>
          </div>

          <div className={styles["cohort-input-fields-labels"]}>
            <span>
              {customization.program_label || "Program"}
              <span className={styles["mandatory"]}>*</span>
              {editProgram && (
                <span>
                  {" "}
                  <StyledEditIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      updateToggleEdit(true);
                    }}
                  />
                </span>
              )}
            </span>
            <FormControl
              sx={{ m: 1 }}
              size="small"
              classes={{
                root: styles["form-control-root"],
              }}
            >
              <Autocomplete
                disablePortal
                id="program-dropdown"
                options={options}
                classes={{
                  root: styles["select-root"],
                }}
                value={programName}
                freeSolo
                onChange={(event, newValue) => {
                  if (event.target.value !== undefined) {
                    setEditProgram(true);
                    setName("");
                  } else if (event.target.value === undefined) {
                    setEditProgram(false);
                  }
                  programs.forEach((item) => {
                    if (item.programName === newValue) {
                      setSelectedProgram(item._id);
                    }
                  });
                  if (newValue?.substring(0, 6) === "Create") {
                    setEditProgram(false);
                    setName(
                      newValue.substring(
                        newValue.indexOf('"') + 1,
                        newValue.lastIndexOf('"')
                      )
                    );
                    setDescription("");
                    updateToggle(true);
                    newValue.length = 0;
                    setCustom(true);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push(
                      `Create New ${
                        customization.program_label || "Program"
                      } -"${inputValue}"`
                    );
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                }}
                renderOption={(props, option) => <li {...props}>{option}</li>}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
            <GenericModal
              open={toggle}
              togglePopUp={updateToggle}
              page={setPage}
            >
              <div className={styles["form-wrapper"]}>
                <div className={styles["modal-header"]}>
                  Create {customization.program_label || "Program"}
                </div>
                <div className={styles["content-body"]}>
                  <br />
                  <TitleDescriptionComp
                    hasTitle={true}
                    titleHelper="Enter Title"
                    contentHelper="Enter Description"
                    hasRichText={false}
                    title={name}
                    setTitle={setName}
                    content={description}
                    setContent={setDescription}
                  />
                  <br />
                  <div className={styles["button-section"]}>
                    <FilledButton
                      variant="contained"
                      bgcolor={
                        props.themeInfo[0]?.primarycolor
                          ? props.themeInfo[0]?.primarycolor
                          : "#0e1555"
                      }
                      fontFamily={
                        props.themeInfo[0]?.fontfamily
                          ? props.themeInfo[0]?.fontfamily
                          : "Nunito"
                      }
                      className={styles["create-btn"]}
                      onClick={() => handleProgramCreation()}
                    >
                      Create
                    </FilledButton>
                    <OutlinedButton
                      variant="contained"
                      className={styles["cancel-btn"]}
                      bgcolor={
                        props.themeInfo[0]?.primarycolor
                          ? props.themeInfo[0]?.primarycolor
                          : "#0e1555"
                      }
                      fontFamily={
                        props.themeInfo[0]?.fontfamily
                          ? props.themeInfo[0]?.fontfamily
                          : "Nunito"
                      }
                      onClick={() => {
                        updateToggle(false);
                      }}
                    >
                      Cancel
                    </OutlinedButton>
                  </div>
                  <br />
                </div>
              </div>
            </GenericModal>
            <GenericModal
              open={toggleEdit}
              togglePopUp={updateToggleEdit}
              page={setPage}
            >
              <div className={styles["form-wrapper"]}>
                <div className={styles["modal-header"]}>
                  Edit {customization.program_label || "Program"}
                </div>
                <div className={styles["content-body"]}>
                  <br />
                  <TitleDescriptionComp
                    hasTitle={true}
                    titleHelper="New Title"
                    contentHelper="New Description"
                    hasRichText={false}
                    title={name}
                    setTitle={setName}
                    content={description}
                    setContent={setDescription}
                  />
                  <br />
                  <div className={styles["button-section"]}>
                    <FilledButton
                      variant="contained"
                      bgcolor={
                        props.themeInfo[0]?.primarycolor
                          ? props.themeInfo[0]?.primarycolor
                          : "#0e1555"
                      }
                      fontFamily={
                        props.themeInfo[0]?.fontfamily
                          ? props.themeInfo[0]?.fontfamily
                          : "Nunito"
                      }
                      className={styles["create-btn"]}
                      onClick={() => {
                        handleProgramEdition();
                        setName("");
                        setDescription("");
                      }}
                    >
                      Edit
                    </FilledButton>
                    <OutlinedButton
                      variant="contained"
                      className={styles["cancel-btn"]}
                      bgcolor={
                        props.themeInfo[0]?.primarycolor
                          ? props.themeInfo[0]?.primarycolor
                          : "#0e1555"
                      }
                      fontFamily={
                        props.themeInfo[0]?.fontfamily
                          ? props.themeInfo[0]?.fontfamily
                          : "Nunito"
                      }
                      onClick={() => {
                        updateToggleEdit(false);
                      }}
                    >
                      Cancel
                    </OutlinedButton>
                  </div>
                  <br />
                </div>
              </div>
            </GenericModal>
          </div>
          {courseTimeline === "timePeriod" && (
            <div className={styles["cohort-input-fields-labels"]}>
              <span>
                Start Date
                <span className={styles["mandatory"]}>*</span>
              </span>
              <FormControl
                sx={{ m: 1 }}
                size="small"
                classes={{
                  root: styles["form-control-root"],
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={startDate}
                    onChange={onStartDateChanged}
                    minDate={new Date()}
                    renderInput={(params) => (
                      <>
                        <TextField
                          classes={{
                            root: styles["date-picker-comp"],
                            notchedOutline: styles["notched-override"],
                          }}
                          {...params}
                        />
                        <FormHelperText style={{ color: "red" }}>
                          {startDate && !isValidDate(startDate)
                            ? "Invalid start date"
                            : isStartDateMoreThanEndDate(startDate, endDate)
                            ? "start date is more than end date"
                            : ""}
                        </FormHelperText>
                      </>
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
          )}

          {courseTimeline === "timePeriod" && (
            <div className={styles["cohort-input-fields-labels"]}>
              <span>
                End Date
                <span className={styles["mandatory"]}>*</span>
              </span>
              <FormControl
                sx={{ m: 1 }}
                size="small"
                classes={{
                  root: styles["form-control-root"],
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={endDate}
                    minDate={startDate || new Date()}
                    onChange={onEndDateChanged}
                    renderInput={(params) => (
                      <>
                        <TextField
                          classes={{ root: styles["date-picker-comp"] }}
                          InputProps={{
                            classes: {
                              root: styles["input-style-override"],
                              input: styles["notched-override"],
                            },
                          }}
                          {...params}
                        />
                        <FormHelperText style={{ color: "red" }}>
                          {endDate && !isValidDate(endDate)
                            ? "Invalid end date"
                            : ""}
                        </FormHelperText>
                      </>
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
          )}
          <div className={styles["cohort-input-fields-labels"]}>
            <span>
              Instructor Name
              <span className={styles["mandatory"]}>*</span>
            </span>
            <FormControl
              sx={{ m: 1 }}
              size="small"
              classes={{
                root: styles["form-control-root"],
              }}
              onChange={handleInstructorNameChange}
            >
              <TextField value={instructor} />
            </FormControl>
          </div>
          <div className={styles["cohort-input-fields-labels"]}>
            <span>
              Level
              <span className={styles["mandatory"]}>*</span>
            </span>
            <FormControl
              sx={{ m: 1 }}
              size="small"
              classes={{
                root: styles["form-control-root"],
              }}
            >
              <Select value={level} onChange={handleLevelChange}>
                <MenuItem value={"beginner"}>Beginner</MenuItem>
                <MenuItem value={"intermediate"}>Intermediate</MenuItem>
                <MenuItem value={"advanced"}>Advanced</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={styles["cohort-input-fields-labels"]}>
            <span>Language</span>
            <FormControl
              sx={{ m: 1 }}
              size="small"
              classes={{
                root: styles["form-control-root"],
              }}
            >
              <Select
                value={lang}
                onChange={handleLangChange}
                displayEmpty
                sx={{ width: 310, background: "#FFFFFF" }}
              >
                <MenuItem value={"English"}>English</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={styles["cohort-input-fields-labels"]}>
            <div className={styles["label-progress-wrapper"]}>
              <div className={styles["label-element"]}>Video URL </div>
              {progress > 0 && (
                <div className={styles["progress-element"]}>
                  <LinearProgress variant="determinate" value={progress} />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                  >{`${progress}%`}</Typography>
                </div>
              )}
            </div>
            <FormControl
              sx={{ m: 1 }}
              size="small"
              classes={{
                root: styles["form-control-root"],
              }}
              value={url}
              onChange={handleURLChange}
            >
              {url && (
                <TextField
                  disabled={!isVideoUploadingEnabled}
                  value={url}
                  InputProps={{
                    endAdornment: url && (
                      <ClearIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          const videoId = url.match(
                            /\/([^/]+)\/[^/]+\.mp4$/
                          )[1];
                          deleteVideo(videoId, "video").then(() => {
                            setURL("");
                            setVideoName("");
                          });
                        }}
                      />
                    ),
                  }}
                />
              )}
              {!url && (
                <TextField
                  disabled={!isVideoUploadingEnabled}
                  type={"file"}
                  inputProps={{ accept: "video/mp4" }}
                />
              )}
            </FormControl>
            {isVideoUploadingEnabled === false && (
              <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert severity="info">
                  Video Uploading is a premium feature, contact
                  support@Gritly.us to Enable!
                </Alert>
              </Stack>
            )}
          </div>
        </div>
        {url && (
          <div className={styles["cohort-video-upload"]}>
            {url.includes("vimeo") ? (
              <iframe
                src={`${url}`}
                width="600"
                height="300"
                frameBorder="0"
                allowFullScreen
                title={videoName}
              />
            ) : (
              <video
                ref={videoJsPlayerRef}
                className="video-js"
                controls
                style={{
                  width: "100%",
                  height: "500px",
                  objectFit: "contain",
                }}
              >
                <source src={url} type="video/mp4" />
              </video>
            )}
          </div>
        )}
        <div className={styles["course-details-wrapper"]}>
          <span
            className={styles["course-details-header"]}
            style={{
              color: props.themeInfo[0]?.primarycolor,
              fontFamily: props.themeInfo[0]?.fontfamily,
            }}
          >
            Course Details
          </span>
          <div className={styles["title-desc-comp-container"]}>
            <TitleDescriptionComp
              label="Title"
              withTitle={false}
              hasRichText={true}
              content={content}
              setContent={setContent}
            />
          </div>
        </div>
        <div className={styles["create-cohort-footer"]}>
          <OutlinedButton
            variant="contained"
            bgcolor={
              props.themeInfo[0]?.primarycolor
                ? props.themeInfo[0]?.primarycolor
                : "#0e1555"
            }
            fontFamily={
              props.themeInfo[0]?.fontfamily
                ? props.themeInfo[0]?.fontfamily
                : "Nunito"
            }
            onClick={onBackNavigation}
          >
            Cancel
          </OutlinedButton>
          <FilledButtonSmall
            variant="contained"
            onClick={onClickSave}
            bgcolor={
              props.themeInfo[0]?.primarycolor
                ? props.themeInfo[0]?.primarycolor
                : "#0e1555"
            }
            fontFamily={
              props.themeInfo[0]?.fontfamily
                ? props.themeInfo[0]?.fontfamily
                : "Nunito"
            }
            style={{ marginLeft: "20px" }}
            disabled={isSubmitButtonDisabled()}
          >
            {state && state.editMode ? "Update" : "Save"}
          </FilledButtonSmall>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ app, cohort, user, program, config }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    cohortData: cohort.cohortData,
    userInfo: user.userDetails,
    cohortInfo: cohort.cohortInfo,
    allPrograms: program.allPrograms,
    themeInfo: user.themeInfo,
    customization: user.customization,
    videoMeta: cohort.videoMeta,
    progress: cohort.progress,
    config: config.configData,
    certificatesandBadgesList: cohort.certificatesandBadgesList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCohortCreated: (cohort) => dispatch(setCohortCreated(cohort)),
    setNewCohort: (cohort) => dispatch(setNewCohort(cohort)),
    updateCohort: (cohort, id) => dispatch(updateCohort(cohort, id)),
    getCohortInfo: (cohortId) => dispatch(getCohortInfo(cohortId)),
    getAllPrograms: (orgId) => dispatch(getAllPrograms(orgId)),
    fetchVideoURL: (file) => dispatch(fetchVideoURL(file)),
    createNewProgram: (programData) => dispatch(createNewProgram(programData)),
    updateProgram: (programId, programData) =>
      dispatch(updateProgram(programId, programData)),
    getCohortCertifcatesAndBadges: () =>
      dispatch(getCohortCertifcatesAndBadges()),
    deleteVideo: (videoId, type) => dispatch(deleteVideo(videoId, type)),
    setUploadProgress: (progress) => dispatch(setUploadProgress(progress)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCohorts);
