import { Button } from "@mui/material";
import { Box } from "@mui/system";
import styles from "../../../../Stylesheets/Modules/Admin/Students/studentProfile/studentProfile.module.scss";

function StudentFormHeader({
  headerName,
  buttonName,
  handleOnClick,
  themeInfo,
  preview
}) {
  return (
    <Box className={styles["personal-details-header"]}>
      <Box
        className={styles["profile-header"]}
        style={{
          background:
            themeInfo[0]?.primarycolor !== "#0e1555"
              ? themeInfo[0]?.primarycolor
              : "",
          fontFamily: themeInfo[0]?.fontfamily,
        }}
      >
        <Box className={styles["profile-header-name"]}>{headerName}</Box>
      </Box>
      {!preview && <Box>
        <Button
          variant="outlined"
          className={styles["personal-add-button"]}
          onClick={() => handleOnClick()}
        >
          {" "}
          {buttonName}{" "}
        </Button>
      </Box>}
    </Box>
  );
}

export default StudentFormHeader;
