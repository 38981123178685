import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import CancelIcon from "@mui/icons-material/Cancel";
import TitleDescriptionComp from "../../../Reusable/TitleDescriptionComp";
import {
  createLiveSession,
  updateLiveSession,
} from "../../../Store/Actions/actions.cohort";
import dummyProfile from "../../../Assets/Images/dummyprofile.png";
import { getAllCohorts } from "../../../Store/Actions/actions.cohort";
import styles from "../../../Stylesheets/Modules/Admin/LiveSession/LiveSessionCreation.module.scss";
import { Button } from "@mui/material";
import { StyledKeyboardArrowDownIcon } from "../../../Components/StyledComponents/Icons.style";
import { toast } from "react-hot-toast";
import { logPageView } from "../../../Utils/analytics";
import SetTime from "./SetTime";
import { Create } from "@mui/icons-material";
import { format } from "date-fns";
import CropImageModal from "../../../Reusable/Modals/CropImageModal";
import ImageUploader from "../../../Reusable/ImageUploader";
import { useCallback } from "react";

const LiveSessionCreation = (props) => {
  const { cohortId, moduleId } = useParams();
  const [speakerImage, setSpeakerImage] = useState("");
  const [topic, setTopic] = useState("");
  const [spkName, setSpkName] = useState("");
  const [meetingLink, setMeetingLink] = useState("");
  const { modules } = props;
  const [eventContent, setEventContent] = useState("");
  const [speakerContent, setSpeakerContent] = useState("");
  const [moduleSelected, setModuleSelected] = useState([]);
  const [cropDialogOpen, setCropDialogOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [file, setFile] = useState();
  const [originalFileName, setOriginalFileName] = useState("");
  const [fileURL, setFileURL] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const [dialogueStatusMap, setDialogueStatusMap] = useState({});
  const { liveSession, editMode } = props;
  const [uploaded, setUploaded] = useState(false);
  useEffect(() => {
    if (modules) {
      const modulesWithActiveLiveSessions = modules.filter(module => {
        const liveSessionOption = module.moduleOptions.find(option => option.key.toLowerCase() === "livesessions");
        return liveSessionOption && liveSessionOption.active;
      });

      setOptions(
        modulesWithActiveLiveSessions.map(item => ({
          name: item.name,
          id: item._id
        }))
      );
    }
  }, [modules]);


  const fetchImage = (file) => {
    setUploaded(false);
    // convert file to dataurl
    const reader = new FileReader();
    reader.onload = () => {
      setSpeakerImage(reader.result);
    };
    reader.readAsDataURL(file[0]);
    setOriginalFileName(file[0].name);
    setUploaded(true);
  };

  const handleCroppedFile = useCallback(
    (imageCropped) => {
      // Check if imageCropped is provided and not an empty string
      if (imageCropped && typeof imageCropped === "string") {
        setFileURL(imageCropped);
      } else if (
        liveSession?.photoUrl &&
        typeof liveSession?.photoUrl === "string"
      ) {
        // If imageCropped is empty or not provided, check if liveSession.photoUrl has data
        setFileURL(liveSession?.photoUrl);
      } else {
        // If both imageCropped and liveSession.photoUrl are empty, set fileURL to an empty string
        setFileURL("");
      }
    },
    [liveSession?.photoUrl]
  );

  useEffect(() => {
    if (!editMode && moduleId !== undefined) {
      setModuleSelected((prevModules) => {
        const updatedModules = [...prevModules];
        if (!updatedModules.includes(moduleId)) {
          updatedModules.push(moduleId);
        }
        return updatedModules;
      });
    }
  }, [moduleId, editMode]);
  useEffect(() => {
    if (editMode) {
      setTopic(liveSession.topic);
      setSpkName(liveSession.speakerName);
      setModuleSelected(liveSession.moduleIds);
      setMeetingLink(liveSession.googleMeetLink);
      setEventContent(liveSession.eventOverview);
      setSpeakerContent(liveSession.speaker);
      setFileURL(liveSession?.photoUrl);
      setTimeSlots(liveSession.dateSlot);
    }
  }, []);

  const handleSave = () => {
    const payload = {
      organizerUserId: props.userInfo.id,
      topic: topic,
      speakerName: spkName,
      cohortId: cohortId,
      moduleIds: moduleSelected,
      eventOverview: eventContent,
      speaker: speakerContent,
      groupSession: true,
      dateSlot: timeSlots,
      googleMeetLink: meetingLink,
      photoUrl: fileURL,
    };

    if (isButtonDisabled()) {
      if (!validateTimeSlots()) {
        toast.error("Please set time slots for all selected modules");
        return;
      }
      toast.error("Please fill all the required details");
    } else {
      if (!editMode) {
        props.createLiveSession(payload, props.moduleId);
      } else {
        props.updateLiveSession(liveSession._id, payload, props.moduleId);
      }

      props.togglePopUp(false);
    }
  };
  const validateTimeSlots = () => {
    for (const moduleId of moduleSelected) {
      const timeSlotsForModule = timeSlots.find(
        (slot) => slot.moduleId === moduleId
      );
      if (!timeSlotsForModule) {
        return false;
      }
    }
    return true;
  };

  const isButtonDisabled = () => {
    return !(
      topic !== "" &&
      spkName !== "" &&
      meetingLink !== "" &&
      moduleSelected.length !== 0 &&
      eventContent !== "" &&
      speakerContent !== "" &&
      fileURL !== "" &&
      validateTimeSlots()
    );
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] Live Session Creation Page");
  }, [location]);

  useEffect(() => {
    // Find modules that were removed
    const removedModules = timeSlots.filter(
      (slot) => !moduleSelected.includes(slot.moduleId)
    );

    // Remove the corresponding time slots
    const updatedTimeSlots = timeSlots.filter((slot) =>
      moduleSelected.includes(slot.moduleId)
    );

    // Update the time slots state ONLY if there are changes in the modules selected
    if (JSON.stringify(updatedTimeSlots) !== JSON.stringify(timeSlots)) {
      setTimeSlots(updatedTimeSlots);
    }

    // You can also reset the dialogueStatusMap for the removed modules
    const updatedDialogueStatusMap = { ...dialogueStatusMap };
    removedModules.forEach((slot) => {
      delete updatedDialogueStatusMap[slot.moduleId];
    });
    setDialogueStatusMap(updatedDialogueStatusMap);
  }, [moduleSelected]);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }
  useEffect(() => {
    if (uploaded) setCropDialogOpen(true);
  }, [uploaded]);
  return (
    <>
      <div className={styles["row-wise-wrapper"]}>
        <div className={styles["image-wrapper"]}>
          {fileURL.length !== 0 ? (
            <img
              src={fileURL}
              alt="user-icon"
              className={styles["image-preview-placeholder"]}
              onError={(event) => (event.target.src = dummyProfile)}
            />
          ) : (
            <img
              src={dummyProfile}
              alt="placeholder-user-icon"
              className={styles["image-preview-placeholder"]}
              onError={(event) => (event.target.src = dummyProfile)}
            />
          )}

          <div className={styles["image-upload-option"]}>
            <div className={styles["helper-text"]}>
              At least 256 x 256 PNG or JPG file.
            </div>
            <ImageUploader
              acceptedFormats={["image/jpeg", "image/png"]}
              supportedExtensions={[".jpeg", ".png"]}
              customButton={
                <div
                  className={styles["upload-button"]}
                  onClick={() => setUploaded(false)}
                >
                  Upload Photo
                </div>
              }
              onDrop={fetchImage}
            />
            {fileURL === "" && (
              <div className={styles["no-image-error"]}>
                *Image is mandatory
              </div>
            )}
            {uploaded && (
              <CropImageModal
                imageToBeCropped={speakerImage}
                cropDialogOpen={cropDialogOpen}
                originalFileName={originalFileName}
                setCropDialogOpen={setCropDialogOpen}
                setFileURL={handleCroppedFile}
                maxWidth={256}
                maxHeight={256}
                aspect={256 / 256}
                setUploaded={setUploaded}
                setFile={setFile}
              />
            )}
          </div>
        </div>
        <div className={styles["live-session-modal-wrapper"]}>
          <div className={styles["input-fields-labels"]}>
            <span>
              Topic
              <span className={styles["mandatory"]}>*</span>
            </span>
            <FormControl
              sx={{ m: 1 }}
              size="small"
              classes={{
                root: styles["form-control-root"],
              }}
              onChange={(e) => setTopic(e.target.value)}
            >
              <TextField value={topic} />
            </FormControl>
          </div>
          <div className={styles["input-fields-labels"]}>
            <span>
              Speaker Name
              <span className={styles["mandatory"]}>*</span>
            </span>
            <FormControl
              sx={{ m: 1 }}
              size="small"
              classes={{
                root: styles["form-control-root"],
              }}
              onChange={(e) => setSpkName(e.target.value)}
            >
              <TextField value={spkName} />
            </FormControl>
          </div>

          <div className={styles["input-fields-labels"]}>
            <span>
              Link Module
              <span className={styles["mandatory"]}>*</span>
            </span>

            <FormControl
              sx={{ m: 1 }}
              size="small"
              classes={{
                root: styles["form-control-root"],
              }}
            >
              <Select
                multiple
                value={moduleSelected}
                onChange={(e) => setModuleSelected(e.target.value)}
                IconComponent={StyledKeyboardArrowDownIcon}
                className={styles["select-tag-root"]}
                renderValue={(selected) => (
                  <div
                    className={`${styles["chips"]} ${styles["chips-container"]}`}
                  >
                    {selected.map((value) => {
                      const option = options.find((o) => o.id === value);
                      return (
                        <Chip
                          key={`${value}+${option?.name}`}
                          label={option ? option.name : ""}
                          clickable
                          deleteIcon={
                            <CancelIcon
                              onMouseDown={(event) => event.stopPropagation()}
                            />
                          }
                          className={styles["chip"]}
                          onDelete={(e) => {
                            e.preventDefault();
                            setModuleSelected(
                              moduleSelected.filter((i) => i !== value)
                            );
                          }}
                        />
                      );
                    })}
                  </div>
                )}
              >
                {options.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    <Checkbox checked={moduleSelected.includes(option.id)} />
                    <ListItemText primary={option.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={styles["input-fields-labels"]}>
            <span>
              Meeting Link
              <span className={styles["mandatory"]}>*</span>
            </span>
            <FormControl
              sx={{ m: 1 }}
              size="small"
              classes={{
                root: styles["form-control-root"],
              }}
              onChange={(e) => setMeetingLink(e.target.value)}
            >
              <TextField value={meetingLink} />
            </FormControl>
          </div>
        </div>
      </div>
      <div className={styles["module-name"]}>
        <span>
          Modules Time Slot
          <span className={styles["mandatory"]}>*</span>
        </span>
      </div>
      <div className={styles["module-time-slots"]}>
        {moduleSelected.map((moduleId) => {
          const option = options.find((o) => o.id === moduleId);
          const timeSlotsForModule = timeSlots.filter(
            (slot) => slot.moduleId === moduleId
          )[0];

          return !option || option.length === 0 ? (
            <div>  </div>
          ) : (
            <>
              <div key={option.id} className={styles["slot-wrapper"]}>
                <div className={styles["selected-module-name"]}>
                  {option.name}
                </div>

                {!timeSlotsForModule ? (
                  <div
                    className={styles["set-time-slot"]}
                    onClick={() => {
                      setDialogueStatusMap((prevMap) => ({
                        ...prevMap,
                        [option.id]: true,
                      }));
                    }}
                  >
                    Set Time Slot
                  </div>
                ) : (
                  <div className={styles["selected-time-slot-wrapper"]}>
                    <div className={styles["selected-date-time-slot"]}>
                      <div className={styles["selected-start-slot"]}>
                        <span>
                          {format(
                            new Date(timeSlotsForModule?.start),
                            "dd/MM/yyyy"
                          )}
                        </span>
                        <span>
                          {format(
                            new Date(timeSlotsForModule?.start),
                            "h:mm aa"
                          )}
                        </span>
                      </div>
                      -
                      <div className={styles["selected-end-slot"]}>
                        <span>
                          {format(
                            new Date(timeSlotsForModule?.end),
                            "dd/MM/yyyy"
                          )}
                        </span>
                        <span>
                          {format(new Date(timeSlotsForModule?.end), "h:mm aa")}
                        </span>
                      </div>
                      <Create
                        fontSize="small"
                        className={styles["edit-time-slot"]}
                        onClick={() => {
                          setDialogueStatusMap((prevMap) => ({
                            ...prevMap,
                            [option.id]: true,
                          }));
                        }}
                      />
                    </div>
                  </div>
                )}

                {dialogueStatusMap[option.id] ? (
                  <SetTime
                    key={`set-time-${option.id}`}
                    dialougeStatus={dialogueStatusMap[option.id]}
                    setDialougeStatus={(status) => {
                      setDialogueStatusMap((prevMap) => ({
                        ...prevMap,
                        [option.id]: status,
                      }));
                    }}
                    timeSlots={timeSlots}
                    editMode={editMode}
                    setTimeSlots={setTimeSlots}
                    timeSlotModule={option.id}
                  />
                ) : null}
              </div>
            </>
          );
        })}
      </div>
      <div className={styles["details-wrapper"]}>
        <div className={styles["live-session-details-wrapper"]}>
          <span className={styles["live-session-details-header"]}>
            Event Overview
            <span className={styles["mandatory"]}>*</span>
          </span>
          <div className={styles["title-desc-comp-container"]}>
            <TitleDescriptionComp
              label="Title"
              withTitle={false}
              hasRichText={true}
              content={eventContent}
              setContent={setEventContent}
            />
          </div>
        </div>
        <div className={styles["live-session-details-wrapper"]}>
          <span className={styles["live-session-details-header"]}>
            About Speaker
            <span className={styles["mandatory"]}>*</span>
          </span>
          <div className={styles["title-desc-comp-container"]}>
            <TitleDescriptionComp
              label="Title"
              withTitle={false}
              hasRichText={true}
              content={speakerContent}
              setContent={setSpeakerContent}
            />
          </div>
        </div>
        <div className={styles["button-wrapper"]}>
          <Button
            className={styles["cancel-button"]}
            onClick={() => {
              props.togglePopUp(false);
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              background: ` ${!isButtonDisabled() ? props.themeInfo[0]?.primarycolor : ""
                }`,
            }}
            classes={{
              root: `${styles["submit-button"]} ${isButtonDisabled() ? styles["disabled"] : ""
                }`,
            }}
            onClick={(e) => {
              handleSave(e);
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ app, user, cohort, modules }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    themeInfo: user.themeInfo,
    userInfo: user.userDetails,
    customization: user.customization,
    cohorts: cohort.cohorts,
    modules: modules.modules
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCohorts: () => dispatch(getAllCohorts()),
    createLiveSession: (payload, moduleId) => dispatch(createLiveSession(payload, moduleId)),
    updateLiveSession: (liveSessionId, payload, moduleId) =>
      dispatch(updateLiveSession(liveSessionId, payload, moduleId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveSessionCreation);
