import React from "react";
import GenericModal from "./GenericModal";
import styles from "../../Stylesheets/Modules/WelcomePopup.module.scss";

const WelcomePopup = ({ isOpen, togglePopUp }) => {
  return (
    <GenericModal
      className={styles["welcome-modal"]}
      open={isOpen}
      togglePopUp={togglePopUp}
      bgClass={"welcome-modal-bg"}
    >
      <div className={styles["welcome-modal-body"]}>
        <h1 className={styles["welcome-modal-title"]}>Welcome to Gritly!</h1>
        <div className={styles["welcome-modal-subtitle"]}>
          To start your quest, follow these steps:
        </div>
        <ul>
          <li>Enter the module, click on course content</li>
          <li>Watch video and answer question</li>
        </ul>
        <p className={styles["welcome-modal-bottom-subtitle"]}>
          Once you complete this skill. You can continue to the next module or{" "}
          use your browser to return to EvolveMe.
        </p>
        <p className={styles["welcome-modal-footer"]}>
          Enjoy your learning experience!
        </p>
      </div>
    </GenericModal>
  );
};

export default WelcomePopup;
