import React from "react";
import {
  StyledArticleIcon,
  StyledCheckCircleIcon,
  StyledRemoveRedEyeIcon,
  StyledCloseIcon,
} from "../Components/StyledComponents/Icons.style";
import styles from "../Stylesheets/Modules/statusIcon.module.scss";

const StatusIcon = ({ status }) => {
  const statusIcons = {
    ReSubmit: StyledArticleIcon,
    Submitted: StyledRemoveRedEyeIcon,
    Completed: StyledCheckCircleIcon,
    Failed: StyledCloseIcon,
  };

  const statusText = {
    ReSubmit: "ReSubmit",
    Submitted: "Submitted",
    Completed: "Completed",
    Failed: "Failed",
  };

  const IconComponent = statusIcons[status];

  return (
    <div className={`${styles["icon-text-wrapper"]} ${styles[status]}`}>
      <IconComponent classes={{ root: styles["menu-icon"] }} />
      <span className={styles[status]}>{statusText[status]}</span>
    </div>
  );
};

export default StatusIcon;
