import React from "react";
import { Dialog } from "@mui/material";

import ModalPersonalDetails from "./ModalPersonalDetails";
import ModalProfessionalDetails from "./ModalProfessionalDetails";
import ModalEducationDetails from "./ModalEducationDetails";
import ModalSkills from "./ModalSkills";

import styles from "../../../../../Stylesheets/Modules/Admin/Students/studentProfile/studentProfile.module.scss";
import PromptModal from "./PromptModal";

function ProfileModal(props) {

    const renderModal = (modalName) => {
        switch (modalName) {
            case "EditPersonalDetails":
                return <ModalPersonalDetails {...props} />
            case "EditProfessionalDetails":
                return <ModalProfessionalDetails {...props} />
            case "EditEducationDetails":
                return <ModalEducationDetails {...props} />
            case "SkillsDetails":
                return <ModalSkills {...props} />
            case "promptModal":
                return <PromptModal {...props} />
            default:
                break;
        }
    }
    return (
        <Dialog classes={{ container: styles["dialog-modal"], paper: styles["modal-paper"] }}
            open={props.open} onClose={props.handleClose}>

            {renderModal(props.modalName)}

        </Dialog>
    )
}

export default ProfileModal;