import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// TODO: need to work on this
const themeOptions = {
  palette: {
    type: 'light',
    // primary: {
    //   main: '#142297',
    // },
    // secondary: {
    //   main: '#ffa940',
    // },
    // text: {
    //   primary: '#64607d',
    //   secondary: 'rgba(0,0,0,0)',
    // },
    // background: {
    //   default: '#ffffff',
    //   paper: '#ffffff',
    // },
  },
  overrides: {
    // MuiButton: {
    //   root: {
    //     background: 'linear-gradient(154.49deg, #FFD085 6.61%, #FFAF2E 89.72%)',       
    //     border: 0,
    //     borderRadius: 10,
    //     boxShadow: '0px 10px 22px -2px rgb(250 186 84 / 30%)',        
    //     color: '#11142D',
    //     height: 50,
    //     width: 200
    //   },
    // },
  }
};

export const theme = responsiveFontSizes(createTheme(themeOptions))