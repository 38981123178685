import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from "../../../Stylesheets/Modules/Admin/Profile/adminBootcamp.module.scss";
import editIcon from "../../../Assets/Images/editIcon.jpg";
import BootcampEditModal from "./BootcampEditModal";
import { getAdminBootcampDetails } from "../../../Store/Actions/actions.user";

export const AdminBootcampDetails = (props) => {
  const { adminbootcampDetails, userInfo } = props;

  useEffect(() => {
    props.getAdminBootcampDetails(userInfo.id);
  }, [userInfo]);

  const refreshBootcampDetails = () => {
    props.getAdminBootcampDetails(userInfo.id);
  };

  const bootcampDetails = [
    {
      key: "bootcampName",
      label: "Bootcamp Name",
      value: adminbootcampDetails?.bootcampName,
    },
    {
      key: "bootcampDescription",
      label: "Bootcamp Description",
      value: adminbootcampDetails?.bootcampDescription,
    },
    {
      key: "trainingRoles",
      label: "Training Role",
      value: adminbootcampDetails?.trainingRole,
    },
    {
      key: "salaryPreProgram",
      label: "Average Salary Pre Program",
      value: "$" + (adminbootcampDetails?.salaryPreProgram || 0),
    },
    {
      key: "salaryPostProgram",
      label: "Average Salary Post Program",
      value: "$" + (adminbootcampDetails?.salaryPostProgram || 0),
    },
  ];
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={styles["main-div"]}>
      <div className={styles["label-header"]}>
        <div className={styles["div-label"]}>Bootcamp Details</div>
        <div className={styles["edit-option"]}>
          <img src={editIcon} alt="edit" onClick={handleOpen} />
        </div>
      </div>
      <div className={styles["bootcamp-wrapper"]}>
        {bootcampDetails.map((item) => {
          return (
            <div className={styles["bootcamp-details"]} key={item.key}>
              <div className={styles["label"]}>{item.label}</div>
              <div className={styles["value"]}>{item.value}</div>
            </div>
          );
        })}
      </div>
      <BootcampEditModal
        open={open}
        handleClose={handleClose}
        onDetailsUpdated={refreshBootcampDetails}
      />
    </div>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    adminbootcampDetails: user.bootcampDetails,
    userInfo: user.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAdminBootcampDetails: (userId) =>
      dispatch(getAdminBootcampDetails(userId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminBootcampDetails);
