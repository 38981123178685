import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import styles from "../Stylesheets/Modules/notificationsettings.module.scss";
import {
  getNotificationPreferences,
  toggleNotification,
  toggleStudentNotification,
} from "../Store/Actions/actions.app";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const NotificationSettings = (props) => {
  const {
    getNotificationPreferences,
    toggleNotification,
    toggleStudentNotification,
    notifications,
    student,
  } = props;
  const [notificationforOrg, setNotificationforOrg] = useState(false);
  const [notificationforStudent, setNotificationforStudent] = useState(false);

  useEffect(() => {
    fetchNotificationPreferences();
  }, []);

  const fetchNotificationPreferences = async () => {
    await getNotificationPreferences();
  };

  useEffect(() => {
    if (notifications) {
      setNotificationforOrg(Boolean(notifications.emailSubscription));
      setNotificationforStudent(
        Boolean(notifications.studentEmailSubscription)
      );
    }
  }, [notifications]);

  if (student) {
    return (
      <div className={styles["main-div"]}>
        <div className={styles["student-toggle-section"]}>
          <div className={styles["label"]}> Notifications </div>
          <FormGroup>
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={notificationforStudent}
                  onChange={() => {
                    setNotificationforStudent(!notificationforStudent);
                    toggleStudentNotification({
                      studentSubscription: !notificationforStudent,
                    });
                  }}
                />
              }
            />
          </FormGroup>
        </div>
      </div>
    );
  }
  return (
    <div className={styles["main-div"]}>
      <div className={styles["org-toggle-section"]}>
        <div className={styles["label"]}>Notifications For Organization</div>
        <FormGroup>
          <FormControlLabel
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={notificationforOrg}
                onChange={() => {
                  setNotificationforOrg(!notificationforOrg);
                  toggleNotification({
                    orgSubscription: !notificationforOrg,
                    studentSubscription: notificationforStudent,
                  });
                }}
              />
            }
          />
        </FormGroup>
      </div>
      <div className={styles["student-toggle-section"]}>
        <div className={styles["label"]}> Notifications For Students</div>
        <FormGroup>
          <FormControlLabel
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={notificationforStudent}
                onChange={() => {
                  setNotificationforStudent(!notificationforStudent);
                  toggleNotification({
                    orgSubscription: notificationforOrg,
                    studentSubscription: !notificationforStudent,
                  });
                }}
              />
            }
          />
        </FormGroup>
      </div>
    </div>
  );
};

const mapStateToProps = ({ app }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    notifications: app.notifications,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getNotificationPreferences: () => dispatch(getNotificationPreferences()),
    toggleNotification: (type) => dispatch(toggleNotification(type)),
    toggleStudentNotification: (value) =>
      dispatch(toggleStudentNotification(value)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationSettings);
