import React, { useEffect, useState } from "react";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import Container from "@mui/material/Container";
import styles from "../../../Stylesheets/Modules/Admin/Applications/studentApplications.module.scss";
import { connect } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import {
  getStudentApplications,
  updateApplicationStatus,
  deleteSelectedStudent,
} from "../../../Store/Actions/actions.cohort";
import { Badge, FormControl, MenuItem, Select, TextField } from "@mui/material";

import {
  StyledArrowBackIcon,
  StyledArticleIcon,
  StyledCheckCircleIcon,
  StyledRemoveRedEyeIcon,
  StyledArrowForwardIcon,
  StyledCloseIcon,
} from "../../../Components/StyledComponents/Icons.style";
import { StyledDeleteIcon } from "../../../Components/StyledComponents/Icons.style";
import { logPageView } from "../../../Utils/analytics";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
const StudentApplications = (props) => {
  const history = useNavigate();
  const { cohortId } = useParams();
  const [searchParams] = useSearchParams();
  const [headers, setHeaders] = useState([]);
  const [applicantsData, setApplicantsData] = useState([]);
  const {
    getStudentApplications,
    cohortApplicants,
    applicantsMetadata,
    updateApplicationStatus,
    deleteSelectedStudent,
  } = props;

  useEffect(() => {
    getStudentApplications(cohortId);
  }, []);

  useEffect(() => {
    setApplicantsData(cohortApplicants);
    return () => {
      setApplicantsData([]);
    };
  }, [cohortApplicants]);

  useEffect(() => {
    let headersMeta = applicantsMetadata?.headers;
    headersMeta = headersMeta?.map((header) => {
      return {
        key: header.key,
        label: header.label,
        type: header.type,
        options: header.options,
        minWidth: 150,
      };
    });
    headersMeta?.splice(0, 0, {
      key: "slNo",
      label: "S.No",
      type: "Number",
    });
    headersMeta && setHeaders(headersMeta);
  }, [applicantsMetadata]);

  const onImageError = () => {};

  const updateApplicantStatus = async (status, studentId) => {
    await updateApplicationStatus(cohortId, status, studentId);
  };

  const searchHandler = (query) => {
    // If the search query is empty, return the original data
    if (!query) {
      setApplicantsData(cohortApplicants);
      return;
    }
    const lowerCaseQuery = query.toLowerCase();

    // Filter the data based on the search query
    const filteredData = applicantsData.filter((item) => {
      const firstName = item.firstName ? item.firstName.toLowerCase() : "";
      const lastName = item.lastName ? item.lastName.toLowerCase() : "";
      const email = item.email ? item.email.toLowerCase() : "";

      // Check if the first name, last name, or email includes the search query
      return (
        firstName.includes(lowerCaseQuery) ||
        lastName.includes(lowerCaseQuery) ||
        email.includes(lowerCaseQuery)
      );
    });
    setApplicantsData(filteredData);
  };

  const getImage = (url, name) => {
    if (url?.length > 0) {
      return (
        <img
          className={styles["user-image"]}
          src={url}
          alt={""}
          onError={onImageError}
          referrerPolicy="no-referrer"
        />
      );
    }
    let letters = name?.split(/\s/).map((w) => w[0]);
    const first = letters[0];
    const last = letters.length > 1 ? letters[letters.length - 1] : "";
    letters = [first, last].join("").toUpperCase();
    return <div className={styles["name-letters"]}>{letters}</div>;
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] Student Applications Page");
  }, [location]);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }

  return (
    <>
      <Container
        maxWidth="xl"
        classes={{
          root: styles["container-root"],
        }}
      >
        <div
          className={styles["create-module-header"]}
          style={{
            color: localStorage.getItem("primarycolor"),
            fontFamily: localStorage.getItem("fontfamily"),
          }}
        >
          <Breadcrumbs
            aria-label="breadcrumb"
            style={{
              color: props.themeInfo[0]?.secondarycolor,
            }}
          >
            <Link underline="hover" color="inherit" href="/admin/cohorts">
              {props.customization.cohort_label || "Cohorts"}
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href={`/admin/cohortMenu/${cohortId}?cohort=${searchParams.get(
                "cohort"
              )}`}
            >
              {searchParams.get("cohort") + " Overview"}
            </Link>
            <Typography>
              <b>{"Applicants"}</b>
            </Typography>
          </Breadcrumbs>
        </div>
        <div className={styles["search"]}>
          <TextField
            label="Search"
            onChange={(e) => searchHandler(e.target.value)}
            classes={{
              root: styles["search-field-root"],
            }}
          />
        </div>
        <div className={styles["filter-div"]}>
          <div className={styles["total-text"]}>
            Total {applicantsData.length}
          </div>
        </div>
        <TableContainer sx={{ height: "calc(100vh - 12rem)" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              classes={{
                root: styles["applications-header-root"],
              }}
            >
              <TableRow
                classes={{
                  root: styles["header-row"],
                }}
              >
                {headers.map((column) => (
                  <TableCell
                    classes={{
                      root: styles["header-cell"],
                    }}
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {applicantsData?.map((row, inx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.key}>
                    {headers.map((column) => {
                      if (column.type === "Number") {
                        return (
                          <TableCell
                            classes={{
                              root: `${styles["body-cell"]} ${styles["name"]}`,
                            }}
                            key={column.key}
                            align={column.align}
                          >
                            {inx + 1}
                          </TableCell>
                        );
                      }
                      if (column.type === "ACTION") {
                        return (
                          <TableCell
                            classes={{
                              root: `${styles["body-cell"]} ${styles["name"]}`,
                            }}
                            key={column.key}
                            align={column.align}
                          >
                            <StyledDeleteIcon
                              width={"40%"}
                              onClick={() => {
                                deleteSelectedStudent(cohortId, row.studentId);
                              }}
                            />
                          </TableCell>
                        );
                      }
                      if (column.type === "LINK") {
                        return (
                          <TableCell
                            classes={{
                              root: `${styles["body-cell"]} ${styles["name"]}`,
                            }}
                            key={column.key}
                            align={column.align}
                          >
                            <Button
                              classes={{
                                root: styles["view-progress-button"],
                              }}
                              style={{
                                color: props.themeInfo[0]?.primarycolor,
                                fontSize: "12px",
                              }}
                              endIcon={
                                <StyledArrowForwardIcon
                                  classes={{
                                    root: styles["arrow-icon"],
                                  }}
                                />
                              }
                              onClick={() => {
                                history(
                                  `/admin/cohort/${cohortId}/viewSubmissions/${
                                    row.studentId
                                  }/${
                                    row.questionnaireId
                                  }?cohort=${searchParams.get("cohort")}`
                                );
                              }}
                            >
                              View Submissions
                            </Button>
                          </TableCell>
                        );
                      }
                      let value = row[column.key];
                      value = value?.length > 0 ? value : "NA";
                      if (column.key === "name") {
                        const name = `${row.firstName} ${row.lastName}`;
                        return (
                          <TableCell
                            classes={{
                              root: `${styles["body-cell"]} ${styles["name"]}`,
                            }}
                            key={column.key}
                            align={column.align}
                          >
                            <div className={styles["name-container"]}>
                              <div className={styles["profile-img"]}>
                                {getImage(row?.profileImgUrl, name)}
                                {row.applicationStatus === "Applied" && (
                                  <Badge
                                    className={styles["section-badge"]}
                                    color="error"
                                    badgeContent={"New"}
                                  />
                                )}
                              </div>
                              <div className={styles["name-text"]}>{name}</div>
                            </div>
                          </TableCell>
                        );
                      }
                      if (column.type === "DROPDOWN") {
                        const disabled = [];
                        column.options?.forEach((option) => {
                          if (option.key === value) {
                            disabled.push(option.key);
                          } else if (
                            value === "Accepted" ||
                            value === "Rejected"
                          ) {
                            disabled.push(...["Applied", "InReview"]);
                          } else if (
                            value === "InReview" &&
                            option.key === "Applied"
                          ) {
                            disabled.push(option.key);
                          }
                        });
                        let selectedItemClass = "applied";
                        if (value === "InReview") {
                          selectedItemClass = "review";
                        } else if (value === "Accepted") {
                          selectedItemClass = "accepted";
                        } else if (value === "Rejected") {
                          selectedItemClass = "rejected";
                        }
                        return (
                          <TableCell
                            classes={{
                              root: styles["body-cell"],
                            }}
                            key={column.key}
                            align={column.align}
                          >
                            <FormControl
                              classes={{
                                root: styles["form-root"],
                              }}
                            >
                              {value && (
                                <Select
                                  classes={{
                                    select: `${styles["menu-select"]} ${styles[selectedItemClass]}`,
                                  }}
                                  value={value || ""}
                                  onChange={(e) => {
                                    updateApplicantStatus(
                                      e.target.value.toLowerCase(),
                                      row.studentId
                                    );
                                  }}
                                >
                                  {column.options?.map((option) => {
                                    let menuItemClass = "applied";
                                    if (option.key === "InReview") {
                                      menuItemClass = "review";
                                    } else if (option.key === "Accepted") {
                                      menuItemClass = "accepted";
                                    } else if (option.key === "Rejected") {
                                      menuItemClass = "rejected";
                                    }
                                    return (
                                      <MenuItem
                                        key={option.key}
                                        value={option.key}
                                        disabled={
                                          disabled.indexOf(option.key) !== -1
                                        }
                                        classes={{
                                          root: `${styles["menu-item-root"]} ${styles[menuItemClass]}`,
                                          selected: styles["selected-item"],
                                        }}
                                      >
                                        {option.key === "Applied" && (
                                          <StyledArticleIcon
                                            classes={{
                                              root: styles["menu-icon"],
                                            }}
                                          />
                                        )}
                                        {option.key === "InReview" && (
                                          <StyledRemoveRedEyeIcon
                                            classes={{
                                              root: styles["menu-icon"],
                                            }}
                                          />
                                        )}
                                        {option.key === "Accepted" && (
                                          <StyledCheckCircleIcon
                                            classes={{
                                              root: styles["menu-icon"],
                                            }}
                                          />
                                        )}
                                        {option.key === "Rejected" && (
                                          <StyledCloseIcon
                                            classes={{
                                              root: styles["menu-icon"],
                                            }}
                                          />
                                        )}
                                        <span
                                          className={styles["menu-item-text"]}
                                        >
                                          {option.label}
                                        </span>
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              )}
                            </FormControl>
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell
                          classes={{
                            root: styles["body-cell"],
                          }}
                          key={column.id}
                          align={column.align}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

const mapStateToProps = ({ app, cohort, user }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    cohortApplicants: cohort.cohortApplicants,
    applicantsMetadata: cohort.applicantsMetadata,
    themeInfo: user.themeInfo,
    customization: user.customization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStudentApplications: (cohortId) =>
      dispatch(getStudentApplications(cohortId)),
    updateApplicationStatus: (status, cohortId, studentId) =>
      dispatch(updateApplicationStatus(status, cohortId, studentId)),
    deleteSelectedStudent: (cohortId, studentId) =>
      dispatch(deleteSelectedStudent(cohortId, studentId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentApplications);
