import axios from "axios";

import store from "../Store/store";
import { clearToken, refreshToken } from "./token";

export const API_sql = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT_SQL,
});
API_sql.interceptors.request.use((config) => {
  const auth = store.getState().auth;
  const user = store.getState().user;
  if (!config?.headers?.authorization) {
    //check if the token is expired or not
    const accessTokenExpiry = localStorage.getItem("accessTokenExpiry");
    const tokenExpiration = new Date(accessTokenExpiry);
    // reduce the expiration time by 1 minute
    tokenExpiration.setMinutes(tokenExpiration.getMinutes() - 1);
    const now = new Date();
    if (accessTokenExpiry && tokenExpiration < now) {
      //now the token is expired call the refresh token function
      refreshToken();
    }
    config.headers.authorization = auth.userToken;
  }
  config.headers = { ...config.headers, "X-AD-USER-UID": auth.userName };
  config.headers = {
    ...config.headers,
    "X-SUB-DOMAIN": window.location.hostname === 'localhost' ? "dev" : window.location.hostname.split(".")[0],
  };
  config.headers.orgId = user?.userDetails?.orgId;
  config.headers = { ...config.headers, "X-ORGANIZATION-UID": user?.orgList.orgUid, "X-USER-UID": user.userUid };
  return config;
});

const API = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  withCredentials: true,
});

API.interceptors.request.use((config) => {
  const auth = store.getState().auth;
  const user = store.getState().user;

  if (!config?.headers?.authorization) {
    //check if the token is expired or not
    const accessTokenExpiry = localStorage.getItem("accessTokenExpiry");
    const tokenExpiration = new Date(accessTokenExpiry);
    // reduce the expiration time by 1 minute
    tokenExpiration.setMinutes(tokenExpiration.getMinutes() - 1);
    const now = new Date();
    if (accessTokenExpiry && tokenExpiration < now) {
      //now the token is expired call the refresh token function
      refreshToken();
    }
    config.headers.authorization = `Bearer ${auth.userToken}`;
  }
  config.headers.orgId = user?.userDetails?.orgId;
  config.headers = { ...config.headers, "X-ORGANIZATION-UID": user?.orgList.orgUid, "X-USER-UID": user.userUid };

  config.headers = { ...config.headers, "X-CLIENT-ID": localStorage.getItem("cognito-user-type") === "ORG_ADMIN" ? process.env.REACT_APP_CLIENT_ID : process.env.REACT_APP_CLIENT_ID_STUDENT };
  return config;
});

// if the current token is expired or invalid, logout the user
API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const auth = store.getState().auth;
    if (error.response.status === 401 && auth.accessToken) {
      clearToken();
    }
    return Promise.reject(error.response);
  }
);

export default API;
