import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { connect } from "react-redux";
import {
  setAuthModal,
  setForgotPassModal,
  setOrgLoginModal,
} from "../../Store/Actions/actions.app";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import styles from "../../Stylesheets/Modules/loginModal.module.scss";
import {
  login,
  register,
  setUserToken,
  orgLogin,
} from "../../Store/Actions/actions.auth";
import CloseIcon from "@mui/icons-material/Close";
import "../../Stylesheets/Modules/general.scss";
import { toast } from "react-hot-toast";
import { validateEmail, validatePassword } from "../../Utils/validate";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Visibility from "@mui/icons-material/Visibility";
import Logo from "../../Assets/Images/Gritly-logo.svg";
import { FilledButton } from "../../Components/StyledComponents/Button.style";
import {
  StyledArrowForwardIcon,
  StyledCloseIcon,
  StyledVisibility,
  StyledVisibilityOff,
} from "../../Components/StyledComponents/Icons.style";

const OrgLoginModal = (props) => {
  const [imageUrl, setImgURL] = React.useState(Logo);
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [currView, setCurrView] = useState("login");
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [authObj, setAuthObj] = useState({});
  React.useEffect(() => {
    if (props.themeInfo[0]?.logourl) {
      setImgURL(props.themeInfo[0]?.logourl);
      // window
      //   .getElementById("logoPreview")
      //   .setAttribute("src", localStorage.getItem("recentlogo"));
    } else {
      setImgURL(Logo);
      // window.getElementById("logoPreview").setAttribute("src", Logo);
    }
  }, [props.themeInfo]);
  const handleClose = () => {
    setCurrView("login");
    props.setOrgLoginModal(false);
  };

  const onSubmit = () => {
    if (!validPayload()) {
      return;
    }

    callOrgLogin();
  };

  const callOrgLogin = async () => {
    const { userName, password } = authObj;

    await props.orgLogin(userName, password);
  };

  const validPayload = () => {
    const { userName, password } = authObj;

    if (!validateEmail(userName)) {
      toast.error("Please enter a valid email");
      return false;
    } else if (!validatePassword(password)) {
      toast.error(
        "Password must be atleast 8 letter long with a small, capital, number and special characters"
      );
      return false;
    }
    return true;
  };

  const handleChange = (key, val) => {
    const newAuthObj = {
      ...authObj,
      [key]: val,
    };
    setAuthObj(newAuthObj);
  };

  return (
    <div>
      <Dialog
        className={styles["auth-modal-container"]}
        classes={{
          root: styles["auth-modal-root"],
          paper: styles["auth-modal-paper"],
        }}
        fullScreen={fullScreen}
        open={props.orgLoginModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent
          className={`${styles["auth-modal-body"]} ${styles[currView]}`}
        >
          <div className={styles["close-container"]} onClick={handleClose}>
            <StyledCloseIcon />
          </div>
          <div className={styles["header"]}>
            <img
              src={imageUrl}
              alt="Gritly"
              style={{ width: "40px", height: "40px" }}
            />
            <span className={styles["subtitle"]}>Org Login</span>
          </div>
          <div className={styles["content"]}>
            <FormControl variant="outlined" className={styles["form-control"]}>
              <InputLabel
                htmlFor="outlined-adornment-email"
                classes={{
                  root: styles["input-label"],
                }}
              >
                Email
              </InputLabel>
              <OutlinedInput
                type="email"
                id="outlined-adornment-email"
                className={styles["input-field"]}
                value={authObj.userName || ""}
                onChange={(e) => handleChange("userName", e.target.value)}
                classes={{
                  root: styles["outline-root"],
                  notchedOutline: styles["notched-outline-root"],
                  input: styles["user-input-field"],
                }}
                placeholder={"Enter your Email"}
                label="Email"
              />
            </FormControl>
            <FormControl variant="outlined" className={styles["form-control"]}>
              <InputLabel
                htmlFor="outlined-adornment-email"
                classes={{
                  root: styles["input-label"],
                }}
              >
                {" "}
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                className={styles["input-field"]}
                classes={{
                  root: styles["outline-root"],
                  notchedOutline: styles["notched-outline-root"],
                  input: styles["user-input-field"],
                }}
                placeholder={"Enter your password"}
                type={showPassword ? "text" : "password"}
                value={authObj.password || ""}
                onChange={(e) => handleChange("password", e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      className={styles["password-toggle-icon"]}
                      classes={{
                        root: styles["icon-button-root"],
                      }}
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <StyledVisibilityOff />
                      ) : (
                        <StyledVisibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <div className={styles["links-container"]}>
              <span
                onClick={() => {
                  props.setForgotPassModal(false);
                  props.toggleAuthModal(true);
                  props.setOrgLoginModal(false);
                }}
                className={styles["reset-pass-link"]}
                style={{
                  color: props.themeInfo[0]?.primarycolor,
                  fontFamily: props.themeInfo[0]?.fontfamily,
                }}
              >
                Back to Student Login
              </span>
            </div>

            <FilledButton
              variant="contained"
              bgcolor={
                props.themeInfo[0]?.primarycolor
                  ? props.themeInfo[0]?.primarycolor
                  : "#0e1555"
              }
              fontFamily={
                props.themeInfo[0]?.fontfamily
                  ? props.themeInfo[0]?.fontfamily
                  : "Nunito"
              }
              primary="true"
              endIcon={
                <StyledArrowForwardIcon
                  classes={{
                    root: styles["arrow-icon"],
                  }}
                />
              }
              onClick={onSubmit}
            >
              {"Continue"}
            </FilledButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = ({ app, auth, user }) => {
  return {
    openLoginModal: app.openLoginModal,
    userToken: auth.userToken,
    orgLoginModal: app.orgLoginModal,
    themeInfo: user.themeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    toggleAuthModal: (status) => dispatch(setAuthModal(status)),
    setUserToken: (token) => dispatch(setUserToken(token)),
    register: (signUpInfo) => dispatch(register(signUpInfo)),
    orgLogin: (email, pass) => dispatch(orgLogin(email, pass)),
    setForgotPassModal: (status) => dispatch(setForgotPassModal(status)),
    setOrgLoginModal: (status) => dispatch(setOrgLoginModal(status)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrgLoginModal);
