import React, { useEffect } from "react";
import styles from "../Stylesheets/Modules/notFound.module.scss";
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { logPageView } from "../Utils/analytics";

const ASARedirect = () => {
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    logPageView("ASA Redirect Access Denied");
  }, [location]);

  return (
    <center className={styles["main"]}>
      <h2>Oops! Access Denied</h2>
      <Button
        classes={{
          root: styles["button-root"],
        }}
        variant="contained"
        onClick={() => {
          const referrer = document.referrer;
          if (referrer === "" || referrer === undefined || referrer === null) {
            navigate(`/`);
          } else window.location.replace(`${referrer}`);
        }}
      >
        Back to Main Page
      </Button>
    </center>
  );
};

export default ASARedirect;
