import React, { createContext, useContext, useState } from "react";
import ConfirmDialog from "../Reusable/Modals/ConfirmDialog";
import { getQueryParams } from "../Helpers/utils";

const ConfirmDialogContext = createContext();

export const useConfirmDialog = () => useContext(ConfirmDialogContext);

export const ConfirmDialogProvider = ({ children }) => {
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    onConfirm: () => {},
  });

  // Function to open the confirm dialog
  const openConfirmDialog = (title, onYesAction) => {
    setConfirmDialog({
      isOpen: true,
      title,
      onConfirm: () => {
        onYesAction(); // Perform the action passed in when "Yes" is clicked
        setConfirmDialog((prev) => ({ ...prev, isOpen: false })); // Then close the dialog
      },
    });
  };

  // Helper function to handle condition checking and opening the dialog
  const checkConditionAndShowDialog = (onYesAction) => {
    const { user_id, unique_task_key, step_number } = getQueryParams();
    const referrerObj = JSON.parse(localStorage.getItem("referrerObj"));
    if (
      referrerObj?.referrer?.includes("evolveme") ||
      user_id ||
      unique_task_key ||
      step_number
    ) {
      openConfirmDialog(
        `⚠️ You will not be rewarded any points if you choose to navigate away. Are you sure you want to navigate away?`,
        onYesAction
      );
    } else {
      // if condition is not met, just perform the action
      onYesAction();
    }
  };

  const contextValue = {
    confirmDialog,
    setConfirmDialog,
    checkConditionAndShowDialog,
  };

  return (
    <ConfirmDialogContext.Provider value={contextValue}>
      {children}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </ConfirmDialogContext.Provider>
  );
};
