import { TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { FilledButton } from "../Components/StyledComponents/Button.style";
import { StyledArrowBackIcon } from "../Components/StyledComponents/Icons.style";
import styles from "../Stylesheets/Modules/labelcustomization.module.scss";
import { updateLabels } from "../Store/Actions/actions.user";
import { useState } from "react";
import toast from "react-hot-toast";
import { logPageView } from "../Utils/analytics";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
export const LabelCustomization = (props) => {
  const program = props.customization.program_label;
  const cohort = props.customization.cohort_label;
  const module = props.customization.module_label;
  const [programName, SetProgramName] = useState(program);
  const [cohortName, SetcohortName] = useState(cohort);
  const [moduleName, SetModuleName] = useState(module);
  const history = useNavigate();
  const handleSave = () => {
    if (programName !== "" && moduleName !== "" && cohortName !== "")
      props.updateLabels({ cohortName, moduleName, programName });
    else toast.error("Cannot save empty field");
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] Label Customization Page");
  }, [location]);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }

  return (
    <div className={styles["main-div"]}>
      <div className={styles["page-heading"]}>
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{
            color: props.themeInfo[0]?.secondarycolor,
          }}
        >
          <Link underline="hover" color="inherit" href="/admin/settings">
            Settings
          </Link>

          <Typography>
            <b>Label Customisation</b>
          </Typography>
        </Breadcrumbs>
      </div>
      <div className={styles["mini-window"]}>
        <div className={styles["form-wrapper"]}>
          <div className={styles["section-wrapper"]}>
            <label>Enter Program Label</label>
            <TextField
              variant="outlined"
              onChange={(e) => {
                SetProgramName(e.target.value);
              }}
              value={programName}
            />
          </div>
          <div className={styles["section-wrapper"]}>
            <label>Enter Cohort Label</label>
            <TextField
              variant="outlined"
              onChange={(e) => {
                SetcohortName(e.target.value);
              }}
              value={cohortName}
            />
          </div>
          <div className={styles["section-wrapper"]}>
            <label>Enter Module Label</label>
            <TextField
              variant="outlined"
              value={moduleName}
              onChange={(e) => {
                SetModuleName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={styles["button-section"]}>
          <FilledButton
            className={styles["save-btn"]}
            variant="contained"
            bgcolor={
              props.themeInfo[0]?.primarycolor
                ? props.themeInfo[0]?.primarycolor
                : "#0e1555"
            }
            onClick={handleSave}
          >
            Save
          </FilledButton>
          <FilledButton
            className={styles["cancel-btn"]}
            variant="contained"
            bgcolor={
              props.themeInfo[0]?.primarycolor
                ? props.themeInfo[0]?.primarycolor
                : "#0e1555"
            }
            onClick={() => history(`/admin/settings`)}
          >
            Cancel
          </FilledButton>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth, user, app, cohort }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    themeInfo: user.themeInfo,
    customization: user.customization,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateLabels: (labelInfo) => dispatch(updateLabels(labelInfo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelCustomization);
