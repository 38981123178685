import { triggerASAPoints } from "../Store/Actions/actions.app";
import store from "../Store/store";
import { logEvent } from "../Utils/analytics";
import { getQueryParams } from "./utils";


export const isAppLoading = () => {
    const appLoadingStatus = store.getState().app.appLoadingStatus
    return appLoadingStatus !== 0
}

export const handleActionCompletionASA = async () => {
    const { user_id, unique_task_key, step_number } = getQueryParams();
    const closeWindowAndClearSession = () => {
        window.close();
        sessionStorage.removeItem('referrerObj');
    };

    try {
        if (step_number === "1") {
            await store.dispatch(triggerASAPoints())
        }

        // Log event regardless of step_number
        logEvent('ASA_Completion_Trigger', { path: window.location.pathname, user_id, unique_task_key, step_number })

        // Close window and clear session if certain conditions are met
        const referrerObj = JSON.parse(localStorage.getItem('referrerObj'));
        const shouldClose = referrerObj?.referrer?.includes('evolveme') || user_id || unique_task_key || step_number;
        if (shouldClose) {
            closeWindowAndClearSession();
        }
    } catch (error) {
        console.error('An error occurred during the action completion:', error);
    }

};