import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { toast } from "react-hot-toast";
import API from "../../Helpers/axiosInit";
import { API_sql } from "../../Helpers/axiosInit";
import { AuthEvents } from "../../Utils/events";
import { setAppLoading, setInitLoading, setAuthModal, setOrgLoginModal, setForgotPassModal, setVerificationCodeInput } from "./actions.app";
import {
  setUserUid,
  setOrglistData,
  setUserDetails,
  setUserProfileDataAction,
  setImageURL,
  setCustomizationInfo,
  setAdminBootcampDetails,
  setAdminUserDetails,
  setUserInfo,
  setSkillsUserDataAction,
} from "./actions.user";
import { clearToken, triggerTokenExpiration } from "../../Helpers/token";

export const setUserToken = (token) => {
  return {
    type: "SET_ACCESS_TOKEN",
    payload: {
      token,
    },
  };
};

export const setAccessToken = (token) => {
  return {
    type: "SET_ACCESS_TOKEN",
    payload: {
      token,
    },
  };
};
export const setRefreshToken = (refreshToken) => {
  return {
    type: "SET_REFRESH_TOKEN",
    payload: {
      refreshToken,
    },
  };
};

export const setUserName = (userName) => {
  return {
    type: "SET_USER_NAME_TOKEN",
    payload: {
      userName,
    },
  };
};

export const setTokenExpiration = (exp) => {
  return {
    type: "SET_TOKEN_EXPIRATION",
    payload: {
      exp,
    },
  };
};

export const setConfigAction = (data) => {
  return {
    type: "SET_CONFIG",
    payload: {
      configData: data,
    },
  };
};

export const setcognitoToken = (data) => {
  return {
    type: "COGNITO_TOKEN",
    payload: {
      data,
    },
  };
};

export const logout = (userId) => async (dispatch) => {
  try {
    const time = new Date().toISOString();
    await API.put("/reporting/setLastLogoutTime", { time, userId });
  } catch (err) {
    console.log("error while uploading lastLogoutTime");
  }
  dispatch(clearToken());
};

export const register = (signUpInfo) => async (dispatch) => {
  dispatch(setAppLoading(true, "register - true"));
  try {
    await Auth.signUp({
      username: signUpInfo.username,
      password: signUpInfo.password,
      attributes: {
        given_name: signUpInfo.firstName,
        family_name: signUpInfo.lastName,
        phone_number: signUpInfo.mobileNo,
        email: signUpInfo.email,
        'custom:termsAndCondition': signUpInfo.acceptTerms.toString(),
      },
      autoSignIn: {
        // optional - enables auto sign in after user is confirmed
        enabled: false,
      },
    });
    dispatch(setVerificationCodeInput(true));
    toast.success("Please enter the verification code sent to your mail");
  } catch (err) {
    if (err?.data?.msg) {
      toast.error(err?.data?.msg);
    } else {
      toast.error("Sign up failed. Email is already registed");
    }
  } finally {
    dispatch(setAppLoading(false, "register - false"));
  }
};

export const verifyUser = (signUpInfo) => async (dispatch) => {
  dispatch(setAppLoading(true, "verification-true"));
  try {
    await Auth.confirmSignUp(signUpInfo.username, signUpInfo.code);
    toast.success("Successfully verified 🎉")
    dispatch(setVerificationCodeInput(false));
    dispatch(setAuthModal(false));
    return true;
  } catch (err) {
    if (err.code === 'CodeMismatchException') {
      toast.error("The confirmation code was incorrect, please re-enter")
      return false;
    }
  } finally {
    dispatch(setAppLoading(false, "verification - false"));
  }
}

export const socialLogin = () => (dispatch) => {
  dispatch(setAppLoading(true, "login - true"));
  try {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  } finally {
    dispatch(setAppLoading(false, "login - false"));
  }
}

export const login = (email, password) => async (dispatch) => {
  dispatch(setAppLoading(true, "login - true"));
  try {
    const user = await Auth.signIn(email, password);
    dispatch(
      setToken(
        user.signInUserSession.accessToken.jwtToken,
        user?.signInUserSession.refreshToken.token,
        user.username,
        new Date(user.signInUserSession.accessToken.payload.exp * 1000)
      )
    );
    dispatch(getUserInfo(false, 'ORG_USER'));
    dispatch(setcognitoToken(user));
    dispatch(setAuthModal(false));
    dispatch(setOrgLoginModal(false));
    toast.success("Successfully Logged in 🥳");

    window.localStorage.setItem("cognito-username", user.username);
    window.localStorage.setItem("cognito-key", user.keyPrefix);
    window.localStorage.setItem("cognito-user-type", 'ORG_USER');
    window.localStorage.setItem("accessTokenExpiry", new Date(user.signInUserSession.accessToken.payload.exp * 1000));
    // fire an event to let all tabs know they should login
    window.localStorage.setItem(AuthEvents.LOGIN, new Date().toISOString());
    window.location.reload();
  } catch (err) {
    if (err?.data?.msg) {
      toast.error(err?.data?.msg);
    } else if (err.code === 'UserNotConfirmedException') {
      dispatch(setVerificationCodeInput(true));
      toast.error("Please enter the verification code sent to your mail");
    } else {
      toast.error("Login failed. Please try again");
    }
  } finally {
    dispatch(setAppLoading(false, "login - false"));
  }
};

export const orgLogin = (email, password, newPassword, fromUpdatePassword = false) => async (dispatch) => {
  dispatch(setAppLoading(true, "login - true"));
  try {
    const user = await Auth.signIn(email, password);
    if (!fromUpdatePassword && user.challengeName === "NEW_PASSWORD_REQUIRED") {
      toast("Please change your password")
      window.location.href = `/changePassword/${email}`
    } else if (fromUpdatePassword) {
      await Auth.completeNewPassword(user, newPassword);
    }
    dispatch(
      setToken(
        user.signInUserSession.accessToken.jwtToken,
        user.signInUserSession.refreshToken.token,
        user.username,
        new Date(user.signInUserSession.accessToken.payload.exp * 1000)
      )
    );

    dispatch(getUserInfo(false, 'ORG_ADMIN'));
    dispatch(setcognitoToken(user));
    dispatch(setAuthModal(false));
    dispatch(setOrgLoginModal(false));
    toast.success("Successfully Logged in 🥳");

    window.localStorage.setItem("cognito-username", user.username);
    window.localStorage.setItem("cognito-key", user.keyPrefix);
    window.localStorage.setItem("cognito-user-type", 'ORG_ADMIN');
    window.localStorage.setItem("accessTokenExpiry", new Date(user.signInUserSession.accessToken.payload.exp * 1000));
    window.localStorage.setItem(AuthEvents.LOGIN, new Date().toISOString());

    if (fromUpdatePassword) {
      toast.success("Redirecting you to home page")
      window.location.href = '/'
    }

  } catch (err) {
    if (err?.data?.msg) {
      toast.error(err?.data?.msg);
    } else {
      toast.error("Login failed. Please try again");
    }
  } finally {
    dispatch(setAppLoading(false, "login - false"));
  }
};

export const resetPassword = (email) => async (dispatch) => {
  try {
    // Send confirmation code to user's email
    return Auth.forgotPassword(email).then(() => {
      toast.success("Please check your 📬 inbox to reset your password");
      dispatch(setcognitoToken({ username: email }));
      dispatch(setForgotPassModal(false));
    });

  } catch (err) {
    if (err?.data?.msg) {
      toast.error(err?.data?.msg);
    }
  } finally {
  }
};

export const updatePassword =
  (password, username, code) => async () => {
    try {
      // Collect confirmation code and new password, then
      Auth.forgotPasswordSubmit(username, code, password)
        .then(() => {
          toast.success(
            "Password updated successfully 🥳. Redirecting you to home page"
          );
          setTimeout(() => {
            window.location.replace("/");
          }, 3000);
        });

    } catch (err) {
      if (err?.data?.msg) {
        toast.error(err?.data?.msg);
      }
    } finally {
    }
  };

export const setConfig =
  (isInitLoad = false) =>
    (dispatch) => {
      try {
        //const mockData = await API.put('/config/getUserConfig', {userId})

        const mockData = {
          liveSession: true,
          content: true,
          videoUploading: true,
          cohortCreation: true,
          reporting: true,
        };

        dispatch(setConfigAction(mockData));
      } catch (err) {
        console.log(err, "error in setConfig");
      }
    };
// cognito - mongo mapped userinfo
export const getUserInfo =
  (isInitLoad = false, loginType) =>
    async (dispatch) => {
      if (isInitLoad) {
        dispatch(setInitLoading(true, "refreshToken - true"));
      } else {
        dispatch(setAppLoading(true, "refreshToken - true"));
      }
      try {
        const res = await API_sql.get("/userManagement/user/userInfo");
        const {
          userUid,
          orgList,
          user,
          userProfile,
          customization,
          adminProfile,
          adminUserDetails,
        } = res.data;
        if (loginType) {
          user.role = user.role.filter(e => e === loginType)[0];
        } else {
          user.role = 'PUBLIC'
        }
        dispatch(setOrglistData(orgList[0]));
        dispatch(setUserUid(userUid));
        dispatch(setUserDetails(user));
        dispatch(setImageURL(user.profileImgUrl));
        dispatch(setCustomizationInfo(customization));
        dispatch(setUserProfileDataAction(userProfile));
        dispatch(setUserLoginTime(user.id));
        dispatch(setAdminBootcampDetails(adminProfile));
        dispatch(setAdminUserDetails(adminUserDetails));
        dispatch(setSkillsUserDataAction(userProfile));
      } catch (err) {
        // catch goes here
      } finally {
        if (isInitLoad) {
          dispatch(setInitLoading(false, "refreshToken - false"));
        } else {
          dispatch(setAppLoading(false, "refreshToken - false"));
        }
        dispatch(setConfig(dispatch));
      }
    };

export const setToken =
  (token, refreshToken, username, expiration) => (dispatch) => {
    dispatch(setAccessToken(token));
    dispatch(setRefreshToken(refreshToken));
    dispatch(setUserName(username));
    dispatch(setTokenExpiration(expiration));
    triggerTokenExpiration(expiration);
  };

export const setUserLoginTime = (userId) => async () => {
  try {
    const time = new Date(window.localStorage.getItem(AuthEvents.LOGIN));
    await API.put("/reporting/setLastLoginTime", { time, userId });
  } catch (err) {
    console.log("error while uploading lastLoginTime");
  }
};

export const setTimeSpentOnPlatform = async (time, userId) => {
  try {
    await API.put("/reporting/setTotalTimeSpentOnPlatform", { time, userId });
  } catch (err) {
    console.log("error while setting total platform time", err);
  }
};