import * as Types from '../StaticTypes'

const initialState = {
    configData: {}
};

export default function config(state = initialState, action) {
    switch (action.type) {

      case Types.SET_CONFIG:
        const {configData} = action.payload
        if(configData) {
          return {
            ...state,
            configData
          }
        } 
        break;

      default:
        return state
    }
  }