import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import SocialAuthCallback from "../Components/SocialAuthCallback";
import Cohort from "../Pages/Cohort";
import CohortApplication from "../Pages/CohortApplication";
import Home from "../Pages/Home";
import NotFound from "../Pages/NotFound";
import PasswordReset from "../Pages/PasswordReset";
import Ping from "../Pages/Ping";
import ForgotPasswordModal from "../Reusable/Modals/ForgotPasswordModal";
import LoginModal from "../Reusable/Modals/LoginModal";
import OrgLoginModal from "../Reusable/Modals/OrgLoginModal";
import SideMenuBar from "../Reusable/SideMenuBar";
import CreateCohorts from "../Pages/Admin/Cohorts/CreateCohorts";
import Cohorts from "../Pages/Admin/Cohorts/Cohorts";
import CohortMenu from "../Pages/Admin/Cohorts/CohortMenu";
import CreateModule from "../Pages/Admin/Modules/CreateModule";
import { setUserToken } from "../Store/Actions/actions.auth";
import "../Stylesheets/Modules/general.scss";
import RequireAuth from "./RequireAuth";
import CohortCurriculum from "../Pages/CohortCurriculum";
import ModuleOverview from "../Pages/ModuleOverview";
import ModuleContent from "../Pages/ModuleContent";
import ModuleQuestions from "../Pages/ModuleQuestions";
import UnderDevelopment from "../Pages/UnderDevelopment";
import Footer from "../Reusable/Footer";
import AdminModuleOverview from "../Pages/Admin/Modules/AdminModuleOverview";
import ModuleContentCreation from "../Pages/Admin/Modules/ModuleContentCreation";
import ModuleQuestionCreation from "../Pages/Admin/Modules/ModuleQuestionCreation";
import StudentApplications from "../Pages/Admin/Application/StudentApplications";
import StudentsList from "../Pages/Admin/Students/StudentsList";
import ViewStudentProgress from "../Pages/Admin/Students/ViewStudentProgress";
import QuestionnaireFeedBack from "../Pages/Admin/Students/QuestionnaireFeedBack";
import ThemeSetting from "../Pages/ThemeSetting";
import Settings from "../Pages/Settings";
import OnboardingApplication from "../Pages/Admin/Application/OnboardingApplication";
import ViewSubmissions from "../Pages/Admin/Application/ViewSubmissions";
import LiveSessionWrapper from "../Pages/Admin/LiveSession/LiveSessionWrapper";
import LiveSessionDisplay from "../Pages/Admin/LiveSession/LiveSessionDisplay";
import LabelCustomization from "../Pages/LabelCustomization";
import StudentSettings from "../Pages/Admin/Students/StudentSettings";
import StudentProfile from "../Pages/Admin/Students/StudentProfile/StudentProfile";
import ModuleLiveSession from "../Pages/Admin/LiveSession/ModuleLiveSession";
import AdminProfile from "../Pages/Admin/Profile/AdminProfile";
import QuestionsProgress from "../Pages/Admin/Students/QuestionsProgress";
import ContentProgress from "../Pages/Admin/Students/ContentProgress";
import LiveSessionStudentView from "../Pages/LiveSessionStudentView";
import LiveSessionOverview from "../Pages/LiveSessionOverview";
import ASARedirect from "../Pages/ASARedirect";
import Help from "../Pages/Admin/Students/Help";
import ShareableProfile from "../Pages/Admin/Students/StudentProfile/ShareableProfile";
const Router = (props) => {
  const NavWrapper = (Component, hasRight = true, scroll = true) => {
    return (
      <>
        <Navbar user={props.userToken} hasRight={hasRight} />
        <div className={`comp-with-nav ${scroll ? "" : "no-scroll"}`}>
          <Component />
        </div>
      </>
    );
  };

  const NavWithLeftDrawer = (Component, hasRight = true, scroll = true) => {
    return (
      <>
        <Navbar user={props.userToken} hasRight={hasRight} />
        <SideMenuBar />
        <div
          className={`comp-with-nav no-footer  ${scroll ? "" : "no-scroll"}`}
        >
          <Component />
        </div>
      </>
    );
  };

  const navigate = () => {
    if (!props?.role) {
      return <Navigate replace to="/cohorts" />;
    }
    if (props?.role === "ORG_ADMIN") {
      return <Navigate replace to="/admin/cohorts" />;
    }
    return <Navigate replace to="/cohorts" />;
  };

  return (
    <BrowserRouter>
      <div className="route-container">
        <Routes>
          <Route path="/" element={navigate()} />
          {/*
                Routes that can be accessed by PUBLIC and USER
            */}
          <Route path="/profile/:userId" element={NavWrapper(ShareableProfile, false, true)} />
          <Route
            element={<RequireAuth allowedRoles={["PUBLIC", "ORG_USER"]} />}
          >
            <Route path="/cohorts" element={NavWrapper(Home)} />
            <Route path="/cohort/:cohortId" element={NavWrapper(Cohort)} />
            <Route
              path="/studentSettings"
              element={NavWrapper(StudentSettings)}
            />
            <Route path="/profile" element={NavWrapper(StudentProfile)} />
            <Route path="/help" element={NavWrapper(Help)} />
            {/*
                Authenticated routes which only a USER can access
            */}
            <Route element={<RequireAuth allowedRoles={["ORG_USER"]} />}>
              <Route
                path="/cohort/:cohortId/apply"
                element={NavWrapper(CohortApplication)}
              />
              <Route
                path="/cohort/:cohortId/curriculum"
                element={NavWrapper(CohortCurriculum)}
              />
              <Route
                path="/cohort/:cohortId/curriculum/:moduleId"
                element={NavWrapper(ModuleOverview)}
              />
              <Route
                path="/cohort/:cohortId/curriculum/:moduleId/content"
                element={NavWrapper(ModuleContent, true, false)}
              />

              <Route
                path="/cohort/:cohortId/curriculum/:moduleId/liveSessions"
                element={NavWrapper(LiveSessionOverview, true, false)}
              />
              <Route
                path="/cohort/:cohortId/curriculum/:moduleId/liveSessionDetails/:livessionId"
                element={NavWrapper(LiveSessionStudentView, true, false)}
              />
              <Route
                path="/cohort/:cohortId/curriculum/:moduleId/questions"
                element={NavWrapper(ModuleQuestions, true, false)}
              />
              <Route
                path="/studentSettings/profile"
                element={NavWrapper(StudentProfile)}
              />
            </Route>
          </Route>
          {/*
              Authenticated routes which only an ORG_ADMIN can access
          */}
          <Route element={<RequireAuth allowedRoles={["ORG_ADMIN"]} />}>
            <Route
              path="/admin/dashboard"
              element={NavWithLeftDrawer(UnderDevelopment)}
            />
            <Route
              path="/admin/onboarding"
              element={NavWithLeftDrawer(UnderDevelopment)}
            />
            <Route
              path="/admin/candidates"
              element={NavWithLeftDrawer(UnderDevelopment)}
            />
            <Route
              path="/admin/settings"
              element={NavWithLeftDrawer(Settings, true, false)}
            />
            <Route
              path="/admin/cohorts"
              element={NavWithLeftDrawer(Cohorts, true, false)}
            />
            <Route
              path="/admin/createNewCohort"
              element={NavWithLeftDrawer(CreateCohorts)}
            />
            <Route
              path="/admin/createNewCohort/:cohortId"
              element={NavWithLeftDrawer(CreateCohorts)}
            />
            <Route
              path="/admin/cohortMenu/:cohortId"
              element={NavWithLeftDrawer(CohortMenu, true, false)}
            />
            <Route
              path="/admin/createModule/:cohortId"
              element={NavWithLeftDrawer(CreateModule, true, false)}
            />
            <Route
              path="/admin/cohort/:cohortId/module/:moduleId"
              element={NavWithLeftDrawer(AdminModuleOverview)}
            />
            <Route
              path="/admin/cohort/:cohortId/applications"
              element={NavWithLeftDrawer(StudentApplications)}
            />
            <Route
              path="/admin/cohort/:cohortId/onboarding"
              element={NavWithLeftDrawer(OnboardingApplication)}
            />
            <Route
              path="/admin/cohort/:cohortId/sessions"
              element={NavWithLeftDrawer(LiveSessionWrapper, true, false)}
            />
            <Route
              path="/admin/liveSession/:liveSessionId/cohort/:cohortId"
              element={NavWithLeftDrawer(LiveSessionDisplay, true, false)}
            />
            <Route
              path="/admin/cohort/:cohortId/module/:moduleId/liveSessions"
              element={NavWithLeftDrawer(ModuleLiveSession, true, false)}
            />
            <Route
              path="/admin/cohort/:cohortId/viewSubmissions/:studentId/:questionnaireId"
              element={NavWithLeftDrawer(ViewSubmissions)}
            />
            <Route
              path="/admin/cohort/:cohortId/module/:moduleId/content"
              element={NavWrapper(ModuleContentCreation, true, false)}
            />
            <Route
              path="/admin/cohort/:cohortId/module/:moduleId/questions"
              element={NavWrapper(ModuleQuestionCreation, true, false)}
            />
            <Route
              path="/admin/cohort/students/:cohortId"
              element={NavWithLeftDrawer(StudentsList)}
            />
            <Route
              path="/admin/cohort/students/:cohortId/progress/:studentId"
              element={NavWithLeftDrawer(ViewStudentProgress)}
            />
            <Route
              path="/admin/cohort/students/:cohortId/:moduleId/progress/:studentId/questionnaires"
              element={NavWithLeftDrawer(QuestionsProgress)}
            />
            <Route
              path="/admin/cohort/students/:cohortId/:moduleId/progress/:studentId/courseContent"
              element={NavWithLeftDrawer(ContentProgress)}
            />
            <Route
              path="/admin/cohort/students/:cohortId/:moduleId/progress/:studentId/questionnaires/:questionnaireId"
              element={NavWithLeftDrawer(QuestionnaireFeedBack)}
            />
            <Route path="/adminProfile" element={NavWrapper(AdminProfile)} />
          </Route>
          <Route path="/ping" element={<Ping />} />
          <Route
            path="/authentication/redirect"
            element={<SocialAuthCallback />}
          />
          <Route
            path="/:action/:username"
            element={NavWrapper(PasswordReset, false)}
          />
          <Route
            path="/admin/settings/themesettings"
            element={NavWithLeftDrawer(ThemeSetting)}
          />
          <Route
            path="/admin/settings/labelcustomization"
            element={NavWithLeftDrawer(LabelCustomization)}
          />
          <Route path="/access-denied" element={<ASARedirect />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <LoginModal />
      <ForgotPasswordModal />
      <OrgLoginModal />
      {props.role && props.role !== "ORG_ADMIN" && <Footer />}
    </BrowserRouter>
  );
};

// export default Router;
const mapStateToProps = ({ auth, user }) => {
  return {
    userToken: auth.userToken,
    role: user.userDetails?.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    // setUserToken: (token) => dispatch(setUserToken(token))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Router);
