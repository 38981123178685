import "./Stylesheets/App.scss";
import RouterInit from "../src/Routing/Router";
import Toast from "./Reusable/Toast";
import { useEffect, useState } from "react";
import { AuthEvents } from "./Utils/events";
import {
  setToken,
  setTimeSpentOnPlatform,
  getUserInfo,
  setcognitoToken,
} from "./Store/Actions/actions.auth";
import { connect } from "react-redux";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./Utils/theme";
import { getTheme, setTheme, setUserInfo } from "./Store/Actions/actions.user";
import { StyledEngineProvider } from "@mui/material/styles";
import TimeMe from "timeme.js";
import { Amplify, Hub } from "aws-amplify";
import awsconfig from "./environment/aws-export";
import { setAuthModal, setOrgLoginModal } from "./Store/Actions/actions.app";
import { toast } from "react-hot-toast";
import WelcomePopup from "./Reusable/Modals/WelcomePopup";
import { ConfirmDialogProvider } from "./Context/ConfirmDialogContext";
import { initAnalytics, useGlobalEventTracker } from "./Utils/analytics";
import ConsentModal from "./Reusable/Modals/ConsentModal";
import { useCookies } from "react-cookie";

const App = (props) => {
  useGlobalEventTracker();
  const [isWelcomePopupOpen, setIsWelcomePopupOpen] = useState(false);
  const [cookies] = useCookies(["analyticsConsent"]);
  useEffect(() => {
    const userType = localStorage.getItem("cognito-user-type");
    if (props.orgLoginModal || userType === "ORG_ADMIN") {
      Amplify.configure(awsconfig(true));
    } else {
      Amplify.configure(awsconfig(false));
    }
  }, [props.orgLoginModal]);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "cognitoHostedUI":
          // this is for handling google signin
          setToken(
            data?.signInUserSession.accessToken.jwtToken,
            data?.signInUserSession.refreshToken.token,
            data?.username,
            new Date(data?.signInUserSession.accessToken.payload.exp * 1000)
          );
          props.getUserInfo(false, "ORG_USER");
          props.setcognitoToken(data);
          props.setAuthModal(false);
          props.setOrgLoginModal(false);

          window.localStorage.setItem("cognito-username", data?.username);
          window.localStorage.setItem("cognito-key", data?.keyPrefix);
          window.localStorage.setItem("cognito-user-type", "ORG_USER");
          window.localStorage.setItem(
            "refreshToken",
            data?.signInUserSession.refreshToken.token
          );
          window.localStorage.setItem(
            "accessTokenExpiry",
            new Date(data?.signInUserSession.accessToken.payload.exp * 1000)
          );
          // fire an event to let all tabs know they should login
          window.localStorage.setItem(
            AuthEvents.LOGIN,
            new Date().toISOString()
          );
          window.location.reload();
          toast.success("Successfully Logged in 🥳");
          break;
        case "tokenRefresh":
          console.info("token refresh succeeded");
          break;
        case "tokenRefresh_failure":
          console.error("token refresh failed");
          window.localStorage.clear();
          window.sessionStorage.clear();

          window.localStorage.setItem(
            AuthEvents.LOGOUT,
            new Date().toISOString()
          );
          window.location.href = "/";
          break;
        case "oAuthSignOut":
        case "signOut":
          window.localStorage.clear();
          window.sessionStorage.clear();

          window.localStorage.setItem(
            AuthEvents.LOGOUT,
            new Date().toISOString()
          );
          window.location.href = "/";
          break;
        default:
          break;
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    TimeMe.startTimer("Total-Platform-Time");
    const unloadCallback = (event) => {
      TimeMe.stopTimer("Total-Platform-Time");
      let timeSpentOnPlatform = TimeMe.getTimeOnPageInSeconds(
        "Total-Platform-Time"
      );
      setTimeSpentOnPlatform(timeSpentOnPlatform, props.userInfo.id);
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => {
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, [props.userToken]);

  useEffect(() => {
    if (!props.userToken) {
      const userID = localStorage.getItem("cognito-username");
      const userType = localStorage.getItem("cognito-user-type");
      const cognitokey = localStorage.getItem("cognito-key");
      const refreshToken = localStorage.getItem(
        `${cognitokey}.${userID}.refreshToken`
      );
      const accessToken = localStorage.getItem(
        `${cognitokey}.${userID}.accessToken`
      );
      const tokenExpiryTime = localStorage.getItem("accessTokenExpiry");
      console.log("tokenExpiryTime", tokenExpiryTime);

      props.setToken(accessToken, refreshToken, userID, tokenExpiryTime);
      props.getUserInfo(true, userType);
    }
  }, []);

  useEffect(() => {
    if (props.userInfo) {
      const host =
        window.location.hostname === "localhost"
          ? "dev"
          : window.location.hostname.split(".")[0];
      props.getTheme(host);
    }
  }, []);

  // Logic to determine when to show the popup, for example, on first visit
  useEffect(() => {
    // Check if the hostname contains 'asa.gritly.us'
    if (
      window.location.hostname.includes("dev.gritly.us") ||
      window.location.hostname.includes("asa.gritly.us")
    ) {
      // Check if the user is visiting for the first time
      if (!localStorage.getItem("visited")) {
        setIsWelcomePopupOpen(true);
        localStorage.setItem("visited", true); // Mark as visited
      }
    }
  }, []);

  const handleUserConsent = (consent) => {
    if (consent) {
      initAnalytics(cookies); // Re-initialize analytics with consent
    }
  };

  // TODO: Generalize one state property to enable/disable loader
  if (props.isInitLoading) {
    return <div className="gif-loader" />;
  }

  return (
    <>
      <ConsentModal onConsent={handleUserConsent} />
      <ConfirmDialogProvider>
        <StyledEngineProvider injectFirst>
          <Toast />
          <ThemeProvider theme={theme}>
            <RouterInit />
          </ThemeProvider>
          <WelcomePopup
            isOpen={isWelcomePopupOpen}
            togglePopUp={setIsWelcomePopupOpen}
          />
        </StyledEngineProvider>
      </ConfirmDialogProvider>
    </>
  );
};

const mapStateToProps = ({ auth, app, user }) => {
  return {
    userToken: auth.userToken,
    isInitLoading: app.isInitLoading,
    themeInfo: user.themeInfo,
    userInfo: user.userDetails,
    orgLoginModal: app.orgLoginModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTheme: (organisationId) => dispatch(getTheme(organisationId)),
    setTheme: (themeData) => dispatch(setTheme(themeData)),
    setToken: (accessToken, refreshToken, username, expiry) =>
      dispatch(setToken(accessToken, refreshToken, username, expiry)),
    setUserInfo: (info) => dispatch(setUserInfo(info)),
    getUserInfo: (isInitLoad, user) => dispatch(getUserInfo(isInitLoad, user)),
    setcognitoToken: (user) => dispatch(setcognitoToken(user)),
    setAuthModal: (status) => dispatch(setAuthModal(status)),
    setOrgLoginModal: (status) => dispatch(setOrgLoginModal(status)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
