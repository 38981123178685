import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import StudentCareAboutPrompts from './StudentCareAboutPrompts';
import StudentAtWorkPrompts from './StudentAtWorkPrompts';
import { getLearnerProfileDetails, getAllPrompts } from '../../../../Store/Actions/actions.user';

export const StudentPrompts = (props) => {
    const { preview } = props;
    useEffect(() => {
        props.getLearnerProfileDetails(props.userUID);
        props.getAllPrompts()
    }, [])
    return (
        <>
            <StudentCareAboutPrompts preview={preview} />
            <StudentAtWorkPrompts preview={preview} />
        </>
    )
}

const mapStateToProps = ({ user }) => ({
    userUID: user.userUid,
})

const mapDispatchToProps = (dispatch) => ({
    getLearnerProfileDetails: (userUID) =>
        dispatch(getLearnerProfileDetails(userUID)),
    getAllPrompts: () => dispatch(getAllPrompts())
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentPrompts)