import React from "react";

export default function Footer() {
  return (
    <div className="footer">
      <span className="footer-text">
        All Rights Reserved @Gritly {new Date().getFullYear()}
      </span>
    </div>
  );
}
