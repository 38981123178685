import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Button, Dialog } from "@mui/material";
import styles from "../../Stylesheets/Modules/cropImageModal.module.scss";
import { findOptimalQuality } from "../../Helpers/utils";
import { uploadImageToGCP } from "../../Store/Actions/actions.app";

export const CropImageModal = (props) => {
  const cropperRef = useRef(null);
  const [croppedBlob, setCroppedBlob] = useState(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const {
    imageToBeCropped,
    cropDialogOpen,
    setCropDialogOpen,
    setFileURL,
    aspect,
    setUploaded,
    setFile,
    uploadImageToGCP,
    originalFileName,
  } = props;

  const handleSave = () => {
    if (croppedBlob) {
      const formData = new FormData();
      const name = `${
        originalFileName.split(".")[0]
      }_${new Date().getUTCSeconds()}.${originalFileName.split(".")[1]}`;
      formData.append("image", croppedBlob, name);

      uploadImageToGCP(formData).then((res) => {
        setFileURL(res.data.photoUrl);
        setCropDialogOpen(false);
      });
    }
  };

  const handleCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas({
        minWidth: 256,
        minHeight: 256,
        maxWidth: 4096,
        maxHeight: 4096,
        fillColor: "#fff",
        imageSmoothingEnabled: true,
        imageSmoothingQuality: "high",
      });

      croppedCanvas.toBlob(async (blob) => {
        const optimalQuality = await findOptimalQuality(blob);
        croppedCanvas.toBlob(
          (optimizedBlob) => {
            setCroppedBlob(optimizedBlob);
          },
          getImageMimeType(imageToBeCropped), // Determine MIME type dynamically
          optimalQuality
        );
      });
    }
  };

  const getImageMimeType = (imageSrc) => {
    if (imageSrc.endsWith(".png")) return "image/png";
    if (imageSrc.endsWith(".jpg") || imageSrc.endsWith(".jpeg"))
      return "image/jpeg";
    if (imageSrc.endsWith(".svg")) return "image/svg+xml";
    return "image/png"; // Default to PNG
  };

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const cropperInstance = cropperRef?.current?.cropper;

    if (cropperInstance) {
      const containerData = cropperInstance.getContainerData();
      cropperInstance.setCanvasData({
        width: containerData.width,
        height: containerData.height,
      });
    }
  }, [dimensions]);

  const handleReady = () => {
    const cropperInstance = cropperRef.current.cropper;
    const containerData = cropperInstance.getContainerData();
    const canvasData = cropperInstance.getCanvasData();

    // Calculate the scale factors
    const scaleX = containerData.width / canvasData.naturalWidth;
    const scaleY = containerData.height / canvasData.naturalHeight;
    const scale = Math.min(scaleX, scaleY);

    // Set the canvas dimensions to fit within the container
    cropperInstance.setCanvasData({
      ...canvasData,
      width: canvasData.naturalWidth * scale,
      height: canvasData.naturalHeight * scale,
    });
  };

  return (
    <Dialog
      classes={{
        container: styles["dialog-modal"],
        paper: styles["modal-paper"],
      }}
      open={cropDialogOpen}
    >
      <div className={styles["main-div"]}>
        <div className={styles["modal-header"]}>Drag and adjust to crop</div>
        <div className={styles["crop-image-container"]}>
          <Cropper
            className={styles["React-crop"]}
            ref={cropperRef}
            src={imageToBeCropped}
            viewMode={1}
            zoomable={false}
            scalable={true}
            zoomOnTouch={false}
            zoomOnWheel={false}
            responsive={true}
            aspectRatio={aspect}
            guides={false}
            crop={handleCrop}
            ready={handleReady}
          />
        </div>
        <div className={styles["button-wrapper"]}>
          <Button onClick={handleSave} className={styles["save-btn"]}>
            Save
          </Button>
          <Button
            onClick={() => {
              setCroppedBlob(null); // Clear blob state
              setCropDialogOpen(false);
              setFileURL("");
              setUploaded(false);
              setFile(null);
            }}
            className={styles["cancel-btn"]}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    uploadImageToGCP: (file) => dispatch(uploadImageToGCP(file)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CropImageModal);
