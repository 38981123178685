import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import Hotjar from '@hotjar/browser'
import store from "../Store/store";
import { useEffect } from 'react';

const hotjarVersion = 6;
let analyticsInitialized = false;

// Utility function to determine if we're in the production environment on the correct hostname
const isASAEnv = () => process.env.NODE_ENV === 'production' && window.location.hostname === 'asa.gritly.us';

export const initAnalytics = (cookies) => {
    const consentGiven = cookies.analyticsConsent || false;
    if (consentGiven) {
        analyticsInitialized = true;
        ReactGA.initialize(process.env.REACT_APP_GTAG_ID);
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID, { track_pageview: true, persistence: 'localStorage', ignore_dnt: true });
        if (isASAEnv()) {
            Hotjar.init(process.env.REACT_APP_HOTJAR_ID, hotjarVersion);
        }
    }
};

// Identify the current user with analytics services
export const identifyUser = (user) => {
    if (!analyticsInitialized || !user || !user.userUid) return;
    if (user && user.userUid) {
        mixpanel.identify(user.userUid);
        mixpanel.people.set({
            user_id: user.userUid,
            last_login: new Date(),
        });
    }
};

export const logPageView = (pageview) => {
    if (!analyticsInitialized) return;

    // Send pageview with a custom path
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    // Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
    mixpanel.track(pageview, { 'page': window.location.href });
    // Set this to a unique identifier for the user performing the event.
    const user = store.getState().user;
    identifyUser(user);
    if (isASAEnv()) {
        Hotjar.stateChange(window.location.pathname);
    }
};

export const logEvent = (category = '', action = {}) => {
    if (!analyticsInitialized || !category || !action) return;

    try {
        // A unified event object
        const eventDetails = { category, ...action };

        // Tracking for GA
        ReactGA.event(eventDetails);

        // Tracking for Mixpanel
        mixpanel.track(category, eventDetails);

        // Tracking for Hotjar, if applicable
        if (isASAEnv()) {
            Hotjar.event(category);
        }
    } catch (error) {
        console.error('Analytics tracking failed', error);
    }

}


export const useGlobalEventTracker = () => {
    useEffect(() => {
        if (!analyticsInitialized) return;
        const trackEvent = (e) => {
            // You can use a data attribute to specify the event name
            const eventName = e.target.getAttribute('data-event-name') || 'Generic Interaction';

            // You can also implement other logic to determine the event name
            // based on the target element's properties (like id, class, etc.)

            // Common event properties
            const commonProps = {
                type: e.type,
                text: e.target.innerText,
                class: e.target.className,
                id: e.target.id,
                elementType: e.target.nodeName,
                // ... add other properties as needed
            };

            // Send the event to Mixpanel
            console.log("eventName", eventName);
            mixpanel.track(eventName, commonProps);
        };
        document.addEventListener('click', trackEvent);

        // Cleanup the event handlers when the component unmounts
        return () => {
            document.removeEventListener('click', trackEvent);
        };
    }, []);
};