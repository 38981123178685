import React, { useState, useEffect } from "react";
import styles from "../../Stylesheets/Modules/ConsentModal.module.scss";
import { OutlinedButtonSmall } from "../../Components/StyledComponents/Button.style";
import { useCookies } from "react-cookie";

const ConsentModal = ({ onConsent }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [cookies, setCookie] = useCookies(["analyticsConsent"]);

  useEffect(() => {
    const consentGiven =
      cookies.analyticsConsent !== null &&
      typeof cookies.analyticsConsent === "boolean";
    setIsVisible(!consentGiven);
  }, []);

  const handleConsent = (consent) => {
    setCookie("analyticsConsent", consent.toString(), { path: "/" });
    setIsVisible(false);
    onConsent(consent);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <div className={styles.modalBackdrop} />
      <div className={styles.modalContent}>
        <h2 className={styles.modalHeader}>Data Usage Consent</h2>
        <p className={styles.modalBody}>
          We use cookies and other tracking technologies to improve your
          browsing experience on our website, to analyze our website traffic,
          and to understand where our visitors are coming from.
        </p>
        <div className={styles.buttonContainer}>
          <OutlinedButtonSmall
            className={styles.agreeButton}
            onClick={() => handleConsent(true)}
          >
            I Agree
          </OutlinedButtonSmall>
          <OutlinedButtonSmall
            className={styles.declineButton}
            onClick={() => handleConsent(false)}
          >
            No, thanks
          </OutlinedButtonSmall>
        </div>
      </div>
    </>
  );
};

export default ConsentModal;
