import React, { useEffect } from "react";
import styles from "../Stylesheets/Modules/settings.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Container } from "@mui/material";
import {
  StyledLogoutIcon,
  StyledPaletteIcon,
  StyledEditIcon,
  StyledPasswordIcon,
  StyledNotificationIcon,
} from "../Components/StyledComponents/Icons.style";
import { setForgotPassModal } from "../Store/Actions/actions.app";
import { logout } from "../Store/Actions/actions.auth";
import { connect } from "react-redux";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { logPageView } from "../Utils/analytics";
import NotificationSettings from "./NotificationSettings";
const settingOptions = [
  {
    key: "theme",
    label: "Theme Settings",
    icon: <StyledPaletteIcon />,
    route: "/admin/settings/themesettings",
    active: true,
  },
  {
    key: "label",
    label: "Label Settings",
    icon: <StyledEditIcon />,
    route: "/admin/settings/labelcustomization",
    active: true,
  },
  {
    key: "notification",
    label: "Notification Settings",
    icon: <StyledNotificationIcon />,
    route: "",
    active: true,
  },
  {
    key: "password",
    label: "Reset Password",
    icon: <StyledPasswordIcon />,
    route: "",
    active: true,
  },
  {
    key: "logout",
    label: "Logout",
    icon: <StyledLogoutIcon />,
    route: "",
    active: true,
  },
];
export const Settings = (props) => {
  const history = useNavigate();
  const [toggle, updateToggle] = React.useState(false);
  const logoutHandler = () => {
    props.logout();
  };
  const [modalFor, setModalFor] = React.useState("");

  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] Settings Page");
  }, [location]);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }

  return (
    <div>
      <Container
        maxWidth="xl"
        classes={{
          root: styles["main-container"],
        }}
      >
        <div
          className={styles["settings-header"]}
          style={{
            color: localStorage.getItem("primarycolor"),
            fontFamily: localStorage.getItem("fontfamily"),
          }}
        >
          Settings
        </div>
        <div className={styles["settings-wrapper"]}>
          {settingOptions.map((item) => {
            return (
              <div key={item.key}>
                {item.active && (
                  <div className={styles["option-wrapper"]} key={item.key}>
                    <div
                      className={styles["icon"]}
                      style={{
                        color: props.themeInfo[0]?.primarycolor
                          ? props.themeInfo[0]?.primarycolor
                          : "#0e1555",
                      }}
                    >
                      {item.icon}
                    </div>
                    <div
                      className={styles["label"]}
                      onClick={() => {
                        if (item.key === "password") {
                          props.setForgotPassModal(true);
                        }
                        if (item.key === "logout") {
                          updateToggle(true);
                          setModalFor("logout");
                        }
                        if (item.key === "notification") {
                          updateToggle(true);
                          setModalFor("notification");
                        }
                        history(item.route);
                      }}
                    >
                      <div className={styles["label-text"]}>{item.label}</div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <Modal
          open={toggle}
          sx={{ color: "white" }}
          onClose={() => updateToggle(false)}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              borderRadius: "9px",
              p: 4,
            }}
          >
            {modalFor === "logout" && (
              <div className={styles["modal-wrapper"]}>
                <div className={styles["modal-header"]}>Logout</div>
                <div className={styles["modal-sub-header"]}>
                  Are you sure you want logout?
                </div>
                <div className={styles["button-wrapper"]}>
                  <Button
                    className={styles["cancel-btn"]}
                    onClick={() => updateToggle(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={styles["save-btn"]}
                    onClick={logoutHandler}
                  >
                    Logout
                  </Button>
                </div>
              </div>
            )}
            {modalFor === "notification" && <NotificationSettings />}
          </Box>
        </Modal>
      </Container>
    </div>
  );
};
const mapStateToProps = ({ app, user }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    themeInfo: user.themeInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setForgotPassModal: (status) => dispatch(setForgotPassModal(status)),
    logout: () => dispatch(logout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
