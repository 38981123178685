import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import styles from "../Stylesheets/Modules/passwordReset.module.scss";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { toast } from "react-hot-toast";
import { validatePassword } from "../Utils/validate";
import { updatePassword, orgLogin } from "../Store/Actions/actions.auth";
import {
  StyledVisibility,
  StyledVisibilityOff,
} from "../Components/StyledComponents/Icons.style";
import { logPageView } from "../Utils/analytics";

const PasswordReset = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const { username, action } = useParams();
  const [verificationCode, setVerificationCode] = useState("");
  const [password, setPassword] = useState("");
  const [confPass, setConfPass] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const onSubmit = () => {
    if (action === "reset") {
      if (!verificationCode) {
        toast.error("Please enter verification code recieved in your email");
        return;
      }
    }
    else {
      if (oldPassword === "") {
        toast.error("Please enter old/current password");
        return;
      }
    }
    if (password !== confPass) {
      toast.error("Passwords didn't match. Please check again");
      return;
    } if (!validatePassword(password) || !validatePassword(confPass)) {
      toast.error(
        "Password must be atleast 8 letter long with a small, capital, number and special characters"
      );
      return;
    }
    if (action === "reset") {
      props.updatePassword(
        password,
        props.username || username,
        verificationCode
      )
    }
    else
      props.orgLogin(username || props.username, oldPassword, password, true)
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("Password Reset Page");
  }, [location]);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }

  return (
    <div className={styles["password-reset-body"]}>
      <div className={styles["content"]}>
        {action === "reset" ? <FormControl variant="outlined" className={styles["form-control"]}>
          <InputLabel
            htmlFor="outlined-adornment-verification"
            classes={{
              root: styles["input-label"],
            }}
          >
            {" "}
            Verification Code
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-verification"
            className={styles["input-field"]}
            classes={{
              root: styles["outline-root"],
              notchedOutline: styles["notched-outline-root"],
              input: styles["user-input-field"],
            }}
            placeholder={"Enter the 6 digit verification code"}
            type={"number"}
            value={verificationCode || ""}
            onChange={(e) => setVerificationCode(e.target.value)}
            label="Verification Code"
          />
        </FormControl> : <FormControl variant="outlined" className={styles["form-control"]}>
          <InputLabel
            htmlFor="outlined-adornment-verification"
            classes={{
              root: styles["input-label"],
            }}
          >
            {" "}
            Old Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-verification"
            className={styles["input-field"]}
            classes={{
              root: styles["outline-root"],
              notchedOutline: styles["notched-outline-root"],
              input: styles["user-input-field"],
            }}
            type={"password"}
            placeholder={"Enter your current password"}
            value={oldPassword || ""}
            onChange={(e) => setOldPassword(e.target.value)}
            label="Old Password"
          />
        </FormControl>
        }
        <FormControl variant="outlined" className={styles["form-control"]}>
          <InputLabel
            htmlFor="outlined-adornment-password"
            classes={{
              root: styles["input-label"],
            }}
          >
            {" "}
            New Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            className={styles["input-field"]}
            classes={{
              root: styles["outline-root"],
              notchedOutline: styles["notched-outline-root"],
              input: styles["user-input-field"],
            }}
            placeholder={"Enter your password"}
            type={"password"}
            value={password || ""}
            onChange={(e) => setPassword(e.target.value)}
            label="Password"
          />
        </FormControl>
        <FormControl variant="outlined" className={styles["form-control"]}>
          <InputLabel
            htmlFor="outlined-adornment-confirm-password"
            classes={{
              root: styles["input-label"],
            }}
          >
            {" "}
            Confirm New Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-confirm-password"
            className={styles["input-field"]}
            classes={{
              root: styles["outline-root"],
              notchedOutline: styles["notched-outline-root"],
              input: styles["user-input-field"],
            }}
            placeholder={"Re-enter your password"}
            type={showPassword ? "text" : "password"}
            value={confPass || ""}
            onChange={(e) => setConfPass(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  className={styles["password-toggle-icon"]}
                  classes={{
                    root: styles["icon-button-root"],
                  }}
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? (
                    <StyledVisibilityOff />
                  ) : (
                    <StyledVisibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
            label="Confirm Password"
          />
        </FormControl>
        <Button
          data-event-name="Submitted Reset Password Form"
          classes={{
            root: styles["button-root"],
          }}
          variant="contained"
          endIcon={
            <ArrowForward
              classes={{
                root: styles["arrow-icon"],
              }}
            />
          }
          onClick={onSubmit}
        >
          {"Update"}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ app, auth }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    username: auth.cognito?.username,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePassword: (password, username, verificationCode) =>
      dispatch(updatePassword(password, username, verificationCode)),
    orgLogin: (email, oldPassword, newPassword, fromUpdatePassword) => dispatch(orgLogin(email, oldPassword, newPassword, fromUpdatePassword))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
