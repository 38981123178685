import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { connect } from "react-redux";
import { setForgotPassModal } from "../../Store/Actions/actions.app";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import styles from "../../Stylesheets/Modules/loginModal.module.scss";
import { resetPassword, setUserToken } from "../../Store/Actions/actions.auth";
import "../../Stylesheets/Modules/general.scss";
import { toast } from "react-hot-toast";
import { validateEmail } from "../../Utils/validate";
import Logo from "../../Assets/Images/Gritly-logo.svg";
import {
  StyledArrowForwardIcon,
  StyledCloseIcon,
} from "../../Components/StyledComponents/Icons.style";
import { FilledButton } from "../../Components/StyledComponents/Button.style";

const ForgotPasswordModal = (props) => {
  const navigate = useNavigate();
  const [imageUrl, setImgURL] = React.useState(Logo);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [email, setEmail] = useState("");
  useEffect(() => {
    if (props.themeInfo[0]?.logourl) {
      setImgURL(props.themeInfo[0]?.logourl);
    } else {
      setImgURL(Logo);
    }
  }, [props.themeInfo]);
  const handleClose = () => {
    props.setForgotPassModal(false);
  };

  const onSubmit = () => {
    if (!validateEmail(email)) {
      toast.error("Please enter a valid email");
      return;
    }
    props.resetPassword(email).then(() => {
      navigate(`/reset/${email}`);
    });
  };

  return (
    <div>
      <Dialog
        className={styles["auth-modal-container"]}
        classes={{
          root: styles["auth-modal-root"],
          paper: styles["auth-modal-paper"],
        }}
        fullScreen={fullScreen}
        open={props.forgotPassModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent className={`${styles["auth-modal-body"]}`}>
          <div className={styles["close-container"]} onClick={handleClose}>
            <StyledCloseIcon />
          </div>
          <div className={styles["header"]}>
            <img
              src={imageUrl}
              alt="Gritly"
              style={{ width: "40px", height: "40px" }}
            />
            <span className={styles["subtitle"]}>Reset your password</span>
          </div>
          <div className={styles["content"]}>
            <FormControl variant="outlined" className={styles["form-control"]}>
              <InputLabel
                htmlFor="outlined-adornment-email"
                classes={{
                  root: styles["input-label"],
                }}
              >
                Email
              </InputLabel>
              <OutlinedInput
                type="email"
                id="outlined-adornment-email"
                className={styles["input-field"]}
                value={email || ""}
                onChange={(e) => setEmail(e.target.value)}
                classes={{
                  root: styles["outline-root"],
                  notchedOutline: styles["notched-outline-root"],
                  input: styles["user-input-field"],
                }}
                placeholder={"Enter your Email"}
                label="Email"
              />
            </FormControl>
            <FilledButton
              // classes={{
              //   root: styles["button-root"],
              // }}
              bgcolor={
                props.themeInfo[0]?.primarycolor
                  ? props.themeInfo[0]?.primarycolor
                  : "#0e1555"
              }
              variant="contained"
              endIcon={
                <StyledArrowForwardIcon
                  classes={{
                    root: styles["arrow-icon"],
                  }}
                />
              }
              onClick={onSubmit}
            >
              {"Get Email link"}
            </FilledButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = ({ app, auth, user }) => {
  return {
    forgotPassModal: app.forgotPassModal,
    userToken: auth.userToken,
    themeInfo: user.themeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    setForgotPassModal: (status) => dispatch(setForgotPassModal(status)),
    setUserToken: (token) => dispatch(setUserToken(token)),
    resetPassword: (email) => dispatch(resetPassword(email)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordModal);
