import { styled } from "@mui/material";
import { Button } from "@mui/material";

export const FilledButton = styled(Button)(({ bgcolor, fontfamily }) => ({
  height: "3.125rem",
  borderRadius: "0.625rem",
  backgroundColor: bgcolor,
  fontFamily: fontfamily,
  color: "white",
  "&:hover": {
    backgroundColor: bgcolor,
  },
}));

export const OutlinedButton = styled(Button)(({ bgcolor, fontfamily }) => ({
  boxShadow: "unset",
  height: "3.125rem",
  borderRadius: "0.625rem",
  padding: "0 1.25rem",
  backgroundColor: " rgba(25, 118, 210, 0.1)",
  fontFamily: fontfamily,
  color: bgcolor,
  "&:hover": {
    backgroundColor: " rgba(25, 118, 210, 0.1)",
  },
}));

export const FilledButtonSmall = styled(Button)(({ bgcolor, fontfamily }) => ({
  boxShadow: "unset",
  height: "3.125rem",
  borderRadius: "0.625rem",
  padding: "0 1.25rem",
  backgroundColor: bgcolor,
  fontFamily: fontfamily,
  color: "white",
  "&:hover": {
    backgroundColor: bgcolor,
  },
}));

export const TransparentButton = styled(Button)(({ bgcolor, fontfamily }) => ({
  height: "3.125rem",
  borderRadius: "0.625rem",
  padding: "0 1.25rem",
  color: bgcolor,
  fontFamily: fontfamily,
}));

export const OutlinedButtonSmall = styled(Button)(
  ({ bgcolor, backgroundColor }) => ({
    marginLeft: "0.625rem",
    borderRadius: "0.375rem",
    padding: "0.625rem 1.25rem",
    color: bgcolor,
    backgroundColor: backgroundColor
      ? backgroundColor
      : "rgba(25, 118, 210, 0.1)",
    "&:hover": {
      backgroundColor: backgroundColor
        ? backgroundColor
        : "rgba(25, 118, 210, 0.1)",
    },
  })
);

export const SmallButton = styled(Button)(({ bgcolor }) => ({
  borderRadius: "0.375rem",
  padding: "0 0.625rem",
  color: "white",
  backgroundColor: bgcolor,
  "&:hover": {
    backgroundColor: bgcolor,
  },
}));
