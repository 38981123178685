import React, { useState } from "react";
import { connect } from "react-redux";
import styles from "../../../Stylesheets/Modules/Admin/Profile/adminUserDetails.module.scss";
import editIcon from "../../../Assets/Images/editIcon.jpg";
import UserEditModal from "./UserEditModal";
import { getAdminUserDetails } from "../../../Store/Actions/actions.user";
import { useEffect } from "react";
export const AdminUserDetails = (props) => {
  const { userInfo, adminUserDetails } = props;
  const userDetails = [
    // {
    //   key: "title",
    //   label: "Title",
    //   value: adminUserDetails?.title,
    // },
    {
      key: "firstName",
      label: "First Name",
      value: adminUserDetails?.firstName,
    },
    {
      key: "lastName",
      label: "Last Name",
      value: adminUserDetails?.lastName,
    },

    {
      key: "email",
      label: "Email",
      value: adminUserDetails?.email,
    },
    {
      key: "contact",
      label: "Contact Information",
      value: adminUserDetails?.mobileNo,
    },

    {
      key: "linkedin",
      label: "LinkedIn",
      value: adminUserDetails?.linkedin,
    },

    {
      key: "bio",
      label: "Bio",
      value: adminUserDetails?.bio,
    },
  ];
  useEffect(() => {
    props.getAdminUserDetails(userInfo.id);
  }, []);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={styles["main-div"]}>
      <div className={styles["label-header"]}>
        <div className={styles["div-label"]}>User Details</div>
        <div className={styles["edit-option"]}>
          <img src={editIcon} alt="edit" onClick={handleOpen} />
        </div>
      </div>
      <div className={styles["user-details"]}>
        {userDetails.map((item) => {
          return (
            <div key={item.key} className={styles["detail"]}>
              <div className={styles["label"]}>{item.label}</div>
              <div className={styles["value"]}>{item.value}</div>
            </div>
          );
        })}
      </div>
      <UserEditModal open={open} handleClose={handleClose} />
    </div>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    adminUserDetails: user.adminUserDetails,
    userInfo: user.userDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAdminUserDetails: (userId) => dispatch(getAdminUserDetails(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserDetails);
