
import * as Types from '../StaticTypes'

const initialState = {
  openLoginModal: false,
  forgotPassModal: false,
  appLoadingStatus: false,
  orgLoginModal: false,
  appLoadingCount: 0,
  loadingStatusMsg: '',
  isInitLoading: true,
  currentPage: undefined,
  notifications: {}
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case Types.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload.value
      }
    case Types.SET_AUTH_MODAL:
      return {
        ...state,
        openLoginModal: action.payload.status
      }
    case Types.SET_VERIFICATION_CODE_INPUT:
      return {
        ...state,
        verificationCodeInput: action.payload.status
      }
    case Types.SET_ORG_LOGIN_MODAL:
      return {
        ...state,
        orgLoginModal: action.payload.status
      }
    case Types.SET_FORGOT_PASS_MODAL:
      return {
        ...state,
        forgotPassModal: action.payload.status
      }
    case Types.SET_APP_LOADING:
      const { bool, msg } = action.payload
      let count = state.appLoadingCount
      let status = state.appLoadingStatus
      count += bool ? 1 : -1
      if (count === 0) {
        status = false
      } else {
        status = true
      }
      return {
        ...state,
        appLoadingCount: count,
        appLoadingStatus: status,
        loadingStatusMsg: msg
      }
    case Types.SET_INIT_LOADING:
      const { isLoading } = action.payload
      return {
        ...state,
        isInitLoading: isLoading
      }
    case Types.SET_NOTIFICATION_TOGGLE:
      const { emailPreferences } = action.payload;
      return {
        ...state,
        notifications: emailPreferences
      }
    default:
      return state
  }
}