import React from "react";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import styles from "../../../Stylesheets/Modules/Admin/Profile/adminBootcampEditModal.module.scss";

const BootcampField = ({ label, multiline, maxRows, value, onChange }) => {
  return (
    <div className={styles["input-wrapper"]}>
      <div className={styles["label"]}>{label}</div>
      {multiline ? (
        <OutlinedInput
          variant="outlined"
          placeholder={label}
          className={styles["input-field"]}
          multiline
          maxRows={maxRows}
          inputProps={{ maxLength: 255 }}
          value={value}
          onChange={onChange}
          endAdornment={
            <InputAdornment position="end" className={styles["char-length"]}>
              <Typography variant="caption">
                {value.length}/{255}
              </Typography>
            </InputAdornment>
          }
        />
      ) : (
        <TextField
          variant="outlined"
          placeholder={label}
          className={styles["input-field"]}
          value={value}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default BootcampField;
