import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { updateBootcampDetails } from "../../../Store/Actions/actions.user";
import BootcampField from "./BootcampField";
import SalaryField from "./SalaryField";
import styles from "../../../Stylesheets/Modules/Admin/Profile/adminBootcampEditModal.module.scss";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "9px",
};

const BootcampEditModal = ({
  open,
  handleClose,
  adminbootcampDetails,
  userInfo,
  updateBootcampDetails,
  onDetailsUpdated,
}) => {
  const [bootcampData, setBootcampData] = useState({
    bootcampName: "",
    bootcampDescription: "",
    trainingRole: "",
    salaryPreProgram: "",
    salaryPostProgram: "",
  });

  useEffect(() => {
    setUnSetData();
  }, [adminbootcampDetails]);

  const setUnSetData = () => {
    setBootcampData({
      bootcampName: adminbootcampDetails?.bootcampName || "",
      bootcampDescription: adminbootcampDetails?.bootcampDescription || "",
      trainingRole: adminbootcampDetails?.trainingRole || "",
      salaryPreProgram: adminbootcampDetails?.salaryPreProgram || "",
      salaryPostProgram: adminbootcampDetails?.salaryPostProgram || "",
    });
  };

  const handleInputChange = (field, value) => {
    setBootcampData({ ...bootcampData, [field]: value });
  };

  const updateBootcampData = () => {
    updateBootcampDetails(userInfo.id, bootcampData).then(() =>
      onDetailsUpdated()
    );
    closeModal();
  };

  const closeModal = () => {
    setUnSetData();
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <div className={styles["modal-header"]}>
          <div className={styles["heading"]}>Edit Bootcamp Details</div>
          <div className={styles["close-icon"]}>
            <CloseIcon onClick={closeModal} />
          </div>
        </div>
        <div className={styles["form-wrapper"]}>
          <BootcampField
            label="Bootcamp Name"
            value={bootcampData.bootcampName}
            onChange={(e) => handleInputChange("bootcampName", e.target.value)}
          />
          <BootcampField
            label="Bootcamp Description"
            multiline
            maxRows={4}
            value={bootcampData.bootcampDescription}
            onChange={(e) =>
              handleInputChange("bootcampDescription", e.target.value)
            }
          />
          <BootcampField
            label="Training Role"
            value={bootcampData.trainingRole}
            onChange={(e) => handleInputChange("trainingRole", e.target.value)}
          />
          <SalaryField
            label="Average Salary Pre Program"
            value={bootcampData.salaryPreProgram}
            onChange={(e) =>
              handleInputChange("salaryPreProgram", e.target.value)
            }
          />
          <SalaryField
            label="Average Salary Post Program"
            value={bootcampData.salaryPostProgram}
            onChange={(e) =>
              handleInputChange("salaryPostProgram", e.target.value)
            }
          />
        </div>
        <div className={styles["button-wrapper"]}>
          <Button className={styles["cancel-button"]} onClick={closeModal}>
            Cancel
          </Button>
          <Button
            className={styles["update-button"]}
            onClick={updateBootcampData}
          >
            Update
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

const mapStateToProps = ({ user }) => ({
  adminbootcampDetails: user.bootcampDetails,
  userInfo: user.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  updateBootcampDetails: (userId, bootcampDetails) =>
    dispatch(updateBootcampDetails(userId, bootcampDetails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BootcampEditModal);
