import environment from "./environment";

function awsconfig(isOrgModal) {
  let host = window.location.origin;

  return {
    aws_project_region: environment.Auth.aws_project_region,
    Auth: {
      identityPoolId: environment.Auth.identityPoolId, // (required) - Amazon Cognito Identity Pool ID
      region: environment.Auth.region, // (required) - Amazon Cognito Region
      userPoolId: environment.Auth.userPoolId, // (optional) - Amazon Cognito User Pool ID
      userPoolWebClientId: isOrgModal
        ? environment.Auth.userPoolWebClientId_Admin
        : environment.Auth.userPoolWebClientId_Student, // (optional) - Amazon Cognito Web Client ID (App client secret needs to be disabled)
      oauth: {
        domain: environment.Auth.oauth.domain,
        scope: environment.Auth.oauth.scope,
        redirectSignIn: `${host}/cohorts`,
        redirectSignOut: `${host}/cohorts`,
        responseType: environment.Auth.oauth.responseType,
      },
    },
  };
}

export default awsconfig;
