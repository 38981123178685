import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { connect } from "react-redux";
import {
  setAuthModal,
  setForgotPassModal,
  setOrgLoginModal,
  setVerificationCodeInput,
} from "../../Store/Actions/actions.app";
import { socialLogin, verifyUser } from "../../Store/Actions/actions.auth";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import styles from "../../Stylesheets/Modules/loginModal.module.scss";
import GoogleIcon from "../../Assets/Images/google-logo-colors.png";
import { login, register } from "../../Store/Actions/actions.auth";
import MuiPhoneNumber from "material-ui-phone-number";
import "../../Stylesheets/Modules/general.scss";
import { toast } from "react-hot-toast";
import {
  validateEmail,
  validatePassword,
  validatePhone,
} from "../../Utils/validate";
import Logo from "../../Assets/Images/Gritly-logo.svg";
import { useSearchParams } from "react-router-dom";
import { FilledButton } from "../../Components/StyledComponents/Button.style";
import {
  StyledArrowForwardIcon,
  StyledCloseIcon,
  StyledVisibility,
  StyledVisibilityOff,
} from "../../Components/StyledComponents/Icons.style";
import { Checkbox, FormControlLabel } from "@mui/material";

const LoginModal = (props) => {
  const [imageUrl, setImgURL] = React.useState(Logo);
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [currView, setCurrView] = useState("login");
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [authObj, setAuthObj] = useState({});
  const [searchParams] = useSearchParams();
  const signup = searchParams.get("signup");
  const [acceptTerms, setAcceptTerms] = useState(false);

  useEffect(() => {
    if (signup) {
      setCurrView("signup");
    }
  }, []);

  React.useEffect(() => {
    if (props.themeInfo[0]?.logourl) {
      setImgURL(props.themeInfo[0]?.logourl);
    } else {
      setImgURL(Logo);
    }
  }, [props.themeInfo]);
  const handleClose = () => {
    setCurrView("login");
    setAcceptTerms(false);
    props.toggleAuthModal(false);
    props.setverificationCodeInput(false);
  };

  const onSubmit = () => {
    if (!validPayload()) {
      return;
    }

    if (currView === "login") {
      props.verificationCodeInput ? verifyLogin() : callLogin();
    } else if (currView === "signup") {
      if (!acceptTerms) {
        toast.error("You must accept the terms and conditions to proceed.");
        return;
      }
      props.verificationCodeInput ? verifySignUp() : callSignUp();
    }
  };

  const callLogin = async () => {
    const { userName, password } = authObj;

    await props.login(userName, password);
  };

  const callSignUp = async () => {
    const { userName, password, firstName, lastName, phoneNo } = authObj;
    const signUpInfo = {
      username: userName,
      password,
      email: userName,
      firstName,
      lastName,
      mobileNo: phoneNo,
      acceptTerms,
    };
    await props.register(signUpInfo);
  };

  const verifySignUp = async () => {
    const { userName } = authObj;
    const signUpInfo = {
      username: userName,
      code: verificationToken,
    };
    const res = await props.verifyUser(signUpInfo);
    if (res) {
      setCurrView("login");
      setAcceptTerms(false);
    }
  };
  const verifyLogin = async () => {
    const { userName } = authObj;
    const signinInfo = {
      username: userName,
      code: verificationToken,
    };
    await props.verifyUser(signinInfo);
  };
  const [verificationToken, setVerificationToken] = useState("");

  const handleInputChange = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "").slice(0, 6); // Remove non-numeric characters
    setVerificationToken(inputValue);
  };
  const validPayload = () => {
    const { firstName, lastName, userName, password, phoneNo } = authObj;

    if (!validateEmail(userName)) {
      toast.error("Please enter a valid email");
      return false;
    } else if (!validatePassword(password)) {
      toast.error(
        "Password must be atleast 8 letter long with a small, capital, number and special characters"
      );
      return false;
    }
    if (currView === "signup") {
      if (!firstName || firstName?.length === 0) {
        toast.error("First name cannot be empty");
        return false;
      } else if (!lastName || lastName?.length === 0) {
        toast.error("Last name cannot be empty");
        return false;
      } else if (!validatePhone(phoneNo)) {
        toast.error("Please enter a valid phone number");
        return false;
      }
    }
    return true;
  };

  const handleChange = (key, val) => {
    const newAuthObj = {
      ...authObj,
      [key]: val,
    };
    setAuthObj(newAuthObj);
  };

  return (
    <div>
      <Dialog
        data-event-name="Login Modal - Clicked On Close"
        className={styles["auth-modal-container"]}
        classes={{
          root: styles["auth-modal-root"],
          paper: styles["auth-modal-paper"],
        }}
        fullScreen={fullScreen}
        open={props.openLoginModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent
          className={`${styles["auth-modal-body"]} ${styles[currView]}`}
        >
          <div className={styles["close-container"]} onClick={handleClose}>
            <StyledCloseIcon />
          </div>
          <div className={styles["header"]}>
            <img
              src={imageUrl}
              alt="Gritly"
              style={{ width: "40px", height: "40px" }}
            />
            <span className={styles["subtitle"]}>
              Student {currView === "login" ? "Login" : "Signup"}
            </span>
          </div>
          <div className={styles["content"]}>
            <FormControl variant="outlined" className={styles["form-control"]}>
              <InputLabel
                htmlFor="outlined-adornment-email"
                classes={{
                  root: styles["input-label"],
                }}
              >
                Email
              </InputLabel>
              <OutlinedInput
                type="email"
                id="outlined-adornment-email"
                className={styles["input-field"]}
                value={authObj.userName || ""}
                onChange={(e) =>
                  handleChange("userName", e.target.value.toLowerCase())
                }
                classes={{
                  root: styles["outline-root"],
                  notchedOutline: styles["notched-outline-root"],
                  input: styles["user-input-field"],
                }}
                placeholder={"Enter your Email"}
                label="Email"
              />
            </FormControl>
            {currView === "signup" && (
              <>
                <div className={styles["name-container"]}>
                  <FormControl
                    variant="outlined"
                    className={styles["form-control"]}
                  >
                    <InputLabel
                      htmlFor="outlined-adornment-email"
                      classes={{
                        root: styles["input-label"],
                      }}
                    >
                      First Name
                    </InputLabel>
                    <OutlinedInput
                      type="text"
                      id="outlined-adornment-email"
                      className={styles["input-field"]}
                      value={authObj.firstName || ""}
                      onChange={(e) =>
                        handleChange("firstName", e.target.value)
                      }
                      classes={{
                        root: styles["outline-root"],
                        notchedOutline: styles["notched-outline-root"],
                        input: styles["user-input-field"],
                      }}
                      placeholder={"Enter your First Name"}
                      label="First Name"
                    />
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    className={styles["form-control"]}
                  >
                    <InputLabel
                      htmlFor="outlined-adornment-email"
                      classes={{
                        root: styles["input-label"],
                      }}
                    >
                      Last Name
                    </InputLabel>
                    <OutlinedInput
                      type="text"
                      id="outlined-adornment-email"
                      className={styles["input-field"]}
                      value={authObj.lastName || ""}
                      onChange={(e) => handleChange("lastName", e.target.value)}
                      classes={{
                        root: styles["outline-root"],
                        notchedOutline: styles["notched-outline-root"],
                        input: styles["user-input-field"],
                      }}
                      placeholder={"Enter your Last Name"}
                      label="Last Name"
                    />
                  </FormControl>
                </div>
                <MuiPhoneNumber
                  className={styles["form-control"]}
                  name="phone"
                  data-cy="user-phone"
                  defaultCountry={"us"}
                  variant={"outlined"}
                  value={authObj.phoneNo || ""}
                  onChange={(val) => {
                    handleChange("phoneNo", val.replace(/[()\-\s]/g, ""));
                  }}
                />
              </>
            )}
            <FormControl variant="outlined" className={styles["form-control"]}>
              <InputLabel
                htmlFor="outlined-adornment-email"
                classes={{
                  root: styles["input-label"],
                }}
              >
                {" "}
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                className={styles["input-field"]}
                classes={{
                  root: styles["outline-root"],
                  notchedOutline: styles["notched-outline-root"],
                  input: styles["user-input-field"],
                }}
                placeholder={"Enter your password"}
                type={showPassword ? "text" : "password"}
                value={authObj.password || ""}
                onChange={(e) => handleChange("password", e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      className={styles["password-toggle-icon"]}
                      classes={{
                        root: styles["icon-button-root"],
                      }}
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <StyledVisibilityOff />
                      ) : (
                        <StyledVisibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            {props.verificationCodeInput === true && (
              <FormControl
                variant="outlined"
                className={styles["form-control"]}
              >
                <OutlinedInput
                  id="outlined-adornment-password"
                  className={styles["input-field"]}
                  classes={{
                    root: styles["outline-root"],
                    notchedOutline: styles["notched-outline-root"],
                    input: styles["user-input-field"],
                  }}
                  placeholder={"Enter your verification code"}
                  value={verificationToken}
                  onChange={handleInputChange}
                />
              </FormControl>
            )}
            {currView === "signup" && (
              <FormControlLabel
                className={styles["form-control"]}
                control={
                  <Checkbox
                    checked={acceptTerms}
                    onChange={(e) => setAcceptTerms(e.target.checked)}
                    name="acceptTerms"
                    classes={{
                      root: styles["option-root"],
                    }}
                  />
                }
                label={
                  <>
                    I accept the{" "}
                    <a
                      href="https://internalyzeed.github.io/Website1/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      terms and conditions
                    </a>
                  </>
                }
              />
            )}

            <div className={styles["links-container"]}>
              <span
                data-event-name="Login Modal - Switch To Org Login"
                onClick={() => {
                  props.setForgotPassModal(false);
                  props.toggleAuthModal(false);
                  props.setOrgLoginModal(true);
                  setCurrView("login");
                  setAcceptTerms(false);
                }}
                style={{
                  color: props.themeInfo[0]?.primarycolor
                    ? props.themeInfo[0]?.primarycolor
                    : "#0e1555",
                }}
                className={styles["org-login-link"]}
              >
                Org Login
              </span>
              <span
                data-event-name="Login Modal - Forgot Password"
                onClick={() => {
                  props.setForgotPassModal(true);
                  props.toggleAuthModal(false);
                }}
                className={styles["reset-pass-link"]}
                style={{
                  color: props.themeInfo[0]?.primarycolor
                    ? props.themeInfo[0]?.primarycolor
                    : "#0e1555",
                }}
              >
                Forgot password
              </span>
            </div>

            <FilledButton
              data-event-name={`Login Modal - ${
                currView === "login" ? "Continue" : "Register"
              }`}
              variant="contained"
              disabled={currView === "signup" && !acceptTerms}
              primary="true"
              style={{
                backgroundColor:
                  currView === "signup" && !acceptTerms
                    ? "transparent"
                    : props.themeInfo[0]?.primarycolor
                    ? props.themeInfo[0]?.primarycolor
                    : "#0e1555",
              }}
              fontFamily={"Nunito"}
              endIcon={
                <StyledArrowForwardIcon
                  classes={{
                    root: styles["arrow-icon"],
                  }}
                />
              }
              onClick={onSubmit}
            >
              {currView === "login" ? "Continue" : "Register"}
            </FilledButton>

            {!window.location.href.includes("asa.gritly.us") && (
              <>
                <span className={styles["breaking-label"]}>OR</span>
                <div className={styles["social-login-container"]}>
                  <div
                    data-event-name="Login Modal - Continue with Google"
                    className={styles["google-btn"]}
                    onClick={() => props.socialLogin()}
                  >
                    <img
                      className={styles["google-img-btn"]}
                      src={GoogleIcon}
                      alt="Gmail"
                    />
                    <span>Continue with Google</span>
                  </div>
                </div>
              </>
            )}

            <div className={styles["footer-container"]}>
              {currView === "login" ? (
                <span>
                  Don't have a Student account?{" "}
                  <span
                    data-event-name="Login Modal - Switch to Sign Up"
                    className={styles["auth-modal-toggle-btn"]}
                    onClick={() => setCurrView("signup")}
                    style={{ color: props.themeInfo[0]?.primarycolor }}
                  >
                    Signup
                  </span>
                </span>
              ) : (
                <span>
                  Already have an Student account?{" "}
                  <span
                    data-event-name="Login Modal - Switch to Login"
                    className={styles["auth-modal-toggle-btn"]}
                    onClick={() => {
                      setCurrView("login");
                      setAcceptTerms(false);
                    }}
                    style={{ color: props.themeInfo[0]?.primarycolor }}
                  >
                    Login
                  </span>
                </span>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = ({ app, auth, user }) => {
  return {
    openLoginModal: app.openLoginModal,
    userToken: auth.userToken,
    themeInfo: user.themeInfo,
    verificationCodeInput: app.verificationCodeInput,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    toggleAuthModal: (status) => dispatch(setAuthModal(status)),
    register: (signUpInfo) => dispatch(register(signUpInfo)),
    login: (email, pass) => dispatch(login(email, pass)),
    setForgotPassModal: (status) => dispatch(setForgotPassModal(status)),
    setOrgLoginModal: (status) => dispatch(setOrgLoginModal(status)),
    socialLogin: () => dispatch(socialLogin()),
    setverificationCodeInput: (status) =>
      dispatch(setVerificationCodeInput(status)),
    verifyUser: (signUpInfo) => dispatch(verifyUser(signUpInfo)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
