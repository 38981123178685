import { toast } from "react-hot-toast";
import API, { API_sql } from "../../Helpers/axiosInit";
import { getQueryParams } from "../../Helpers/utils";
import { logEvent } from "../../Utils/analytics";

export const setCurrentPage = (value) => {
    return {
        type: 'SET_CURRENT_PAGE',
        payload: {
            value
        }
    }
}

export const setAuthModal = (status) => {
    return {
        type: 'SET_AUTH_MODAL',
        payload: {
            status
        }
    }
}

export const setVerificationCodeInput = (status) => {
    return {
        type: 'SET_VERIFICATION_CODE_INPUT',
        payload: {
            status
        }
    }
}

export const setOrgLoginModal = (status) => {
    return {
        type: 'SET_ORG_LOGIN_MODAL',
        payload: {
            status
        }
    }
}

export const setForgotPassModal = (status) => {
    return {
        type: 'SET_FORGOT_PASS_MODAL',
        payload: {
            status
        }
    }
}

export const setAppLoading = (bool, msg) => {
    return {
        type: 'SET_APP_LOADING',
        payload: {
            bool,
            msg
        }
    }
}


export const setInitLoading = (bool) => {
    return {
        type: 'SET_INIT_LOADING',
        payload: {
            isLoading: bool
        }
    }
}

export const toggleNotification = (type) => async (dispatch) => {
    try {
        await API_sql.put("/userManagement/preferences", {
            "enableStudentEmailSubscription": type.studentSubscription,
            "enableSubscription": type.orgSubscription
        });
        toast.success(`🎉 Successfully updated the notification preferences`);
        dispatch(getNotificationPreferences());
    } catch {
        toast.error(`Failed to update the notification preferences`);
    }
}
export const getNotificationPreferences = () => async (dispatch) => {
    try {
        const res = await API_sql.get("/userManagement/preferences");
        dispatch({
            type: 'SET_NOTIFICATION_TOGGLE',
            payload: res.data
        });
    } catch (error) {
        toast.error(`Failed to fetch the notification preferences`);
    }
}

export const toggleStudentNotification = (value) => async (dispatch) => {
    try {
        await API_sql.put("/userManagement/preferences", {
            "enableStudentEmailSubscription": value.studentSubscription,
        });
        toast.success(`🎉 Successfully updated the notification preferences`);
        dispatch(getNotificationPreferences());
    } catch {
        toast.error(`Failed to update the notification preferences`);
    }
}

export const triggerASAPoints = () => async () => {
    const { user_id, unique_task_key, step_number } = getQueryParams();
    try {
        await API.post('/integration', {
            user_id, unique_task_key, step_number,
        });
        logEvent('ASA_Points_Trigger_Success', { path: window.location.pathname, user_id, unique_task_key, step_number })
        toast.success(`Congratulations! You have earned points for completing this task. 🎉 `);
    } catch {
        logEvent('ASA_Points_Trigger_Failed', { path: window.location.pathname, user_id, unique_task_key, step_number })
        toast.error(`Sorry, something went wrong. Please try again`);
    }
}

export const uploadImageToGCP = (file) => async () => {
    try {
        return await API.post(`/liveSession/uploadPhoto`, file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    } catch (err) {
        console.error("Failed to retrieve the image URL", err);
        toast.error("Failed to crop the image. Please try again. 😢");
    }

}