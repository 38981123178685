import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import styles from "../../../Stylesheets/Modules/Admin/Students/studentsList.module.scss";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { getAllCohortsStudent } from "../../../Store/Actions/actions.cohort";
import DownloadIcon from "@mui/icons-material/Download";
import { Badge, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ClearIcon from "@mui/icons-material/Clear";
import { intervalToDuration } from "date-fns";
import { logPageView } from "../../../Utils/analytics";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
const StudentsList = (props) => {
  const history = useNavigate();
  const { cohortId } = useParams();
  const [searchInput, setSearchInput] = useState("");
  const [searchParams] = useSearchParams();
  const [students, setStudents] = useState([]);
  const [page, setPage] = useState(1);
  //Set the limit to number of items to be displayed on table
  const [limit, setLimit] = useState(10);
  const { totalStudents } = props;
  const totalPages = totalStudents / limit;
  const tableHeaderContent = [
    {
      key: "slNo",
      label: "S.No",
      type: "Number",
    },
    {
      key: "name",
      label: "Name",
      type: "Text",
      minWidth: 150,
    },
    {
      key: "completedModules",
      label: "Completed Modules",
      type: "Text",
      minWidth: 150,
    },
    // {
    //   key: "totalTimeSpentOnContent",
    //   label: "Time Spent On Content",
    //   type: "Text",
    // },
    {
      key: "loginTime",
      label: "Login Time",
      type: "Text",
    },
    {
      key: "logoutTime",
      label: "Logout Time",
      type: "Text",
    },
    // {
    //   key: "timeSpentOnPlatform",
    //   label: "Time Spent On Platform",
    //   type: "Text",
    // },
    {
      key: "progress",
      label: "",
      type: "Button",
    },
  ];
  const searchHandler = (searchValue) => {
    setPage(1);
    setSearchInput(searchValue);
  };
  const goToProgressView = (studentId, name) => {
    history(
      `/admin/cohort/students/${cohortId}/progress/${studentId}?cohort=${searchParams.get(
        "cohort"
      )}&student=${name}`
    );
  };

  useEffect(() => {
    props.getAllCohortsStudent(cohortId, page, limit, searchInput);
  }, [searchInput, page, limit]);

  useEffect(() => {
    setStudents(props.cohortStudents);
    return () => {
      setStudents([]);
    };
  }, [props.cohortStudents]);

  const onImageError = () => {};

  const getImage = (url, name) => {
    if (url?.length > 0) {
      return (
        <img
          className={styles["user-image"]}
          src={url}
          alt={""}
          onError={onImageError}
          referrerPolicy="no-referrer"
        />
      );
    }
    let letters = name?.split(/\s/).map((w) => w[0]);
    const first = letters[0];
    const last = letters.length > 1 ? letters[letters.length - 1] : "";
    letters = [first, last].join("").toUpperCase();
    return <div className={styles["name-letters"]}>{letters}</div>;
  };
  const isPreviousButtonDisabled = () => {
    return page === 1;
  };
  const isNextButtonDisabled = () => {
    return page >= totalPages;
  };
  const handleLimit = (event) => {
    setLimit(event.target.value);
  };

  const formatTime = (seconds) => {
    const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
    const formattedTime = [];

    if (duration.days) {
      formattedTime.push(`${duration.days} days`);
    }

    if (duration.hours) {
      formattedTime.push(`${duration.hours} hrs`);
    }

    if (duration.minutes) {
      formattedTime.push(`${duration.minutes} mins`);
    }

    return formattedTime.join(" ");
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] View Students Page");
  }, [location]);

  return (
    <>
      <Container
        maxWidth="xl"
        classes={{
          root: styles["container-root"],
        }}
      >
        <div className={styles["header"]}>
          <div className={styles["students-header"]}>
            <Breadcrumbs
              aria-label="breadcrumb"
              style={{
                color: props.themeInfo[0]?.secondarycolor,
              }}
            >
              <Link underline="hover" color="inherit" href="/admin/cohorts">
                {props.customization.cohort_label || "Cohorts"}
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href={`/admin/cohortMenu/${cohortId}?cohort=${searchParams.get(
                  "cohort"
                )}`}
              >
                {searchParams.get("cohort") + " Overview"} {" Overview"}
              </Link>
              <Typography>
                <b>{"View Students"}</b>
              </Typography>
            </Breadcrumbs>
          </div>
          <div className={styles["search"]}>
            <Button
              className={styles["download-button"]}
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_API_ENDPOINT}/reporting/downloadcsv?cohortId=${cohortId}`
                );
              }}
            >
              <DownloadIcon />
              Format : Excel
            </Button>
            <TextField
              label="Search"
              value={searchInput}
              onChange={(e) => searchHandler(e.target.value)}
              InputProps={{
                endAdornment: searchInput && (
                  <ClearIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setSearchInput("");
                    }}
                  />
                ),
              }}
              classes={{
                root: styles["search-field-root"],
              }}
            />
          </div>
        </div>

        <div className={styles["filter-div"]}>
          <div className={styles["current-page"]}>
            Page{" "}
            <span className={styles["current-page-number"]}>
              {page ? page : 1} /{" "}
              {totalStudents ? Math.ceil(totalStudents / 10) : 1}
            </span>
          </div>

          <div className={styles["total-text"]}>Total {totalStudents}</div>
        </div>
        <TableContainer>
          <Table>
            <TableHead
              classes={{
                root: styles["applications-header-root"],
              }}
            >
              <TableRow
                classes={{
                  root: styles["header-row"],
                }}
              >
                {tableHeaderContent.map((item, index) => {
                  return (
                    <TableCell
                      classes={{
                        root: styles["header-cell"],
                      }}
                      key={index}
                    >
                      {item.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {students.map((row, inx) => {
                // Calculate the serial number based on the current page and items per page
                const serialNo = (page - 1) * 10 + inx + 1;
                return (
                  <TableRow key={inx}>
                    {tableHeaderContent.map((cellData, i) => {
                      if (cellData.key === "name") {
                        const name = `${row.firstName} ${row.lastName}`;
                        return (
                          <TableCell
                            classes={{
                              root: `${styles["body-cell"]} ${styles["name"]}`,
                            }}
                            key={cellData.key}
                            align={cellData.align}
                          >
                            {row.showBadge && (
                              <Badge
                                className={styles["section-badge"]}
                                color="error"
                                badgeContent={"New"}
                              />
                            )}
                            <div
                              className={styles["name-container"]}
                              style={{
                                color: props.themeInfo[0]?.primarycolor,
                              }}
                            >
                              <div className={styles["profile-img"]}>
                                {getImage(row?.profileImgUrl, name)}
                              </div>
                              <div className={styles["name-text"]}>{name}</div>
                            </div>
                          </TableCell>
                        );
                      } else if (cellData.key === "completedModules") {
                        return (
                          <TableCell
                            key={cellData.key}
                            classes={{
                              root: `${styles["body-cell"]}`,
                            }}
                          >
                            {row.completedModule + "/" + row.totalModules}
                          </TableCell>
                        );
                      } else if (cellData.key === "totalTimeSpentOnContent") {
                        return (
                          <TableCell
                            key={cellData.key}
                            classes={{
                              root: `${styles["body-cell"]}`,
                            }}
                          >
                            {formatTime(row.totalTimeSpentOnContent)}
                          </TableCell>
                        );
                      } else if (cellData.key === "loginTime") {
                        return (
                          <TableCell
                            key={cellData.key}
                            classes={{
                              root: `${styles["body-cell"]}`,
                            }}
                          >
                            {String(
                              new Date(row.lastLoginTime).toLocaleString()
                            ) !== "Invalid Date"
                              ? String(
                                  new Date(row.lastLoginTime).toLocaleString()
                                )
                              : "-"}
                          </TableCell>
                        );
                      } else if (cellData.key === "logoutTime") {
                        return (
                          <TableCell
                            key={cellData.key}
                            classes={{
                              root: `${styles["body-cell"]}`,
                            }}
                          >
                            {String(
                              new Date(row.lastLogoutTime).toLocaleString()
                            ) !== "Invalid Date"
                              ? String(
                                  new Date(row.lastLogoutTime).toLocaleString()
                                )
                              : "-"}
                          </TableCell>
                        );
                      } else if (cellData.key === "timeSpentOnPlatform") {
                        return (
                          <TableCell
                            key={cellData.key}
                            classes={{
                              root: `${styles["body-cell"]}`,
                            }}
                          >
                            {formatTime(row.timeSpentOnPlatform)}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell
                          key={i}
                          classes={{
                            root: `${styles["body-cell"]}`,
                          }}
                        >
                          {cellData.type === "Number" ? (
                            serialNo
                          ) : cellData.type === "Button" ? (
                            <Button
                              classes={{
                                root: styles["view-progress-button"],
                              }}
                              onClick={() => {
                                const name = `${row.firstName} ${row.lastName}`;
                                goToProgressView(row.studentId, name);
                              }}
                            >
                              View
                            </Button>
                          ) : cellData.type === "Object" ? (
                            cellData.key === "studentDetails" ? (
                              row[cellData.key].studentName
                            ) : (
                              row[cellData.key].completedModules +
                              "/" +
                              row[cellData.key].totalModules +
                              "\n" +
                              "Modules Completed"
                            )
                          ) : (
                            row[cellData.key]
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {!(limit >= totalStudents) && (
          <div className={styles["button-container"]}>
            <Button
              classes={{
                root: `${styles["previous-btn"]} ${
                  isPreviousButtonDisabled() ? styles["disabled"] : ""
                }`,
              }}
              disabled={isPreviousButtonDisabled()}
              onClick={() => setPage(page - 1)}
            >
              <ArrowBackIcon />
              Prev
            </Button>
            <Button
              classes={{
                root: `${styles["next-btn"]} ${
                  isNextButtonDisabled() ? styles["disabled"] : ""
                }`,
              }}
              disabled={isNextButtonDisabled()}
              onClick={() => {
                setPage(page + 1);
              }}
            >
              Next
              <ArrowForwardIcon />
            </Button>
          </div>
        )}
      </Container>
    </>
  );
};

const mapStateToProps = ({ app, cohort, user }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    cohortStudents: cohort.cohortStudents,
    themeInfo: user.themeInfo,
    totalStudents: cohort.totalStudents,
    customization: user.customization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCohortsStudent: (cohortId, page, limit, searchvalue) =>
      dispatch(getAllCohortsStudent(cohortId, page, limit, searchvalue)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentsList);
