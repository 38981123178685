import React, { useEffect, useState, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import "../Stylesheets/Components/editor.scss";
import styles from "../Stylesheets/Modules/editor.module.scss";
import {
  colorOptions,
  fontOptions,
  imgToolbarOptions,
  tableContantToolbarOptions,
  allToolbarHeaderOptions,
} from "../Utils/textEditor";
import { Dialog, Skeleton, Zoom, Typography, Button } from "@mui/material";
import API from "../Helpers/axiosInit";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import {
  StyledBorderColorOutlinedIcon,
  StyledVisibilityOutlinedIcon,
} from "./StyledComponents/Icons.style";

import { connect } from "react-redux";
const TextEditor = (props) => {
  // const API_URl = "https://noteyard-backend.herokuapp.com";
  // const UPLOAD_ENDPOINT = "api/blogs/uploadImg";
  const [readOnly, setReadOnly] = useState(props.readOnly || false);
  const [showPreview, setShowPreview] = useState(props.showPreview || false);
  const [textEditor, setTextEditor] = useState();
  const [openImg, setOpenImg] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const editorRef = useRef(null);
  const variants = ["h1", "h3", "body1", "caption"];

  // uplaod adapter custom plugin to store and get image urls
  const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            /**
             *  logic to store files for a period of 10min
                body.append("uploadImg", file);
                fetch(`${API_URl}/${UPLOAD_ENDPOINT}`, {
                  method: "post",
                  body: body,
                })
                  .then((res) => res.json())
                  .then((res) => {
                    resolve({ default: `${API_URl}/${res.url}` });
                    // resolve(file);
                  })
                  .catch((err) => {
                    reject(err);
                  });
             */

            body.append("inputFile", file);
            API.post(`/uploads`, body)
              .then((res) => {
                const fileId = res.data.fileId;
                resolve({
                  default: `${process.env.REACT_APP_API_ENDPOINT}/uploads/${fileId}`,
                });
                // resolve(file);
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  };

  // configuration object for setting the ckeditor
  const editorConfiguration = {
    toolbar: {
      items: [...allToolbarHeaderOptions],
    },
    mediaEmbed: {
      toolbar: [],
      previewsInData: true,
    },
    extraPlugins: [],
    language: "en",
    image: {
      toolbar: [...imgToolbarOptions],
    },
    table: {
      contentToolbar: [...tableContantToolbarOptions],
    },
    fontFamily: {
      supportAllValues: true,
    },
    fontSize: {
      options: [...fontOptions],
    },
    fontColor: {
      columns: 10,
      documentColors: 200,
      colors: [...colorOptions],
    },
    fontBackgroundColor: {
      columns: 10,
      documentColors: 200,
      colors: [...colorOptions],
    },
    removePlugins: ["MediaEmbedToolbar"],
  };

  useEffect(() => {
    if (textEditor) {
      // adding the custom uplaod plugin to the editor
      textEditor.plugins.get("FileRepository").createUploadAdapter = (
        loader
      ) => {
        return uploadAdapter(loader);
      };
    }
  }, [textEditor]);

  useEffect(() => {
    if (textEditor) {
      updateReadOnly(readOnly);
    }
  }, [readOnly, textEditor]);

  useEffect(() => {
    if (!props.isEditorLoading && editorRef?.current && readOnly) {
      const ele = editorRef.current;
      const clickCB = (e) => {
        if (e.target.tagName === "IMG") {
          setImgSrc(e.target.src);
          setOpenImg(true);
        }
      };
      ele.addEventListener("click", clickCB);
      return () => {
        ele.removeEventListener("click", clickCB);
      };
    }
  }, [props.isEditorLoading, readOnly]);

  const updateReadOnly = (val) => {
    if (val) {
      textEditor.enableReadOnlyMode("editor-view");
    } else {
      textEditor.disableReadOnlyMode("editor-view");
    }
    setReadOnly(val);
  };

  const handleClose = (e, reason) => {
    setOpenImg(false);
  };

  if (props.isEditorLoading) {
    return (
      <div>
        {[...variants, ...variants, ...variants].map((variant, idx) => (
          <Typography component="div" key={idx} variant={variant}>
            <Skeleton animation={"wave"} />
          </Typography>
        ))}
      </div>
    );
  }

  return (
    <div className={`text-editor ${readOnly && "read-only"} ${props.root}`}>
      {showPreview && (
        <div className="preview-action-container">
          {readOnly ? (
            <Button
              variant="contained"
              classes={{
                root: "preview-option-btn",
              }}
              startIcon={
                <StyledBorderColorOutlinedIcon
                  classes={{
                    root: "save-icon",
                  }}
                />
              }
              style={{
                color: props.themeInfo[0]?.primarycolor,
              }}
              onClick={() => updateReadOnly(!readOnly)}
            >
              Editor
            </Button>
          ) : (
            <Button
              variant="contained"
              classes={{
                root: "preview-option-btn",
              }}
              startIcon={
                <StyledVisibilityOutlinedIcon
                  classes={{
                    root: "save-icon",
                  }}
                />
              }
              onClick={() => updateReadOnly(!readOnly)}
              style={{
                color: props.themeInfo[0]?.primarycolor,
              }}
            >
              Preview
            </Button>
          )}
        </div>
      )}
      {!props.isEditorLoading && (
        <div ref={editorRef}>
          <CKEditor
            editor={Editor}
            config={
              !readOnly && {
                ...editorConfiguration,
              }
            }
            data={props.content}
            onReady={(editor) => {
              if (editor) {
                setTextEditor(editor);
                editor.on(
                  "change:isReadOnly",
                  (evt, propertyName, isReadOnly) => {
                    if (editor) {
                      const editableElement =
                        editor.editing.view.domRoots.get("main");
                      editableElement.querySelectorAll("a").forEach((a) => {
                        a.setAttribute("target", "_blank");
                      });
                      const toolbarElement = editor.ui.view.toolbar.element;
                      if (isReadOnly) {
                        toolbarElement.style.display = "none";
                      } else {
                        toolbarElement.style.display = "flex";
                      }
                    }
                  }
                );
              } else {
                props.setEditorLoading && props.setEditorLoading(true);
                setTimeout(() => {
                  props.setEditorLoading && props.setEditorLoading(false);
                }, 1000);
              }
            }}
            onError={(error, { willEditorRestart }) => {
              // If the editor is restarted, the toolbar element will be created once again.
              // The `onReady` callback will be called again and the new toolbar will be added.
              // This is why you need to remove the older toolbar.
              if (willEditorRestart) {
                textEditor && textEditor.ui.view.toolbar.element.remove();
              }
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              props.setContent && props.setContent(data);
            }}
            onBlur={(editor) => { }}
            onFocus={(editor) => { }}
          />
        </div>
      )}

      <Dialog
        className={styles["img-modal-container"]}
        classes={{
          root: styles["img-modal-root"],
          paper: styles["img-modal-paper"],
        }}
        fullScreen={true}
        open={openImg}
        onClose={handleClose}
        onBackdropClick={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Zoom
          in={openImg}
          style={{ transitionDelay: openImg ? "500ms" : "0ms" }}
        >
          <img className={styles["img-body"]} src={imgSrc} alt="" />
        </Zoom>
      </Dialog>
    </div>
  );
};

const mapStateToProps = ({ app, modules, user }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    sections: modules.sections,
    themeInfo: user.themeInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(TextEditor);
