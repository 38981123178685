import * as Types from '../StaticTypes'

const initialState = {
  allPrograms: []
};

export default function cohort(state = initialState, action) {
    switch (action.type) {
      case Types.SET_ALL_PROGRAMS:
        const { allPrograms } = action.payload
        return {
            ...state,
            allPrograms: allPrograms
        }
      default:
        return state
    }
  }