import React, { useState } from "react";
import { connect } from "react-redux";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { setSkillsUserData } from "../../../../../Store/Actions/actions.user";

import styles from "../../../../../Stylesheets/Modules/Admin/Students/studentProfile/studentProfile.module.scss";

function ModalSkills({ userId, skills, setSkillsData, handleClose }) {
  const [value, setValue] = useState(skills || []);

  const handleSubmit = () => {
    setSkillsData(value, userId);
    handleClose();
  };
  return (
    <div className={styles["skills-wrapper"]}>
      <Box className={styles["modal-skills-heading"]}>
        <Box>Skills</Box>
        <div className={styles["modal-heading-close"]} onClick={handleClose}>
          <CloseIcon />
        </div>
      </Box>

      <div className={styles["skills-search-text"]}>Search</div>
      <Autocomplete
        multiple
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        id="skills-selection"
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            className={styles["skills-auto-box"]}
            label="Ex. ReactJs"
            placeholder="Add Skills"
          />
        )}
      />
      <br />

      <Box className={styles["skills-content-box"]}>
        {value.map((skill, index) => (
          <p className={styles["skills-content"]} key={index}>
            {skill}
          </p>
        ))}
      </Box>

      <Box className={styles["modal-skills-add-box"]}>
        <Button
          className={styles["modal-skills-add"]}
          variant="contained"
          onClick={handleSubmit}
        >
          Add
        </Button>
      </Box>
    </div>
  );
}

const mapStateToProps = ({ user }) => ({
  userId: user.userDetails.id,
  skills: user.userProfile?.skills,
});

const mapDispatchToProps = (dispatch) => ({
  setSkillsData: (data, userId) => dispatch(setSkillsUserData(data, userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalSkills);

const options = [
  "ReactJS",
  "Nodejs",
  "python",
  "C++",
  "java",
  "Analytical and problem solving",
  "Microsoft Excel",
  "Enterprise resource planning software",
  "Business and leadership",
  "Verbal and writing skills",
  "Data analytics",
  "Revenue recognition",
  "Risk and compliance",

  "Data entry",
  "Attentive listening, empathy",
  "Troubleshooting and research",
  "Patience",
  "Speed and efficiency",
  "Positive attitude",
  "Diplomacy",
  "Communication skills",
  "Time management",

  "Coding languages",
  "Troubleshooting and testing skills",
  "Operating systems",
  "Database software",
  "UX and UI design",
  "Project management",
  "Web frameworks",
  "API design",
  "Teamwork",
];
