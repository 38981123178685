import React, { useEffect, useState } from "react";
import styles from "../Stylesheets/Modules/application.module.scss";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import { setAuthModal } from "../Store/Actions/actions.app";
import { connect } from "react-redux";
import {
  getApplicationInfo,
  getCohortInfo,
  submitApplication,
} from "../Store/Actions/actions.cohort";
import CohortBackNav from "../Components/CohortBackNav";
import { logPageView } from "../Utils/analytics";
import { handleActionCompletionASA } from "../Helpers/app";
import { getQueryParams } from "../Helpers/utils";

const CohortApplication = (props) => {
  const [answers, setAnswers] = useState({});
  // const [checked, setChecked] = useState(false); // TODO: Disabled for now
  const [searchParams] = useSearchParams();
  const { cohortId } = useParams();
  const navigate = useNavigate();

  const {
    applicationInfo,
    isAuthenticated,
    getApplicationInfo,
    submitApplication,
  } = props;

  useEffect(() => {
    if (isAuthenticated) {
      getApplicationInfo(cohortId);
    } else {
      navigate("/");
    }
  }, [isAuthenticated]);

  const handleChange = (id, val) => {
    setAnswers({
      ...answers,
      [id]: {
        questionId: id,
        answer: val,
      },
    });
  };

  useEffect(() => {
    enableSubmit(answers);
  }, [answers, applicationInfo]);

  const enableSubmit = () => {
    let enableSubmit = true;
    if (Object.keys(answers).length !== applicationInfo?.questions?.length) {
      enableSubmit = false;
    } else {
      const ans = Object.values(answers);
      for (let i = 0; i < ans.length; i++) {
        if (ans[i].answer === "") {
          enableSubmit = false;
          break;
        }
      }
    }
    // return enableSubmit && checked; // disabled this for now
    return enableSubmit;
  };

  const apply = async () => {
    const application = {
      cohortId: cohortId,
      studentId: props.userInfo.id,
      questionnaireId: applicationInfo._id,
      questions: Object.values(answers),
    };
    let res = await submitApplication(application);
    if (res?.success) {
      const { user_id, unique_task_key, step_number } = getQueryParams();
      if (!user_id || !unique_task_key || !step_number) {
        navigate(`/cohort/${cohortId}`);
      } else {
        handleActionCompletionASA();
        navigate(`/cohort/${cohortId}`);
      }
    }
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("[Student] Cohort Application Page");
  }, [location]);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }

  if (!applicationInfo) {
    return;
  }

  return (
    <div className={styles["cohort-application-container"]}>
      <CohortBackNav
        data-event-name={`[Student] Clicked On Back to ${searchParams.get(
          "cohort"
        )} Cohort Curriculum`}
        link={`/cohort/${cohortId}`}
        text={`Back to ${searchParams.get("cohort")} Cohort Curriculum`}
      />
      <div className={styles["questions-main"]}>
        <div className={styles["header"]}>{applicationInfo?.title}</div>
        <div className={styles["questions-container"]}>
          {applicationInfo?.questions?.map((question, qIdx) => {
            if (question.questionType === "CHECKBOX") {
              return (
                <div className={styles["question"]} key={question._id}>
                  <div className={styles["title"]}>
                    <span className={styles["prefix"]}>{`Q${qIdx + 1})`}</span>
                    <span>{question.question}</span>
                  </div>
                  <div className={styles["select-options"]}>
                    {question?.values.map((option, idx) => {
                      return (
                        <FormControlLabel
                          classes={{
                            root: styles["label-field-root"],
                          }}
                          key={idx}
                          control={
                            <Checkbox
                              classes={{
                                root: styles["option-root"],
                              }}
                              checked={
                                answers[question._id]?.answer
                                  ? answers[question._id]?.answer.indexOf(
                                      option
                                    ) !== -1
                                  : false
                              }
                              name={option}
                              onChange={() => {
                                let exist = answers[question._id]?.answer || [];
                                let newVal = [...exist];
                                let newOptionIdx = newVal.indexOf(option);
                                if (newOptionIdx !== -1) {
                                  newVal.splice(newOptionIdx, 1);
                                } else {
                                  newVal.push(option);
                                }
                                handleChange(question._id, newVal);
                              }}
                            />
                          }
                          label={option}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            } else if (question.questionType === "RADIO") {
              return (
                <div className={styles["question"]} key={question._id}>
                  <div className={styles["title"]}>
                    <span className={styles["prefix"]}>{`Q${qIdx + 1})`}</span>
                    <span>{question.question}</span>
                  </div>
                  <div className={styles["radio-options"]}>
                    {question.values.map((option, idx) => {
                      return (
                        <FormControlLabel
                          classes={{
                            root: styles["label-field-root"],
                          }}
                          key={idx}
                          control={
                            <Radio
                              classes={{
                                root: styles["radio-root"],
                              }}
                              checked={
                                answers[question._id]?.answer
                                  ? answers[question._id]?.answer === option
                                  : false
                              }
                              name={option}
                              onChange={() => {
                                handleChange(question._id, option);
                              }}
                            />
                          }
                          label={option}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            } else if (question.questionType === "TEXT") {
              return (
                <div className={styles["question"]} key={question._id}>
                  <div className={styles["title"]}>
                    <div className={styles["prefix"]}>{`Q${qIdx + 1})`}</div>
                    <div>{question.question}</div>
                  </div>
                  <div className={styles["input"]}>
                    <OutlinedInput
                      type="text"
                      id="outlined-adornment-email"
                      multiline
                      maxRows={3}
                      classes={{
                        root: styles["outline-root"],
                        notchedOutline: styles["notched-outline-root"],
                        input: styles["user-input-field"],
                      }}
                      value={answers[question._id]?.answer || ""}
                      onChange={(e) => {
                        handleChange(question._id, e.target.value);
                      }}
                    />
                  </div>
                </div>
              );
            }
          })}
        </div>

        {/* TODO: Disabled for now */}
        {/* <div className={styles["optin-msg-container"]}>
          <Checkbox
            checked={checked}
            classes={{
              root: styles["checkbox-root"],
            }}
            onChange={(e) => setChecked(e.target.checked)}
            inputProps={{ "aria-label": "controlled" }}
          />
          <span>Opt-in to receive emails and sms messages</span>
        </div> */}
      </div>
      <div className={styles["action-container"]}>
        <Button
          data-event-name="[Student] Clicked On Submit Button In Cohort Application Page"
          classes={{
            root: enableSubmit()
              ? styles["button-root"]
              : styles["button-root-disabled"],
          }}
          disabled={!enableSubmit()}
          variant="contained"
          onClick={() => {
            apply();
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth, cohort, user, app }) => {
  return {
    applicationInfo: cohort.applicationInfo,
    isAuthenticated: auth.isAuthenticated,
    cohortInfo: cohort.cohortInfo,
    userInfo: user.userDetails,
    appLoadingStatus: app.appLoadingStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleAuthModal: (status) => dispatch(setAuthModal(status)),
    getCohortInfo: (cohortId) => dispatch(getCohortInfo(cohortId)),
    getApplicationInfo: (cohortId) => dispatch(getApplicationInfo(cohortId)),
    submitApplication: (application) =>
      dispatch(submitApplication(application)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CohortApplication);
