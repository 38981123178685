import React, { useState } from "react";
import { connect } from "react-redux";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import Pie from "../../../Reusable/Pie";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import couseContentLogo from "../../../Assets/Images/courseContentLogo.png";
import liveSessionsLogo from "../../../Assets/Images/liveSessionsLogo.png";
import assignmentLogo from "../../../Assets/Images/assignmentsLogo.png";
import styles from "../../../Stylesheets/Modules/Admin/Students/viewStudentProgress.module.scss";
import { getStudentCohortModuleProgress } from "../../../Store/Actions/actions.cohort";
import { getStudentModuleQuestionContent } from "../../../Store/Actions/actions.modules";
import { Badge, Typography } from "@mui/material";
import { useEffect } from "react";
import { StyledArrowBackIcon } from "../../../Components/StyledComponents/Icons.style";
import { logPageView } from "../../../Utils/analytics";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

export const ViewStudentProgress = (props) => {
  const history = useNavigate();
  const { cohortId, studentId } = useParams();
  const [searchParams] = useSearchParams();

  const [modules, setModules] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [selectedModule, setModule] = useState();
  const [studentProgress, setStudentProgress] = useState();
  const {
    getStudentModuleQuestionContent,
    getStudentCohortModuleProgress,
    studentCohortModuleProgress,
  } = props;
  React.useEffect(() => {
    if (searchParams.get("moduleId")) {
      setModule(searchParams.get("moduleId"));
    }
  }, []);

  const getStudentProgress = async (cohortId, studentId) => {
    await getStudentCohortModuleProgress(cohortId, studentId);
  };

  React.useEffect(() => {
    getStudentProgress(cohortId, studentId);
  }, []);

  //see if there are changes in allmodules and call the setmodules
  React.useEffect(() => {
    setModules(studentCohortModuleProgress);
  }, [studentCohortModuleProgress]);

  const isModuleSelected = (moduleId) => {
    if (studentProgress) return selectedModule === moduleId;
    else return false;
  };

  const getModuleQuestions = async (moduleId) => {
    setModule(moduleId);
    const questions = await getStudentModuleQuestionContent(
      studentId,
      cohortId,
      moduleId
    );
    if (questions) {
      setQuestions(questions);
    }
  };

  useEffect(() => {
    if (selectedModule) {
      getModuleQuestions(selectedModule);
    }
  }, [selectedModule]);
  const getStudentModuleProgressDetails = (moduleId) => {
    let obj = studentCohortModuleProgress.find(
      (item) => item.moduleId === moduleId
    );
    setStudentProgress(obj);
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] Student Progress Page");
  }, [location]);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }

  return (
    <>
      <div className={styles["student-progress-menu-header"]}>
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{
            color: props.themeInfo[0]?.secondarycolor,
          }}
        >
          <Link underline="hover" color="inherit" href="/admin/cohorts">
            {props.customization.cohort_label || "Cohorts"}
          </Link>
          <Link
            underline="hover"
            color="inherit"
            href={`/admin/cohortMenu/${cohortId}?cohort=${searchParams.get(
              "cohort"
            )}`}
          >
            {searchParams.get("cohort") + " Overview"} {" Overview"}
          </Link>
          <Link
            underline="hover"
            color="inherit"
            href={`/admin/cohort/students/${cohortId}?cohort=${searchParams.get(
              "cohort"
            )}`}
          >
            {"View Students"}
          </Link>
          <Typography>
            {" "}
            <b>{searchParams.get("student")}</b>
          </Typography>
        </Breadcrumbs>
      </div>
      <div className={styles["student-progress-tiles-main-container"]}>
        <div className={styles["left-side-modules"]}>
          {modules.length > 0 &&
            modules.map((module, idx) => {
              return (
                <Card
                  classes={{
                    root: `${styles["student-progress-tiles-wrapper"]} ${
                      isModuleSelected(module.moduleId)
                        ? styles["selected"]
                        : ""
                    }`,
                  }}
                  key={idx}
                  onClick={() => {
                    getModuleQuestions(module.moduleId);
                    history(
                      `/admin/cohort/students/${cohortId}/progress/${studentId}?cohort=${searchParams.get(
                        "cohort"
                      )}&moduleId=${module.moduleId}&student=${searchParams.get(
                        "student"
                      )}`
                    );
                    getStudentModuleProgressDetails(module.moduleId);
                  }}
                >
                  <CardContent
                    classes={{
                      root: styles["student-progress-tiles-content"],
                    }}
                  >
                    {module.showBadge && (
                      <Badge
                        className={styles["section-badge"]}
                        color="error"
                        badgeContent={"New"}
                      />
                    )}
                    <div className={styles["module-index"]}>M{idx + 1}</div>
                    <div className={styles["student-progress-tile-title"]}>
                      <span className={styles["module-title"]}>
                        {module.name}
                      </span>
                    </div>
                  </CardContent>
                </Card>
              );
            })}
        </div>
        <div className={styles["right-side-information"]}>
          <div>
            {selectedModule && studentProgress ? (
              <div className={styles["module-progress-wrapper"]}>
                {studentProgress.moduleSubSections.map((item, key) => {
                  return (
                    <div
                      className={styles["module-options-tiles"]}
                      key={key}
                      onClick={() => {
                        item.key === "moduleQuestions" &&
                          history(
                            `/admin/cohort/students/${cohortId}/${searchParams.get(
                              "moduleId"
                            )}/progress/${studentId}/questionnaires?cohort=${searchParams.get(
                              "cohort"
                            )}&moduleId=${searchParams.get(
                              "moduleId"
                            )}&student=${searchParams.get("student")}`
                          );
                        item.key === "courseContent" &&
                          history(
                            `/admin/cohort/students/${cohortId}/${searchParams.get(
                              "moduleId"
                            )}/progress/${studentId}/courseContent?cohort=${searchParams.get(
                              "cohort"
                            )}&moduleId=${searchParams.get(
                              "moduleId"
                            )}&student=${searchParams.get("student")}`
                          );
                      }}
                    >
                      <div className={styles["left-side-wrapper"]}>
                        <div className={styles["images"]}>
                          {item.key === "courseContent" && (
                            <img src={couseContentLogo} alt="courseContent" />
                          )}
                          {item.key === "liveSessions" && (
                            <img src={liveSessionsLogo} alt="liveSessions" />
                          )}
                          {item.key === "moduleQuestions" && (
                            <img src={assignmentLogo} alt="assignment" />
                          )}
                          {item.showBadge && (
                            <Badge
                              className={styles["section-badge"]}
                              color="error"
                              badgeContent={"New"}
                            />
                          )}
                        </div>
                        <div className={styles["label"]}>{item.label}</div>
                      </div>
                      {item.key !== "liveSessions" && (
                        <div className={styles["right-side-wrapper"]}>
                          <div className={styles["circular-wrapper"]}>
                            {item.subSectionCompleted === 0 ||
                            item.subSectionTotal === 0 ? (
                              <Pie
                                percentage={0}
                                colour="#03A20A"
                                className={styles["cicle"]}
                                numerator={item.subSectionCompleted}
                                denominator={item.subSectionTotal}
                              />
                            ) : (
                              <Pie
                                percentage={
                                  (item.subSectionCompleted /
                                    item.subSectionTotal) *
                                  100
                                }
                                colour="#03A20A"
                                className={styles["cicle"]}
                                numerator={item.subSectionCompleted}
                                denominator={item.subSectionTotal}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className={styles["select-helper-text"]}>
                Select a module to view progresss
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ app, modules, user, cohort }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
    studentCohortModuleProgress: cohort.studentCohortModuleProgress,
    customization: user.customization,
    themeInfo: user.themeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStudentModuleQuestionContent: (cohortId, studentId, moduleId) =>
      dispatch(getStudentModuleQuestionContent(cohortId, studentId, moduleId)),
    getStudentCohortModuleProgress: (cohortId, studentId) =>
      dispatch(getStudentCohortModuleProgress(cohortId, studentId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewStudentProgress);
