import React, { useEffect } from "react";
import { connect } from "react-redux";
import styles from "../../../Stylesheets/Modules/Admin/Students/studentSettings.module.scss";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { setForgotPassModal } from "../../../Store/Actions/actions.app";
import { logout } from "../../../Store/Actions/actions.auth";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import helpBanner from "../../../Assets/Images/help-banner.png";
import { useNavigate, useLocation } from "react-router-dom";
import HubspotForm from "react-hubspot-form";
import { logPageView } from "../../../Utils/analytics";
import NotificationSettings from "../../NotificationSettings";

const menuOptions = [
  {
    key: "profile",
    label: "Profile",
    active: true,
    route: "studentSettings/profile",
  },
  {
    key: "account",
    label: "Account",
    active: false,
    route: "",
  },
  {
    key: "notification",
    label: "Notifications",
    active: true,
    route: "",
  },
  {
    key: "password",
    label: "Reset Password",
    active: true,
    route: "",
  },
  {
    key: "help",
    label: " Need Help?",
    active: true,
    route: "",
  },
  {
    key: "logout",
    label: "Logout",
    active: true,
    route: "",
  },
];
export const StudentSettings = (props) => {
  const [menuSelected, setMenuSelected] = React.useState("");
  const [toggle, updateToggle] = React.useState(false);
  const [modalFor, setModalFor] = React.useState("");

  const navigate = useNavigate();

  const logoutHandler = () => {
    props.logout();
  };
  const menuHandler = (item) => {
    setMenuSelected(item.key);
    switch (item.key) {
      case "password":
        props.setForgotPassModal(true);
        break;
      case "logout":
        updateToggle(true);
        setModalFor("logout");
        break;
      case "profile":
        navigate(`/${item.route}`);
        break;
      case "notification":
        updateToggle(true);
        setModalFor("notification");
      default:
        break;
    }
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("[Student] Settings Page");
  }, [location]);

  return (
    <div className={styles["main-div"]}>
      <div
        className={styles["header"]}
        style={{
          background:
            props.themeInfo[0]?.primarycolor !== "#0e1555"
              ? props.themeInfo[0]?.primarycolor
              : "",
        }}
      >
        <div className={styles["setting-header"]}>Settings</div>
      </div>
      <div className={styles["settings-wrapper"]}>
        <div className={styles["menu-options"]}>
          {menuOptions.map((item) => {
            return (
              <div key={item.key}>
                {" "}
                {item.active && (
                  <div
                    key={item.key}
                    className={styles["options"]}
                    onClick={() => menuHandler(item)}
                    style={{
                      color:
                        props.themeInfo[0]?.primarycolor !== "#0e1555"
                          ? props.themeInfo[0]?.primarycolor
                          : "",
                    }}
                  >
                    <div className={styles["label"]}>{item.label}</div>
                    <ArrowForwardIosIcon
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className={styles["menu-display"]}>
          {menuSelected === "help" && (
            <div className={styles["help-form-wrapper"]}>
              <div className={styles["menu-header"]}>Need Help ?</div>
              <div className={styles["menu-sub-header"]}>
                We will get back to you within 24 hours
              </div>
              <div className={styles["form-wrapper"]}>
                <div className={styles["help-banner"]}>
                  <img
                    src={helpBanner}
                    alt="banner"
                    className={styles["banner"]}
                  />
                </div>
                <div className={styles["hubspot-form"]}>
                  <HubspotForm
                    portalId="20124119"
                    formId="0ac9e534-437a-4858-adb5-5e3db9ff29bb"
                    onSubmit={() => console.log("Submit!")}
                    onReady={(form) => console.log("Form ready!")}
                    loading={<div>Loading...</div>}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <Modal
          open={toggle}
          sx={{ color: "white" }}
          onClose={() => updateToggle(false)}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              borderRadius: "9px",
              p: 4,
            }}
          >
            {modalFor === "logout" && (
              <div className={styles["modal-wrapper"]}>
                <div className={styles["modal-header"]}>Logout</div>
                <div className={styles["modal-sub-header"]}>
                  Are you sure you want logout?
                </div>
                <div className={styles["button-wrapper"]}>
                  <Button
                    className={styles["cancel-btn"]}
                    onClick={() => updateToggle(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={styles["save-btn"]}
                    onClick={logoutHandler}
                  >
                    Logout
                  </Button>
                </div>
              </div>
            )}
            {modalFor === "notification" && (
              <NotificationSettings student={true} />
            )}
          </Box>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    themeInfo: user.themeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setForgotPassModal: (status) => dispatch(setForgotPassModal(status)),
    logout: () => dispatch(logout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StudentSettings);
