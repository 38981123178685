import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import ProfileBanner from "../StudentProfile/ProfileBanner";
import StudentPersonalDetails from "./StudentPersonalDetails";
import StudentSkills from "./StudentSkills";
import StudentProfessionalDetails from "./StudentProfessionalDetails";
import StudentEducationDetails from "./StudentEducationDetails";
import { logPageView } from "../../../../Utils/analytics";
import { connect } from "react-redux";
import styles from "../../../../Stylesheets/Modules/Admin/Students/studentProfile/studentProfile.module.scss";
import { getLearnerProfileDetails } from "../../../../Store/Actions/actions.user";
import StudentPrompts from "./StudentPrompts";
import { Button } from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-hot-toast";
import StudentCertificates from "./StudentCertificates";
import StudentBadges from "./StudentBadges";
import API from "../../../../Helpers/axiosInit";

function StudentProfile(props) {
  const location = useLocation();
  const hiddenProfileRef = useRef(null);
  const [preview, setPreview] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    logPageView("[Student] Profile Page");
  }, [location]);

  useEffect(() => {
    props.getLearnerProfileDetails(props.userUId);
  }, []);

  const convertBlobToDataURL = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const preloadImagesAndReplaceSrc = async () => {
    const images = document.querySelectorAll("img");
    for (const img of images) {
      if (img.src && !img.src.startsWith("data:")) {
        try {
          const proxiedUrl = `${
            process.env.PUBLIC_URL
          }/certificates/download?url=${encodeURIComponent(img.src)}`;
          const response = await API.get(proxiedUrl, {
            responseType: "blob",
          });

          // Check if the response is successful
          if (response.status !== 200) {
            throw new Error(`Cannot fetch image: ${response.statusText}`);
          }

          const dataURL = await convertBlobToDataURL(response.data);
          img.src = dataURL;
        } catch (error) {
          console.error(
            "Failed to preload image and convert to Data URL",
            error
          );
        }
      }
    }
  };

  const exportToPDF = async () => {
    setPreview(true);

    // Wait for the next render after setting the state
    await new Promise((resolve) => setTimeout(resolve, 0));

    try {
      await preloadImagesAndReplaceSrc();
      const input = hiddenProfileRef.current;

      // Adjust the height of the element to match its content
      const contentHeight = input.scrollHeight;
      const contentWidth = input.scrollWidth;

      // Convert content dimensions to a4 page ratio
      const a4Width = 210; // A4 width in mm
      const a4Height = 297; // A4 height in mm
      const ratio = Math.min(a4Width / contentWidth, a4Height / contentHeight);

      // Set canvas width and height to match content size
      const canvas = await html2canvas(input, {
        width: contentWidth,
        height: contentHeight,
        scale: 2,
      });

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: contentWidth > contentHeight ? "landscape" : "portrait",
        unit: "mm",
        format: [canvas.width * ratio, canvas.height * ratio],
      });

      pdf.addImage(
        imgData,
        "PNG",
        0,
        0,
        canvas.width * ratio,
        canvas.height * ratio
      );
      const blob = pdf.output("blob");
      const pdfUrl = URL.createObjectURL(blob);
      window.open(pdfUrl, "_blank");

      setPreview(false);
    } catch (error) {
      console.error("Error exporting to PDF:", error);
      toast.error("Failed to export profile to PDF.");
      setPreview(false);
    }
  };

  const copyTexttoClickBoard = async () => {
    await navigator.clipboard.writeText(
      `${window.location.href}/${props.userUId}`
    );
    toast.success("Copied link to clipboard");
  };
  return (
    <div ref={hiddenProfileRef}>
      <Box className={styles["profile-page"]}>
        <ProfileBanner preview={preview} />
        {!preview && (
          <div className={styles["export-section"]}>
            <Button
              data-event-name="[Student] Clicked On Export Profile"
              className={styles["export-button"]}
              onClick={handleClick}
            >
              <IosShareIcon /> Export Profile
            </Button>
          </div>
        )}
        <Box className={styles["student-profile-body"]}>
          <StudentPrompts preview={preview} />
          <StudentPersonalDetails preview={preview} />
          <StudentSkills preview={preview} />
          <StudentCertificates />
          <StudentBadges />
          <StudentProfessionalDetails preview={preview} />
          <StudentEducationDetails preview={preview} />
        </Box>
      </Box>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          data-event-name="[Student] Clicked On Generate Shareable Profile Link"
          onClick={() => {
            handleClose();
            copyTexttoClickBoard();
          }}
        >
          Create Shareable Link
        </MenuItem>
        <MenuItem
          data-event-name="[Student] Clicked On Export Profile As PDF"
          onClick={() => {
            handleClose();
            exportToPDF();
          }}
        >
          Export to PDF
        </MenuItem>
      </Menu>
    </div>
  );
}

const mapStateToProps = ({ user }) => ({
  userUId: user.userUid,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getLearnerProfileDetails: (userUId) =>
      dispatch(getLearnerProfileDetails(userUId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentProfile);
