import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { getModuleCourseContent } from "../../../Store/Actions/actions.modules";
import {
  StyledArrowBackIcon,
  StyledCheckCircleIcon,
} from "../../../Components/StyledComponents/Icons.style";
import styles from "../../../Stylesheets/Modules/Admin/Students/viewStudentProgress.module.scss";
import { logPageView } from "../../../Utils/analytics";

export const ContentProgress = (props) => {
  const history = useNavigate();
  const { cohortId, studentId, moduleId } = useParams();
  const [searchParams] = useSearchParams();
  const [content, setContent] = useState([]);
  const { getModuleCourseContent } = props;

  useEffect(() => {
    getCourseContent(cohortId, studentId, moduleId);
  }, []);

  const getCourseContent = async (cohortId, studentId, moduleId) => {
    const content = await getModuleCourseContent(cohortId, studentId, moduleId);
    if (content) {
      setContent(content.sections);
    }
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("[Admin] Content Progress Page");
  }, [location]);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }

  return (
    <>
      <div className={styles["student-progress-menu-header"]}>
        <StyledArrowBackIcon
          sx={{ cursor: "pointer", marginRight: "0.625rem" }}
          onClick={() => {
            history(
              `/admin/cohort/students/${cohortId}/progress/${studentId}?cohort=${searchParams.get(
                "cohort"
              )}&moduleId=${searchParams.get(
                "moduleId"
              )}&student=${searchParams.get("student")}`
            );
          }}
        />{" "}
        {searchParams.get("student")}
      </div>
      <div className={styles["list-wrapper"]}>
        {content?.map((section, key) => {
          return (
            <div className={styles["parent-wrap"]} key={key}>
              <div className={styles["title"]}>
                {section.title}
                {section.isCompleted && (
                  <div className={styles["status"]}>
                    <StyledCheckCircleIcon className={styles["check-circle"]} />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const mapStateToProps = ({ app }) => {
  return {
    appLoadingStatus: app.appLoadingStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getModuleCourseContent: (cohortId, studentId, moduleId) =>
      dispatch(getModuleCourseContent(cohortId, studentId, moduleId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentProgress);
