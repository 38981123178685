import {
  FormControl,
  FormLabel,
  InputBase,
  OutlinedInput,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TextEditor from "../Components/TextEditor";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import styles from "../Stylesheets/Modules/TitleDescription.module.scss";

export default function TitleDescriptionComp(props) {
  const {
    className,
    content,
    contentHelper,
    setContent,
    hasRichText,
    titleHelper,
    hasTitle,
    title,
    setTitle,
  } = props;
  const [isEditorLoading, setEditorLoading] = useState(true);
  useEffect(() => {
    // this is to re-render and get ckeditor as it is not rendering in initial load
    setEditorLoading(false);
  }, []);

  return (
    <div className={styles["content-wrapper"]}>
      <div
        className={`${styles["title-desc-wrapper"]} ${styles[`${className}`]}`}
      >
        {hasTitle && (
          <>
            <FormControl variant="outlined" className={styles["form-control"]}>
              {/* <FormLabel className={styles["title-label"]}>Title</FormLabel> */}
              <OutlinedInput
                type="email"
                className={styles["input-field"]}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                classes={{
                  root: styles["title-root"],
                  notchedOutline: styles["notched-outline-root"],
                  input: styles["user-input-field"],
                }}
                placeholder={titleHelper}
              />
            </FormControl>
          </>
        )}
        {hasRichText ? (
          <TextEditor
            readOnly={false}
            content={content}
            setContent={setContent}
            isEditorLoading={isEditorLoading}
            setEditorLoading={setEditorLoading}
          />
        ) : (
          <FormControl
            variant="outlined"
            className={styles["form-control-multiline"]}
          >
            <OutlinedInput
              type="text"
              className={styles["input-field"]}
              value={content}
              multiline
              maxRows={4}
              inputProps={{
                maxLength: 255,
              }}
              onChange={(e) => setContent(e.target.value)}
              classes={{
                root: styles["desc-root"],
                notchedOutline: styles["notched-outline-root"],
                input: styles["user-input-field"],
              }}
              endAdornment={
                <InputAdornment
                  position="end"
                  className={styles["char-length"]}
                >
                  <Typography variant="caption">
                    {content.length}/{255}
                  </Typography>
                </InputAdornment>
              }
              placeholder={contentHelper}
            />
          </FormControl>
        )}
      </div>
    </div>
  );
}
