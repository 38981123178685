import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-hot-toast";

const ImageUploader = ({
  customButton,
  acceptedFormats,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  supportedExtensions,
  onDrop,
}) => {
  const handleDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        // Check if the file format is allowed
        const fileFormat = file.type;
        if (!acceptedFormats.includes(fileFormat)) {
          toast.error("Invalid file format. Please select a valid image file.");
          return;
        }

        // Check if the image dimensions meet the minimum requirements
        const image = new Image();
        image.src = URL.createObjectURL(file);

        image.onload = () => {
          // const width = image.width;
          // const height = image.height;

          // if (width < minWidth || height < minHeight) {
          //   toast.error(
          //     `Image dimensions must be at least ${minWidth}x${minHeight} pixels.`
          //   );
          //   return;
          // }

          // if (width > maxWidth || height > maxHeight) {
          //   toast.error(
          //     `Image dimensions should not exceed ${maxWidth}x${maxHeight} pixels.`
          //   );
          //   return;
          // }

          // If all checks pass, call the onDrop function
          if (typeof onDrop === "function") {
            onDrop(acceptedFiles);
          }
        };
      }
    },
    [onDrop, acceptedFormats, minWidth, minHeight, maxWidth, maxHeight]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: {
      "image/*": supportedExtensions,
    },
    maxFiles: 1,
  });

  return (
    <div>
      <div {...getRootProps()}>
        {customButton}
        <input {...getInputProps()} />
      </div>
    </div>
  );
};

export default ImageUploader;
