import React, { useState, useEffect, useRef } from "react";
import styles from "../Stylesheets/Modules/cohort.module.scss";
import Button from "@mui/material/Button";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Modal from "@mui/material/Modal";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { setAuthModal } from "../Store/Actions/actions.app";
import {
  getCohortInfo,
  setCohortInfo,
  getCohortAdditionalDetails,
  updateCohortAdditionalDetails,
  getdtaDocumentLink,
  uploadDtaDocumentLink,
  getApplicationInfo,
  submitApplication,
} from "../Store/Actions/actions.cohort";
import { getTheme } from "../Store/Actions/actions.user";
import EditorWrapper from "../Components/EditorWrapper";
import { StyledCloseIcon } from "../Components/StyledComponents/Icons.style";
import {
  StyledCheckCircleIcon,
  StyledLanguageIcon,
  StyledRemoveRedEyeIcon,
} from "../Components/StyledComponents/Icons.style";
import { Box } from "@mui/system";
import { toast } from "react-hot-toast";
import axios from "axios";
import dayjs from "dayjs";
import "dayjs/locale/en";
import { logPageView } from "../Utils/analytics";
import { getQueryParams } from "../Helpers/utils";
import { handleActionCompletionASA } from "../Helpers/app";
import videojs from "video.js";
import "video.js/dist/video-js.css";

// Set locale to English
dayjs.locale("en");

const Cohort = (props) => {
  const { cohortId } = useParams();
  const navigate = useNavigate();
  // TODO: need to integrate with API
  const {
    title,
    overview,
    level,
    courseDetails,
    language,
    startDate,
    endDate,
    cohortVideoUrl,
    cohortVideoName,
  } = props.cohortInfo;
  const { courseContent } = courseDetails || {};
  const additionalDetails = props.additionalDetails;
  const onBoardingData = props.onBoardingData;
  const [content, setContent] = useState("");
  const [duration, setDuration] = useState("Unknown");
  const date = new Date(props.cohortInfo?.startDate || startDate);
  const month = date.toLocaleString("default", { month: "short" });
  const cohortStartDate = `${date.getDate()} ${month} ${date.getFullYear()}`;
  const [isEditorLoading, setEditorLoading] = useState(true);
  const [status, setStatus] = useState();
  const [hasAccess, setAccess] = useState(false);
  const [hasApplication, setApplicationStatus] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = useState();
  const [fileURL, setFileURL] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setFile(undefined);
    setOpen(false);
  };
  const [modelContent, setModelContent] = useState("");
  const [interviewScheduled, setInterviewScheduled] = useState(false);
  const videoJsPlayerRef = useRef(null);

  const fetchImage = (event) => {
    setFile(event.target.files[0]);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setFileURL(reader.result);
    });
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    const url = `${process.env.REACT_APP_API_ENDPOINT}/uploads/`;
    const formData = new FormData();
    formData.append("inputFile", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios.post(url, formData, config);
    if (fileURL === "") {
      toast.error("Document Not Uploaded");
    } else {
      props.uploadDtaDocumentLink(cohortId, props.userInfo.id, fileURL);
      handleClose();
    }
  };

  const handleClick = () => {
    document.getElementById("avatar").click();
  };
  const cohortInfoSection = [
    {
      title: "Start Date",
      image: require("../Assets/Images/calendar.png"),
      value: cohortStartDate,
      active: !!props.cohortInfo.startDate,
    },
    {
      title: "Duration",
      image: require("../Assets/Images/duration.png"),
      value: duration,
      active: props.cohortInfo.startDate && props.cohortInfo.endDate,
    },
    {
      title: "Level",
      image: require("../Assets/Images/rank.png"),
      value: level,
      active: true,
    },
  ];

  useEffect(() => {
    getCohortsDetails(cohortId);
    getApplicationData(cohortId);
  }, []);

  useEffect(() => {
    cohortAdditionalDetails(cohortId);
  }, []);
  useEffect(() => {
    getStudentCohortdtaDocumentLink(cohortId, props.userInfo.id);
  }, []);
  useEffect(() => {
    if (props.cohortInfo) {
      setDuration(calculateDuration(startDate, endDate));
      setContent(courseContent);
      const { applicationStatus, hasAccess } = props.cohortInfo.access || {};
      const status = applicationStatus;
      setStatus(status);
      setAccess(hasAccess);
      //props.getTheme(props.cohortInfo.organization);
      if (props.cohortInfo?.applicationProcess?.questionnaire) {
        setApplicationStatus(true);
      } else {
        setApplicationStatus(false);
      }
    }
  }, [props.cohortInfo]);

  useEffect(() => {
    // this is to re-render and get ckeditor as it is not rendering in initial load
    setTimeout(() => {
      setEditorLoading(false);
    }, 1000);
  }, []);

  const apply = async () => {
    const application = {
      cohortId: cohortId,
      studentId: props.userInfo.id,
      questionnaireId: props.applicationInfo._id,
      questions: Object.values({}),
    };
    let res = await props.submitApplication(application);
    if (res?.success) {
      const { user_id, unique_task_key, step_number } = getQueryParams();
      if (!user_id || !unique_task_key || !step_number) {
        navigate(`/cohort/${cohortId}`);
      } else {
        handleActionCompletionASA();
        navigate(`/cohort/${cohortId}`);
      }
      window.location.reload();
    }
  };

  useEffect(() => {
    let player;

    // Function to initialize Video.js
    const initializePlayer = () => {
      if (videoJsPlayerRef.current) {
        player = videojs(videoJsPlayerRef.current, {
          sources: [{ src: cohortVideoUrl, type: "video/mp4" }],
          controlBar: {
            playToggle: true, // Play/Pause button
            currentTimeDisplay: true,
            timeDivider: true,
            durationDisplay: true,
            progressControl: true, // Seek bar
            remainingTimeDisplay: true,
            volumePanel: true, // Volume control
            fullscreenToggle: false, // Fullscreen button
          },
        });

        // Disable right-click on the video player
        const videoElement = player.el();
        videoElement.addEventListener("contextmenu", (event) => {
          event.preventDefault();
        });
      }
    };

    if (cohortVideoUrl && !cohortVideoUrl.includes("vimeo")) {
      // Ensure the player initializes after the component has mounted
      setTimeout(() => {
        initializePlayer();
      }, 100);
    }

    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, [videoJsPlayerRef, cohortVideoUrl]);

  const getCohortsDetails = async (cohortId) => {
    await props.getCohortInfo(cohortId);
  };

  const getApplicationData = async (cohortId) => {
    await props.getApplicationInfo(cohortId);
  };

  const getStudentCohortdtaDocumentLink = async (cohortId, studentId) => {
    await props.getdtaDocumentLink(cohortId, studentId);
  };
  const cohortAdditionalDetails = async (cohortId) => {
    await props.getCohortAdditionalDetails(cohortId);
  };
  const calculateDuration = (d1, d2) => {
    const startDate = dayjs(d1);
    const endDate = dayjs(d2);

    // Get the difference between the two dates in days
    const duration = endDate.diff(startDate, "day");

    // Calculate the number of years, months, and days in the duration
    const years = Math.floor(duration / 365);
    const months = Math.floor((duration % 365) / 30);
    const days = duration % 30;

    // Build the formatted duration string
    let formattedDuration = "";
    if (years > 0) {
      formattedDuration += `${years} year${years === 1 ? "" : "s"} `;
    }
    if (months > 0) {
      formattedDuration += `${months} month${months === 1 ? "" : "s"} `;
    }
    if (days > 0) {
      formattedDuration += `${days} day${days === 1 ? "" : "s"}`;
    }

    return formattedDuration.trim();
  };

  const location = useLocation();

  useEffect(() => {
    logPageView("[Student] Cohort Page");
  }, [location]);

  if (props.appLoadingStatus) {
    return <div className="gif-loader" />;
  }
  return (
    <div className={styles["cohort-container"]}>
      <div
        className={styles["cohort-header-container"]}
        style={{
          background:
            props.themeInfo[0].primarycolor !== "#0e1555"
              ? props.themeInfo[0].primarycolor
              : "",
        }}
      >
        <div className={styles["left"]}>
          <div className={styles["main"]}>
            <span className={styles["title"]}>{title}</span>
            <span className={styles["sub"]}>{overview}</span>
          </div>
          <div className={styles["sub-section"]}>
            {cohortInfoSection.map((info, idx) => {
              return (
                info.active && (
                  <div
                    className={styles["sub-section-item-container"]}
                    key={idx}
                  >
                    <div className={styles["sub-section-item-image"]}>
                      <img src={info.image} alt={info.title} />
                    </div>
                    <div className={styles["sub-section-item-content"]}>
                      <div className={styles["title"]}>{info.title}</div>
                      <div className={styles["value"]}>{info.value}</div>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>
        <div
          className={`${styles["right"]} ${
            cohortVideoUrl && styles["box-shadow"]
          }`}
        >
          {cohortVideoUrl?.includes("vimeo") ? (
            <iframe
              className={styles["intro-video"]}
              src={`${cohortVideoUrl}&amp;title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=256295`}
              width="100%"
              height="70%"
              frameBorder="0"
              allowFullScreen
              title={cohortVideoName}
            />
          ) : (
            cohortVideoUrl && (
              <div className={styles["vp-center"]}>
                <video
                  ref={videoJsPlayerRef}
                  className={`${styles["intro-video"]} video-js `}
                  controls
                >
                  <source src={cohortVideoUrl} type="video/mp4" />
                </video>
              </div>
            )
          )}

          {/* <div className={styles["footer"]}>
            <div className={styles["cohort-lang"]}>
              <StyledLanguageIcon
                classes={{
                  root: styles["icon"],
                }}
              />
              <span className={styles["body"]}>{language}</span>
            </div>
          </div> */}
          <div className={styles["button-container"]}>
            {status === "Applied" && (
              <span
                className={`${styles["status-container"]} ${styles["applied"]}`}
              >
                {!additionalDetails?.dtaMandatory &&
                  !additionalDetails?.videoInterviewMandatory && (
                    <>
                      <StyledCheckCircleIcon />
                      <span className={styles["text"]}>Applied</span>{" "}
                    </>
                  )}
                {additionalDetails?.dtaMandatory &&
                  onBoardingData?.dtaDocumentLink !== "" &&
                  !additionalDetails?.videoInterviewMandatory && (
                    <>
                      <StyledCheckCircleIcon />
                      <span className={styles["text"]}>Applied</span>{" "}
                    </>
                  )}
                {!additionalDetails?.dtaMandatory &&
                  additionalDetails?.videoInterviewMandatory &&
                  interviewScheduled && (
                    <>
                      <StyledCheckCircleIcon />
                      <span className={styles["text"]}>Applied</span>{" "}
                    </>
                  )}
                {additionalDetails?.dtaMandatory &&
                  onBoardingData?.dtaDocumentLink !== "" &&
                  additionalDetails?.videoInterviewMandatory &&
                  additionalDetails?.videoInterviewLink !== "" &&
                  interviewScheduled && (
                    <>
                      <StyledCheckCircleIcon />
                      <span className={styles["text"]}>Applied</span>{" "}
                    </>
                  )}
                {additionalDetails?.dtaMandatory &&
                  onBoardingData?.dtaDocumentLink === "" && (
                    <div
                      className={styles["upload-section"]}
                      data-event-name="[Student] Clicked On Upload Document"
                      onClick={() => {
                        handleOpen();
                        setModelContent("documentUpload");
                      }}
                    >
                      Upload Document
                    </div>
                  )}

                {additionalDetails?.videoInterviewMandatory &&
                  additionalDetails?.videoInterviewLink !== "" &&
                  !interviewScheduled && (
                    <div className={styles["schedule-interview-section"]}>
                      <Button
                        data-event-name="[Student] Clicked On Schedule Interview"
                        className={styles["schedule-interview"]}
                        onClick={() => {
                          handleOpen();
                          setModelContent("scheduleInterview");
                          window.open(
                            "https://" + additionalDetails?.videoInterviewLink,
                            "_blank"
                          );
                        }}
                      >
                        Schedule Interview
                      </Button>
                    </div>
                  )}
              </span>
            )}
            {status === "InReview" && (
              <span
                className={`${styles["status-container"]} ${styles["in-review"]}`}
              >
                <StyledRemoveRedEyeIcon />
                <span className={styles["text"]}>In Review</span>
              </span>
            )}
            {status === "Rejected" && (
              <span
                className={`${styles["status-container"]} ${styles["rejected"]}`}
              >
                <StyledCloseIcon />
                <span className={styles["text"]}>Rejected</span>
              </span>
            )}
            {status === "Accepted" && hasAccess && (
              <Button
                data-event-name="[Student] Clicked On Continue in Cohort Page"
                classes={{
                  root: styles["button-root"],
                }}
                variant="contained"
                onClick={() => {
                  if (!props.isAuthenticated) {
                    props.toggleAuthModal(true);
                  } else {
                    const { user_id, unique_task_key, step_number } =
                      getQueryParams();
                    if (!user_id || !unique_task_key || !step_number) {
                      navigate(
                        `/cohort/${cohortId}/curriculum?cohort=${title}`
                      );
                    } else {
                      handleActionCompletionASA();
                    }
                  }
                }}
              >
                Continue
              </Button>
            )}
            {!status && (
              <Button
                data-event-name="[Student] Clicked On Submit Application"
                classes={{
                  root: hasApplication
                    ? styles["button-root"]
                    : styles["button-root-disabled"],
                }}
                variant="contained"
                disabled={!hasApplication}
                onClick={() => {
                  if (!hasApplication) {
                    return;
                  }
                  if (!props.isAuthenticated) {
                    props.toggleAuthModal(true);
                  } else if (!props.applicationInfo?.questions?.length) {
                    apply();
                  } else {
                    const { user_id, unique_task_key, step_number } =
                      getQueryParams();
                    const queryParams = `user_id=${user_id}&unique_task_key=${unique_task_key}&step_number=${step_number}`;
                    if (!user_id || !unique_task_key || !step_number) {
                      navigate(
                        `/cohort/${props.cohortInfo._id}/apply?cohort=${title}`
                      );
                    } else {
                      navigate(
                        `/cohort/${props.cohortInfo._id}/apply?cohort=${title}&${queryParams}`
                      );
                    }
                  }
                }}
              >
                Submit Application
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className={styles["content-container"]}>
        <div className={styles["course-details"]}>
          <EditorWrapper
            root="course-details-wrapper"
            childRoot="course-details-content-wrapper"
            readOnly={true}
            content={content}
            setContent={setContent}
          />
        </div>
      </div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "20%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            backgroundColor: "white",
            boxShadow: 24,
            borderRadius: "9px",
            p: 4,
          }}
        >
          {modelContent === "documentUpload" && (
            <div className={styles["modal-wrapper"]}>
              <div className={styles["header-section"]}>
                <div className={styles["header"]}>Upload Document</div>
                <StyledCloseIcon
                  onClick={handleClose}
                  sx={{ cursor: "pointer" }}
                />
              </div>
              <div className={styles["file-upload-section"]}>
                {file === undefined ? (
                  <div
                    data-event-name="[Student] Clicked On Upload Document File Upload"
                    className={styles["file-upload"]}
                    onClick={handleClick}
                  >
                    <DescriptionOutlinedIcon sx={{ color: " #299EF3" }} />
                    <div className={styles["label"]}>
                      Click or drag file to this area to change image
                    </div>
                    <input
                      type="file"
                      id="avatar"
                      name="avatar"
                      accept="application/pdf"
                      hidden
                      onChange={fetchImage}
                    />
                  </div>
                ) : (
                  <div className={styles["label"]}>{file?.name}</div>
                )}
              </div>
              <div className={styles["submit-section"]}>
                <Button
                  data-event-name="[Student] Clicked On Submit Uploaded Document"
                  className={styles["button"]}
                  onClick={handleUpload}
                >
                  Ok
                </Button>
              </div>
            </div>
          )}
          {modelContent === "scheduleInterview" && (
            <div className={styles["modal-wrapper2"]}>
              <div className={styles["schedule-interview-section"]}>
                <div className={styles["label"]}>
                  Have you scheduled Interview ?
                </div>
                <div className={styles["submit-section"]}>
                  <Button
                    data-event-name="[Student] Clicked On Scheduled The Interview - Yes"
                    className={styles["button"]}
                    onClick={() => {
                      setInterviewScheduled(true);
                      handleClose();
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    data-event-name="[Student] Clicked On Scheduled The Interview - No"
                    className={styles["button"]}
                    onClick={() => {
                      setInterviewScheduled(false);
                      handleClose();
                    }}
                  >
                    No
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ auth, cohort, user, app }) => {
  return {
    isAuthenticated: auth.isAuthenticated,
    cohortInfo: cohort.cohortInfo,
    userInfo: user.userDetails,
    themeInfo: user.themeInfo,
    userToken: auth.userToken,
    appLoadingStatus: app.appLoadingStatus,
    additionalDetails: cohort.additionalDetails,
    onBoardingData: cohort.onBoardingData,
    applicationInfo: cohort.applicationInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleAuthModal: (status) => dispatch(setAuthModal(status)),
    setCohortInfo: (cohort) => dispatch(setCohortInfo(cohort)),
    getCohortInfo: (cohortId) => dispatch(getCohortInfo(cohortId)),
    getTheme: (hostname) => dispatch(getTheme(hostname)),
    getCohortAdditionalDetails: (cohortId) =>
      dispatch(getCohortAdditionalDetails(cohortId)),
    updateCohortAdditionalDetails: (cohortId, payload) => {
      dispatch(updateCohortAdditionalDetails(cohortId, payload));
    },
    getdtaDocumentLink: (cohortId, studentId) => {
      dispatch(getdtaDocumentLink(cohortId, studentId));
    },
    uploadDtaDocumentLink: (cohortId, studentId, dtaDocumentLink) => {
      dispatch(uploadDtaDocumentLink(cohortId, studentId, dtaDocumentLink));
    },
    getApplicationInfo: (cohortId) => dispatch(getApplicationInfo(cohortId)),
    submitApplication: (application) =>
      dispatch(submitApplication(application)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Cohort);
