import { applyMiddleware, compose, createStore } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from "redux-thunk";

import monitorReducersEnhancer from "../Middleware/monitorReducerEnhancer";
import loggerMiddleware from "../Middleware/logger";
import rootReducer from "./rootReducer";

const configureStore = (preloadedState) => {
  const middlewares = [thunkMiddleware];
  if (process.env.NODE_ENV === "development") {
    middlewares.push(loggerMiddleware);
  }
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const enhancers = [middlewareEnhancer];
  if (process.env.NODE_ENV === "development") {
    enhancers.push(monitorReducersEnhancer);
  }
  const composedEnhancers = process.env.NODE_ENV === "development" ? composeWithDevTools(...enhancers) : compose(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("./rootReducer.js", () =>
      store.replaceReducer(rootReducer)
    );
  }

  return store;
};

const store = configureStore();

export default store;
