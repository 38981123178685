import React, { createContext } from "react";
import { useState } from "react";
export const ThemeContext = createContext({
  primarycolorcontext: "#0e1555",
  undefined,
});
export const ThemeProvider = ({ children }) => {
  const [primarycolorcontext, setprimarycolorcontext] = useState("#0e1555");
  return (
    <ThemeContext.Provider
      value={{ primarycolorcontext, setprimarycolorcontext }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
