import { Auth } from "aws-amplify";
import { setToken, setTokenExpiration } from "../Store/Actions/actions.auth";
import store from "../Store/store";
import { setInitLoading } from "../Store/Actions/actions.app";
import {
  setCustomizationInfo,
  setUserInfo,
} from "../Store/Actions/actions.user";
import { AuthEvents } from "../Utils/events";

export const clearAutomaticTokenRefresh = (clearAutomaticRefresh) => {
  window.clearTimeout(clearAutomaticRefresh);
  store.dispatch(setTokenExpiration(undefined));
};

export const clearToken = () => async () => {
  try {
    await Auth.signOut({ global: true }).finally(() => {
      clearStorage();
    });
  } catch (error) {
    console.error("error inside clearToken", error);
    store.dispatch(setInitLoading(false));
  }
};

export const refreshToken = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const session = user.getSignInUserSession();
    const refreshToken = session.getRefreshToken();

    if (!refreshToken) {
      throw new Error("Refresh token not available");
    }

    const refreshedSession = await Auth.currentSession(); // Use currentSession to refresh tokens
    const {
      accessToken,
      refreshToken: newRefreshToken,
      idToken,
    } = refreshedSession;
    const { exp } = accessToken.payload;
    const { "cognito:username": username } = idToken.payload;
    window.localStorage.setItem("accessTokenExpiry", new Date(exp * 1000));
    store.dispatch(
      setToken(
        accessToken.getJwtToken(),
        newRefreshToken.getToken(),
        username,
        new Date(exp * 1000)
      )
    );
  } catch (error) {
    console.error(error);
    window.localStorage.clear();
    window.sessionStorage.clear();
    store.dispatch(setToken(null, null, null, null));
    store.dispatch(setUserInfo(null));
    store.dispatch(setCustomizationInfo(null));
    window.localStorage.setItem(AuthEvents.LOGOUT, new Date().toISOString());
    window.location.href = "/";
    store.dispatch(setInitLoading(false));
  }
};

export const isAuthenticated = () => {
  const auth = store.getState().auth;
  return !!auth.accessToken;
};

export const triggerTokenExpiration = (expirationDate) => {
  if (expirationDate && !isNaN(expirationDate.valueOf())) {
    const now = new Date();
    const triggerAfterMs = expirationDate.getTime() - now.getTime();
    let clearAutomaticRefreshRef = window.setTimeout(() => {
      console.info("automatic token refresh triggered");
      refreshToken();
    }, triggerAfterMs);
    return () => {
      window.clearTimeout(clearAutomaticRefreshRef.current);
    };
  }
};

//clear all Storage
export const clearStorage = () => {
  store.dispatch(setToken(null, null, null, null));
  store.dispatch(setUserInfo(null));
  store.dispatch(setCustomizationInfo(null));
  window.localStorage.clear();
  window.sessionStorage.clear();
  window.localStorage.setItem(AuthEvents.LOGOUT, new Date().toISOString());
  window.location.href = "/";
};
